import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";

import useDefer from "../../../../modules/hooks/useDefer";

import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import { Doughnut, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Filler,
    Tooltip,
    Legend
} from "chart.js";
import { scaleSqrt } from "d3-scale";
import { useSelector } from "react-redux";

import * as backendModule from "../../../../modules/backendModule";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import { chartBackgroundColorSets, chartColorSets } from "../../../../modules/miscModule";
import { getParamsFromURLObject } from "../../../../modules/urlModule";

import Spinner from "../../../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import FilterByDate from "../../../../components/filters/FilterByDate";

ChartJS.register(
    ArcElement, Tooltip, Legend,
    CategoryScale, LinearScale, PointElement,
    PointElement, LineElement, BarElement,
    Title, Filler
);

let curTimeout = null;
const RealtimeReport = () => {
    const [display, setDisplay] = React.useState("pc");
    const [realtimeVisits, setRealtimeVisits] = React.useState(null);
    const [data, setData] = React.useState();
    const [visitsOverTime, setVisitsOverTime] = React.useState();
    const [mapZoomFactor, setMapZoomFactor] = React.useState(1);
    const [dateFilter, setDateFilter] = React.useState();
    const [progress, setProgress] = React.useState({cur: 0, max: 300, loading: true});

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const realtimeVisitsDefer = useDefer();
    const timestampRef = React.useRef();

    const doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
                legend: {
                    position: "top"
                },
                tooltip: {
                callbacks: {
                    label: (context) =>
                    `${context.label}: ${context.raw} visits`
                }
            }
        }
    };
    const lineChartOptions = {
        ...doughnutChartOptions, plugins: {...doughnutChartOptions.plugins, legend: false},
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return Math.floor(value);
                    },
                    stepSize: 1
                }
            }
        }
    };

    const getProgressPercent = () => {
        if (progress.cur === 0) return 0;
        return Number(progress.cur / progress.max * 100).toFixed(0);
    };

    const getRealtime = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                TableHeaders: ["Realtime"],
                filters: [
                    {name: "createdAt", op: "pdgeq", value: moment().startOf("day")}
                ],
                allUsers: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setRealtimeVisits({value: res.data.data?.TableData?.Realtime ?? 0, ts: Date.now()})
            } else {
                setRealtimeVisits({value: "?", ts: Date.now()});
            };
        }).catch(() => {
            setRealtimeVisits({value: "?", ts: Date.now()}); 
        });
    };

    const getVisitStats = async (ts) => {
        setProgress(p => {
            return {...p, loading: true};
        });
        await new Promise(r => setTimeout(() => r(), 1000));
        if (timestampRef.current !== ts) return;

        let filters = [];
        if (dateFilter) {
            filters.push({name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()});
            filters.push({name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()});
        };

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getDashboardStats`,
            data: {
                allUsers: true,
                filters: [
                    ...filters
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                utcOffset: (new Date()).getTimezoneOffset(),
                TableHeaders: [
                    "Visits",
                    "Date_day",
                    "Date_month",
                    "Date_year",
                    "Date_hour"
                ],
                trackGroupByDate: true,
                filters: [
                    ...filters
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            if (res.data.status === "ok") {
                let out = [];
                for (let item of res.data.data.TableData?.["Visits"] ?? []) {
                    let date = moment(`${item.Date_year}-${item.Date_month}-${item.Date_day} ${item.Date_hour}`, "YYYY-MM-DD HH");
                    out.push({Date: date.format("YYYY-MM-DD HH"), Visits: item.Value});
                };

                out = out.sort((a, b) => {
                    let a1 = moment(a.Date, "YYYY-MM-DD HH");
                    let b1 = moment(b.Date, "YYYY-MM-DD HH");
                    return a1.isAfter(b1) ? 1 : -1;
                });
                if (out.length > 1) {
                    let firstDate = out[0].Date;
                    let lastDate = out.find(o => o.Date.split(" ")[0] !== firstDate.split(" ")[0])?.Date;
                    if (!lastDate) lastDate = out[1].Date;

                    if (firstDate.split(" ")[0] === lastDate.split(" ")[0]) {
                        for (let item of out) {
                            item.Date = item.Date.split(" ")[1];
                        };
                    } else {
                        for (let item of out) {
                            item.Date = item.Date.split(" ")[0];
                        };
                    };
                };

                // group the data
                let outTmp = {};
                for (let item of out) {
                    if (!outTmp[item.Date]) outTmp[item.Date] = 0;
                    outTmp[item.Date] += item.Visits;
                };
                outTmp = Object.keys(outTmp).map(key => {return {Date: key, Visits: outTmp[key]}});

                setVisitsOverTime({status: "ok", data: outTmp});
            } else {
                setVisitsOverTime(res.data);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setVisitsOverTime(backendModule.genericError);
        });

        if (timestampRef.current !== ts) return;
        setProgress(p => {
            return {...p, cur: 0, loading: false};
        });
    };

    const calculatePercentage = (smaller, bigger) => {
        smaller = Number(smaller);
        bigger = Number(bigger);
        if (isNaN(smaller) || isNaN(bigger)) return "0%";
        if (!smaller) return "0%";
        if (!bigger) return "0%";

        return `${Number(smaller / bigger * 100).toFixed(0)}%`;
    };

    const convertTimeToString = time => {
        time = Number(time);
        if (isNaN(time)) return "-";
        time = Number(time.toFixed(0));

        let seconds = +time;
        let minutes = 0;
        let hours = 0;

        while (seconds >= 60) {
            seconds -= 60;
            minutes++;
        };
        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        };

        if (hours) return `${hours}h : ${minutes}m : ${seconds}s`;
        if (minutes) return `${minutes}m : ${seconds}s`;
        return `${seconds}s`;
    };

    const getIntegrationChartData = () => {
        let labels = [];
        let datasetData = [];

        for (let key of Object.keys(data.data.UniqueVisitsByIntegrationType)) {
            let foundType = supportedIntegrationsSelector.find(si => si.Type === +key);
            labels.push(foundType ? foundType.Name : "?");
            datasetData.push(data.data.UniqueVisitsByIntegrationType[key] ?? 0);
        };
        return {
            labels: labels,
            datasets: [{
                data: datasetData,
                backgroundColor: chartBackgroundColorSets.slice(0, datasetData.length),
                borderWidth: 1
            }]
        };
    };

    const getDeviceChartData = () => {
        let labels = [];
        let datasetData = [];

        for (let key of Object.keys(data.data.UniqueVisitsByDevice)) {
            labels.push(key);
            datasetData.push(data.data.UniqueVisitsByDevice[key] ?? 0);
        };
        return {
            labels: labels,
            datasets: [{
                data: datasetData,
                backgroundColor: chartBackgroundColorSets.slice(0, datasetData.length),
                borderWidth: 1
            }]
        };
    };

    const getOverTimeChartData = () => {
        let labels = [];
        let datasetData = [];

        for (let item of visitsOverTime.data) {
            labels.push(item.Date);
            datasetData.push(item.Visits);
        };
        return {
            labels: labels,
            datasets: [{
                data: datasetData,
                borderWidth: 1,
                backgroundColor: chartBackgroundColorSets[0],
                borderColor: chartColorSets[0],
                fill: true
            }]
        };
    };

    const sizeScale = React.useCallback((...args) => {
        if (data?.status !== "ok") return 0;
    
        // Adjust range dynamically based on zoom factor
        const minSize = 10 / mapZoomFactor; // Make dots smaller as zoom increases
        const maxSize = 20 / mapZoomFactor;
    
        return scaleSqrt()
            .domain([1, Math.max(...data.data.RealtimeVisitsByCountryCity.map((d) => d.Visits))])
            .range([minSize, maxSize])(...args);
    }, [data, mapZoomFactor]);

    React.useEffect(() => {
        if (!realtimeVisits) return getRealtime();
        realtimeVisitsDefer(getRealtime, 30000);

        return () => realtimeVisitsDefer(() => null, 0);
    }, [realtimeVisits]);

    React.useEffect(() => {
        if (!dateFilter) return;
        let ts = Date.now();
        timestampRef.current = ts;
        getVisitStats(ts);
    }, [dateFilter]);

    React.useEffect(() => {
        clearTimeout(curTimeout);

        if (progress.loading) return;
        if (progress.cur >= progress.max) {
            let ts = Date.now();
            timestampRef.current = ts;
            getVisitStats(ts);
            return;
        };

        curTimeout = setTimeout(() => setProgress(p => {return {...p, cur: p.cur + 1}}), 1000);
        return () => clearTimeout(curTimeout);
    }, [progress]);

    React.useLayoutEffect(() => {
        const sidebar = document.querySelector(".component__sidebar");
        if (!sidebar) return;

        const mainContent = document.querySelector(".component__contentWrapper");
        if (!mainContent) return;

        let oldSidebarDisplay = sidebar.style.display ?? null;
        let oldMainContentPaddingLeft = mainContent.style.paddingLeft ?? null;

        let curParams = getParamsFromURLObject(String(window.location));
        if (curParams["display"]) {
            if (curParams["display"] === "tv") {
                sidebar.style.display = "none";
                mainContent.style.paddingLeft = 0;
            };
            setDisplay(curParams["display"]);
        };
        if (curParams["date_start"] || curParams["date_end"]) {
            let startDate = null;
            let endDate = null;
            if (curParams["date_start"]) startDate = moment(curParams["date_start"], "DD.MM.YYYY");
            if (curParams["date_end"]) endDate = moment(curParams["date_end"], "DD.MM.YYYY");

            if (startDate) {
                if (!startDate.isValid()) startDate = null;
            };
            if (endDate) {
                if (!endDate.isValid()) endDate = null;
            };
            if (!startDate && endDate) startDate = moment(endDate);
            if (endDate && !startDate) endDate = moment(startDate);

            if (startDate && endDate) {
                if (startDate.isAfter(endDate)) [startDate, endDate] = [endDate, startDate];
                setTimeout(() => {
                    setDateFilter({
                        type: "custom",
                        start: startDate,
                        end: endDate
                    });
                }, 500);
            };
        };
        if (curParams["date_range"]) {
            let range = Number(curParams["date_range"]);
            if (!isNaN(range) && range > 0) {
                range -= 1; // today is 1

                let endDate = moment().endOf("day");
                let startDate = moment().add(range * -1, "days").startOf("day");
                setTimeout(() => {
                    setDateFilter({
                        type: "custom",
                        start: startDate,
                        end: endDate
                    });
                }, 500);
            };
        };

        return () => {
            sidebar.style.display = oldSidebarDisplay;
            mainContent.style.paddingLeft = oldMainContentPaddingLeft;
        };
    }, []);

    return <div className="route__reports__realtime">
        <div key={"dashboard-progress"} style={{left: display !== "tv" ? "86px" : "0px"}} className={`route__reports__realtime__progress ${progress.loading ? "route__reports__realtime__progress--marque" : ""}`}>
            <div style={{
                width: progress.loading ? null : `${getProgressPercent()}%`
            }}></div>
        </div>

        <div className="route__reports__realtime__filters" style={{display: display === "tv" ? "none" : null}}>
            <div className="route__reports__realtime__filters__left"></div>
            <div className="route__reports__realtime__filters__right">
                <FilterByDate defaultValue="today" disable24h={true} onChange={setDateFilter} style={{width: "300px"}} disableAll={true} />
            </div>
        </div>

        <div className="route__reports__realtime__row">

            <div className="route__reports__realtime__row__item">
                <p>Real-time visitors (30s update)</p>
                <div className="route__reports__realtime__row__item__box" style={{fontSize: "32px"}}>{realtimeVisits === null ? <Spinner style={{width: "18px", height: "18px"}} color={themeSelector === "dark" ? "white" : "black"} /> : realtimeVisits.value}</div>
            </div>
            <div className="route__reports__realtime__row__item">
                <p>Real-time map</p>

                <ComposableMap projection="geoEqualEarth">
                    <ZoomableGroup zoom={1} maxZoom={50} onMoveEnd={e => setMapZoomFactor(e.zoom)}>
                        <Geographies geography="/assets/countries.json">
                            {({ geographies }) =>
                                geographies.map((geo) => (
                                    <Geography key={geo.rsmKey} geography={geo} fill={themeSelector === "dark" ? "#232630" : "gray"} stroke="#E7E8EC" strokeWidth={0.1} 
                                        onMouseEnter={() => null} 
                                        onMouseLeave={() => null}
                                    />
                                ))
                            }
                        </Geographies>
                        {data?.status === "ok" ? data.data["RealtimeVisitsByCountryCity"].map((rv, rvIdx) => {
                            if (!rv.lon || !rv.lat) return null;

                            return <Marker key={rvIdx} coordinates={[rv.lon, rv.lat]}>
                                <title>{`[${rv.Country} / ${rv.City}] - ${rv.Visits} Visits`}</title>
                                <circle
                                    r={sizeScale(rv.Visits)}
                                    fill="#FF5722"
                                    stroke="#fff"
                                    strokeWidth={1 / mapZoomFactor}
                                    style={{border: "5px solid while", borderRadius: "50%"}}
                                />
                            </Marker>
                        }) : null}
                    </ZoomableGroup>
                </ComposableMap>
            </div>

            <div className="route__reports__realtime__row__item">
                <p>Visits over time</p>
                {visitsOverTime ? <>
                    {visitsOverTime.status === "ok" ? <>
                        <div style={{height: "300px"}}>
                            <Line data={getOverTimeChartData()} options={lineChartOptions} />
                        </div>
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching data</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>

        </div>
        <div className="route__reports__realtime__row">

            <div className="route__reports__realtime__row__item">
                <p>General stats</p>

                {data ? <>
                    {data.status === "ok" ? <>
                        <p className="route__reports__realtime__row__item__stat"><span>{data.data.TotalVisits}</span> total visits</p>
                        <p className="route__reports__realtime__row__item__stat"><span>{data.data.TotalConversions}</span> total conversions <span>({calculatePercentage(data.data.TotalConversions, data.data.TotalVisits)})</span></p>
                        <p className="route__reports__realtime__row__item__stat"><span>{data.data.TotalReturningVisits}</span> returning visits <span>({calculatePercentage(data.data.TotalReturningVisits, data.data.TotalVisits)})</span></p>
                        <p className="route__reports__realtime__row__item__stat"><span>{data.data.TotalVisits - data.data.TotalReturningVisits}</span> unique visits <span>({calculatePercentage(data.data.TotalVisits - data.data.TotalReturningVisits, data.data.TotalVisits)})</span></p>
                        <p className="route__reports__realtime__row__item__stat"><span>{data.data.TotalCTAClicks}</span> CTA button clicks</p>
                        <p className="route__reports__realtime__row__item__stat">Average session duration is <span>{convertTimeToString(data.data.AverageSessionDuration)}</span></p>
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching data</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>

            <div className="route__reports__realtime__row__item">
                <p>Unique visits by traffic source</p>
                {data ? <>
                    {data.status === "ok" ? <>
                        <div style={{height: "300px"}}>
                            <Doughnut data={getIntegrationChartData()} options={doughnutChartOptions} />
                        </div>
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching data</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>

            <div className="route__reports__realtime__row__item">
                <p>Unique visits by device type</p>
                {data ? <>
                    {data.status === "ok" ? <>
                        <div style={{height: "300px"}}>
                            <Doughnut data={getDeviceChartData()} options={doughnutChartOptions} />
                        </div>
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Error while fetching data</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}
            </div>

        </div>
        <div className="route__reports__realtime__row">

            <div className="route__reports__realtime__row__item">
                <p>Visits by offer (top 10)</p>
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Offer", "Visits", "Unique", "Conversions"]}
                    customColumns={["1fr", "max-content", "max-content", "max-content"]}
                    style={{columnGap: "20px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]]
                        if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        for (let item of data.data.VisitsByOffer) {
                            if (out.length === 10) break;
                            out.push([
                                {keyID: item.Name, type: "text", text: item.Name},
                                {keyID: item.Name, type: "text", text: item.Visits},
                                {keyID: item.Name, type: "text", text: item.Unique},
                                {keyID: item.Name, type: "text", text: item.Conversions},
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>

            <div className="route__reports__realtime__row__item">
                <p>Visits by website (top 10)</p>
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Offer", "Visits", "Unique", "Conversions"]}
                    customColumns={["1fr", "max-content", "max-content", "max-content"]}
                    style={{columnGap: "20px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]]
                        if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                        let out = [];
                        for (let item of data.data.VisitsBySite) {
                            if (out.length === 10) break;
                            out.push([
                                {keyID: item.Name, type: "text", text: item.Name},
                                {keyID: item.Name, type: "text", text: item.Visits},
                                {keyID: item.Name, type: "text", text: item.Unique},
                                {keyID: item.Name, type: "text", text: item.Conversions},
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>

        </div>
    </div>
};

export default RealtimeReport;