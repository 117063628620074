import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import exceljs from "exceljs";
import axios from "axios";
import moment from "moment";
import { countries } from "../../../modules/countryModule";
import { animateBox } from "../../../modules/componentAnimation";
import { arrayToCSVString } from "../../../modules/csvParserModule";
import { getTrackingProfileImage } from "../../../modules/miscModule";
import { getParamsFromURLObject } from "../../../modules/urlModule";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctinsActions from "../../../actions/siteFunctionsActions";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import StyledButton from "../../../components/styledComponents/Button";
import Checkbox from "../../../components/customComponents/Checkbox";
import RadioButton from "../../../components/customComponents/RadioButton";

import FilterColumns from "../../../components/filters/FilterColumns";
import YesNoModal from "../../../components/modals/YesNoModal";
import PreviewImageModal from "../../../components/modals/PreviewImageModal";
import PreviewVideoModal from "../../../components/modals/PreviewVideoModal";
import { TrackingScript } from "../Sites/SitesCard";

let dataTimeout = null;
const allExportColumns = [
    "ID", "Name", "Brand", "Condition", "Responsible", "Price", "Country", "Type", "Niche", "Status", "Target demographic", "Scalelead Offer ID", "Assigned at", "Conversions", "Datasets", "Daily cap", "Cap achieved"
];
const UserOffers = () => {
    const [data, setData] = React.useState();
    const [offerTypes, setOfferTypes] = React.useState();
    const [selectedData, setSelectedData] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [countryFilter, setCountryFilter] = React.useState();
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [allUsers, setAllUsers] = React.useState();
    const [visibleColumns, setVisibleColumns] = React.useState();
    const [filterActiveOffer, setFilterActiveOffer] = React.useState(true);
    const [filterOfferType, setFilterOfferType] = React.useState("free");
    const [filterOfferNiche, setFilterOfferNiche] = React.useState("nutra");
    const [filterResponsiblePersons, setFilterResponsiblePersons] = React.useState();
    const [toggleCheckDatasets, setToggleCheckDatasets] = React.useState(false);
    const [conversionDates, setConversionDates] = React.useState();
    const [exportSpinner, setExportSpinner] = React.useState(false);

    const [settings, setSettings] = React.useState({
        showSensitive: true
    });

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const selectedTrackingSelector = useSelector(state => state?.trackingProfiles ?? null);
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const offerNichesSelector = useSelector(state => state?.types?.offerNiches ?? []);
    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const checkboxFunctionsRef = React.useRef();
    const curTimestampRef = React.useRef();

    const curDispatch = useDispatch();
    const onScreen = useOnScreen();
    const curDefer = useDefer();
    const visibleColumnsDefer = useDefer();

    const getOfferTypes = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOfferTypes`,
            ...backendModule.axiosConfig
        }).then(res => {
            setOfferTypes(res.data);
        }).catch(() => {
            setOfferTypes(backendModule.genericError);
        });
    };

    const prepareSearchFilters = search => {
        if (!search) return [];
        let tmp = [];

        for (let item of search.split(" ")) {
            if (!item) continue;

            let genderFilter = undefined;
            let ageFilter = null
            let userIDs = [];
            if (item && allUsers?.status === "ok") {
                for (let usr of allUsers.data) {
                    if (usr.Username.toLowerCase().startsWith(item.toLowerCase())) userIDs.push(usr.ID);
                };
            };
            if (item.toLowerCase() === "male") genderFilter = true;
            if (item.toLowerCase() === "female") genderFilter = false;
            if (item.toLowerCase() === "any") genderFilter = null;
            if (item.includes("-")) {
                let tmpFlt = item.split("-");
                if (tmpFlt.length === 2) {
                    let a = Number(tmpFlt[0]);
                    let b = Number(tmpFlt[1]);
                    if (!isNaN(a) && !isNaN(b)) {
                        ageFilter = { start: a, end: b };
                    };
                };
            };

            tmp.push({
                or: [
                    { name: "OfferName", op: "like", value: item },
                    { name: "OfferCondition", op: "like", value: item },
                    { name: "Country", op: "like", value: item },
                    { name: "OfferType", op: "like", value: item },
                    { name: "OfferNiche", op: "like", value: item },
                    { name: "OfferBrand", op: "like", value: item },
                    { name: "ScaleleadOfferID", op: "like", value: item },
                    (userIDs?.length > 0 ? {or: userIDs.map(rp => {
                        return {name: "ResponsiblePerson", op: "jaeq", value: {ID: rp}};
                    })} : null),
                    (genderFilter !== undefined ? { name: "TargetDemographicGender", op: "eq", value: genderFilter } : null),
                    (ageFilter ? {
                        and: [
                            { name: "TargetDemographicAgeStart", op: "leq", value: ageFilter.start },
                            { name: "TargetDemographicAgeEnd", op: "geq", value: ageFilter.end }
                        ]
                    } : null)
                ].filter(t => t)
            })
        };

        if (tmp.length > 0) return [{ and: tmp }];
        return [];
    };

    const prepareFiltersExternal = () => {
        let userIDs = [];
        if (search && allUsers?.status === "ok") {
            for (let usr of allUsers.data) {
                if (usr.Username.toLowerCase().startsWith(search.toLowerCase())) userIDs.push(usr.ID);
            };
        };

        return [
            ...(search ? prepareSearchFilters(search) : []),
            (typeof (filterActiveOffer) === "boolean" ? { name: "isActive", op: "eq", value: filterActiveOffer } : null),
            (filterActiveOffer === "onHold" ? { name: "isOnHold", op: "eq", value: true } : null),
            (filterActiveOffer === true ? { name: "isOnHold", op: "eq", value: false } : null),
            (countryFilter ? { name: "Country", op: "eq", value: countryFilter } : null)
        ].filter(t => t);
    };

    const editOffer = (e, idx) => {
        if (!idx) return;
        if (!data) return;
        if (data.status !== "ok") return;

        let curOffer = data.data.find(d => d.ID === idx);
        if (!curOffer) return;

        animateBox(e, <AddOffer edit={curOffer} onChange={() => {
            setGlobalSpinner(true);
            let ts = Date.now();
            curTimestampRef.current = ts;
            curDefer(() => getData(ts), 500);
        }} />);
    };

    const removeOffer = (e, ids) => {
        if (ids.length === 0) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove ", <span style={{ color: "#6C5DD3" }}>{ids.length} offers</span>, ", this action is irreversible!"]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);
                let hadError = false;

                for (let item of ids) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/offers/removeOffer`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status !== "ok") hadError = true;
                    }).catch(() => {
                        hadError = true;
                    });
                };

                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();
                let ts = Date.now();
                curTimestampRef.current = ts;
                getData(ts);

                if (hadError) {
                    let tmp = document.querySelector(".route__user__offers__btns__left__selected__btn");
                    if (!tmp) return;

                    animateBox({ currentTarget: tmp }, <YesNoModal
                        heading="Error"
                        text="There were errors while removing some offers."
                        buttonLeftHidden={true}
                        isRightButtonNormal={true}
                        buttonRightText="Ok"
                    />);
                };
            }}
        />);
    };

    const getData = async (ts, onlyReturn = false) => {
        if (checkboxFunctionsRef.current?.reset && !onlyReturn) checkboxFunctionsRef.current.reset();

        let userIDs = [];
        if (search && allUsers?.status === "ok") {
            for (let usr of allUsers.data) {
                if (usr.Username.toLowerCase().startsWith(search.toLowerCase())) userIDs.push(usr.ID);
            };
        };

        return await axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: onlyReturn ? null : 20,
                offset: 0,
                filters: [
                    ...(search ? prepareSearchFilters(search) : []),
                    (typeof (filterActiveOffer) === "boolean" ? { name: "isActive", op: "eq", value: filterActiveOffer } : null),
                    (filterActiveOffer === "onHold" ? { name: "isOnHold", op: "eq", value: true } : null),
                    (filterActiveOffer === true ? { name: "isOnHold", op: "eq", value: false } : null),
                    (countryFilter ? { name: "Country", op: "eq", value: countryFilter } : null),
                    (filterOfferType ? { name: "OfferType", op: "eq", value: filterOfferType === "None" ? null : filterOfferType } : null),
                    (filterOfferNiche ? { name: "OfferNiche", op: "eq", value: filterOfferNiche } : null),
                    (filterResponsiblePersons?.length > 0 ? {or: filterResponsiblePersons.map(rp => {
                        return {name: "ResponsiblePerson", op: "jaeq", value: {ID: rp}}
                    })} : null)
                ].filter(t => t),
                extended: true,
                checkDatasets: toggleCheckDatasets
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (onlyReturn) return res.data;
            if (ts !== curTimestampRef.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !onlyReturn) {
                    if (!canPaginate) setCanPaginate(true);
                } else {
                    if (canPaginate) setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            if (onlyReturn) return backendModule.genericError;
            if (ts !== curTimestampRef.current) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setGlobalSpinner(false);
        });
    };

    const continueData = (timestamp) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;

        let userIDs = [];
        if (search && allUsers?.status === "ok") {
            for (let usr of allUsers.data) {
                if (usr.Username.toLowerCase().startsWith(search.toLowerCase())) userIDs.push(usr.ID);
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    {
                        not: [
                            { name: "ID", op: "in", value: data.data.map(d => d.ID) }
                        ]
                    },
                    ...(search ? prepareSearchFilters(search) : []),
                    (typeof (filterActiveOffer) === "boolean" ? { name: "isActive", op: "eq", value: filterActiveOffer } : null),
                    (filterActiveOffer === "onHold" ? { name: "isOnHold", op: "eq", value: true } : null),
                    (filterActiveOffer === true ? { name: "isOnHold", op: "eq", value: false } : null),
                    (countryFilter ? { name: "Country", op: "eq", value: countryFilter } : null),
                    (filterOfferType ? { name: "OfferType", op: "eq", value: filterOfferType === "None" ? null : filterOfferType } : null),
                    (filterOfferNiche ? { name: "OfferNiche", op: "eq", value: filterOfferNiche } : null),
                    (filterResponsiblePersons?.length > 0 ? {or: filterResponsiblePersons.map(rp => {
                        return {name: "ResponsiblePerson", op: "jaeq", value: {ID: rp}}
                    })} : null)
                ].filter(t => t),
                extended: true,
                checkDatasets: toggleCheckDatasets
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (curTimestampRef.current !== timestamp) return;

                if (res.data.data.length === 20) {
                    if (!canPaginate) setCanPaginate(true);
                } else {
                    if (canPaginate) setCanPaginate(false);
                };
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                })
            } else {
                setData(res.data);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setGlobalSpinner(false);
        });
    };

    const getDatasetColors = () => {
        if (!toggleCheckDatasets) return null;
        if (!data) return null;
        if (data?.status !== "ok") return null;

        let out = [];
        let red_color = "rgba(255,0,0,0.1)";
        let green_color = "rgba(0,255,0,0.1)";

        for (let item of data.data) {
            if (item._hasDataset === undefined) {
                out.push(null);
                continue;
            };
            if (item._hasDataset) {
                out.push(green_color);
            } else {
                out.push(red_color);
            };
        };

        return out;
    };

    const performWeeklyCapExport = () => {
        animateBox(<YesNoModal
            heading="Cap export"
            text="This will take a considerable amount of time (offer data pulls are expensive). Are you sure?"
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage("");

                let offerData = await getData(null, true);
                if (offerData.status === "error") {
                    args.spinner(false);
                    args.disabledAll(false);
                    args.errorMessage("There was an error while getting offers!");
                    return;
                };

                args.spinner(false);
                args.setProgress({ value: 0, max: offerData.data.length });

                setExportSpinner(true);
                let difference = moment(conversionDates.start).diff(moment(conversionDates.end), "days");

                if (difference < 0) difference = difference * -1 + 1
                let csvOut = [];
                let toWork = [];
                let totalProgress = 0;
                for (let single of offerData.data) {
                    const getConversions = async () => {
                        if (!single.ID) return;
                        if (!conversionDates) return;

                        let allSites = await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/sites/getAllSites`,
                            data: {
                                extended: false,
                                filters: [
                                    { name: "OfferID", op: "eq", value: single.ID }
                                ],
                                limit: null,
                                offset: 0
                            },
                            ...backendModule.axiosConfig
                        }).then(res => res.data).catch(() => backendModule.genericError);
                        if (allSites.status === "error" || allSites.data.length === 0) return {
                            Conversions: 0,
                            Spent: 0,
                            ARC: 0,
                            CR: 0,
                            AR: 0,
                            CTR: 0,
                            CPA: 0,
                            CPAO: 0
                        };

                        let allCampaigns = await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
                            data: {
                                allUsers: true,
                                filters: [
                                    {
                                        or: [
                                            { name: "PreLandingSiteID", op: "in", value: allSites.data.map(s => s.ID) },
                                            { name: "LandingSiteID", op: "in", value: allSites.data.map(s => s.ID) }
                                        ]
                                    }
                                ],
                                limit: null,
                                offset: 0
                            },
                            ...backendModule.axiosConfig
                        }).then(res => res.data).catch(() => backendModule.genericError);
                        if (allCampaigns.status === "error" || allCampaigns.data.length === 0) return {
                            Conversions: 0,
                            Spent: 0,
                            ARC: 0,
                            CR: 0,
                            AR: 0,
                            CTR: 0,
                            CPA: 0,
                            CPAO: 0
                        };

                        let finalStats = await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                            data: {
                                allUsers: true,
                                CampaignIDs: allCampaigns.data.map(c => c.ID),
                                skipIntegrationDataPull: false,
                                TableHeaders: ["Conversions", "Spent", "ARC", "CR", "AR", "CTR", "CTR_IN"],
                                filters: [
                                    { name: "createdAt", op: "pdgeq", value: conversionDates.start.toDate().getTime() },
                                    { name: "createdAt", op: "pdleq", value: conversionDates.end.toDate().getTime() }
                                ]
                            },
                            ...backendModule.axiosConfig
                        }).then(res => res.data).catch(() => backendModule.genericError);
                        if (finalStats.status === "error") return {
                            Conversions: 0,
                            Spent: 0,
                            ARC: 0,
                            CR: 0,
                            AR: 0,
                            CTR: 0,
                            CPA: 0,
                            CPAO: 0
                        };

                        let out = {
                            Conversions: finalStats?.data?.TableData?.Conversions ?? 0,
                            ARC: finalStats?.data?.TableData?.ARC ?? 0,
                            CR: finalStats?.data?.TableData?.CR ?? 0,
                            AR: finalStats?.data?.TableData?.AR ?? 0,
                            Spent: 0,
                            CTR: [],
                            CPA: 0,
                            CPAO: 0
                        };

                        for (let key of Object.keys(finalStats?.data?.Integrations)) {
                            let tmpData = finalStats.data.Integrations[key]?.TableData ?? {};

                            if (tmpData["CTR_IN"] || tmpData["CTR"]) {
                                out.CTR.push(tmpData["CTR_IN"] || tmpData["CTR"]);
                            };
                            if (tmpData["Spent_IN"] || tmpData["Spent"]) {
                                out.Spent += tmpData["Spent_IN"] || tmpData["Spent"];
                            };
                        };
                        if (out.CTR.length === 0) out.CTR.push(0);
                        out.CTR = out.CTR.reduce((acc, val) => acc + val, 0) / out.CTR.length;

                        if (out.Spent && out.Conversions) {
                            out.CPA = out.Spent / out.Conversions;
                        };
                        if (out.ARC && out.Spent) {
                            out.CPAO = out.Spent / out.ARC;
                        };

                        return out;
                    };
                    toWork.push(new Promise(async r => {
                        let tmp = await getConversions();
                        csvOut.push({
                            "Media Buyer": single?._ResponsiblePerson?.[0]?.Username ?? "-",
                            Geo: single.Country,
                            "Offer": single.OfferName + " " + single.OfferType,
                            "Daily cap": single.DailyCap,
                            "Lead Forecast": `${Number(single.DailyCap) * difference}`,
                            "Lead generated": tmp.Conversions,
                            "Average Daily Leads": `${(tmp.Conversions / difference).toFixed(2)}`,
                            "% of Daily Cap Achieved": `${Number(tmp.Conversions / (single.DailyCap * difference) * 100).toFixed(2)} %`,
                            "CTR": `${Number(tmp.CTR).toFixed(2)} %`,
                            "CR": `${Number(tmp.CR).toFixed(2)} %`,
                            "AR": `${Number(tmp.CR).toFixed(2)} %`,
                            "CPA": `${Number(tmp.CPA).toFixed(2)} ${currencySignSelector}`,
                            "CPAO": `${Number(tmp.CPAO).toFixed(2)} ${currencySignSelector}`,
                            "Reported period": `${moment(conversionDates.start).format("DD.MM.YYYY")}-${moment(conversionDates.end).format("DD.MM.YYYY")}`
                        });
                        totalProgress += 1;
                        args.setProgress({value: totalProgress, max: offerData.data.length});
                        r();
                    }));
                };
                await Promise.allSettled(toWork);
                let keysSplit = [...Object.keys(csvOut[0])];

                const workbook = new exceljs.Workbook();
                const sheet = workbook.addWorksheet("Offer report");
                let headersInserted = [];
                for (let elem of csvOut) {
                    if (headersInserted.length === 0) {
                        headersInserted = Object.keys(elem).map(key => {
                            return { header: key, key: key };
                        });
                        break;
                    };
                };
                sheet.addTable({
                    name: "Conversions",
                    "ref": "A1",
                    headerRow: true,
                    style: {
                        theme: 'TableStyleLight1',
                        showRowStripes: true,
                    },
                    columns: headersInserted.map(hi => { return { name: hi.header } }),
                    rows: csvOut.map(c => {
                        let out = [];
                        for (let item of keysSplit) {
                            out.push(c[item]);
                        };
                        return out;
                    })
                });
                sheet.views = [
                    { state: "frozen", ySplit: 1, xSplit: 1 }
                ];
                sheet.columns.forEach(function (column, i) {
                    let maxLength = 0;
                    column["eachCell"]({ includeEmpty: true }, function (cell) {
                        var columnLength = cell.value ? cell.value.toString().length : 10;
                        if (columnLength > maxLength) {
                            maxLength = columnLength;
                        }
                    });
                    column.width = maxLength < 10 ? 10 : maxLength;
                });

                workbook.xlsx.writeBuffer().then(data => {
                    let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                    var a = document.createElement("a"),
                        url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = `capReport-${moment(conversionDates.start).format("DD.MM.YYYY")}-${moment(conversionDates.end).format("DD.MM.YYYY")}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                        setExportSpinner(false)
                    }, 0);

                }).catch(() => {
                    setExportSpinner(false)
                });
                args.setProgress();
                args.close();
            }}
        />);
    };

    React.useEffect(() => {
        clearTimeout(dataTimeout);

        if (!canPaginate) return;
        if (!onScreen.isIntersecting) return;

        let curTimestamp = Date.now();
        curTimestampRef.current = curTimestamp;

        dataTimeout = setTimeout(() => {
            continueData(curTimestamp);
        }, 500);

        try {
            onScreen.observer.unobserve(onScreen.measureRef.current);
        } catch { };
    }, [onScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        curTimestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);

        let handler = () => {
            setGlobalSpinner(true);
            let ts = Date.now();
            curTimestampRef.current = ts;
            getData(ts);
        };

        curDispatch(siteFunctinsActions.addHeaderRefreshAction(handler));

        return () => siteFunctinsActions.removeHeaderRefreshAction(handler);
    }, [search, countryFilter, selectedTrackingSelector?.selectedProfile, selectedTrackingSelector?.selectedTeam, filterActiveOffer, filterOfferType, filterOfferNiche, filterResponsiblePersons, toggleCheckDatasets]);

    React.useEffect(() => {
        if (visibleColumns) {
            visibleColumnsDefer(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
                    data: {
                        Integration: "usr_offerList",
                        Columns: visibleColumns
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            }, 500);
        } else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
                data: {
                    Integration: "usr_offerList"
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setVisibleColumns(res.data.data);
                } else {
                    setVisibleColumns([]);
                };
            }).catch(() => {
                setVisibleColumns([]);
            });
        };
    }, [visibleColumns]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });

        getOfferTypes();
    }, []);

    return <div className="route__user__offers">
        <div className="route__user__offers__filters">
            <FilterBySearch onChange={setSearch} />
            <AdvancedDropdown
                headline="Country"
                data={[{
                    key: "all",
                    name: "All",
                    value: null
                }, ...countries.map(c => {
                    return {
                        image: `/images/countryFlags/${c.code.toLowerCase()}.png`,
                        key: c.code,
                        value: c.code,
                        name: c.name
                    }
                })]}
                selected={(() => {
                    if (!countryFilter) return 0;
                    return countries.indexOf(countries.find(c => c.code.toLowerCase() === countryFilter.toLowerCase())) + 1;
                })()}
                onChange={e => (e?.value !== countryFilter) && setCountryFilter(e?.value)}
                showSearch={true}
            />
            <FilterByDate
                disableAll={true}
                text="Display conversions for date"
                onChange={e => setConversionDates(e)}
            />
            <AdvancedDropdown
                headline="Offer type"
                data={offerTypes ? ([{ key: "all", name: "Any", value: null }, ...(offerTypes.status === "ok" ? offerTypes.data : []).map(ot => {
                    return { key: ot, name: ot, value: ot }
                })]) : null}
                onChange={e => { e?.value !== undefined && e?.value !== filterOfferType && setFilterOfferType(e?.value) }}
                selected={(() => {
                    if (!offerTypes) return null;
                    if (!filterOfferType) return 0;
                    let tmp = offerTypes.status === "ok" ? offerTypes.data : [];

                    return tmp.indexOf(tmp.find(t => t === filterOfferType)) + 1;
                })()}
            />
            <AdvancedDropdown
                headline="Offer active"
                data={[
                    { key: "all", name: "Any", value: null },
                    { key: "true", name: "Only active", value: true },
                    { key: "false", name: "Only inactive", value: false },
                    { key: "onHold", name: "Only on hold", value: "onHold" }
                ]}
                onChange={e => { e?.value !== undefined && e?.value !== filterActiveOffer && setFilterActiveOffer(e?.value) }}
                selected={(() => {
                    switch (filterActiveOffer) {
                        case null: return 0;
                        case true: return 1;
                        case false: return 2;
                        case "onHold": return 3;
                    };
                })()}
            />
            {offerNichesSelector.length > 0 && <AdvancedDropdown
                headline="Offer niche"
                data={[
                    { key: "all", name: "Any", value: null },
                    ...offerNichesSelector.map(n => {
                        return { key: n, name: n, value: n };
                    })
                ]}
                onChange={e => e?.value !== undefined && e?.value !== filterOfferNiche && setFilterOfferNiche(e?.value)}
                selected={(() => {
                    if (!filterOfferNiche) return 0;

                    return offerNichesSelector.indexOf(offerNichesSelector.find(f => f === filterOfferNiche)) + 1;
                })()}
            />}
        </div>

        <div className="route__user__offers__btns">
            <div className="route__user__offers__btns__left">
                <div className="route__user__offers__btns__left__item">
                    <RadioButton checked={toggleCheckDatasets} onChange={e => toggleCheckDatasets !== e && setToggleCheckDatasets(e)} />
                    <span>Check datasets</span>
                </div>

                <div className={`route__user__offers__btns__left__selected ${selectedData.length > 0 ? "route__user__offers__btns__left__selected--active" : ""}`}>
                    {`${selectedData.length} offers selected`}
                    <div
                        className="route__user__offers__btns__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>

            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isManager) ? <div className="route__user__offers__btns__right">
                {selectedData.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        (selectedData.length === 1 && { name: "View datasets", onClick: e => animateBox(e, <OfferDatasets ID={selectedData[0]} />) }),
                        (selectedData.length === 1 && { name: "View creatives", onClick: e => animateBox(e, <OfferGallery ID={selectedData[0]} />) }),
                        (selectedData.length === 1 && { name: "View product photos", onClick: e => animateBox(e, <OfferGallery gallery={true} ID={selectedData[0]} />) }),
                        (selectedData.length === 1 && { name: "View history", onClick: e => animateBox(e, <OfferHistory ID={selectedData[0]} />) }),
                        ((selectedData.length === 1 && userInfoSelector?.Flags?.isAdmin) && { name: "Export conversions", onClick: e => animateBox(e, <ExportOfferConversions ID={selectedData[0]} />) }),
                        (selectedData.length === 1 && { name: "Edit", onClick: e => editOffer(e, selectedData[0]) }),
                        { name: `Remove ${selectedData.length} offers`, onClick: (e) => removeOffer(e, selectedData) },
                        (selectedData.length > 1 && { name: `Bulk edit ${selectedData.length} offers`, onClick: (e) => animateBox(e, <BulkEdit IDs={selectedData} onChange={() => { let ts = Date.now(); curTimestampRef.current = ts; getData(ts); }} />) })
                    ]}
                />}
                <div className="route__user__offers__btns__right__btn" onClick={async e => {
                    if (exportSpinner) return;
                    performWeeklyCapExport();
                }}>
                    {exportSpinner ? <Spinner style={{ width: "20px", height: "20px" }} /> : <>
                        <img src="/images/icon_export.svg" />
                        <span>Export caps</span>
                    </>}

                </div>
                {selectedData.length === 0 && <div className="route__user__offers__btns__right__btn" onClick={e => {
                    animateBox(e, <AddOffer onChange={() => {
                        setGlobalSpinner(true);
                        let ts = Date.now();
                        curTimestampRef.current = ts;
                        curDefer(() => getData(ts), 500);
                    }} />);
                }}>
                    <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
                    <span>Add offer</span>
                </div>}

                <StyledButton style={{padding: "10px 16px", height: "100%"}} onClick={() => {
                    animateBox(<Offers_selectUsers selected={filterResponsiblePersons} onChange={e => setFilterResponsiblePersons(e)} />)
                }}>Manage users</StyledButton>
                {visibleColumns && <FilterColumns columns={allExportColumns} defaultValue={visibleColumns} onChange={e => {
                    if (visibleColumns.join(",") === e.join(",")) return;
                    setVisibleColumns(e);
                }} />}
                <div className="route__user__offers__btns__right__settings" onClick={e => {
                    animateBox(e, <OfferSettings selected={selectedData} filters={prepareFiltersExternal()} settings={settings} onChange={setSettings} />);
                }}>
                    <img src="/images/settings.svg" />
                </div>
            </div> : <div className="route__user__offers__btns__right">
                {selectedData.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        ((selectedData.length === 1) && { name: "View datasets", onClick: e => animateBox(e, <OfferDatasets ID={selectedData[0]} />) }),
                        (selectedData.length === 1 && { name: "View creatives", onClick: e => animateBox(e, <OfferGallery ID={selectedData[0]} />) }),
                        (selectedData.length === 1 && { name: "View product photos", onClick: e => animateBox(e, <OfferGallery gallery={true} ID={selectedData[0]} />) })
                    ]}
                />}
                {visibleColumns && <FilterColumns columns={allExportColumns} defaultValue={visibleColumns} onChange={e => {
                    if (visibleColumns.join(",") === e.join(",")) return;
                    setVisibleColumns(e);
                }} />}
                <div className="route__user__offers__btns__right__settings" onClick={e => {
                    animateBox(e, <OfferSettings selected={selectedData} filters={prepareFiltersExternal()} settings={settings} onChange={setSettings} />);
                }}>
                    <img src="/images/settings.svg" />
                </div>
            </div>}
        </div>
        <div className="route__user__offers__tableWrap">
            {visibleColumns && <FilteredCustomTable
                colors={getDatasetColors()}
                spinnerColor={themeSelector === "dark" ? "white" : "black"}
                showSpinner={globalSpinner}
                key="user-offers-table"
                checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedData : false}
                checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
                accent="#6C5DD3"
                theme={themeSelector}
                headers={
                    settings?.showSensitive ? ["No.", ...(visibleColumns.length > 0 ? visibleColumns : allExportColumns)] :
                        ["No.", "ID", "Name", "Country", "Status"]
                }
                customColumns={["auto", ...(new Array(settings?.showSensitive ? (visibleColumns.length || allExportColumns.length) : 4).fill("auto"))]}
                style={{ columnGap: "10px" }}
                data={(() => {
                    let out = [];

                    if (!data) {
                        out.push([{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]);
                    } else {
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                if (settings?.showSensitive) {
                                    let tmp = [
                                        { keyID: String(item.ID), type: "text", text: data.data.indexOf(item) + 1 }
                                    ];
                                    for (let col of (visibleColumns.length > 0 ? visibleColumns : allExportColumns)) {
                                        if (col === "ID") tmp.push({ keyID: String(item.ID), type: "text", text: item.ID });
                                        if (col === "Brand") tmp.push({ keyID: String(item.ID), type: "text", text: item.OfferBrand });
                                        if (col === "Name") tmp.push({ keyID: String(item.ID), type: "text", text: item.OfferName });
                                        if (col === "Condition") tmp.push({ keyID: String(item.ID), type: "text", text: item.OfferCondition ?? "-" });
                                        if (col === "Responsible") tmp.push({ keyID: String(item.ID), type: "text", text: item.ResponsiblePerson?.length > 0 ? item.ResponsiblePerson.map(rp => {
                                            return <span className="route__user__offers__tableWrap__country" style={{display: "inline-flex", marginRight: "10px"}}>
                                                    <img style={{width: "16px"}} src={getTrackingProfileImage(supportedIntegrationsSelector.find(si => si.Type === rp.IntegrationID)?.NamedType)} />
                                                    <span>{rp.Username}</span>
                                                </span>
                                        }) : "-" });
                                        if (col === "Price") tmp.push({ keyID: String(item.ID), type: "text", text: `${Number(item.OfferPrice).toFixed(2)} ${currencySignSelector}` });
                                        if (col === "Country") tmp.push({
                                            keyID: String(item.ID), type: "text", text: item.Country ? (() => {
                                                let curCountry = countries.find(c => c.code === item.Country);

                                                if (!curCountry) return "?";
                                                return <p className="route__user__offers__tableWrap__country">
                                                    <img src={`/images/countryFlags/${curCountry.code.toLowerCase()}.png`} />
                                                    <span>{curCountry.name}</span>
                                                </p>
                                            })() : "-"
                                        });
                                        if (col === "Type") tmp.push({ keyID: String(item.ID), type: "text", text: item.OfferType ?? "-" });
                                        if (col === "Niche") tmp.push({ keyID: String(item.ID), type: "text", text: item.OfferNiche ?? "-" });
                                        if (col === "Status") tmp.push({ keyID: String(item.ID), type: "text", text: item.isOnHold ? <span style={{ color: themeSelector === "dark" ? "#ffff60" : "#97972d" }}>On hold</span> : (item.isActive ? <span style={{ color: themeSelector === "dark" ? "rgb(102, 238, 102)" : "green" }}>Active</span> : <span style={{ color: "rgb(238, 102, 102)" }}>Inactive</span>) });
                                        if (col === "Scalelead Offer ID") tmp.push({ keyID: String(item.ID), type: "text", text: item.ScaleleadOfferID ?? "-" });
                                        if (col === "Assigned at") tmp.push({ keyID: String(item.ID), type: "text", text: (new Date(item.Assigned).toLocaleString()) ?? "-" });
                                        if (col === "Target demographic") tmp.push({ keyID: String(item.ID), type: "text", text: `${item.TargetDemographicGender === null ? "Any" : (item.TargetDemographicGender ? "Male" : "Female")}, ${item.TargetDemographicAgeStart}-${item.TargetDemographicAgeEnd}` });
                                        if (col === "Conversions") tmp.push({ keyID: String(item.ID), type: "custom", data: <GetConversionsForOffer key={item.ID} ID={item.ID} dates={conversionDates} /> });
                                        if (col === "Datasets") tmp.push({ keyID: String(item.ID), type: "custom", data: <GetDatasetsForOffer key={item.ID} ID={item.ID} /> });
                                        if (col === "Daily cap") tmp.push({ keyID: String(item.ID), type: "text", text: item.DailyCap ?? "-" });
                                        if (col === "Cap achieved") tmp.push({ keyID: String(item.ID), type: "custom", data: <GetCapAchievedForOffer key={item.ID} ID={item.ID} dates={conversionDates} DailyCap={item.DailyCap} /> });
                                    };
                                    out.push(tmp);
                                } else {
                                    out.push([
                                        { keyID: String(item.ID), type: "text", text: data.data.indexOf(item) + 1 },
                                        { keyID: String(item.ID), type: "text", text: item.ID },
                                        { keyID: String(item.ID), type: "text", text: item.OfferName },
                                        {
                                            keyID: String(item.ID), type: "text", text: item.Country ? (() => {
                                                let curCountry = countries.find(c => c.code === item.Country);

                                                if (!curCountry) return "?";
                                                return <p className="route__user__offers__tableWrap__country">
                                                    <img src={`/images/countryFlags/${curCountry.code.toLowerCase()}.png`} />
                                                    <span>{curCountry.name}</span>
                                                </p>
                                            })() : "-"
                                        },
                                        { keyID: String(item.ID), type: "text", text: item.isActive ? <span style={{ color: "rgb(102, 238, 102)" }}>Active</span> : <span style={{ color: "rgb(238, 102, 102)" }}>Inactive</span> }
                                    ]);
                                };
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "An error occured!" }]);
                        };
                    };

                    if (out.length === 0) {
                        out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
                    };
                    if (canPaginate) {
                        out.push([
                            { keyID: "data-paginationSpinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" },
                            { keyID: "data-pagination", type: "custom", data: <div ref={onScreen.measureRef}></div> }
                        ]);
                    };

                    return out;
                })()}
            />}
        </div>

    </div>
};

const Offers_selectUsers = props => {
    const [allUsers, setAllUsers] = React.useState();
    const [selectedUsers, setSelectedUsers] = React.useState(props.selected ?? []);
    const [savedSelections, setSavedSelections] = React.useState();
    const [search, setSearch] = React.useState("");

    const mainRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const getPresets = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/userSavedColumns/getPresets`,
            data: {
                Integration: `user_offers`
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setSavedSelections(res.data);
        }).catch(() => {
            setSavedSelections({status: "ok", data: []});
        });
    };

    const loadPreset = preset => {
        if (allUsers?.status !== "ok") return;
        if (!Array.isArray(preset)) return;

        let out = [];
        for (let usr of allUsers.data) {
            if (preset.includes(usr.ID)) out.push(usr.ID);
        };

        if (typeof(props.onChange) === "function") {
            props.onChange([...new Set(out)]);
        };
        onClose();
    };

    const removePreset = ID => {
        animateBox(<YesNoModal
            heading="Are you sure?"
            text="This will remove the preset. This actio is irreversible!"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.disabledAll(true);
                args.spinner(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/removePreset`,
                    data: {
                        ID
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null).finally(() => {
                    getPresets();
                    args.disabledAll(false);
                    args.spinner(false);
                    args.close();
                });
            }}
        />);
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        if (allUsers?.status === "ok" && (!selectedUsers || selectedUsers?.length === 0)) {
            setSelectedUsers(allUsers.data.map(usr => usr.ID));
        };
    }, [allUsers]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        });

        getPresets();
    }, []);

    const SaveAsPreset = props2 => {
        const [spinner, setSpinner] = React.useState(false);
        const [infoP, setInfoP] = React.useState("");

        const presetNameRef = React.useRef();

        const savePreset = () => {
            if (spinner) return;
            setInfoP("");

            let data = {
                Integration: "user_offers",
                Name: presetNameRef.current.value,
                Columns: props2.items
            };

            if (!data.Name) return setInfoP("Preset name can't be empty!");

            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/userSavedColumns/addPreset`,
                data,
                ...backendModule.axiosConfig
            }).then(() => null).catch(() => null).finally(() => {
                if (typeof(props2.onChange) === "function") props2.onChange();

                setSpinner(false);
                props2.onClose();
            });
        };

        return <div className="genericModal">
            <div className="genericModal__wrap">
                <div className="genericModal__wrap__head">
                    <div className="genericModal__wrap__head__left">Save preset</div>
                    <div className="genericModal__wrap__head__right" onClick={() => props2.onClose()} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
                </div>

                <div className="genericModal__wrap__input">
                    <p>Preset name</p>
                    <input ref={presetNameRef} type="text" placeholder="Preset name" />
                </div>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={() => props2.onClose()}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={() => savePreset()}>
                        {spinner ? <Spinner color="white" style={{width: "16px", height: "16px"}} /> : "Save"}
                    </div>
                </div>

                <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</p>
            </div>
        </div>
    };

    return <div className="route__user__offers__selectUsers" onClick={onClose}>
        <div className="route__user__offers__selectUsers__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__offers__selectUsers__wrap__top">
                <div className="route__user__offers__selectUsers__wrap__top__left">Select users</div>

                {allUsers?.status === "ok" && <StyledButton style={{height: "100%", marginLeft: "auto", marginRight: "20px"}} onClick={() => {
                    props.onChange(selectedUsers);
                    onClose();
                }}>Save</StyledButton>}
                <div className="route__user__offers__selectUsers__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__offers__selectUsers__wrap__content">
                <div className="route__user__offers__selectUsers__wrap__content__search">
                    <FilterBySearch onChange={e => setSearch(e)} />
                </div>
                <div className="route__user__offers__selectUsers__wrap__content__buttons">
                    <StyledButton onClick={() => {
                        if (allUsers?.status !== "ok") return;
                        setSelectedUsers(allUsers.data.map(u => u.ID));
                    }}>
                        <img src="/images/filters/filterColumns_selectAll.svg" />
                        <span>Selet all</span>
                    </StyledButton>
                    <StyledButton onClick={() => setSelectedUsers([])}>
                        <img src="/images/filters/filterColumns_deselectAll.svg" />
                        <span>Deselect all</span>
                    </StyledButton>
                </div>

                <div className="route__user__offers__selectUsers__wrap__content__presets">
                    <div className="route__user__offers__selectUsers__wrap__content__presets__text">
                        <div className="route__user__offers__selectUsers__wrap__content__presets__text__left">Presets {!savedSelections && <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "16px", height: "16px"}} />}</div>
                        <div className="route__user__offers__selectUsers__wrap__content__presets__text__right">
                            <div className="route__user__offers__selectUsers__wrap__content__presets__text__right__button" onClick={() => {
                                if (Array.isArray(selectedUsers)) {
                                    if (selectedUsers.length > 0) {
                                        animateBox(<SaveAsPreset items={[...selectedUsers]} onChange={() => getPresets()} />)
                                    };
                                };
                            }}>
                                <img src="/images/icon_addRound.svg" />
                                <span>Save selection as preset</span>
                            </div>
                        </div>
                    </div>
                    {savedSelections?.status === "ok" && <div className="route__user__offers__selectUsers__wrap__content__presets__items">
                        {/* TODO: finish presets */}
                        {savedSelections.data.map(ss => {
                            return <div className="route__user__offers__selectUsers__wrap__content__presets__items__item" onClick={() => loadPreset(ss.Columns)}>
                                <p className="route__user__offers__selectUsers__wrap__content__presets__items__item__name">{ss.Name}</p>
                                <div className="route__user__offers__selectUsers__wrap__content__presets__items__item__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={(e) => {
                                    e.stopPropagation();
                                    removePreset(ss.ID);
                                }}></div>
                            </div>
                        })}
                    </div>}
                </div>

                {allUsers ? <>
                    {allUsers?.status === "ok" ? <>
                        <div className="route__user__offers__selectUsers__wrap__content__users">
                            {allUsers.data.sort((a, b) => {
                                if (!a.Team && b.Team) return 1;
                                if (a.Team && !b.Team) return 0;
                                if (a.Team === b.Team) {
                                    return a.Username > b.Username ? 1 : -1;
                                };
                                return a.Team > b.Team ? 1 : -1;
                            }).filter(au => {
                                if (search) {
                                    if (
                                        au.Username.toLowerCase().includes(search.toLowerCase()) ||
                                        String(au.Team).toLowerCase().includes(search)
                                    ) return true;
                                    return false;
                                };
                                return true;
                            }).map(usr => {
                                return <div className="route__user__offers__selectUsers__wrap__content__users__user">
                                    <RadioButton checked={selectedUsers.includes(usr.ID)} onChange={e => {
                                        if (e && !selectedUsers.includes(usr.ID)) {
                                            setSelectedUsers(su => [...su, usr.ID]);
                                        } else if (!e && selectedUsers.includes(usr.ID)) {
                                            setSelectedUsers(su => su.filter(suf => suf !== usr.ID));
                                        };
                                    }} />
                                    <span>{usr.Team ? <span style={{color: "rgb(69, 168, 255)"}}>[{usr.Team}]&nbsp;</span> : ""}{usr.Username}</span>
                                </div>
                            })}
                        </div>
                    </> : <p>There was an error while fetching users</p>}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} />}
            </div>

        </div>
    </div>
};

const AddOffer = (props) => {
    const [spinner, setSpinner] = React.useState();
    const [infoP, setInfoP] = React.useState({
        error: "",
        inputs: [],
        hadError: false
    });
    const [allOfferBrands, setAllOfferBrands] = React.useState();
    const [allUsers, setAllUsers] = React.useState();
    const [responsiblePerson, setResponsiblePerson] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [selectedOfferType, setSelectedOfferType] = React.useState();
    const [selectedOfferNiche, setSelectedOfferNiche] = React.useState();
    const [selectedActive, setSelectedActive] = React.useState(true);
    const [selectedOnHold, setSelectedOnHold] = React.useState(false);
    const [selectedBrand, setSelectedBrand] = React.useState();
    const [selectedGender, setSelectedGender] = React.useState(null);
    const [selectedAngles, setSelectedAngles] = React.useState([""]);
    const [selectedPersonas, setSelectedPersonas] = React.useState([""]);

    const nameRef = React.useRef();
    const descRef = React.useRef();
    const priceRef = React.useRef();
    const conditionRef = React.useRef();
    const scaleleadOfferIDRef = React.useRef();
    const demographicAgeStartRef = React.useRef();
    const demographicAgeEndRef = React.useRef();
    const keywordsRef = React.useRef();
    const dailyCapRef = React.useRef();

    const cms_displayNameRef = React.useRef();
    const cms_oldDisplayPriceRef = React.useRef();
    const cms_newDisplayPriceRef = React.useRef();

    const responsiblePersonResetRef = React.useRef();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const offerTypesSelector = useSelector(state => state?.types?.offerTypes ?? []);
    const offerNichesSelector = useSelector(state => state?.types?.offerNiches ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const onClose = (force = false) => {
        if (spinner && !force) return;
        return props.onClose();
    };

    const checkDifferent = (oldData, newData) => {
        if (oldData && !newData) return true;

        for (let key of Object.keys(oldData)) {
            if (!newData[key]) return true;

            if (typeof (oldData[key]) !== typeof (newData[key])) return true;

            if (typeof (oldData[key]) === "object" && !Array.isArray(oldData[key])) {
                let tmp = checkDifferent(oldData[key] ?? {}, newData[key] ?? {});
                if (tmp) return true;
            } else {
                if (oldData[key] !== newData[key]) return true;
            };
        };

        return false;
    };

    const addOffer = () => {
        setInfoP(i => { return { ...i, hadError: false, inputs: [] } });

        let data = {
            Name: nameRef.current.value,
            Brand: selectedBrand,
            Condition: conditionRef.current.value,
            Description: descRef.current.value,
            Price: priceRef.current.value,

            ResponsiblePerson: responsiblePerson,
            Country: selectedCountry,

            Type: selectedOfferType,
            Niche: selectedOfferNiche,
            isActive: selectedActive,
            isOnHold: selectedOnHold,
            Keywords: keywordsRef.current.value,
            Angles: Array.isArray(selectedAngles) ? selectedAngles.filter(f => f) : [],
            Personas: Array.isArray(selectedPersonas) ? selectedPersonas.filter(f => f) : [],
            DailyCap: dailyCapRef.current.value,

            TargetDemographicGender: selectedGender,
            TargetDemographicAgeStart: demographicAgeStartRef.current.value,
            TargetDemographicAgeEnd: demographicAgeEndRef.current.value,

            CMS_DisplayName: cms_displayNameRef.current.value,
            CMS_OldDisplayPrice: cms_oldDisplayPriceRef.current.value,
            CMS_NewDisplayPrice: cms_newDisplayPriceRef.current.value,

            ScaleleadOfferID: scaleleadOfferIDRef.current.value,
            IntegrationData: {}
        };
        if (!Array.isArray(data.ResponsiblePerson)) data.ResponsiblePerson = [];

        if (!data.Name) return setInfoP(i => { return { ...i, hadError: true, inputs: ["name"], error: "Name can't be empty" } });
        if (data.Price === null || data.Price === undefined) return setInfoP(i => { return { ...i, hadError: true, inputs: ["price"], error: "Price can't be empty" } });
        if (data.DailyCap !== "") {
            data.DailyCap = Number(data.DailyCap);
            if (isNaN(data.DailyCap)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["dailycap"], error: "Daily cap must be a number" } });
        } else {
            data.DailyCap = null;
        };

        data.Price = Number(data.Price.replace(",", "."));
        if (isNaN(data.Price)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["price"], error: "Price must be a number" } });

        data.TargetDemographicAgeStart = Number(data.TargetDemographicAgeStart);
        data.TargetDemographicAgeEnd = Number(data.TargetDemographicAgeEnd);
        if (isNaN(data.TargetDemographicAgeStart) || isNaN(data.TargetDemographicAgeEnd)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["demographic-age"], error: "Demographic age must be a number!" } });
        if (data.TargetDemographicAgeStart < 0 || data.TargetDemographicAgeEnd < 0) return setInfoP(i => { return { ...i, hadError: true, inputs: ["demographic-age"], error: "Demographic age is not valid" } });
        if (data.TargetDemographicAgeStart > data.TargetDemographicAgeEnd) return setInfoP(i => { return { ...i, hadError: true, inputs: ["demographic-age"], error: "Demographic age start can't be greater than the end" } });

        if (props.edit) {
            data["ID"] = props.edit.ID;
            data["IntegrationData"] = props.edit.IntegrationData;
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/${props.edit ? "editOffer" : "addOffer"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                onClose(true);
            } else {
                setInfoP(i => { return { ...i, hadError: true, inputs: [], error: `Error while ${props.edit ? "editing" : "adding"} an offer` } });
            };
        }).catch(() => {
            setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out." } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!props?.edit) return;
        if (props.edit?.ResponsiblePerson && Array.isArray(props.edit?.ResponsiblePerson)) setResponsiblePerson(props.edit.ResponsiblePerson);
        if (props.edit?.Country) setSelectedCountry(props.edit.Country);
        if (props.edit?.OfferType) setSelectedOfferType(props.edit.OfferType);
        if (props.edit?.OfferNiche) setSelectedOfferNiche(props.edit.OfferNiche);
        if (props.edit) setSelectedActive(!!props.edit.isActive);
        if (props.edit) setSelectedOnHold(!!props.edit.isOnHold);
        if (props.edit) setSelectedBrand(props.edit.OfferBrand);
        if (props.edit) setSelectedGender(props.edit.TargetDemographicGender);
        if (props.edit) setSelectedAngles(Array.isArray(props.edit.OfferAngles) ? [...props.edit.OfferAngles, ""] : [""]);
        if (props.edit) setSelectedPersonas(Array.isArray(props.edit.OfferPersonas) ? [...props.edit.OfferPersonas, ""] : [""]);
    }, [props.edit]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        })
    }, []);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOfferBrands`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllOfferBrands(res.data);
        }).catch(() => {
            setAllOfferBrands(backendModule.genericError);
        })
    }, []);

    return <div className="route__user__offers__add">
        <div className="route__user__offers__add__wrap">
            <div className="route__user__offers__add__wrap__head">
                <div className="route__user__offers__add__wrap__head__left">
                    {props.edit ? "Edit" : "Add"} offer
                </div>
                <div className="route__user__offers__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("name") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Offer name</p>
                <input ref={nameRef} type="text" placeholder="Offer name" defaultValue={props.edit?.OfferName} />
            </div>

            <div className="route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown">
                <p>Offer brand</p>
                {allOfferBrands ? <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Offer brand"
                    data={[
                        { value: null, name: "New brand", alwaysVisible: true, saveValue: true, showValue: true, style: { color: "rgb(146, 240, 157)" } },
                        ...(allOfferBrands?.status === "ok" ? allOfferBrands.data : []).map(brand => {
                            return { name: brand, value: brand };
                        })
                    ]}
                    onChange={e => {
                        setSelectedBrand(e?.value);
                    }}
                    selected={(() => {
                        if (!allOfferBrands) return null;
                        if (allOfferBrands.status !== "ok") return null;

                        let tmpIndex = allOfferBrands.data.indexOf(allOfferBrands.data.find(a => a === selectedBrand));
                        if (tmpIndex === -1) return null;
                        return tmpIndex + 1;
                    })()}
                /> : <Spinner style={{ width: "54px", height: "54px" }} color="white" />}
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("name") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Offer condition</p>
                <input ref={conditionRef} type="text" placeholder="Offer condition" defaultValue={props.edit?.OfferCondition} />
            </div>

            <div className="route__user__offers__add__wrap__input route__user__offers__add__wrap__input--text">
                <p>Description</p>
                <textarea ref={descRef} placeholder="Description" defaultValue={props.edit?.OfferDescription}></textarea>
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("price") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Offer price ({currencySignSelector})</p>
                <input ref={priceRef} type="text" placeholder="Offer price" defaultValue={props.edit?.OfferPrice} />
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>Responsible person</p>

                {allUsers?.status === "ok" && responsiblePerson.map((rp, rpIdx) => {
                    return <div className="route__user__offers__add__wrap__input__responsible">
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={[
                                ...(allUsers?.status === "ok" ? allUsers.data : []).map(usr => {
                                    return { name: usr.Username, value: usr.ID };
                                })
                            ]}
                            selected={(()=>{
                                if (!rp.ID) return null;
                                return allUsers.data.indexOf(allUsers.data.find(u => u.ID === rp.ID));
                            })()}
                            onChange={e => {
                                setResponsiblePerson((srp) => srp.map((srpm, srpmIdx) => {
                                    if (srpmIdx !== rpIdx) return srpm;
                                    let tmp = {...srpm, ID: e?.value};
                                    return tmp;
                                }));
                            }}
                        />
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            data={supportedIntegrationsSelector.map(si => {
                                return {name: <div className="route__user__offers__add__wrap__input__responsible__image">
                                    <img src={getTrackingProfileImage(si.NamedType)} />
                                    <span>{si.Name}</span>
                                </div>, value: si.Type}
                            })}
                            selected={(()=>{
                                if (rp.IntegrationID === null || rp.IntegrationID === undefined) return null;
                                return supportedIntegrationsSelector.indexOf(supportedIntegrationsSelector.find(si => si.Type === rp.IntegrationID));
                            })()}
                            onChange={e => {
                                setResponsiblePerson((srp) => srp.map((srpm, srpmIdx) => {
                                    if (srpmIdx !== rpIdx) return srpm;
                                    let tmp = {...srpm, IntegrationID: e?.value};
                                    return tmp;
                                }));
                            }}
                        />
                        <StyledButton style={{width: "100%", height: "100%", padding: 0}} isSecondary={true} onClick={() => {
                            setResponsiblePerson((srp) => srp.filter((_, srpIdx) => srpIdx !== rpIdx));
                        }}>
                            <img src="/images/icon_close.svg" />
                        </StyledButton>
                    </div>
                })}
                {allUsers ? <Dropdown
                    theme={themeSelector}
                    inlinePlaceholder="Add new responsible user"
                    accent="#6C5DD3"
                    data={[
                        ...(allUsers?.status === "ok" ? allUsers.data : []).map(usr => {
                            return { name: usr.Username, value: usr.ID };
                        })
                    ]}
                    onChange={e => {
                        setResponsiblePerson(rp => [...rp, {ID: e?.value, IntegrationID: -1}]);
                        responsiblePersonResetRef.current();
                    }}
                    onReset={e => responsiblePersonResetRef.current = e}
                /> : <Spinner style={{ width: "54px", height: "54px" }} color="white" />}
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>Country</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "No country", value: null },
                        ...(countries.map(c => {
                            return { name: c.name, value: c.code }
                        }))
                    ]}
                    selected={(() => {
                        if (!selectedCountry) return 0;

                        return countries.indexOf(countries.find(c => c?.code === selectedCountry)) + 1;
                    })()}
                    onChange={e => setSelectedCountry(e?.value)}
                />
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>Type</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "No type", value: null },
                        ...(offerTypesSelector.map(c => {
                            return { name: c, value: c }
                        }))
                    ]}
                    selected={(() => {
                        if (!selectedOfferType) return 0;

                        return offerTypesSelector.indexOf(offerTypesSelector.find(o => o === selectedOfferType)) + 1;
                    })()}
                    onChange={e => setSelectedOfferType(e?.value)}
                />
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>Niche</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        ...(offerNichesSelector.map(c => {
                            return { name: c, value: c }
                        }))
                    ]}
                    selected={(() => {
                        if (!selectedOfferNiche) return 0;

                        return offerNichesSelector.indexOf(offerNichesSelector.find(o => o === selectedOfferNiche));
                    })()}
                    onChange={e => setSelectedOfferNiche(e?.value)}
                />
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("scalelead_offerid") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Scalelead Offer ID (optional)</p>
                <input ref={scaleleadOfferIDRef} type="text" placeholder="Scalelead Offer ID" defaultValue={props.edit?.ScaleleadOfferID} />
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>Target demographic gender</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "Any", value: null },
                        { name: "Male", value: true },
                        { name: "Female", value: false }
                    ]}
                    selected={(() => {
                        if (selectedGender === null || selectedGender === undefined) return 0;
                        if (selectedGender) return 1;
                        return 2;
                    })()}
                    onChange={e => setSelectedGender(e?.value)}
                />
            </div>
            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--split ${infoP.inputs.includes("demographic-age") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Target demographic age</p>
                <input ref={demographicAgeStartRef} type="text" placeholder="Age start" defaultValue={props.edit?.TargetDemographicAgeStart ?? 0} />
                <input ref={demographicAgeEndRef} type="text" placeholder="Age end" defaultValue={props.edit?.TargetDemographicAgeEnd ?? 80} />
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("dailycap") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Daily cap</p>
                <input ref={dailyCapRef} type="text" placeholder="Daily cap" defaultValue={props.edit?.DailyCap} />
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>On hold</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "Yes", value: true },
                        { name: "No", value: false }
                    ]}
                    selected={(() => {
                        if (selectedOnHold) return 0;
                        return 1;
                    })()}
                    onChange={e => setSelectedOnHold(e?.value)}
                />
            </div>

            <div className={`route__user__offers__add__wrap__input route__user__offers__add__wrap__input--dropdown`}>
                <p>Active</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "Yes", value: true },
                        { name: "No", value: false }
                    ]}
                    selected={(() => {
                        if (selectedActive) return 0;
                        return 1;
                    })()}
                    onChange={e => setSelectedActive(e?.value)}
                />
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("angles") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Angles</p>
                {selectedAngles.map((angle, angleIdx) => {
                    return <input type="text" placeholder="Angle" value={angle} onChange={e => {
                        let val = e?.target?.value;

                        setSelectedAngles(angles => {
                            let tmp = [...angles];
                            tmp[angleIdx] = val;

                            tmp = tmp.filter(f => f);
                            tmp.push("");

                            return tmp;
                        });
                    }} />
                })}
            </div>
            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("personas") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>Personas</p>
                {selectedPersonas.map((angle, angleIdx) => {
                    return <input type="text" placeholder="Persona" value={angle} onChange={e => {
                        let val = e?.target?.value;

                        setSelectedPersonas(angles => {
                            let tmp = [...angles];
                            tmp[angleIdx] = val;

                            tmp = tmp.filter(f => f);
                            tmp.push("");

                            return tmp;
                        });
                    }} />
                })}
            </div>

            <div className="route__user__offers__add__wrap__input route__user__offers__add__wrap__input--text">
                <p>Keywords (seperated by comma)</p>
                <textarea ref={keywordsRef} defaultValue={props.edit?.OfferKeywords}></textarea>
            </div>

            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("cms_displayname") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>CMS Display name</p>
                <input ref={cms_displayNameRef} type="text" placeholder="Display name" defaultValue={props.edit?.CMS_DisplayName} />
            </div>
            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("cms_olddisplayprice") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>CMS Old display price</p>
                <input ref={cms_oldDisplayPriceRef} type="text" placeholder="Old display price" defaultValue={props.edit?.CMS_OldDisplayPrice} />
            </div>
            <div className={`route__user__offers__add__wrap__input ${infoP.inputs.includes("cms_newdisplayprice") ? "route__user__offers__add__wrap__input--error" : ""}`}>
                <p>CMS New display price</p>
                <input ref={cms_newDisplayPriceRef} type="text" placeholder="New display price" defaultValue={props.edit?.CMS_NewDisplayPrice} />
            </div>

            <div className="route__user__offers__add__wrap__buttons">
                <div className="route__user__offers__add__wrap__buttons__btn route__user__offers__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
                <div className="route__user__offers__add__wrap__buttons__btn" onClick={addOffer}>
                    {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : "Save"}
                </div>
            </div>

            <p className="route__admin__users__add__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

const GetDatasetsForOffer = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = async () => {
        if (!props.ID) return;

        let datasets = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/datasets/getAll`,
            data: {
                limit: null,
                offset: 0,
                filters: [
                    { name: "OfferID", op: "eq", value: props.ID }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (datasets.status === "error") return setData(datasets);

        let out = [];
        for (let item of datasets.data) {
            if (item.IntegrationID.startsWith("fb-")) {
                out.push(`fb:${item.Value?.PixelID ?? "?"}`);
            } else if (item.IntegrationID.startsWith("mg-")) {
                out.push(`mg:${item?.Value?.ClientID ?? "?"}`);
            };
        };

        setData({ status: "ok", data: out });
    };

    React.useEffect(() => {
        getData();
    }, [props.ID]);

    return <>
        {data ? <>
            {data.status === "ok" ? (data.data.length === 0 ? <span style={{ color: themeSelector === "dark" ? "#ffff60" : "#97972d" }}>No datasets</span> : data.data.join(", ")) : <span style={{ color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight }}>Error</span>}
        </> : <Spinner style={{ width: "16px", height: "16px" }} color={themeSelector === "dark" ? "white" : "black"} />}
    </>
};

const GetConversionsForOffer = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = async () => {
        if (!props.ID) return;
        if (!props.dates) return;

        setData();

        let finalStats = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                CampaignIDs: [],
                skipIntegrationDataPull: true,
                TableHeaders: ["Conversions"],
                filters: [
                    { name: "createdAt", op: "pdgeq", value: props.dates.start.toDate().getTime() },
                    { name: "createdAt", op: "pdleq", value: props.dates.end.toDate().getTime() },
                    { name: "OfferID", op: "eq", value: props.ID }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (finalStats.status === "error") return setData(backendModule.genericError);

        return setData({ status: "ok", data: finalStats?.data?.TableData?.Conversions ?? 0 });
    };

    React.useEffect(() => {
        getData();
    }, [props.ID, props.dates]);
    return <>
        {data ? <>
            {data.status === "ok" ? data.data : "0 ?"}
        </> : <Spinner style={{ width: "16px", height: "16px" }} color={themeSelector === "dark" ? "white" : "black"} />}
    </>

};

const GetCapAchievedForOffer = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = async () => {
        if (!props.ID) return;
        if (!props.dates) return;

        setData();

        let finalStats = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                CampaignIDs: [],
                skipIntegrationDataPull: true,
                TableHeaders: ["Conversions"],
                filters: [
                    { name: "createdAt", op: "pdgeq", value: props.dates.start.toDate().getTime() },
                    { name: "createdAt", op: "pdleq", value: props.dates.end.toDate().getTime() },
                    { name: "OfferID", op: "eq", value: props.ID }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (finalStats.status === "error") return setData(backendModule.genericError);

        let sd = moment(props.dates.start);
        let ed = moment(props.dates.end);
        let diff = ed.diff(sd, "days");
        if (!diff) diff = 1;

        let dailyCap = Number(props.DailyCap);
        let estimated = dailyCap * diff;
        
        let totalConversions = finalStats?.data?.TableData?.Conversions ?? 0;
        let capCompletedPercent = totalConversions / estimated * 100;

        if (capCompletedPercent === Number.POSITIVE_INFINITY || capCompletedPercent === Number.NEGATIVE_INFINITY) capCompletedPercent = 0;

        return setData({ status: "ok", data: `${capCompletedPercent.toFixed(0)}%` });
    };

    React.useEffect(() => {
        getData();
    }, [props.ID, props.dates, props.DailyCap]);
    return <>
        {data ? <>
            {data.status === "ok" ? data.data : "-"}
        </> : <Spinner style={{ width: "16px", height: "16px" }} color={themeSelector === "dark" ? "white" : "black"} />}
    </>

};

const OfferGallery = (props) => {
    const [data, setData] = React.useState();
    const [uploadSpinner, setUploadSpinner] = React.useState(false);

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getOfferImages`,
            data: {
                ID: props.ID,
                isGallery: props.gallery
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const deleteImageReq = async image => {
        let imgName = image.url.split("/").pop();

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}${image.type === "image" ? "/images/deleteImageByFilename" : "/files/deleteFileByFilename"}`,
            data: {
                FileName: imgName
            },
            ...backendModule.axiosConfig
        }).catch(() => null);
    };

    const deleteImage = async (e, image) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="File will be permanently deleted, are you sure?"
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.spinner(true);
                args.disabledAll(true);

                await deleteImageReq({ url: image.URL, type: image.Type });
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/offers/removedOfferImage`,
                    data: {
                        ID: props.ID,
                        Image: image.URL,
                        isGallery: props.gallery
                    },
                    ...backendModule.axiosConfig
                }).catch(() => null);
                getData();

                args.spinner(false);
                args.disabledAll(false);
                args.close();
            }}
        />);
        // await deleteImageReq(image);
        // getData();
    };

    const uploadImages = async images => {
        setUploadSpinner(true);

        let curImages = [];
        for (let image of images) {
            let fileType = "file";
            if (image.type.startsWith("image")) {
                fileType = "image";
            } else if (image.type.startsWith("video")) {
                fileType = "video";
            };

            if (props.gallery && fileType !== "image") continue;

            let fd = new FormData();
            if (fileType === "image") {
                fd.append("ImageName", `offer-${props.ID}`);
                fd.append("tag", "offer");
                fd.append("image", image);
            } else {
                fd.append("FileName", `offer-${props.ID}`);
                fd.append("tag", "offer");
                fd.append("file", image);
                fd.append("FileType", fileType);
            };

            let upload = await axios({
                method: "POST",
                url: `${backendModule.backendURL}${fileType === "image" ? "/images/uploadImage" : "/files/uploadFile"}`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => res.data.status === "ok" ? res.data.data?.url : null).catch(() => null);

            if (upload) {
                curImages.push({ url: upload, type: fileType });
            } else {
                for (let img of curImages) await deleteImageReq(img);
                break;
            };

            let final = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/offers/adddOfferImage`,
                data: {
                    ID: props.ID,
                    Image: upload,
                    Type: fileType,
                    Name: image.name,
                    isGallery: props.gallery
                },
                ...backendModule.axiosConfig
            }).then(res => res.data.status === "ok" ? true : false).catch(() => false);

            if (!final) {
                for (let img of curImages) await deleteImageReq(img);
                break;
            };
        };

        setUploadSpinner(false);
        getData();
    };

    const getImageName = url => {
        let tmpName = url.split("/").pop();
        tmpName = tmpName.split(".");
        tmpName.pop();
        return tmpName.join(".");
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__user__offers__gallery genericModal">
        <div className="route__user__offers__gallery__wrap genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.gallery ? "Product photos" : "Creatives"}</div>
                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isManager) && <div className="route__user__offers__gallery__wrap__head__middle">
                    <StyledButton isSpinner={uploadSpinner} onClick={e => {
                        e.target.parentNode.parentNode.querySelector("input[type=file]")?.click?.();
                    }}>Add</StyledButton>
                </div>}
                {!uploadSpinner && <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>}

                <input type="file" accept={props.gallery ? "image/*" : "*/*"} style={{ display: "none" }} multiple={true} onChange={e => uploadImages(e.target.files)} />
            </div>

            <div className="route__user__offers__gallery__wrap__images">
                {data ? <>
                    {data.status === "ok" ? <>
                        {data.data.map(img => {
                            return <div className="route__user__offers__gallery__wrap__images__img">
                                <p className="route__user__offers__gallery__wrap__images__img__name">[{img.Type}] {img.Name ?? getImageName(img.URL)}</p>
                                <p className="route__user__offers__gallery__wrap__images__img__date">{moment(img.Date).toDate().toLocaleString()}</p>
                                <img src={(() => {
                                    if (img.Type === "image") return img.URL;
                                    if (img.Type === "video") return "/images/fileTypes/video.svg";
                                    return "/images/fileTypes/file.svg";
                                })()} onClick={e => {
                                    if (img.Type === "image") return animateBox({ currentTarget: e.target.parentNode }, <PreviewImageModal image={img.URL} />);
                                    if (img.Type === "video") return animateBox({ currentTarget: e.target.parentNode }, <PreviewVideoModal video={img.URL} />)
                                }} />
                                <div className="route__user__offers__gallery__wrap__images__img__buttons">
                                    {img.Type === "file" && <StyledButton isSecondary={false} style={{ padding: "0 10px" }} onClick={e => window.open(img.URL, "_blank")}>Download</StyledButton>}
                                    {(img.Type === "video" || img.Type === "image") && <StyledButton isSecondary={false} style={{ padding: "0 10px" }} onClick={e => window.open((() => {
                                        let tmpURL = img.URL;
                                        tmpURL += tmpURL.includes("?") ? "&" : "?";
                                        tmpURL += "download=1";
                                        return tmpURL;
                                    })(), "_blank", "download")}>Download</StyledButton>}
                                    {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isManager) && <StyledButton isSpinner={uploadSpinner} isSecondary={true} style={{ padding: "0 10px" }} onClick={e => deleteImage(e, img)}>Remove</StyledButton>}
                                </div>
                            </div>
                        })}
                        {data.data.length === 0 && <p style={{ gridColumn: "1 / span all" }}>Nothing to show...</p>}
                    </> : <p className="genericModal__wrap__infoP" style={{ opacity: 1, gridColumn: "1 / span all" }}>Error while fetching images!</p>}
                </> : <Spinner color="white" />}
            </div>
        </div>
    </div>
};

const OfferSettings = props => {
    const [offerCount, setOfferCount] = React.useState();
    const [offerSpinner, setOfferSpinner] = React.useState(false);
    const [settings, setSettings] = React.useState(props.settings ?? {});
    const [exportColumns, setExportColumns] = React.useState([]);

    const wrapRef = React.useRef();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const onClose = () => {
        if (props.onChange) props.onChange(settings);

        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const getOfferIDs = (e, onlySelected = false) => {
        setOfferSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: props.filters ?? [],
                extended: true,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let rData = res.data.data;
                if (onlySelected) rData = rData.filter(f => props.selected.includes(f?.ID));
                let final = rData.map(d => d?.ScaleleadOfferID).filter(t => t);

                animateBox({ currentTarget: e?.target }, <TrackingScript
                    heading="Scalelead Offer IDs"
                    data={final.join("\n")}
                />);
            } else {
                animateBox({ currentTarget: e?.target }, <YesNoModal
                    heading="Error"
                    text="There was an error while fetching data, please try again later!"
                    buttonLeftHidden={true}
                    buttonRightText={"Ok"}
                    isRightButtonNormal={true}
                />);
            };
        }).catch(() => {
            animateBox({ currentTarget: e?.target }, <YesNoModal
                heading="Error"
                text="There was an error while fetching data, please try again later!"
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setOfferSpinner(false);
        });
    };

    const exportOfferAsCSV = e => {
        let curTarget = { currentTarget: e?.target };

        if (exportColumns.length === 0) {
            return animateBox(curTarget, <YesNoModal
                heading="No columns selected"
                text="Select at least 1 column before attempting to export the data."
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
            />)
        };

        setOfferSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                extended: true,
                filters: props.filters ?? [],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let out = [];

                for (let item of res.data.data) {
                    let tmp = {};

                    for (let col of exportColumns) {
                        if (col === "ID") tmp[col] = item.ID;
                        if (col === "Name") tmp[col] = item.OfferName;
                        if (col === "Condition") tmp[col] = item.OfferCondition ?? "";
                        if (col === "Responsible") tmp[col] = item.ResponsiblePerson?.length > 0 ? (item.ResponsiblePerson.map(rp => rp?.Username).join(", ") ?? "Unknown user") : "";
                        if (col === "Price") tmp[col] = `${Number(item.OfferPrice).toFixed(2)} ${currencySignSelector}`;
                        if (col === "Country") {
                            let curCountry = countries.find(c => c.code.toLowerCase() === item.Country?.toLowerCase?.());
                            tmp[col] = curCountry ? curCountry.name : "";
                        };
                        if (col === "Type") tmp[col] = item.OfferType ?? "";
                        if (col === "Niche") tmp[col] = item.OfferNiche ?? "";
                        if (col === "Status") tmp[col] = item.isOnHold ? "On hold" : (item.isActive ? "Active" : "Inactive");
                        if (col === "Scalelead Offer ID") tmp[col] = item.ScaleleadOfferID ?? "";
                        if (col === "Brand") tmp[col] = item.OfferBrand;
                        if (col === "Assigned at") tmp[col] = (new Date(item.Assigned)).toLocaleString();
                        if (col === "Target demographic") tmp[col] = `${item.TargetDemographicGender === null ? "Any" : (item.TargetDemographicGender ? "Male" : "Female")}, ${item.TargetDemographicAgeStart}-${item.TargetDemographicAgeEnd}`;
                        if (col === "Daily cap") tmp[col] = item.DailyCap ?? "";
                    };

                    out.push(tmp);
                };

                arrayToCSVString(out).then(data => {
                    if (data.status === "ok") {
                        let file = new Blob([data.data]);
                        var a = document.createElement("a"),
                            url = URL.createObjectURL(file);
                        a.href = url;
                        a.download = `ScaleTrack-Offers-${new Date().toLocaleString().replace(".", "-").replace(" ", "-").replace("_", "-").replace(",", "-")}.csv`;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(url);
                        }, 0);
                    } else {
                        animateBox(curTarget, <YesNoModal
                            heading="Error"
                            text="There was an error while fetching data, please try again later!"
                            buttonLeftHidden={true}
                            buttonRightText={"Ok"}
                            isRightButtonNormal={true}
                        />);
                    };
                }).catch(() => {
                    animateBox(curTarget, <YesNoModal
                        heading="Error"
                        text="There was an error while fetching data, please try again later!"
                        buttonLeftHidden={true}
                        buttonRightText={"Ok"}
                        isRightButtonNormal={true}
                    />);
                });
            } else {
                animateBox(curTarget, <YesNoModal
                    heading="Error"
                    text="There was an error while fetching data, please try again later!"
                    buttonLeftHidden={true}
                    buttonRightText={"Ok"}
                    isRightButtonNormal={true}
                />);
            };
        }).catch((e) => {
            animateBox(curTarget, <YesNoModal
                heading="Error"
                text="There was an error while fetching data, please try again later!"
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
            />);
        }).finally(() => {
            setOfferSpinner(false);
        });
    };

    const getOffersCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffersCount`,
            data: {
                filters: props.filters ?? []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOfferCount(res.data);
        }).catch(() => {
            setOfferCount(backendModule.genericError);
        });
    };

    const testCRMIDs = e => {
        animateBox(e, <TestCRMOffers />);
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getOffersCount();
    }, [props.filters]);

    return <div className="route__user__offers__settings" onClick={() => onClose()}>
        <div className="route__user__offers__settings__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__offers__settings__wrap__top">
                <div className="route__user__offers__settings__wrap__top__left">Settings</div>
                <div className="route__user__offers__settings__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__offers__settings__wrap__content">

                <p className="route__user__offers__settings__wrap__content__checkbox">
                    <Checkbox checked={settings.showSensitive} onChange={e => {
                        setSettings(s => {
                            return { ...s, ["showSensitive"]: !!e };
                        });
                    }} />
                    Show sensitive information
                </p>

                <div className="route__user__offers__settings__wrap__content__input" onClick={getOfferIDs}>{offerSpinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : `Get all Scalelead Offer IDs (${offerCount ? (offerCount.status === "ok" ? offerCount.data : "?") : <Spinner style={{ width: "17px", height: "17px" }} color="white" />})`}</div>
                <div className="route__user__offers__settings__wrap__content__input" onClick={e => getOfferIDs(e, true)}>{offerSpinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : `Get selected Scalelead Offer IDs (${Array.isArray(props.selected) ? props.selected.length : 0})`}</div>

                <div className="route__user__offers__settings__wrap__content__split">
                    <div className="route__user__offers__settings__wrap__content__input" onClick={e => exportOfferAsCSV(e)}>{offerSpinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : `Export offers as CSV (${offerCount ? (offerCount.status === "ok" ? offerCount.data : "?") : <Spinner style={{ width: "17px", height: "17px" }} color="white" />})`}</div>
                    <FilterColumns columns={allExportColumns.filter(i => !["Conversions", "Datasets"].includes(i))} defaultValue={exportColumns} onChange={e => setExportColumns(e)} />
                </div>

                <div className="route__user__offers__settings__wrap__content__input" style={{ marginTop: "20px" }} onClick={e => animateBox(e, <OfferWeeklyReport selected={props.selected} />)}>Get offer report ({Array.isArray(props.selected) ? (props.selected.length === 0 ? "all" : props.selected.length) : "all"})</div>

                <p className="route__user__offers__settings__wrap__content__info">NOTE: All export buttons will take search into the account, so you can narrow down what you actually export!</p>

                <br />
                <br />

                <div className="route__user__offers__settings__wrap__content__input" onClick={e => testCRMIDs(e)}>Test Scale-CRM IDs</div>
            </div>

        </div>
    </div>
};

const OfferWeeklyReport = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState();
    const [progressData, setProgressData] = React.useState();
    const [useDecimals, setUseDecimals] = React.useState(true);
    const [includeDates, setIncludeDates] = React.useState(false);
    const [includeDr, setIncludeDr] = React.useState(false);
    const [includeAvgProcessTime, setIncludeAvgProcessTime] = React.useState(false);
    const [includeScore, setIncludeScore] = React.useState(false);
    const [groupDataBy, setGroupDataBy] = React.useState("week");
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: ""
    });
    const [includeCampaignTypes, setIncludeCampaignTypes] = React.useState({
        traffic: true,
        leads: true,
        sales: true,
        forms: true
    });
    const [includeIntegrations, setIncludeIntegrations] = React.useState({
        "-1": true,
        "-2": true,
        "-3": true,
        "mg": true,
        "fb": true,
        "md": true,
        "an": true
    });
    const [includeFacebookStats, setIncludeFacebookStats] = React.useState(false);

    const scoreCalculationsSelector = useSelector(state => state?.types?.scoreCalculations ?? {});

    const converSecondsToString = (d) => {
        let [h, m, s] = [Math.floor(d / 3600), Math.floor(d % 3600 / 60), Math.floor(d % 3600 % 60)];

        if (h > 0) return `${h}h:${m}m`;
        return `${m}m`;
    };

    const grabFacebookCampaignData = async (type, filters) => {
        let cmpTmp = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                allUsers: true,
                limit: null,
                offset: 0,
                filters: [
                    { name: "CampaignType", op: "eq", value: type },
                    { name: "IntegrationType", op: "eq", value: 0 }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (cmpTmp.status === "error") return {};
        if (cmpTmp.data.length === 0) return {};

        let totalData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                skipIntegrationDataPull: false,
                TableHeaders: [
                    "Visits",
                    "CPA",
                    "CPAO",
                    "Conversions",
                    "Approved",
                    "PLC"
                ],
                CampaignIDs: cmpTmp.data.map(c => c.ID),
                filters: [
                    ...(Array.isArray(filters) ? filters : [])
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (totalData.status === "error") return {};

        let finalData = await generateColumns(grabTableData(totalData.data));
        let out = {};
        for (let key of Object.keys(finalData)) {
            out[`FB-${type}-${key}`] = finalData[key];
        };
        return out;
    };

    const getCRMAverageLeadProcess = async leads => {
        if (leads.length === 0) return 0;

        let total = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/scalecrm/getAverageLeadProcessDuration`,
            data: {
                IDs: leads
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);

        if (total.status === "ok") return total.data / 1000;
        return 0;
    };

    const sumWeekData = offersWeek => {
        let out = {};

        for (let offer of offersWeek) {
            for (let key of Object.keys(offer)) {
                if (!out[key]) out[key] = 0;

                out[key] += offer[key];
            };
        };

        return out;
    };

    const grabTableData = item => {
        if (item.Integrations) {
            let out = {};
            for (let key of Object.keys(item.Integrations)) {
                if (item.Integrations[key]?.TableData) {
                    let td = item.Integrations[key].TableData;
                    for (let tdk of Object.keys(td)) {
                        if (tdk.startsWith("--")) {
                            if (!out[tdk]) out[tdk] = [];
                            out[tdk].push(...td[tdk]);
                        } else {
                            if (!out[tdk]) out[tdk] = 0;
                            out[tdk] += +td[tdk];
                        };
                    };
                };
            };
            return out;
        };

        return item.TableData;
    };

    const generateColumns = async item => {
        let out = {
            "Conversions": item["Conversions"] ?? 0,
            "PLC": item["PLC"] ?? 0,
            "Approved": item["Approved"] ?? 0,
            "Visits": item["Visits"] ?? 0,
            "Spent": (item["Spent_IN"] || item["Spent"]) ?? 0,
            "DR": item["DR"] ?? null,
            "ADP": item["ADP"] ?? null
        };
        if (includeAvgProcessTime) {
            let tmpIDs = []
            if (item["--scalecrm_lead"]) tmpIDs.push(...item["--scalecrm_lead"]);
            out["h"] = await getCRMAverageLeadProcess(tmpIDs);
        };

        return out;
    };

    const prepareTotalRow = offers => {
        let out = {};
        for (let offer of offers) {
            for (let key of Object.keys(offer.stats.total)) {
                if (!out[key]) out[key] = 0;
                out[key] += +offer.stats.total[key];
            };
        };

        if (out["h"]) {
            out["h"] /= offers.filter(o => o.stats.total["h"]).length;
            if (isNaN(out["h"])) out["h"] = 0;
        };

        return out;
    };

    const splitOffersIntoWeeks = offers => {
        let out = [];

        while (true) {
            let tmp = [];
            let shouldBreak = false;
            for (let offer of offers) {
                if (offer.stats.weeks.length === 0) {
                    shouldBreak = true;
                    break;
                };

                tmp.push(offer.stats.weeks.shift());
            };
            if (shouldBreak) break;
            out.push(tmp);
            tmp = [];
        };

        return out;
    };

    const calculateData = item => {
        let out = {
            "CR %": item["Conversions"] === 0 ? 0 : 100 / item["Visits"] * item["Conversions"],
            "AR %": item["Approved"] === 0 ? 0 : 100 / item["PLC"] * item["Approved"],
            "Leads": item["Conversions"],
            "Processed": item["PLC"],
            ...(includeAvgProcessTime ? { h: converSecondsToString(item["h"]) } : {}),
            "Deals": item["Approved"],
            "ADP": item["ADP"],
            "CPL": item["Conversions"] === 0 ? 0 : item["Spent"] / item["Conversions"],
            "CPAO": item["Approved"] === 0 ? 0 : item["Spent"] / item["Approved"],
            ...(includeDr ? { "DR": item["DR"] } : {})
        };

        if (item["Score"] !== null && item["Score"] !== undefined && includeScore) {
            out["Score"] = "-";

            if (item?.["_Offer"]?.Country) {
                let shipmentPrices = scoreCalculationsSelector?.shipmentPrices ?? { default: 5 };
                let shipmentFn = scoreCalculationsSelector?.getCalculation;
                if (shipmentPrices && shipmentFn) {
                    let canContinue = true;
                    try {
                        shipmentFn = eval(shipmentFn);
                    } catch {
                        canContinue = false;
                    };

                    let shipPrice = shipmentPrices[item._Offer.Country ?? "default"];
                    if (shipPrice === null || shipPrice === undefined) shipPrice = shipmentPrices["default"];

                    if (canContinue) {
                        out["Score"] = shipmentFn(out["ADP"], out["CPAO"], item["DR"], shipPrice);
                    };
                };
            };
        };

        if (includeFacebookStats) {
            let tmpOut = {};
            for (let key of Object.keys(includeCampaignTypes)) {
                if (!tmpOut[key]) tmpOut[key] = {};
                for (let fbKey of Object.keys(item)) {
                    if (fbKey.startsWith(`FB-${key}`)) tmpOut[key][fbKey.replace(`FB-${key}-`, "")] = item[fbKey];
                };
            };

            let finalOut = {};
            for (let key of Object.keys(tmpOut)) {
                let curData = tmpOut[key];

                if (!curData["Conversions"]) curData["Conversions"] = 0;
                if (!curData["Visits"]) curData["Visits"] = 0;
                if (!curData["Approved"]) curData["Approved"] = 0;
                if (!curData["PLC"]) curData["PLC"] = 0;

                let percent_converted = 100 / out.Leads * curData["Conversions"];
                if (isNaN(percent_converted)) percent_converted = 0;
                finalOut[`FB-CMP-TYP-${key} (% & sum)`] = `${Number(percent_converted).toFixed(0)}%, ${curData["Conversions"]}`;

                let conversionsPercent = curData["Conversions"] === 0 ? 0 : 100 / curData["Visits"] * curData["Conversions"];
                finalOut[`FB-CMP-TYP-CR-${key}`] = Number(conversionsPercent).toFixed(0);

                let approvedPercent = curData["Approved"] === 0 ? 0 : 100 / curData["PLC"] * curData["Approved"];
                finalOut[`FB-CMP-TYP-AR-${key}`] = Number(approvedPercent).toFixed(0);
            };

            out = { ...out, ...finalOut };
        };

        for (let key of Object.keys(out)) {
            if (key === "h") continue;
            if (key.startsWith("FB-")) continue;
            if (isNaN(out[key])) out[key] = 0;

            out[key] = Number(out[key]).toFixed(useDecimals ? 2 : 0)
        };

        return out;
    };

    const generateReport = async () => {
        if (spinner) return;

        setSpinner(true);
        setInfoP(ip => { return { ...ip, hadError: false, inputs: [] } });

        let allOffers = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                IntegrationType: undefined,
                filters: props.selected.length > 0 ? [{ name: "ID", op: "in", value: props.selected }] : []
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);

        if (allOffers.status === "error") {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, error: "Error while fetching offers" } });
        };
        if (allOffers.data.length === 0) {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, error: "No offers found" } });
        };
        allOffers = allOffers.data;

        let allSites = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null,
                IntegrationType: undefined,
                filters: [
                    { name: "OfferID", op: "in", value: allOffers.map(o => o.ID) }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (allSites.status === "error") {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, error: "Error while fetching sites" } });
        };
        if (allSites.data.length === 0) {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, error: "No sites found" } });
        };
        allSites = allSites.data;

        // merge sites and offers
        for (let offer of allOffers) {
            let foundSites = allSites.filter(s => s.OfferID === offer.ID).map(s => s.ID);

            offer.SiteIDs = foundSites;
        };
        // remove unused offers
        allOffers = allOffers.filter(o => o.SiteIDs.length > 0);

        // prepare date by weeks
        let dateStart = moment(selectedDate.start);
        let dateEnd = moment(selectedDate.end);
        let weeks = [];

        if (groupDataBy === "week") {
            while (dateStart.isBefore(dateEnd)) {
                let s = moment(dateStart.toDate().getTime()).startOf("day").toDate().getTime();
                let e = moment(dateStart.toDate().getTime()).add(6, "days").endOf("day").toDate().getTime();
                weeks.push({ start: s, end: e });

                dateStart = moment(dateStart.toDate().getTime()).add(7, "days");
            };
        } else if (groupDataBy === "day") {
            while (dateStart.isBefore(dateEnd)) {
                let s = moment(dateStart.toDate().getTime()).startOf("day").toDate().getTime();
                let e = moment(dateStart.toDate().getTime()).endOf("day").toDate().getTime();
                weeks.push({ start: s, end: e });

                dateStart = moment(dateStart.toDate().getTime()).add(1, "days");
            };
        };

        if (weeks.length === 0 && groupDataBy) {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, error: "Error while creating weeks, check your date" } });
            return;
        };

        // getAllCampaignsWithoutData
        let allCampaignIDs = [];
        if (
            Object.keys(includeCampaignTypes).some(key => !includeCampaignTypes[key]) ||
            Object.keys(includeIntegrations).some(key => !includeIntegrations[key]) ||
            includeFacebookStats
        ) {
            let acceptedCampaigns = Object.keys(includeCampaignTypes).filter(k => includeCampaignTypes[k]);
            let acceptedIntegrations = Object.keys(includeIntegrations)
                .filter(k => includeIntegrations[k])
                .map(k => {
                    if (k == "-1") return null;
                    if (k == "fb") return 0;
                    if (k == "mg") return 1;
                    if (k === "md") return 4;
                    if (k === "an") return 5;
                    return +k;
                });
            if (acceptedCampaigns.length === 0) {
                setSpinner(false);
                setInfoP(ip => { return { ...ip, hadError: true, error: "At least 1 campaign type has to be selected." } });
                return;
            };
            if (acceptedIntegrations.length === 0) {
                setSpinner(false);
                setInfoP(ip => { return { ...ip, hadError: true, error: "At least 1 integration has to be selected." } });
                return;
            };

            let cmpTmp = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
                data: {
                    allUsers: true,
                    limit: null,
                    offset: 0,
                    filters: [
                        { name: "CampaignType", op: "in", value: acceptedCampaigns },
                        { name: "IntegrationType", op: "in", value: acceptedIntegrations }
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => res.data).catch(() => backendModule.genericError);

            if (cmpTmp.status === "error") {
                setSpinner(false);
                setInfoP(ip => { return { ...ip, hadError: true, error: "Error while fetching campaigns for further data filtering!" } });
                return;
            };
            if (cmpTmp.data.length === 0) {
                setSpinner(false);
                setInfoP(ip => { return { ...ip, hadError: true, error: "No campaigns with the specified filters were found!" } });
                return;
            };
            allCampaignIDs = cmpTmp.data.map(c => c.ID);
        };

        let curTableHeaders = [
            "Visits",
            ((includeDr || includeScore) ? "DR" : null),
            "CPA",
            "CPAO",
            "Conversions",
            "Approved",
            "PLC",
            "Spent",
            "Spent_IN",
            "ADP",
            (includeAvgProcessTime ? "--scalecrm_lead" : null)
        ].filter(t => t);
        let finalOffers = [];
        setProgressData({ max: allOffers.length * (weeks.length > 0 ? weeks.length : 1), cur: 0 });
        for (let offer of allOffers) {
            let finalWeeks = [];
            let totalData = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                data: {
                    allUsers: true,
                    skipIntegrationDataPull: false,
                    TableHeaders: curTableHeaders,
                    CampaignIDs: allCampaignIDs,
                    filters: [
                        { name: "createdAt", op: "pdgeq", value: selectedDate.start.toDate().getTime() },
                        { name: "createdAt", op: "pdleq", value: selectedDate.end.toDate().getTime() },
                        { name: "SiteID", op: "in", value: offer.SiteIDs.flat(4) }
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => res.data).catch(() => backendModule.genericError);
            if (totalData.status === "error") continue;
            totalData.data = await generateColumns(grabTableData(totalData.data));
            if (includeScore) {
                totalData.data["Score"] = "-";
                totalData.data["_Offer"] = offer;
            };

            if (includeFacebookStats) {
                for (let key of Object.keys(includeCampaignTypes)) {
                    let tmp = await grabFacebookCampaignData(key, [
                        { name: "createdAt", op: "pdgeq", value: selectedDate.start.toDate().getTime() },
                        { name: "createdAt", op: "pdleq", value: selectedDate.end.toDate().getTime() },
                        { name: "SiteID", op: "in", value: offer.SiteIDs.flat(4) }
                    ]);
                    for (let finalKey of Object.keys(tmp)) {
                        totalData.data[finalKey] = tmp[finalKey];
                    };
                };
            };

            if (weeks.length === 1 || !groupDataBy) {
                finalWeeks.push(totalData.data);
                setProgressData(pd => { return { ...pd, cur: pd.cur += 1 } });
            } else {
                for (let week of weeks) {
                    setProgressData(pd => { return { ...pd, cur: pd.cur += 1 } });
                    let weekData = await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                        data: {
                            allUsers: true,
                            skipIntegrationDataPull: false,
                            TableHeaders: curTableHeaders,
                            CampaignIDs: allCampaignIDs,
                            filters: [
                                { name: "createdAt", op: "pdgeq", value: week.start },
                                { name: "createdAt", op: "pdleq", value: week.end },
                                { name: "SiteID", op: "in", value: offer.SiteIDs.flat(4) }
                            ]
                        },
                        ...backendModule.axiosConfig
                    }).then(res => res.data).catch(() => backendModule.genericError);
                    if (weekData.status === "ok") {
                        let tmpWeekData = await generateColumns(grabTableData(weekData.data));
                        if (includeFacebookStats) {
                            for (let key of Object.keys(includeCampaignTypes)) {
                                let tmp = await grabFacebookCampaignData(key, [
                                    { name: "createdAt", op: "pdgeq", value: week.start },
                                    { name: "createdAt", op: "pdleq", value: week.end },
                                    { name: "SiteID", op: "in", value: offer.SiteIDs.flat(4) }
                                ]);
                                for (let finalKey of Object.keys(tmp)) {
                                    tmpWeekData[finalKey] = tmp[finalKey];
                                };
                            };
                        };
                        finalWeeks.push(tmpWeekData);
                    };
                };
            };

            finalOffers.push({
                ...offer,
                stats: {
                    total: totalData.data,
                    weeks: finalWeeks
                }
            });
        };
        let csvArray = [];

        for (let offer of finalOffers) {
            let totalData = calculateData(offer.stats.total);
            let csvItem = {
                "Offer": `${offer.OfferName} (${offer.Country ?? "?"}, ${offer.OfferCondition ?? "-"})`,
                ...totalData
            };
            if (offer.stats.weeks.length > 0 && groupDataBy) {
                for (let key of Object.keys(totalData)) {
                    let counter = 1;
                    let nameKey = key.replace(" %", "");
                    for (let w of offer.stats.weeks) {
                        let wFinal = calculateData(w);
                        if (wFinal[key] !== undefined) csvItem[`${nameKey}-${counter}`] = wFinal[key];
                        counter += 1;
                    };
                };
            };

            if (includeDates && groupDataBy) {
                let counter = 1;
                for (let week of weeks) {
                    csvItem[`Date-${counter}`] = `${moment(week.start).format("DD.MM.YYYY")} - ${moment(week.end).format("DD.MM.YYYY")}`
                    counter += 1;
                };
            };
            if (includeDates && !groupDataBy) {
                csvItem["Date"] = `${selectedDate.start.format("DD.MM.YYYY")} - ${selectedDate.end.format("DD.MM.YYYY")}`;
            };

            csvArray.push(csvItem);
        };

        if (csvArray.length === 0) {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, error: "Nothing to export (no data found)." } });
            return;
        };

        let totalRow = calculateData(prepareTotalRow(finalOffers));
        let finalTotal = {
            "Offer": "Total"
        };
        for (let key of Object.keys(totalRow)) {
            finalTotal[key] = totalRow[key];
        };
        csvArray.push(finalTotal);

        // week summary
        if (groupDataBy) {
            let weekSum = splitOffersIntoWeeks(finalOffers);

            let counter = 1;
            for (let week of weekSum) {
                let total = sumWeekData(week);
                if (total["h"] && includeAvgProcessTime) total["h"] /= week.filter(w => w["h"]).length;
                total = calculateData(total);

                for (let key of Object.keys(total)) {
                    let nameKey = key.replace(" %", "");
                    finalTotal[`${nameKey}-${counter}`] = total[key];
                };

                counter++;
            };
        };

        setSpinner(false);
        setProgressData(null);

        let keysSplit = [...Object.keys(csvArray[0])];

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet("Offer report");
        let headersInserted = [];
        for (let elem of csvArray) {
            if (headersInserted.length === 0) {
                headersInserted = Object.keys(elem).map(key => {
                    let nameKey = key;
                    if (key === "h") nameKey = "Hold/h";
                    if (nameKey.startsWith("h-")) nameKey = nameKey.replace("h-", "hold-");
                    return { header: nameKey, key: key };
                });
                break;
            };
        };

        sheet.addTable({
            name: "Offer report",
            "ref": "A1",
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: headersInserted.map(hi => { return { name: hi.header } }),
            rows: csvArray.map(c => {
                let out = [];
                for (let item of keysSplit) {
                    out.push(c[item]);
                };
                return out;
            })
        });
        sheet.views = [
            { state: "frozen", ySplit: 1, xSplit: 1 }
        ];
        sheet.columns.forEach((col, colIdx) => {
            if (colIdx === 0) return;
            col.eachCell((cell, row) => {
                cell.alignment = { indent: 2 };
                if (row === 1) return;
                if (cell === null || cell === undefined) return;
                if (!cell.value) {
                    cell.value = "";
                    return;
                };
                if (!isNaN(Number(cell.value))) {
                    cell.value = +cell.value;
                    cell.numFmt = useDecimals ? '###,##0.00' : '#,##0';
                };
            });
        });

        sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        workbook.xlsx.writeBuffer().then(data => {
            let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = `ScaleTrack-Offer-Report-${moment(selectedDate.start).format("DD_MM_YYYY")}-${moment(selectedDate.end).format("DD_MM_YYYY")}.xlsx`;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                props.onClose();
            }, 0);
        }).catch(() => {
            setInfoP(ip => { return { ...ip, hadError: true, error: "A critical error occured while creating an CSV. Please try again later!" } });
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Offer report</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{ backgroundImage: `url("/images/icon_close.svg")` }}></div>
            </div>

            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p>Select the date from which to generate the report</p>
                <FilterByDate onChange={e => setSelectedDate(e)} disableAll={true} defaultValue="thisMonth" disable24h={true} />
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <AdvancedDropdown
                    headline="Group data by"
                    data={[
                        { key: "0", name: "No grouping", value: null },
                        { key: "1", name: "Day", value: "day" },
                        { key: "2", name: "Week", value: "week" },
                    ]}
                    onChange={e => setGroupDataBy(e?.value)}
                    selected={(() => {
                        if (!groupDataBy) return 0;
                        if (groupDataBy === "day") return 1;
                        if (groupDataBy === "week") return 2;
                        return null;
                    })()}
                />
            </div>


            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => useDecimals !== e && setUseDecimals(!!e)} checked={useDecimals} />
                    <span>Use decimal places</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeDates !== e && setIncludeDates(!!e)} checked={includeDates} />
                    <span>Include dates</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeDr !== e && setIncludeDr(!!e)} checked={includeDr} />
                    <span>Include delivery rate</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeAvgProcessTime !== e && setIncludeAvgProcessTime(!!e)} checked={includeAvgProcessTime} />
                    <span>Include average lead process time (SLOW!)</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeFacebookStats !== e && setIncludeFacebookStats(!!e)} checked={includeFacebookStats} />
                    <span>Include stats per facebook campaign type (FB-CMP)</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeScore !== e && setIncludeScore(!!e)} checked={includeScore} />
                    <span>Include offer score (0-5)</span>
                </p>
            </div>

            <h3 style={{ marginBottom: "10px" }}>Include following campaign types</h3>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeCampaignTypes["traffic"] !== e && setIncludeCampaignTypes(ct => { return { ...ct, "traffic": !!e } })} checked={includeCampaignTypes["traffic"]} />
                    <span>Traffic campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeCampaignTypes["leads"] !== e && setIncludeCampaignTypes(ct => { return { ...ct, "leads": !!e } })} checked={includeCampaignTypes["leads"]} />
                    <span>Leads camapigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeCampaignTypes["sales"] !== e && setIncludeCampaignTypes(ct => { return { ...ct, "sales": !!e } })} checked={includeCampaignTypes["sales"]} />
                    <span>Sales campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeCampaignTypes["forms"] !== e && setIncludeCampaignTypes(ct => { return { ...ct, "forms": !!e } })} checked={includeCampaignTypes["forms"]} />
                    <span>Forms campaigns</span>
                </p>
            </div>

            <h3 style={{ marginBottom: "10px" }}>Include following integrations</h3>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["-1"] !== e && setIncludeIntegrations(ct => { return { ...ct, "-1": !!e } })} checked={includeIntegrations["-1"]} />
                    <span>Generic campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["-2"] !== e && setIncludeIntegrations(ct => { return { ...ct, "-2": !!e } })} checked={includeIntegrations["-2"]} />
                    <span>SMS campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["fb"] !== e && setIncludeIntegrations(ct => { return { ...ct, "fb": !!e } })} checked={includeIntegrations["fb"]} />
                    <span>Facebook campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["mg"] !== e && setIncludeIntegrations(ct => { return { ...ct, "mg": !!e } })} checked={includeIntegrations["mg"]} />
                    <span>Mgid campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["md"] !== e && setIncludeIntegrations(ct => { return { ...ct, "md": !!e } })} checked={includeIntegrations["md"]} />
                    <span>Midas campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["an"] !== e && setIncludeIntegrations(ct => { return { ...ct, "an": !!e } })} checked={includeIntegrations["an"]} />
                    <span>AdNow campaigns</span>
                </p>
            </div>
            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeIntegrations["-3"] !== e && setIncludeIntegrations(ct => { return { ...ct, "-3": !!e } })} checked={includeIntegrations["-3"]} />
                    <span>Social campaigns</span>
                </p>
            </div>

            {spinner && <>
                {progressData ? <>
                    <div style={{
                        position: "relative",
                        width: "100%",
                        height: "10px",
                        border: "1px solid gray"
                    }}>
                        <div style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: `${100 / progressData.max * progressData.cur}%`,
                            height: "100%",
                            backgroundColor: "#5A49CE",
                            transition: "width 0.3s linear"
                        }}></div>
                    </div>
                    <span style={{
                        color: "gray",
                        fontSize: "12px",
                        marginBottom: "10px"
                    }}>{progressData.cur} / {progressData.max}</span>
                </> : <p style={{ marginBottom: "10px", color: "gray" }}>Waiting for initial offer data...</p>}
            </>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && generateReport()}>
                    {spinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : "Generate report"}
                </div>
            </div>

            <p className="route__admin__users__add__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

const TestCRMOffers = props => {
    const [dateFilters, setDateFilters] = React.useState();
    const [out, setOut] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const scaleCrmIdsRef = React.useRef();
    const scaleLeadIdsRef = React.useRef();

    const performCheck = () => {
        if (spinner) return;

        let ScaleCrmIDs = [];
        let ScaleLeadIDs = [];

        if (scaleCrmIdsRef.current.value) {
            ScaleCrmIDs = scaleCrmIdsRef.current.value.split(",").map(i => String(i).trim()).filter(t => t);
        };
        if (scaleLeadIdsRef.current.value) {
            ScaleLeadIDs = scaleLeadIdsRef.current.value.split(",").map(i => String(i).trim()).filter(t => t);
        };

        let filters = [];
        if (dateFilters) {
            if (dateFilters.start && dateFilters.end) {
                filters.push({ name: "createdAt", op: "dgeq", value: dateFilters.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "dleq", value: dateFilters.end.toDate().getTime() });
            };
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/integration_testOrdersExist`,
            data: {
                filters,
                ScaleCRM_ID: ScaleCrmIDs,
                ScaleLead_ID: ScaleLeadIDs
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOut(res.data);
        }).catch(() => {
            setOut(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const checkStatus = () => {
        for (let key of Object.keys(out.data)) {
            if (out.data[key].length > 0) {
                return true;
            };
        };

        return false;
    };

    return <div className="genericModal" style={{ alignItems: "flex-start" }}>
        <div className="genericModal__wrap" style={{ overflow: "visible" }}>
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Test IDs</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{ backgroundImage: `url("/images/icon_close.svg")` }}></div>
            </div>

            {out ? <>
                {out.status === "ok" ? <>
                    {checkStatus() ? <>
                        <p>The following IDs were NOT tracked:</p>
                        <br />
                        <br />

                        {out.data["ScaleCRM"]?.length > 0 && <div className="genericModal__wrap__input genericModal__wrap__input--text">
                            <p>ScaleCRM IDs (comma-seperated)</p>
                            <textarea readOnly={true} defaultValue={out.data["ScaleCRM"].join(", ")}></textarea>
                        </div>}
                        {out.data["ScaleLead"]?.length > 0 && <div className="genericModal__wrap__input genericModal__wrap__input--text">
                            <p>ScaleLead IDs (comma-seperated)</p>
                            <textarea readOnly={true} defaultValue={out.data["ScaleLead"].join(", ")}></textarea>
                        </div>}
                    </> : <>
                        <p>All IDs were tracked by Scale-Track !</p>
                    </>}
                    <div className="genericModal__wrap__buttons">
                        <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                    </div>
                </> : <>
                    <p>There was an error while checking the IDs!</p>
                    <div className="genericModal__wrap__buttons">
                        <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                    </div>
                </>}
            </> : <>
                <div className="genericModal__wrap__input">
                    <FilterByDate defaultValue="all" onChange={e => setDateFilters(e)} />
                </div>

                <div className="genericModal__wrap__input genericModal__wrap__input--text">
                    <p>ScaleCRM IDs (comma-seperated)</p>
                    <textarea ref={scaleCrmIdsRef}></textarea>
                </div>

                <div className="genericModal__wrap__input genericModal__wrap__input--text">
                    <p>ScaleLead IDs (comma-seperated)</p>
                    <textarea ref={scaleLeadIdsRef}></textarea>
                </div>

                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && performCheck()}>
                        {spinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : "Test"}
                    </div>
                </div>
            </>}
        </div>
    </div>
};

const OfferHistory = (props) => {
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();
    const [date, setDate] = React.useState();
    const mainRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const getUserData = uid => {
        if (Array.isArray(uid)) {
            let out = [];

            for (let usr of uid.sort((a, b) => {
                if (a.ID === b.ID) return 0;
                return a.ID > b.ID;
            })) {
                if (!users) out.push("...");
                if (users.status !== "ok") out.push("N/A");
                if (!usr) out.push("No responsible person");
        
                let curUser = users.data.find(u => u.ID === usr?.ID);
                if (!curUser) out.push(`Unknown user (${uid})`);

                let integration = supportedIntegrationsSelector.find(si => si.Type === usr.IntegrationID);
        
                out.push(<div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <img src={getTrackingProfileImage(integration?.NamedType)} style={{width: "16px", height: "16px"}} />
                    <span>{`${curUser.Username} ${curUser.Email}`}</span>
                </div>);
            };
            return out;
        } else {
            if (!users) return "...";
            if (users.status !== "ok") return "N/A";
            if (!uid) return "No responsible person";
    
            let curUser = users.data.find(u => u.ID === uid);
            if (!curUser) return `Unknown user (${uid})`;
    
            return `${curUser.Username} ${curUser.Email}`;
        };
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOfferResponsiblePersonHistories`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers(backendModule.genericError);
        });
    }, []);

    return <div className="modals__globalChanges" onClick={onClose}>
        <div className="modals__globalChanges__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__globalChanges__wrap__header">
                <div className="modals__globalChanges__wrap__header__left">Offer history</div>
                <div className="modals__globalChanges__wrap__header__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="modals__globalChanges__wrap__content">
                <FilterByDate defaultValue="all" onChange={e => setDate(e)} />
                <br />
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["Date", "Responsible person"]}
                    data={(() => {
                        if (!data || !users) return [[{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]];
                        if (data.status === "error" || users.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!", style: { color: "#ff450d" } }]];

                        let out = [];
                        for (let item of data.data) {
                            if (date) {
                                if (date?.start && date?.end) {
                                    let tmp = moment(item.Date);
                                    if (tmp.isBefore(date.start) || tmp.isAfter(date.end)) continue;
                                };
                            };

                            out.push([
                                { keyID: item.Date, type: "text", text: moment(item.Date).toDate().toLocaleString() },
                                { keyID: item.Date, type: "text", text: getUserData(item.ResponsiblePerson) }
                            ]);
                        };

                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const BulkEdit = props => {
    const [changeActive, setChangeActive] = React.useState();
    const [changeHold, setChangeHold] = React.useState();
    const [changeCondition, setChangeCondition] = React.useState();
    const [changeResponsible, setChangeResponsible] = React.useState();
    const [changeDemographicGender, setChangeDemographicGender] = React.useState();
    const [changeDemographicAge, setChangeDemographicAge] = React.useState();
    const [changeDailyCap, setChangeDailyCap] = React.useState();
    const [changeOfferAngles, setChangeOfferAngles] = React.useState();
    const [changeOfferPersonas, setChangeOfferPersonas] = React.useState();

    const [spinner, setSpinner] = React.useState(false);
    const [offerActive, setOfferActive] = React.useState(null);
    const [offerOnHold, setOfferOnHold] = React.useState(null);
    const [offerResponsiblePersion, setOfferResponsiblePerson] = React.useState([]);
    const [offerAngles, setOfferAngles] = React.useState([""]);
    const [offerPersonas, setOfferPersonas] = React.useState([""]);
    const [offerDemographicGender, setOfferDemographicGender] = React.useState();
    const [allUsers, setAllUsers] = React.useState();
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: ""
    });

    const offerConditionRef = React.useRef();
    const demographicAgeStartRef = React.useRef();
    const demographicAgeEndRef = React.useRef();
    const dailyCapRef = React.useRef();
    const responsiblePersonResetRef = React.useRef();

    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const updateoffers = () => {
        if (spinner) return;
        setInfoP(ip => { return { ...ip, inputs: [], hadError: false } });

        if (offerActive === null && changeActive) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["active"], error: "Offer active must be selected" } });
        if (offerOnHold === null && changeHold) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["onhold"], error: "Offer on hold must be selected" } });
        if (changeCondition && !offerConditionRef.current.value) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["condition"], error: "Offer condition can't be empty" } });
        if (!offerResponsiblePersion && offerResponsiblePersion !== null && changeResponsible) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["responsibleperson"], error: "Responsible person must be selected" } });
        if (offerDemographicGender === undefined && changeDemographicGender) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["demographicgender"], error: "Target demographic gender must be selected" } });
        if (changeDemographicAge && (!demographicAgeStartRef.current.value || !demographicAgeEndRef.current.value)) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["demographicage"], error: "Target demographic age cant be empty" } });

        let data = { IDs: props.IDs };
        if (changeActive) data["isActive"] = offerActive;
        if (changeHold) data["isOnHold"] = offerOnHold;
        if (changeCondition) data["OfferCondition"] = offerConditionRef.current.value;
        if (changeResponsible) {
            data["ResponsiblePerson"] = offerResponsiblePersion;
            if (!Array.isArray(data["ResponsiblePerson"])) data["ResponsiblePerson"] = [];
        };
        if (changeDemographicGender) data["TargetDemographicGender"] = offerDemographicGender;
        if (changeDemographicAge) {
            let a = Number(demographicAgeStartRef.current.value);
            let b = Number(demographicAgeEndRef.current.value);
            if (isNaN(a) || isNaN(b)) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["demographicage"], error: "Target demographic age must be a number" } });
            if (a < 0 || b < 0) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["demographicage"], error: "Target demographic age is invalid" } });
            if (a > b) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["demographicage"], error: "Target demographic age start must be lower than end" } });

            data["TargetDemographicAgeStart"] = a;
            data["TargetDemographicAgeEnd"] = b;
        };
        if (changeDailyCap) {
            if (dailyCapRef.current.value !== "") {
                data.DailyCap = Number(dailyCapRef.current.value);
                if (isNaN(data.DailyCap)) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["dailycap"], error: "Daily cap must be a number" } });
            } else {
                data.DailyCap = null;
            };
        };
        if (changeOfferAngles) {
            data["Angles"] = offerAngles.filter(f => f);
        };
        if (changeOfferPersonas) {
            data["Personas"] = offerPersonas.filter(f => f);
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/bulkEdit`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                setInfoP(ip => { return { ...ip, hadError: true, inputs: [], error: "There was an error while updating the offers." } });
            };
        }).catch(() => {
            setInfoP(ip => { return { ...ip, hadError: true, inputs: [], error: "Server timed out!" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
        })
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Bulk edit ({props.IDs.length} offers)</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{ backgroundImage: `url("/images/icon_close.svg")` }}></div>
            </div>

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("active") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeActive} onChange={e => setChangeActive(!!e)} />
                    <span>Edit active</span>
                </p>
                {changeActive && <Dropdown
                    theme="dark"
                    accent="#6C5DD3"
                    inlinePlaceholder="Active"
                    data={[
                        { name: "Yes", value: true },
                        { name: "No", value: false }
                    ]}
                    onChange={e => setOfferActive(!!e?.value)}
                    selected={(() => {
                        if (offerActive === null) return null;
                        return offerActive ? 0 : 1
                    })()}
                />}
            </div>
            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("onhold") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeHold} onChange={e => setChangeHold(!!e)} />
                    <span>Edit on hold</span>
                </p>
                {changeHold && <Dropdown
                    theme="dark"
                    accent="#6C5DD3"
                    inlinePlaceholder="On hold"
                    data={[
                        { name: "Yes", value: true },
                        { name: "No", value: false }
                    ]}
                    onChange={e => setOfferOnHold(!!e?.value)}
                    selected={(() => {
                        if (offerOnHold === null) return null;
                        return offerOnHold ? 0 : 1
                    })()}
                />}
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("condition") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeCondition} onChange={e => setChangeCondition(!!e)} />
                    <span>Edit condition</span>
                </p>
                {changeCondition && <input ref={offerConditionRef} type="text" placeholder="Condition" />}
            </div>

            {(!allUsers || allUsers?.status === "ok") && <div className={`genericModal__wrap__input ${infoP.inputs.includes("responsiblepersion") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    {allUsers ? <Checkbox checked={changeResponsible} onChange={e => setChangeResponsible(!!e)} /> : <Spinner style={{ width: "20px", height: "20px" }} color="white" />}
                    <span>Edit responsible person</span>
                </p>
                {changeResponsible && <>
                    {allUsers?.status === "ok" && offerResponsiblePersion.map((rp, rpIdx) => {
                        return <div className="route__user__offers__add__wrap__input__responsible">
                            <Dropdown
                                theme="dark"
                                accent="#6C5DD3"
                                data={[
                                    ...(allUsers?.status === "ok" ? allUsers.data : []).map(usr => {
                                        return { name: usr.Username, value: usr.ID };
                                    })
                                ]}
                                selected={(()=>{
                                    if (!rp.ID) return null;
                                    return allUsers.data.indexOf(allUsers.data.find(u => u.ID === rp.ID));
                                })()}
                                onChange={e => {
                                    setOfferResponsiblePerson((srp) => srp.map((srpm, srpmIdx) => {
                                        if (srpmIdx !== rpIdx) return srpm;
                                        let tmp = {...srpm, ID: e?.value};
                                        return tmp;
                                    }));
                                }}
                            />
                            <Dropdown
                                theme="dark"
                                accent="#6C5DD3"
                                data={supportedIntegrationsSelector.map(si => {
                                    return {name: <div className="route__user__offers__add__wrap__input__responsible__image">
                                        <img src={getTrackingProfileImage(si.NamedType)} />
                                        <span>{si.Name}</span>
                                    </div>, value: si.Type}
                                })}
                                selected={(()=>{
                                    if (rp.IntegrationID === null || rp.IntegrationID === undefined) return null;
                                    return supportedIntegrationsSelector.indexOf(supportedIntegrationsSelector.find(si => si.Type === rp.IntegrationID));
                                })()}
                                onChange={e => {
                                    setOfferResponsiblePerson((srp) => srp.map((srpm, srpmIdx) => {
                                        if (srpmIdx !== rpIdx) return srpm;
                                        let tmp = {...srpm, IntegrationID: e?.value};
                                        return tmp;
                                    }));
                                }}
                            />
                            <StyledButton style={{width: "100%", height: "100%", padding: 0}} isSecondary={true} onClick={() => {
                                setOfferResponsiblePerson((srp) => srp.filter((_, srpIdx) => srpIdx !== rpIdx));
                            }}>
                                <img src="/images/icon_close.svg" />
                            </StyledButton>
                        </div>
                    })}
                    {allUsers ? <Dropdown
                        theme="dark"
                        inlinePlaceholder="Add new responsible user"
                        accent="#6C5DD3"
                        data={[
                            ...(allUsers?.status === "ok" ? allUsers.data : []).map(usr => {
                                return { name: usr.Username, value: usr.ID };
                            })
                        ]}
                        onChange={e => {
                            setOfferResponsiblePerson(rp => [...rp, {ID: e?.value, IntegrationID: -1}]);
                            responsiblePersonResetRef.current();
                        }}
                        onReset={e => responsiblePersonResetRef.current = e}
                    /> : <Spinner style={{ width: "54px", height: "54px" }} color="white" />}
                </>}
            </div>}

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("angles") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeOfferAngles} onChange={e => setChangeOfferAngles(!!e)} />
                    <span>Edit Angles</span>
                </p>
                {changeOfferAngles && <>
                    {offerAngles.map((ag, agIdx) => {
                        return <input type="text" value={ag} placeholder="Angle" onChange={e => {
                            let val = e?.target?.value;

                            setOfferAngles(angles => {
                                let tmp = [...angles];
                                tmp[agIdx] = val;
                                tmp = tmp.filter(f => f);
                                tmp.push("");

                                return tmp;
                            });
                        }} />
                    })}
                </>}
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("personas") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeOfferPersonas} onChange={e => setChangeOfferPersonas(!!e)} />
                    <span>Edit Personas</span>
                </p>
                {changeOfferPersonas && <>
                    {offerPersonas.map((ag, agIdx) => {
                        return <input type="text" value={ag} placeholder="Angle" onChange={e => {
                            let val = e?.target?.value;

                            setOfferPersonas(angles => {
                                let tmp = [...angles];
                                tmp[agIdx] = val;
                                tmp = tmp.filter(f => f);
                                tmp.push("");

                                return tmp;
                            });
                        }} />
                    })}
                </>}
            </div>

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("demographicgender") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeDemographicGender} onChange={e => setChangeDemographicGender(!!e)} />
                    <span>Edit target demographic gender</span>
                </p>
                {changeDemographicGender && <Dropdown
                    theme="dark"
                    accent="#6C5DD3"
                    inlinePlaceholder="Demographic gender"
                    data={[
                        { name: "Any", value: null },
                        { name: "Male", value: true },
                        { name: "Female", value: false }
                    ]}
                    onChange={e => setOfferDemographicGender(!!e?.value)}
                    selected={(() => {
                        if (offerDemographicGender === null) return 0;
                        if (offerDemographicGender) return 1;
                        if (offerDemographicGender === false) return 2;
                        if (!offerDemographicGender) return null;
                    })()}
                />}
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("demographicage") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeDemographicAge} onChange={e => setChangeDemographicAge(!!e)} />
                    <span>Edit target demographic age</span>
                </p>
                {changeDemographicAge && <>
                    <input ref={demographicAgeStartRef} type="text" placeholder="Age start" />
                    <input ref={demographicAgeEndRef} type="text" placeholder="Age end" />
                </>}
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("dailycap") ? "genericModal__wrap__input--error" : ""}`}>
                <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox checked={changeDailyCap} onChange={e => setChangeDailyCap(!!e)} />
                    <span>Edit daily cap</span>
                </p>
                {changeDailyCap && <input ref={dailyCapRef} type="text" placeholder="Daily cap" />}
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && updateoffers()}>
                    {spinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : "Save"}
                </div>
            </div>

            <p className="route__admin__users__add__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};


const mappingsDatasets = {
    "facebook": {
        "value1": "PixelID",
        "value2": "PixelToken"
    },
    "tiktok": {
        "value1": "PixelID",
        "value2": "PixelToken"
    },
    "mgid": {
        "value1": "ClientID",
        "value2": "EventName",
        "value3": "PixelID"
    },
    "midas": {
        "value1": "PixelID"
    }
};
const mappingsDatasetsViewOnly = {
    "facebook": {
        "value1": "PixelID"
    },
    "tiktok": {
        "value1": "PixelID"
    },
    "mgid": {
        "value1": "ClientID",
        "value2": "EventName",
    },
    "midas": {
        "value1": "PixelID"
    }
};
const OfferDatasets = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [filterAccount, setFilterAccount] = React.useState(null);
    const [filterSearch, setFilterSearch] = React.useState("");
    const [urlParams, setURLParams] = React.useState({});

    const mainRef = React.useRef();

    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const prepareSearchFilters = async () => {
        let out = [];
        if (!filterSearch) return out;

        for (let item of filterSearch.split(" ").filter(t => t)) {
            let tmp = [];
            tmp.push({ name: "ID", op: "like", value: item });
            for (let key of Object.keys(mappingsDatasetsViewOnly)) {
                for (let key2 of Object.keys(mappingsDatasetsViewOnly[key])) {
                    tmp.push({ name: `Value.${mappingsDatasetsViewOnly[key][key2]}`, op: "like", value: item });
                }
            };
            if (tmp.length > 0) out.push({ or: tmp });
        };

        return out;
    };

    const getData = async () => {
        setSpinner(true);

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/datasets/getAll`,
            data: {
                offset: 0,
                limit: null,
                getOfferInfo: true,
                filters: [
                    { name: "OfferID", op: "eq", value: props.ID },
                    (filterAccount ? { name: "IntegrationID", op: "eq", value: filterAccount } : null),
                    ...(await prepareSearchFilters())
                ].filter(t => t),
                orders: [
                    { name: "createdAt", order: "desc" }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
        setSpinner(false);
    };

    const getIntegrationByIntegrationID = ID => {
        if (!ID) return null;

        return trackingProfilesSelector.find(ts => ts?.ID === ID);
    };

    const getIntegrationImage = (curIntegration) => {
        switch (curIntegration?.type) {
            case "scale-track": return "/images/logo.svg";
            case "facebook": return "/images/integrations/integration_header_facebook.svg";
            case "mgid": return "/images/integrations/integration_header_mgid.svg";
            case "midas": return "/images/integrations/integration_midas.svg";
            case "tiktok": return "/images/integrations/integration_tiktok.svg";
            case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
            case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";
            default: return "#";
        };
    };

    const generateIntegrationData = (IntegrationID) => {
        let curIntegration = getIntegrationByIntegrationID(IntegrationID);
        if (!curIntegration) return "Integration not found";

        return <>
            <img src={getIntegrationImage(curIntegration)} />
            <span>{curIntegration?.name}</span>
        </>
    };
    const generateValues = item => {
        let curIntegration = getIntegrationByIntegrationID(item.IntegrationID);
        if (!curIntegration) return "Integration not found";
        if (!mappingsDatasetsViewOnly[curIntegration?.type]) return "Integration type not supported";

        let curMapping = mappingsDatasetsViewOnly[curIntegration?.type];
        let out = [];
        for (let key of Object.keys(curMapping)) {
            out.push(<p>
                <span><span style={{ color: themeSelector === "dark" ? "#B2A9F2" : "rgb(109, 92, 230)" }}>[{curMapping[key]}]</span>:</span>
                <span>{item.Value?.[curMapping[key]]}</span>
            </p>)
        };

        return out;
    };

    const removeDataset = (e, item) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={"You are about to remove this dataset, this action is irreversible. Are you sure?"}
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage("");

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/offers/datasets/remove`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "error") {
                        args.errorMessage("Error while removing the dataset");
                    } else {
                        args.close();
                        getData();
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.disabledAll(false);
                    args.spinner(false);
                });
            }}
        />);
    };

    const filterTrackingProfiles = tp => {
        return tp.filter(tpf => {
            switch (tpf.type) {
                case "facebook":
                case "mgid":
                case "tiktok":
                    return true;

                default: break;
            };
            return false;
        });
    };

    const filterProfileTestable = integrationID => {
        if (String(integrationID).startsWith("fb-")) return true;
        if (String(integrationID).startsWith("tt-")) return true;

        return false;
    };

    const getRecentlyUsedStyles = item => {
        let tmp = item.Usage?.LastUsed;
        if (!tmp) return {};

        if (!moment(tmp).isValid()) return {};
        if (moment(tmp).isAfter(moment().add(-10, "minutes"))) {
            if (themeSelector === "dark") return {
                boxShadow: "0px 0px 4px 2px rgba(50,255,50,0.3)"
            };
            return {
                boxShadow: "0px 0px 4px 2px rgba(50,255,50,0.7)"
            };
        };
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        getData();
    }, [filterSearch, filterAccount]);

    React.useEffect(() => {
        try {
            let uParams = getParamsFromURLObject(String(window.location));
            if (typeof(uParams) !== "object" || Array.isArray(uParams)) uParams = {};
            setURLParams(uParams);
        } catch {};
    }, []);

    return <div className="modals__globalChanges" onClick={onClose}>
        <div className="modals__globalChanges__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__globalChanges__wrap__header">
                <div className="modals__globalChanges__wrap__header__left" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <span>Datasets</span>
                    {spinner && <Spinner style={{ width: "24px", height: "24px" }} color="white" />}
                </div>

                {(urlParams["_admin-viewbyid"] || userInfoSelector?.Flags?.isAdmin) && <StyledButton style={{ marginLeft: "auto", height: "30px", marginRight: "20px" }} onClick={e => animateBox(e, <AddOfferDataset ID={props.ID} onChange={() => getData()} />)}>Add dataset</StyledButton>}

                <div className="modals__globalChanges__wrap__header__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="modals__globalChanges__wrap__content route__user__offers__datasets">
                {data ? <>
                    <div className="route__user__offers__datasets__filters">
                        <FilterBySearch onChange={e => setFilterSearch(e)} />
                        <AdvancedDropdown
                            headline="Integration"
                            data={[
                                { key: "all", name: "All accounts", value: null },
                                ...filterTrackingProfiles(trackingProfilesSelector).map(t => {
                                    return {
                                        key: t.ID,
                                        value: t.ID,
                                        search: t.name,
                                        name: <p className="route__user__offers__datasets__item__withImage">
                                            <img src={getIntegrationImage(t)} style={{ width: "16px", height: "16px" }} />
                                            <span>{t.name}</span>
                                        </p>
                                    }
                                })
                            ]}
                            selected={(() => {
                                if (!filterAccount) return 0;
                                return filterTrackingProfiles(trackingProfilesSelector).indexOf(trackingProfilesSelector.find(t => t.ID === filterAccount)) + 1;
                            })()}
                            onChange={e => setFilterAccount(e?.value)}
                        />
                    </div>
                    {data.status === "ok" ? <>
                        {data.data.map(item => {
                            return <div className="route__user__offers__datasets__item" key={`offer-dataset-${item.ID}`} style={getRecentlyUsedStyles(item)}>
                                <p>
                                    <span>ID:</span><span>{item.ID}</span>
                                </p>
                                <p>
                                    <span>Offer:</span><span>{item._Offer?.OfferName} ({item?._Offer?.OfferType ?? "-"}, {item?._Offer?.Country ?? "?"})</span>
                                </p>
                                <p>
                                    <span>Integration:</span><span className="route__user__offers__datasets__item__withImage">{generateIntegrationData(item.IntegrationID)}</span>
                                </p>

                                <br /><br />
                                <p><span>Times used by integration:</span><span>{item.Usage?.UsedTimes}</span></p>
                                <p><span>Last used at:</span><span>{item.Usage?.LastUsed ? `${moment(item.Usage?.LastUsed).fromNow()} (${moment(item.Usage?.LastUsed).toDate().toLocaleString()})` : "Never"}</span></p>

                                <br /><br />
                                {generateValues(item)}

                                <br /><div className="route__user__offers__datasets__item__line"></div><br />
                                <div className="route__user__offers__datasets__item__buttons">
                                    {(urlParams["_admin-viewbyid"] || userInfoSelector?.Flags?.isAdmin) && <StyledButton onClick={e => animateBox(e, <AddOfferDataset ID={props.ID} edit={item} onChange={() => getData()} />)}>
                                        <img src="/images/icon_edit.svg" />
                                        <span>Edit</span>
                                    </StyledButton>}
                                    {filterProfileTestable(item?.IntegrationID) && <StyledButton onClick={e => animateBox(e, <TestOfferDataset item={item} onChange={() => getData()} />)}>
                                        <img src="/images/icon_toggleCompare.svg" />
                                        <span>Test</span>
                                    </StyledButton>}
                                    {(urlParams["_admin-viewbyid"] || userInfoSelector?.Flags?.isAdmin) && <StyledButton isSecondary={true} onClick={e => removeDataset(e, item)}>
                                        <img src="/images/integrations/integration_remove.svg" />
                                        <span>Remove</span>
                                    </StyledButton>}
                                </div>
                            </div>
                        })}
                        {data.data.length === 0 && <p>Nothing to show...</p>}
                    </> : <p style={{ color: basicStylesModule.errorColor }}>There was an error while fetching data</p>}
                </> : <>
                    <p style={{ marginBottom: "10px" }}>Getting datasets, please wait...</p>
                    <Spinner style={{ width: "32px", height: "32px" }} color="white" />
                </>}
            </div>
        </div>
    </div>
};

const TestOfferDataset = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const tesstStrRef = React.useRef();

    const performTest = () => {
        if (spinner) return;
        let tmpStr = tesstStrRef.current.value;
        if (!tmpStr) return;

        setInfoP("");
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/datasets/test`,
            data: {
                OfferID: props.item.OfferID,
                IntegrationID: props.item.IntegrationID,
                TestString: tmpStr
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof (props.onChange) === "function") props.onChange();
                props.onClose();
                return;
            };
            setInfoP("There was an error while sending the test event");
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Test dataset</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{ backgroundImage: `url("/images/icon_close.svg")` }}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Enter test string</p>
                <input ref={tesstStrRef} type="text" placeholder="Test string" />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={performTest}>
                    {spinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : "Test"}
                </div>
            </div>

            {infoP && <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>{infoP}</p>}
        </div>
    </div>
};

const AddOfferDataset = props => {
    const [selectedProfile, setSelectedProfile] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        text: "",
        hadError: false,
        inputs: []
    });

    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const value1Ref = React.useRef();
    const value2Ref = React.useRef();
    const value3Ref = React.useRef();

    const getIntegrationImage = (curIntegration) => {
        switch (curIntegration?.type) {
            case "scale-track": return "/images/logo.svg";
            case "facebook": return "/images/integrations/integration_header_facebook.svg";
            case "mgid": return "/images/integrations/integration_header_mgid.svg";
            case "midas": return "/images/integrations/integration_midas.svg";
            case "tiktok": return "/images/integrations/integration_tiktok.svg";
            case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
            case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";
            default: return "#";
        };
    };

    const filterTrackingProfiles = tp => {
        return tp.filter(tpf => {
            switch (tpf.type) {
                case "facebook":
                case "mgid":
                case "midas":
                case "tiktok":
                    return true;

                default: break;
            };
            return false;
        });
    };

    const addDataset = () => {
        if (spinner) return;

        setInfoP(ip => { return { ...ip, hadError: false, inputs: [] } });

        if (!selectedProfile) return setInfoP(ip => { return { ...ip, hadError: "true", inputs: ["account"], text: "Account not selected!" } });

        let valueItems = [{ name: "value1", ref: value1Ref }, { name: "value2", ref: value2Ref }, { name: "value3", ref: value3Ref }];

        for (let item of valueItems) {
            if (item.ref.current && !item.ref.current?.value) {
                return setInfoP(ip => { return { ...ip, hadError: true, inputs: [item.name], text: "Field can't be empty" } });
            };
        };

        if (!mappingsDatasets[selectedProfile?.type]) return setInfoP(ip => { return { ...ip, hadError: "true", inputs: ["account"], text: "Account type not yet supported" } });
        const curMapping = mappingsDatasets[selectedProfile.type];

        let data = {
            OfferID: props.ID,
            IntegrationID: selectedProfile?.ID,
            Value: {}
        };
        if (props.edit) data["ID"] = props.edit["ID"];

        for (let item of valueItems) {
            if (!item.ref.current) continue;
            if (!curMapping[item.name]) return setInfoP(ip => { return { ...ip, hadError: true, inputs: [item.name], text: "A generic error occured" } });
            data.Value[curMapping[item.name]] = item.ref.current?.value;
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/datasets/${props.edit ? "edit" : "add"}`,
            data,
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
                return;
            };
            setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: `Error while ${props.edit ? "editting" : "adding"} a dataset` } });
        }).catch(() => {
            setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Server timed out" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getDefaultValue = curInput => {
        if (!selectedProfile) return;

        if (props.edit) {
            if (!mappingsDatasets[selectedProfile?.type]) return;
            let curMapping = mappingsDatasets[selectedProfile.type];
            return props.edit?.Value?.[curMapping?.[curInput]] ?? "";
        } else {
            if (selectedProfile?.type === "mgid" && curInput === "value2") {
                return "Lead";
            };
        };
    };

    React.useEffect(() => {
        if (props.edit) {
            let curProfile = trackingProfilesSelector.find(t => t.ID === props.edit.IntegrationID);
            if (curProfile) setSelectedProfile(curProfile);
        };
    }, [props.edit]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">{props.edit ? "Edit" : "Add"} dataset</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("account") ? "genericModal__wrap__input--error" : ""}`} style={{ display: props.edit ? "none" : null }}>
                <p>Select an account</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={filterTrackingProfiles(trackingProfilesSelector).map(tp => {
                        return {
                            value: tp,
                            search: tp.name,
                            name: <div className="route__user__offers__settings__add__profileDropdown">
                                <img src={getIntegrationImage(tp)} />
                                <span>{tp.name}</span>
                            </div>
                        };
                    })}
                    onChange={e => setSelectedProfile(e?.value)}
                    selected={(() => {
                        if (!selectedProfile) return null;

                        let curProfiles = filterTrackingProfiles(trackingProfilesSelector);
                        return curProfiles.indexOf(selectedProfile)
                    })()}
                />
            </div>

            {selectedProfile?.type === "facebook" && <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value1") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Pixel ID</p>
                    <input defaultValue={getDefaultValue("value1")} type="text" ref={value1Ref} placeholder="Facebook pixel ID" />
                </div>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value2") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Conversion Token</p>
                    <input defaultValue={getDefaultValue("value2")} type="text" ref={value2Ref} placeholder="Facebook conversion token" />
                </div>
            </>}
            {selectedProfile?.type === "tiktok" && <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value1") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Pixel ID</p>
                    <input defaultValue={getDefaultValue("value1")} type="text" ref={value1Ref} placeholder="TikTok pixel ID" />
                </div>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value2") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Conversion Token</p>
                    <input defaultValue={getDefaultValue("value2")} type="text" ref={value2Ref} placeholder="TikTok conversion token" />
                </div>
            </>}
            {selectedProfile?.type === "mgid" && <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value1") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Client ID</p>
                    <input defaultValue={getDefaultValue("value1")} type="text" ref={value1Ref} placeholder="Mgid Client ID" />
                </div>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value2") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Event name</p>
                    <input defaultValue={getDefaultValue("value2")} type="text" ref={value2Ref} placeholder="Mgid event name" />
                </div>
            </>}
            {selectedProfile?.type === "midas" && <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("value1") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Pixel ID for retargeting</p>
                    <input defaultValue={getDefaultValue("value1")} type="text" ref={value1Ref} placeholder="Midas Pixel ID for retargeting" />
                </div>
            </>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && addDataset()} style={{ pointerEvents: spinner ? "none" : "all" }}>
                    {spinner ? <Spinner color="white" style={{ width: "18px", height: "18px" }} /> : "Save"}
                </div>
            </div>

            <div className="genericModal__wrap__infoP" style={{ opacity: infoP.hadError ? 1 : 0 }}>{infoP.text}</div>
        </div>
    </div>
};

const ExportOfferConversions = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        text: ""
    });
    const [progressData, setProgressData] = React.useState({
        max: null, cur: null, text: null
    });
    const [filterDate, setFilterDate] = React.useState();
    const [includeCRMID, setIncludeCRMID] = React.useState(false);
    const [cleanIncomplete, setCleanIncomplete] = React.useState(false);
    const [selectedLeadStatusCodes, setSelectedLeadStatusCodes] = React.useState([]);

    const crmLeadStatusCodesSelector = useSelector(state => state?.types?.integrations?.scalecrm?.leadStatusCodes ?? {});

    const resetProgressbar = () => setProgressData({ max: null, cur: null, text: null });

    const exportData = async () => {
        if (spinner) return;
        if (!props.ID) return;
        if (!filterDate) return;

        setInfoP(ip => { return { ...ip, hadError: false, inputs: [] } });
        if (selectedLeadStatusCodes.length === 0) return setInfoP(ip => { return { ...ip, hadError: true, text: "At least 1 status code must be selected!" } });

        setSpinner(true);
        setProgressData(() => { return { max: 2, cur: 0, text: "Fetching sites..." } });

        let curOffer = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                extended: true,
                filters: [
                    { name: "ID", op: "eq", value: props.ID }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (curOffer.status === "error") {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, text: "Offer data not found." } });
            resetProgressbar();
            return;
        };
        if (curOffer.data.length !== 1 || !curOffer.data[0].ScaleleadOfferID) {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, text: "Offer data found, but invalid." } });
            resetProgressbar();
            return;
        };

        setProgressData({ cur: 1, max: 2, text: `Fetching data from CRM` });
        let startDate = filterDate.start.toDate().getTime();
        let endDate = filterDate.end.toDate().getTime();
        let leadData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/scalecrm/getLeadDataExportScaleLead`,
            data: {
                ScaleleadOfferID: curOffer.data[0].ScaleleadOfferID,
                filters: [
                    { name: "Status", op: "in", value: selectedLeadStatusCodes },
                    { name: "createdAt", op: "pdgeq", value: startDate },
                    { name: "createdAt", op: "pdleq", value: endDate }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(backendModule.genericError);
        if (leadData.status !== "ok") {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, text: "Error while fetching CRM data!" } });
            resetProgressbar();
            return;
        };
        if (leadData.data.length === 0) {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, text: "CRM data not found." } });
            resetProgressbar();
            return;
        };

        setProgressData({ cur: 2, max: 2, text: `Exporting ${leadData.data.length} conversions...` });

        let out = [];
        for (let item of leadData.data) {
            let firstLastSplit = item.FirstLastName.split(" ");
            if (firstLastSplit[1] === "undefined") firstLastSplit = [firstLastSplit[0]];
            out.push({
                ...(includeCRMID ? { CRMID: item.ID } : {}),
                fn: firstLastSplit.shift() ?? "",
                ln: firstLastSplit.join(" ") ?? "",
                phone: item.PhoneNumber ?? "",
                gen: item.Gender ?? "",
                ct: item.City ?? "",
                country: item.Country ?? "",
                doby: item.BirthYear ?? "",
                zip: item.ZIP ?? "",
                age: item.BirthYear ? (moment().get("year") - Number(item.BirthYear)) : ""
            });
        };

        if (cleanIncomplete) out = out.filter(f => {
            for (let key of Object.keys(f)) {
                if (!f[key]) {
                    return false;
                };
            };

            return true;
        });

        arrayToCSVString(out).then(data => {
            if (data.status === "ok") {
                let file = new Blob([data.data]);
                var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download = `ScaleTrack-conversions-export-${new Date().toLocaleString().replace(".", "-").replace(" ", "-").replace("_", "-").replace(",", "-")}.csv`;
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
                setSpinner(false);
                props.onClose();
            } else {
                setSpinner(false);
                setInfoP(ip => { return { ...ip, hadError: true, text: "Error while exporting data." } });
                resetProgressbar();
                return;
            };
        }).catch(() => {
            setSpinner(false);
            setInfoP(ip => { return { ...ip, hadError: true, text: "A generic export error occured!" } });
            resetProgressbar();
            return;
        });
    };

    React.useEffect(() => {
        setSelectedLeadStatusCodes(Object.keys(crmLeadStatusCodesSelector).map(s => String(s)))
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Export conversions</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : "all" }}>
                <p>Select date</p>
                <FilterByDate onChange={e => setFilterDate(e)} disableAll={true} disable24h={true} defaultValue="today" />
            </div>

            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => includeCRMID !== e && setIncludeCRMID(!!e)} checked={includeCRMID} />
                    <span>Include CRM ID</span>
                </p>
            </div>

            <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <Checkbox onChange={e => cleanIncomplete !== e && setCleanIncomplete(!!e)} checked={cleanIncomplete} />
                    <span>Exclude incomplete data</span>
                </p>
            </div>

            <h3 style={{ marginBottom: "10px" }}>Export selected lead statuses</h3>
            {Object.keys(crmLeadStatusCodesSelector).map(key => {
                return <div className="genericModal__wrap__input" style={{ pointerEvents: spinner ? "none" : null }}>
                    <p style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                        <Checkbox onChange={e => {
                            if (e && !selectedLeadStatusCodes.includes(String(key))) {
                                setSelectedLeadStatusCodes(ss => {
                                    return [...ss, String(key)];
                                });
                            } else if (!e && selectedLeadStatusCodes.includes(String(key))) {
                                setSelectedLeadStatusCodes(ss => {
                                    return ss.filter(ssf => ssf !== String(key));
                                });
                            };
                        }} checked={selectedLeadStatusCodes.includes(String(key))} />
                        <span>{crmLeadStatusCodesSelector[key]}</span>
                    </p>
                </div>
            })}

            {spinner && (progressData?.cur !== null && progressData?.max !== null) ? <>
                <div style={{
                    position: "relative",
                    width: "100%",
                    height: "10px",
                    border: "1px solid gray"
                }}>
                    <div style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: `${100 / progressData.max * progressData.cur}%`,
                        height: "100%",
                        backgroundColor: "#5A49CE",
                        transition: "width 0.3s linear"
                    }}></div>
                </div>
                <span style={{
                    color: "gray",
                    fontSize: "12px",
                    marginBottom: "10px"
                }}>{progressData.cur} / {progressData.max}{progressData.text && ` (${progressData.text})`}</span>
            </> : progressData.text !== null && <p style={{ marginBottom: "10px", color: "gray" }}>{progressData.text}</p>}

            <div className="genericModal__wrap__buttons" style={{ marginTop: "20px" }}>
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={exportData} style={{ pointerEvents: spinner ? "none" : "all" }}>
                    {spinner ? <Spinner style={{ width: "17px", height: "17px" }} color="white" /> : "Export"}
                </div>
            </div>

            <p className="genericModal__wrap__infoP" style={{ opacity: infoP.hadError ? 1 : 0 }}>{infoP.text}</p>
        </div>
    </div>
};

export default UserOffers;
export { AddOffer }