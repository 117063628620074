import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";

import AdCreativeModal from "../AdCreativeImageModal";

const CampaignAdsModal = props => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();
    const [statsData, setStatsData] = React.useState();
    const [totalData, setTotalData] = React.useState();
    const [globalSpinner, setGlobalSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySelector = useSelector(state => state?.types?.currencySign ?? "?");

    const timestampRef = React.useRef();
    const curDefer = useDefer();

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignAdData`,
            data: {
                ID: props.ID,
                filters: props.search ? [{or: [
                    {name: "AdID", op: "like", value: props.search},
                    {name: "AdName", op: "like", value: props.search}
                ]}] : []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data?.status === "ok" ? {
                status: "ok",
                data: res.data.data.filter(d => !d.AdID.startsWith("c_spentchange"))
            } : res.data);
            if (res.data.status === "ok") {
                getStatsData(res.data.data.map(d => d.AdID));
            };
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setGlobalSpinner(false);
        });
    };

    const getStatsData = async (ads) => {
        // ? Had a feeling
        let adStats = [];

        for (let ad of ads) {
            if (String(ad).startsWith("c_spentchange")) continue;

            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                data: {
                    filters: [
                        ...props.filters,
                        {name: "IntegrationParams.stadid", op: "eq", value: ad}
                    ],
                    CampaignIDs: [props.ID],
                    skipIntegrationDataPull: false,
                    TableHeaders: [
                        "Impressions_IN",
                        "Clicks_IN",
                        "CTR",
                        "CPC",
                        "CR",
                        "Visits",
                        "Conversions",
                        "CPA",
                        "AR",
                        "Approved",
                        "CPAO",
                        "ROI",
                        "Spent_IN",
                        "Revenue",
                        "Profit",
                        "CPM_IN",
                        "Frequency_IN",
                        "React_IN",

                        "sessionDuration_avg",
                        "CTAClicked"
                    ],
                    allUsers: !!props.allUsers
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    adStats.push({AdID: ad, ...res.data.data});
                };
            }).catch(() => null);
        };

        let totalAdData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                filters: [
                    ...props.filters
                ],
                CampaignIDs: [props.ID],
                skipIntegrationDataPull: false,
                TableHeaders: [
                    "Impressions_IN",
                    "Clicks_IN",
                    "CTR",
                    "CPC",
                    "CR",
                    "Visits",
                    "Conversions",
                    "CPA",
                    "AR",
                    "Approved",
                    "CPAO",
                    "ROI",
                    "Spent_IN",
                    "Revenue",
                    "Profit",
                    "CPM_IN",
                    "Frequency_IN",
                    "React_IN",

                    "sessionDuration_avg",
                    "CTAClicked"
                ],
                allUsers: !!props.allUsers
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);

        setStatsData(adStats);
        setTotalData(totalAdData.status === "ok" ? [totalAdData.data] : []);
    };

    const grabStatData = (ID, itemKey, ds = statsData) => {
        if (!Array.isArray(ds)) return null;
        for (let item of ds) {
            if (item.AdID !== ID && ID) continue;

            for (let key of Object.keys(item.Integrations)) {
                let td = item.Integrations[key]?.TableData;
                if (!td) break;

                if (td[itemKey] === null || td[itemKey] === undefined) return "-";

                switch (itemKey) {
                    case "CTR_IN":
                    case "CR":
                    case "AR":
                    case "ROI":
                    case "DR":
                    case "CTR":
                        return `${Number(td[itemKey]).toFixed(2)} %`;
                    case "Spent":
                    case "Spent_IN":
                    case "Revenue":
                    case "Profit":
                    case "CPA":
                    case "CPAO":
                    case "CPC":
                    case "CPM_IN":
                        return `${Number(td[itemKey]).toFixed(2)} ${currencySelector}`;
                    case "ROAS":
                        return `${Number(td[itemKey]).toFixed(2)} x`;
                    case "Frequency_IN":
                        return Number(td[itemKey]).toFixed(2);
                    default: return Number(td[itemKey]).toFixed(0);
                };
            };
        };

        return "-";
    };

    const convertTimeToString = time => {
        time = Number(time);
        if (isNaN(time)) return "-";
        time = Number(time.toFixed(0));

        let seconds = +time;
        let minutes = 0;
        let hours = 0;

        while (seconds >= 60) {
            seconds -= 60;
            minutes++;
        };
        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        };

        if (hours) return `${hours}h : ${minutes}m : ${seconds}s`;
        if (minutes) return `${minutes}m : ${seconds}s`;
        return `${seconds}s`;
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            let a1Compare = a1?.text;
            let b1Compare = b1?.text;

            a1Compare = String(a1Compare);
            b1Compare = String(b1Compare);

            if (a1Compare.endsWith("%") || a1Compare.endsWith(currencySelector)) a1Compare = Number(a1Compare.split(" ")[0]);
            if (b1Compare.endsWith("%") || b1Compare.endsWith(currencySelector)) b1Compare = Number(b1Compare.split(" ")[0]);

            if (!isNaN(a1Compare) && !isNaN(b1Compare)) {
                a1Compare = Number(a1Compare);
                b1Compare = Number(b1Compare);
                return a1Compare > b1Compare ? asc : desc;
            } else {
                return a1Compare > b1Compare ? asc : desc;
            };
        });
    };

    const getCTACTR = (Visits, CTAClicked) => {
        Visits = Number(Visits);
        CTAClicked = Number(CTAClicked);
        if (isNaN(Visits) || isNaN(CTAClicked)) return 0;

        if (!Visits || !CTAClicked) return 0;

        return CTAClicked / Visits * 100;
    };
    const getAbandonCartRate = (Conversions, CTAClicked) => {
        Conversions = Number(Conversions);
        CTAClicked = Number(CTAClicked);

        if (isNaN(Conversions) || isNaN(CTAClicked)) return 0;
        if (!Conversions && CTAClicked) return 100;
        if (!Conversions || !CTAClicked) return 0;

        return 100 - (Conversions / CTAClicked * 100);
    };

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        timestampRef.current = ts;
        setStatsData();
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [props.search, props.filters]);

    return <div className="modals__campaignAdsModal__wrap__content" style={{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__campaignAdsModal__wrap__content__left">
            <div className="modals__campaignAdsModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    orderCB={o => setOrders(o)}
                    headers={["Image", "Ad ID", "Ad name", "Ad status", "Average session duration", "Impressions", "Clicks", "CTR", "CPC", "CR", "Visits", "Conversions", "CPA", "AR", "Approved", "CPAO", "ROI", "Spent", "Revenue", "Profit", "CPM", "Reach", "Frequency", "CTA Clicks", "Add to cart %", "Abandon cart rate %", "Data points"]}
                    customColumns={["max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content"]}
                    showSpinner={globalSpinner}
                    spinnerColor={"#fff"}
                    style={{width: "auto", columnGap: "20px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];

                        let out = [];

                        if (data.status === "ok") {
                            for (let item of data.data) {
                                let tmpSpent = null;
                                if (statsData) {
                                    tmpSpent = grabStatData(item.AdID, "Spent_IN");
                                    if (tmpSpent === "-" || tmpSpent === `0.00 ${currencySelector}`) tmpSpent = grabStatData(item.AdID, "Spent");
                                };
                                out.push([
                                    {keyID: item.AdID, type: "custom", data: <CampaignAdsModal__adImage id={item.AdID} />},
                                    {keyID: item.AdID, type: "text", text: item.AdID},
                                    {keyID: item.AdID, type: "text", text: <span className="modals__campaignAdsModal__wrap__content__left__adName" onClick={() => animateBox(<AdCreativeModal name={item.AdName} />)}>{item.AdName}</span>},
                                    {keyID: item.AdID, type: "text", text: item.AdStatus},
                                    {keyID: item.AdID, type: "text", text: statsData ? convertTimeToString(grabStatData(item.AdID, "sessionDuration_avg")) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},

                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Impressions_IN") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Clicks_IN") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CTR") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CPC") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CR") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Visits") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Conversions") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CPA") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "AR") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Approved") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CPAO") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "ROI") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Spent_IN") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Revenue") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Profit") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CPM_IN") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Reach_IN") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "Frequency_IN") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},

                                    {keyID: item.AdID, type: "text", text: statsData ? grabStatData(item.AdID, "CTAClicked") : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? `${Number(getCTACTR(grabStatData(item.AdID, "Visits"), grabStatData(item.AdID, "CTAClicked"))).toFixed(2)} %` : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: statsData ? `${Number(getAbandonCartRate(grabStatData(item.AdID, "Conversions"), grabStatData(item.AdID, "CTAClicked"))).toFixed(2)} %` : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                    {keyID: item.AdID, type: "text", text: item.DataPoints}
                                ]);
                            };

                            out = orderData(out, ["Ad ID", "Ad name", "Ad status", "Average session duration", "Impressions", "Clicks", "CTR", "CPC", "CR", "Visits", "Conversions", "CPA", "AR", "Approved", "CPAO", "ROI", "Spent", "Revenue", "Profit", "CPM", "Reach", "Frequency", "CTA Clicks", "Add to cart %", "Abandon cart rate %", "Data points"]);
                            if (out.length > 0) out.push([
                                {keyID: "footer-1", type: "text", isFooter: true, text: ""},
                                {keyID: "footer-1", type: "text", isFooter: true, text: ""},
                                {keyID: "footer-1", type: "text", isFooter: true, text: "Total"},
                                {keyID: "footer-1", type: "text", isFooter: true, text: ""},
                                {keyID: "footer-1", type: "text", isFooter: true, text: statsData ? convertTimeToString(grabStatData(null, "sessionDuration_avg", totalData)) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},

                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Impressions_IN", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Clicks_IN", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "CTR", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "CPC", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "CR", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Visits", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Conversions", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "CPA", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "AR", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Approved", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "CPAO", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "ROI", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Spent_IN", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Revenue", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Profit", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "CPM_IN", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Reach_IN", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", isFooter: true, type: "text", text: totalData ? grabStatData(null, "Frequency_IN", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},

                                {keyID: "footer-1", type: "text", isFooter: true, text: statsData ? grabStatData(null, "CTAClicked", totalData) : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", type: "text", isFooter: true, text: statsData ? `${Number(getCTACTR(grabStatData(null, "Visits", totalData), grabStatData(null, "CTAClicked", totalData))).toFixed(2)} %` : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", type: "text", text: statsData ? `${Number(getAbandonCartRate(grabStatData(null, "Conversions", totalData), grabStatData(null, "CTAClicked", totalData))).toFixed(2)} %` : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />},
                                {keyID: "footer-1", type: "text", text: ""},
                            ]);
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching ads!", color: "#f96666" }]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>;
};

const CampaignAdsModal__adImage = props => {
    const [data, setData] = React.useState();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    React.useEffect(() => {
        // axios({
        //     method: "POST",
        //     url: `${backendModule.backendURL}/integrations/facebook/getAdImage`,
        //     data: {
        //         AdID: props.id
        //     },
        //     ...backendModule.axiosConfig
        // }).then(res => {
        //     setData(res.data);
        // }).catch(() => setData(backendModule.genericError));
        setData({status: "error"});
    }, []);

    if (!data) return <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
    return <div>
        <img src={data?.status === "ok" ? data.data : "#"} style={{width: "32px", height: "32px"}} />
    </div>
};

export default CampaignAdsModal;