import React from "react";
import "./index.scss";

import FilterBySearch from "../../filters/FilterBySearch";
import RadioButton from "../../customComponents/RadioButton";
import StyledButton from "../../styledComponents/Button";
import AdvancedDropdown from "../../customComponents/AdvancedDropdown";

const OfferSelectModal = props => {
    const [selectedOffers, setSelectedOffers] = React.useState(Array.isArray(props.defaultValue) ? props.defaultValue : []);
    const [search, setSearch] = React.useState();
    const [selectedBrand, setSelectedBrand] = React.useState();
    const [brands, setBrands] = React.useState([]);
    
    const wrapRef = React.useRef();
    const allOffers = Array.isArray(props.data) ? props.data : [];

    const onClose = () => {
        if (props.onChange) props.onChange(selectedOffers);

        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const searchOffers = offers => {
        let tmp = [...offers];

        if (selectedBrand) {
            tmp = tmp.filter(tf => tf.OfferBrand === selectedBrand);
        };

        if (!search) return tmp;

        let out = [];
        let searchStrings = search.split(" ").map(s => s.trim()).filter(f => f).map(s => s.toLowerCase());
        for (let item of tmp) {
            let found = true;
            for (let s of searchStrings) {
                if (
                    !String(item.Country ?? "").toLowerCase().includes(s) &&
                    !item.ID.toLowerCase().includes(s) &&
                    !item.Name.toLowerCase().includes(s) &&
                    !String(item.OfferType ?? "").toLowerCase().includes(s) &&
                    !String(item.OfferNiche ?? "").toLowerCase().includes(s)
                ) {
                    found = false;
                    break;
                };
                if (found) out.push(item);
            };
        };

        return out;
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        let allBrands = [];

        for (let offer of allOffers) {
            if (!allBrands.includes(offer.OfferBrand)) allBrands.push(offer.OfferBrand);
        };

        setBrands(allBrands);
    }, [props.data]);

    return <div className="modals__offerSelectModal" onClick={() => onClose()}>
         <div className="modals__offerSelectModal__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__offerSelectModal__wrap__top">
                <div className="modals__offerSelectModal__wrap__top__left">Select offers</div>
                <div className="modals__offerSelectModal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>
            <div className="modals__offerSelectModal__wrap__content">
                <AdvancedDropdown
                    theme="dark" style={{marginBottom: "10px"}}
                    inlinePlaceholder="Select brand"
                    data={[
                        {key: "any", name: "Any brand", value: null},
                        ...brands.map(b => {
                            return {key: b, name: b, value: b}
                        })
                    ]}
                    headline="Brand"
                    onChange={e => selectedBrand !== e?.value && setSelectedBrand(e?.value)}
                    selected={(()=>{
                        if (!selectedBrand) return 0;

                        return brands.indexOf(selectedBrand) + 1;
                    })()}
                    showSearch={true}
                />
                <FilterBySearch onChange={e => setSearch(e)} />

                <div className="modals__offerSelectModal__wrap__content__buttons">
                    <StyledButton onClick={() => setSelectedOffers(searchOffers(allOffers))}>Select all{(search || selectedBrand) && ` filtered`}</StyledButton>
                    <StyledButton onClick={() => setSelectedOffers([])}>Deselect all</StyledButton>
                </div>

                {(()=>{
                    let final = searchOffers(allOffers).map(offer => {
                        return <div className="modals__offerSelectModal__wrap__content__offer">
                            <RadioButton key={offer?.ID ? `radio-${offer?.ID ?? "-"}` : undefined} onChange={e => {
                                if (e && !selectedOffers.includes(offer)) {
                                    setSelectedOffers(o => [...o, offer]);
                                } else if (!e && selectedOffers.includes(offer)) {
                                    setSelectedOffers(o => o.filter(of => of !== offer));
                                };
                            }} checked={selectedOffers.includes(offer)} />
                            <p>{offer.Name}{offer.OfferNiche ? ` (${offer.OfferNiche})` : ""} ({offer.Country ?? "-"}, {offer.OfferType ?? "-"})</p>
                        </div>
                    });
                    if (final.length === 0) {
                        if (search) {
                            final.push(<p>No offers were found with this search terms</p>)
                        } else {
                            final.push(<p>No offers to show...</p>)
                        };
                    };
                    return final;
                })()}
            </div>
         </div>
    </div>
};

export default OfferSelectModal;