import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../modules/backendModule";
import * as basicStylesModule from "../../../../modules/basicStylesModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import FilterByDate from "../../../../components/filters/FilterByDate";

const SitePerformanceReport = () => {
    const [data, setData] = React.useState();
    const [dateFilter, setDateFilter] = React.useState();
    const [order, setOrder] = React.useState();

    const timestampRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const performSort = (data, headers) => {
        let finalOrd = order;

        if (!finalOrd) return data;
        if (!finalOrd?.name || !finalOrd?.order) return data;
        
        let curHeaderIndex = headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            return item;
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a[curHeaderIndex]?.text) ||
                    !checkExists(b[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a[curHeaderIndex]?.group)) {
                a1 = removeExtra(a[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a[curHeaderIndex].text);
            };
            if (Array.isArray(b[curHeaderIndex]?.group)) {
                b1 = removeExtra(b[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b[curHeaderIndex].text);
            };

            if (!isNaN(Number(a1)) && !isNaN(Number(b1))) {
                if (finalOrd.order === "asc") {
                    if (+a1 > +b1) return 1; else return -1;
                } else {
                    if (+b1 > +a1) return 1; else return -1;
                };
            } else {
                if (finalOrd.order === "asc") {
                    return (a1 > b1) ? 1 : -1;
                } else {
                    return (b1 > a1) ? 1 : -1;
                };
            };
        });
    };

    const getData = async (ts) => {
        if (timestampRef.current !== ts) return;

        let filters = [];
        let trackFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            filters.push({name: "lastTrackedAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()});

            trackFilters.push({name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()});
            trackFilters.push({name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()});
        };

        let allCampaigns = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                filters,
                limit: null,
                allUsers: true
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (timestampRef.current !== ts) return;
        if (allCampaigns.status !== "ok" || !allCampaigns.data.length) return setData(allCampaigns);

        let stats = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                TableHeaders: ["Visits", "Conversions", "CTAClicked", "Date_CampaignID"],
                CampaignIDs: allCampaigns.data.map(c => c.ID),
                filters: trackFilters,
                skipIntegrationDataPull: true,
                trackGroupByDate: true,
                _o_useDateHints: true
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (timestampRef.current !== ts) return;
        if (stats.status !== "ok" || !stats.data) return setData(stats);
        stats = stats.data.TableData;

        for (let c of allCampaigns.data) {
            c._computed = {Visits: 0, Conversions: 0, CTAClicked: 0};

            for (let tsKey of Object.keys(stats)) {
                for (let tsItem of stats[tsKey]) {
                    if (tsItem["Date_CampaignID"] !== c.ID) continue;
                    c._computed[tsKey] += tsItem["Value"];
                };
            };
        };

        let allSites = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                filters: [
                    {name: "ID", op: "in", value: [...new Set(allCampaigns.data.map(c => c.LandingSiteID))]}
                ],
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (timestampRef.current !== ts) return;
        if (allSites.status !== "ok" || !allSites.data.length) return setData(stats);

        for (let s of allSites.data) {
            s._computed = {Visits: 0, Conversions: 0, CTAClicked: 0, CR: 0, CTACTR: 0};

            for (let c of allCampaigns.data) {
                if (c.LandingSiteID !== s.ID) continue;

                for (let key of Object.keys(c._computed)) s._computed[key] += c._computed[key];
            };

            if (s._computed.Visits && s._computed.Conversions) {
                s._computed["CR"] = Number(s._computed["Conversions"] / s._computed["Visits"] * 100).toFixed(2);
            };
            if (s._computed.Visits && s._computed.CTAClicked) {
                s._computed["CTACTR"] = Number(s._computed["CTAClicked"] / s._computed["Visits"] * 100).toFixed(2);
            };
        };

        setData(allSites);
    };

    React.useEffect(() => {
        if (!dateFilter) return;

        setData();
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [dateFilter]);

    return <div className="route__reports__site">
        <FilterByDate
            defaultValue="today"
            disable24h={true}
            style={{maxWidth: "300px", marginBottom: "20px", marginLeft: "auto"}}
            onChange={setDateFilter}
        />
        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Site", "Visits", "CTA Clicks", "CTA CTR", "Conversions", "CR"]}
            customColumns={(new Array(6)).fill("max-content")}
            style={{columnGap: "40px"}}
            orderCB={o => setOrder(o)}

            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data!", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let site of data.data) {
                    out.push([
                        {keyID: site.ID, type: "text", text: `[${site.Country}] ${site.SiteName}`},
                        {keyID: site.ID, type: "text", text: site._computed["Visits"]},
                        {keyID: site.ID, type: "text", text: site._computed["CTAClicked"]},
                        {keyID: site.ID, type: "text", text: site._computed["CTACTR"] + "%"},
                        {keyID: site.ID, type: "text", text: site._computed["Conversions"]},
                        {keyID: site.ID, type: "text", text: site._computed["CR"] + "%"}
                    ]);
                };

                out = performSort(out, ["Site", "Visits", "CTA Clicks", "CTA CTR", "Conversions", "CR"]);
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
    </div>
};

export default SitePerformanceReport;