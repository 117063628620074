import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import RadioButton from "../../../components/customComponents/RadioButton";
import StyledButton from "../../../components/styledComponents/Button";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";

import FilterByDate from "../../../components/filters/FilterByDate";
import Dropdown from "../../../components/customComponents/Dropdown";
import Spinner from "../../../components/customComponents/Spinner";

import YesNoModal from "../../../components/modals/YesNoModal";

const UserAdditionalSpents = () => {
    const [activeTab, setActiveTab] = React.useState(1);
    const wrapRef = React.useRef();

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const changeTab = tab => {
        if (activeTab === tab) return;

        wrapRef.current.animate([
            {opacity: getComputedStyle(wrapRef.current).opacity},
            {opacity: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"}).onfinish = () => {
            setActiveTab(tab);
        };
    };

    React.useLayoutEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {opacity: getComputedStyle(wrapRef.current).opacity},
            {opacity: 1}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [wrapRef.current, activeTab]);

    return <div className="route__user__additionalSpents">
        <div className="genericTabs">
            <div className={`genericTabs__tab ${activeTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => changeTab(1)}>Spent</div>
            <div className={`genericTabs__tab ${activeTab === 2 ? "genericTabs__tab--active" : ""}`} onClick={() => changeTab(2)}>Recurring</div>
        </div>

        <div className="route__user__additionalSpents__wrap" ref={wrapRef}>
            {activeTab === 1 && <UserAdditionalSpents__spent />}
            {activeTab === 2 && <UserAdditionalSpents__recurring />}
        </div>
    </div>
};

const UserAdditionalSpents__spent = props => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [allUsers, setAllUsers] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [dateFilter, setDateFilter] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const curDispatch = useDispatch();
    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const timestampRef = React.useRef();
    const checkboxFunctionsRef = React.useRef();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);
        try {
            checkboxFunctionsRef?.current?.reset?.();
        } catch {};

        let filters = [];
        if (dateFilter) {
            filters.push({name: "Date", op: "pdgeq", value: dateFilter.start.toDate().getTime()});
            filters.push({name: "Date", op: "pdleq", value: dateFilter.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/getAll`,
            data: {
                limit: 20,
                offset: 0,
                filters,
                allUsers,
                orders: [{name: "Date", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (!data) return;
        if (data.status !== "ok") return;
        setCanPaginate(false);
        
        try {
            checkboxFunctionsRef?.current?.reset?.();
        } catch {};

        let filters = [];
        if (dateFilter) {
            filters.push({name: "Date", op: "pdgeq", value: dateFilter.start.toDate().getTime()});
            filters.push({name: "Date", op: "pdleq", value: dateFilter.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/getAll`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ],
                allUsers,
                orders: [{name: "Date", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};
        setCanPaginate(false);

        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        if (dateFilter === undefined) return;

        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        handler();

        return () => {
            curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
        };
    }, [allUsers, dateFilter]);


    return <div className="route__user__additionalSpents__wrap__spent">
        <div className="route__user__additionalSpents__wrap__spent__filters">
            <div className="route__user__additionalSpents__wrap__spent__filters__left">
                {userInfoSelector?.Flags?.isAdmin && <div className="route__user__additionalSpents__wrap__spent__filters__left__radio" onClick={e => {
                    e?.stopPropagation();
                    setAllUsers(au => !au);
                }}>
                    <RadioButton checked={allUsers} />
                    <span>All users</span>
                </div>}
            </div>
            <div className="route__user__additionalSpents__wrap__spent__filters__right">
                <FilterByDate style={{width: "300px"}} defaultValue="all" onChange={e => setDateFilter(e)} />

                {userInfoSelector?.Flags?.isAdmin ? (selectedItems.length === 0 ?
                <StyledButton onClick={() => animateBox(<UserAdditionalSpents__spent__add onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)}>+ Add</StyledButton> :
                <ButtonWithDropdown />) : null}
            </div>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Date", (allUsers ? "User" : null), "Spent", "Bound to"].filter(f => f)}
            customColumns={(new Array(allUsers ? 4 : 3)).fill("max-content")}
            style={{
                columnGap: "40px"
            }}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    let finalSpent = Number(item.Spent);
                    if (isNaN(finalSpent)) return "-";
                    finalSpent = `${finalSpent.toFixed(3)} ${currencySignSelector}`;

                    let boundTo = <span style={{color: "rgb(108, 93, 211)"}}>User</span>;
                    if (item.CampaignID) {
                        boundTo = <><span style={{color: "rgb(108, 93, 211)"}}>User, Campaign</span> {`-> ${item._Campaign?.CampaignName ?? "-"}`}</>
                    } else if (item.OfferID) {
                        boundTo = <><span style={{color: "rgb(108, 93, 211)"}}>User, Offer</span> {`-> ${item._Offer?.OfferName} (${item._Offer?.Country ?? "-"}, ${item._Offer?.OfferType ?? "-"})`}</>
                    };

                    out.push([
                        {keyID: item.ID, type: "text", text: moment(item.Date).toDate().toLocaleDateString()},
                        (allUsers ? {keyID: item.ID, type: "text", text: item._User?.Username} : null),
                        {keyID: item.ID, type: "text", text: finalSpent},
                        {keyID: item.ID, type: "text", text: boundTo},

                        (item.Description ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "custom", data: <p style={{marginLeft: "-5px"}}>
                                <span style={{color: "gray"}}>Description:&nbsp;</span>
                                {item.Description}
                            </p> }
                        ]} : null)
                    ].filter(f => f))
                };
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
            checkboxCB={data?.data?.length === "ok" ? c => setSelectedItems(c) : undefined}
            checkboxFunctions={f => checkboxFunctionsRef.current = f}
        />
        {canPaginate && <div style={{width: "100%"}} ref={curOnScreen.measureRef}></div>}
    </div>
};

const UserAdditionalSpents__recurring = props => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [allUsers, setAllUsers] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const curDispatch = useDispatch();
    const curOnScreen = useOnScreen();

    const timestampRef = React.useRef();
    const checkboxFunctionsRef = React.useRef();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);
        try {
            checkboxFunctionsRef?.current?.reset?.();
        } catch {};

        let filters = [];

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/getAllRecurring`,
            data: {
                limit: 20,
                offset: 0,
                filters,
                allUsers,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (!data) return;
        if (data.status !== "ok") return;
        setCanPaginate(false);
        
        try {
            checkboxFunctionsRef?.current?.reset?.();
        } catch {};

        let filters = [];

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/getAllRecurring`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ],
                allUsers,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const editItem = () => {
        if (selectedItems.length !== 1) return;
        if (!data) return;
        if (data.status !== "ok") return;

        let tmp = data.data.find(d => d.ID === selectedItems[0]);
        if (!tmp) return;

        animateBox(<UserAdditionalSpents__recurring__add
            edit={tmp}
            onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
                setSelectedItems([]);
            }}
        />);
    };

    const removeItems = () => {
        if (selectedItems.length === 0) return;

        animateBox(<YesNoModal
            heading="Are you sure?"
            text={["You are about to remove ", <span style={{color: "rgb(108, 93, 211)"}}>{selectedItems.length} recurring payment{selectedItems.length > 1 ? "s" : ""}</span>, ". This action is irreversible!"]}
            isRightButtonNormal={true}
            buttonLeftText="No"
            buttonRightText="Yes"
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);

                for (let item of selectedItems) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/additionalSpents/removeRecurring`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null)
                };

                args.disabledAll(false);
                args.spinner(false);
                args.close();

                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
                setSelectedItems([]);
            }}
        />);
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};
        setCanPaginate(false);

        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {

        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        handler();

        return () => {
            curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
        };
    }, [allUsers]);


    return <div className="route__user__additionalSpents__wrap__spent">
        <div className="route__user__additionalSpents__wrap__spent__filters">
            <div className="route__user__additionalSpents__wrap__spent__filters__left">
                {userInfoSelector?.Flags?.isAdmin && <div className="route__user__additionalSpents__wrap__spent__filters__left__radio" onClick={e => {
                    e?.stopPropagation();
                    setAllUsers(au => !au);
                }}>
                    <RadioButton checked={allUsers} />
                    <span>All users</span>
                </div>}
            </div>
            <div className="route__user__additionalSpents__wrap__spent__filters__right">
                {userInfoSelector?.Flags?.isAdmin ? (selectedItems.length === 0 ?
                <StyledButton onClick={() => animateBox(<UserAdditionalSpents__recurring__add onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }} />)}>+ Add</StyledButton> :
                <ButtonWithDropdown
                    value="More actions"
                    image="/images/icon_edit.svg"
                    headStyle={{height: "43px"}}
                    data={[
                        (selectedItems.length === 1 ? {name: "Edit", onClick: editItem} : null),
                        {name: `Remove ${selectedItems.length} items`, onClick: removeItems}
                    ].filter(f => f)}
                />) : null}
            </div>
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Recurrs every", (allUsers ? "User" : null), "Spent", "Last payment", "Bound to"].filter(f => f)}
            customColumns={(new Array(allUsers ? 5 : 4)).fill("max-content")}
            style={{
                columnGap: "40px"
            }}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let item of data.data) {
                    let finalSpent = Number(item.Spent);
                    if (isNaN(finalSpent)) return "-";
                    finalSpent = `${finalSpent.toFixed(3)} ${currencySignSelector}`;

                    let boundTo = <span style={{color: "rgb(108, 93, 211)"}}>User</span>;
                    if (item.CampaignID) {
                        boundTo = <><span style={{color: "rgb(108, 93, 211)"}}>User, Campaign</span> {`-> ${item._Campaign?.CampaignName ?? "-"}`}</>
                    } else if (item.OfferID) {
                        boundTo = <><span style={{color: "rgb(108, 93, 211)"}}>User, Offer</span> {`-> ${item._Offer?.OfferName} (${item._Offer?.Country ?? "-"}, ${item._Offer?.OfferType ?? "-"})`}</>
                    };

                    out.push([
                        {keyID: item.ID, type: "text", text: `${item.RecurringDays} days`},
                        (allUsers ? {keyID: item.ID, type: "text", text: item._User?.Username} : null),
                        {keyID: item.ID, type: "text", text: finalSpent},
                        {keyID: item.ID, type: "text", text: moment(item.lastExecutedQuery).fromNow()},
                        {keyID: item.ID, type: "text", text: boundTo},

                        (item.Description ? {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "custom", data: <p style={{marginLeft: "-5px"}}>
                                <span style={{color: "gray"}}>Description:&nbsp;</span>
                                {item.Description}
                            </p> }
                        ]} : null)
                    ].filter(f => f))
                };
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
            checkboxCB={data?.data?.length ? c => setSelectedItems(c) : undefined}
            checkboxFunctions={f => checkboxFunctionsRef.current = f}
        />
        {canPaginate && <div style={{width: "100%"}} ref={curOnScreen.measureRef}></div>}
    </div>
};

const UserAdditionalSpents__spent__add = props => {
    const [allUsers, setAllUsers] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [allCampaigns, setAllCampaigns] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        text: "",
        inputs: [],
        hadError: "false"
    });

    const [selectedSpentType, setSelectedSpentType] = React.useState("offer");
    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [selectedCampaign, setSelectedCampaign] = React.useState();

    const dateRef = React.useRef();
    const descriptionRef = React.useRef();
    const spentRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const addSpent = () => {
        if (spinner) return;

        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});
        let data = {
            Date: dateRef.current.value,
            UserID: selectedUser,
            OfferID: selectedOffer,
            CampaignID: selectedCampaign,
            Spent: spentRef.current.value,
            Description: descriptionRef.current.value
        };

        if (!moment(data.Date).isValid()) return setInfoP(ip => {return {...ip, text: "Date is invalid", inputs: ["date"], hadError: true}});
        if (!data.UserID) return setInfoP(ip => {return {...ip, text: "User must be selected", inputs: ["user"], hadError: true}});
        if (!data.Spent) return setInfoP(ip => {return {...ip, text: "Spent can't be 0 or empty", inputs: ["spent"], hadError: true}});
        data.Spent = Number(data.Spent);
        if (isNaN(data.Spent)) return setInfoP(ip => {return {...ip, text: "Spent is not a number", inputs: ["spent"], hadError: true}});

        data.Date = moment(data.Date).startOf("day").add(12, "hours").toDate().getTime();

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/add`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, text: "There was an error while creating a new spent!", inputs: [], hadError: true}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, text: "Server timed out!", inputs: [], hadError: true}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getAllCampaigns = () => {
        if (!selectedUser) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                allUsers: true,
                limit: null,
                filters: [
                    {name: "CreatedBy", op: "eq", value: selectedUser}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllCampaigns(res.data.data);
            } else {
                setAllCampaigns([]);
            };
        }).catch(() => setAllCampaigns([]));
    };

    const getAllOffers = () => {
        if (!selectedUser) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                extended: false,
                filters: [
                    {name: "ResponsiblePerson", op: "jaeq", value: {ID: selectedUser}}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllOffers(res.data.data);
            } else {
                setAllOffers([]);
            };
        }).catch(() => setAllOffers([]));
    };

    React.useEffect(() => {
        if (selectedSpentType === "offer") {
            setSelectedCampaign();
            setAllCampaigns();
            setAllOffers();
            getAllOffers();
        } else if (selectedSpentType === "campaign") {
            setSelectedOffer();
            setAllCampaigns();
            setAllOffers();
            getAllCampaigns();
        };
    }, [selectedSpentType, selectedUser]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllUsers(res.data.data);
            } else {
                setAllUsers([]);
            };
        }).catch(() => setAllUsers([]));
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add spent</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={() => props.onClose()}></div>
            </div>

            {allUsers ? <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("date") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Date</p>
                    <input type="date" ref={dateRef} />
                </div>

                <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("user") ? "genericModal__wrap__input--active" : ""}`}>
                    <p>User</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={allUsers.map(usr => {
                            return {name: usr.Username, value: usr.ID};
                        })}
                        onChange={e => selectedUser !== e?.value && setSelectedUser(e?.value)}
                        selected={(()=>{
                            if (!selectedUser) return null;
                            if (!allUsers) return null;

                            return allUsers.indexOf(allUsers.find(u => u.ID === selectedUser));
                        })()}
                        inlinePlaceholder="Select an user"
                    />
                </div>
            </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}

            {selectedUser && <>
                <div className="genericTabs">
                    <div className={`genericTabs__tab ${selectedSpentType === "offer" ? "genericTabs__tab--active" : ""}`} onClick={e => selectedSpentType !== "offer" && setSelectedSpentType("offer")}>Offer</div>
                    <div className={`genericTabs__tab ${selectedSpentType === "campaign" ? "genericTabs__tab--active" : ""}`} onClick={e => selectedSpentType !== "campaign" && setSelectedSpentType("campaign")}>Campaign</div>
                </div>

                {selectedSpentType === "offer" && (allOffers ? <div className="genericModal__wrap__input genericModal__wrap__input--dropdown">
                    <p>Offer (optional)</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={[
                            {name: "No offer", value: null},
                            ...allOffers.map(c => {
                                return {name: `${c.OfferName} (${c.Country ?? "-"}, ${c.OfferType ?? "-"})`, value: c.ID};
                            })
                        ]}
                        onChange={e => selectedOffer !== e?.value && setSelectedOffer(e?.value)}
                        selected={(()=>{
                            if (!selectedOffer) return 0;
                            if (!allOffers) return 0;

                            return allOffers.indexOf(allOffers.find(o => o.ID === selectedOffer)) + 1;
                        })()}
                    />
                </div> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />)}

                {selectedSpentType === "campaign" && (allCampaigns ? <div className="genericModal__wrap__input genericModal__wrap__input--dropdown">
                    <p>Campaign (optional)</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={[
                            {name: "No campaign", value: null},
                            ...allCampaigns.map(c => {
                                return {name: c.CampaignName, value: c.ID};
                            })
                        ]}
                        onChange={e => selectedCampaign !== e?.value && setSelectedCampaign(e?.value)}
                        selected={(()=>{
                            if (!selectedCampaign) return 0;
                            if (!allCampaigns) return 0;

                            return allCampaigns.indexOf(allCampaigns.find(c => c.ID === selectedCampaign)) + 1;
                        })()}
                    />
                </div> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />)}

                <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Spent</p>
                    <input ref={spentRef} type="text" placeholder="Spent" />
                </div>

                <div className="genericModal__wrap__input genericModal__wrap__input--text">
                    <p>Description (optional)</p>
                    <textarea ref={descriptionRef} placeholder="Description" />
                </div>
            </>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                {(selectedUser) && <div className="genericModal__wrap__buttons__btn" onClick={e => spinner ? e?.stopPropagation?.() : addSpent()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Add"}
                </div>}
            </div>

            {infoP.text && <p style={{opacity: infoP.hadError ? 1 : 0}} className="genericModal__wrap__infoP">{infoP.text}</p>}
        </div>
    </div>
};

const UserAdditionalSpents__recurring__add = props => {
    const [allUsers, setAllUsers] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [allCampaigns, setAllCampaigns] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        text: "",
        inputs: [],
        hadError: "false"
    });

    const [selectedSpentType, setSelectedSpentType] = React.useState(props.edit ? (props.edit.OfferID ? "offer" : props.edit.CampaignID ? "campaign" : "offer") : "offer");
    const [selectedUser, setSelectedUser] = React.useState(props.edit ? props.edit.UserID : undefined);
    const [selectedOffer, setSelectedOffer] = React.useState(props.edit ? props.edit.OfferID : undefined);
    const [selectedCampaign, setSelectedCampaign] = React.useState(props.edit ? props.edit.CampaignID : undefined);

    const dateRef = React.useRef();
    const descriptionRef = React.useRef();
    const spentRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const addSpent = () => {
        if (spinner) return;

        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});
        let data = {
            RecurringDays: dateRef.current.value,
            UserID: selectedUser,
            OfferID: selectedOffer,
            CampaignID: selectedCampaign,
            Spent: spentRef.current.value,
            Description: descriptionRef.current.value
        };

        data.RecurringDays = Number(data.RecurringDays);
        if (isNaN(data.RecurringDays)) return setInfoP(ip => {return {...ip, text: "Recurring days is invalid", inputs: ["date"], hadError: true}});
        if (data.RecurringDays < 1) return setInfoP(ip => {return {...ip, text: "Recurring days must be greater than 0", inputs: ["date"], hadError: true}});
        if (!data.UserID) return setInfoP(ip => {return {...ip, text: "User must be selected", inputs: ["user"], hadError: true}});
        if (!data.Spent) return setInfoP(ip => {return {...ip, text: "Spent can't be 0 or empty", inputs: ["spent"], hadError: true}});
        data.Spent = Number(data.Spent);
        if (isNaN(data.Spent)) return setInfoP(ip => {return {...ip, text: "Spent is not a number", inputs: ["spent"], hadError: true}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/${props.edit ? "edit" : "add"}Recurring`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, text: "There was an error while creating a new recurring spent!", inputs: [], hadError: true}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, text: "Server timed out!", inputs: [], hadError: true}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getAllCampaigns = () => {
        if (!selectedUser) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                allUsers: true,
                limit: null,
                filters: [
                    {name: "CreatedBy", op: "eq", value: selectedUser}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllCampaigns(res.data.data);
            } else {
                setAllCampaigns([]);
            };
        }).catch(() => setAllCampaigns([]));
    };

    const getAllOffers = () => {
        if (!selectedUser) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                extended: false,
                filters: [
                    {name: "ResponsiblePerson", op: "jaeq", value: {ID: selectedUser}}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllOffers(res.data.data);
            } else {
                setAllOffers([]);
            };
        }).catch(() => setAllOffers([]));
    };

    React.useEffect(() => {
        if (selectedSpentType === "offer") {
            setSelectedCampaign();
            setAllCampaigns();
            setAllOffers();
            getAllOffers();
        } else if (selectedSpentType === "campaign") {
            setSelectedOffer();
            setAllCampaigns();
            setAllOffers();
            getAllCampaigns();
        };
    }, [selectedSpentType, selectedUser]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAllUsers(res.data.data);
            } else {
                setAllUsers([]);
            };
        }).catch(() => setAllUsers([]));
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add recurring spent</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={() => props.onClose()}></div>
            </div>

            {allUsers ? <>
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("date") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Recurrs every X days</p>
                    <input type="text" ref={dateRef} placeholder="" defaultValue={props.edit ? props.edit.RecurringDays : 1} />
                </div>

                <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("user") ? "genericModal__wrap__input--active" : ""}`}>
                    <p>User</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={allUsers.map(usr => {
                            return {name: usr.Username, value: usr.ID};
                        })}
                        onChange={e => selectedUser !== e?.value && setSelectedUser(e?.value)}
                        selected={(()=>{
                            if (!selectedUser) return null;
                            if (!allUsers) return null;

                            return allUsers.indexOf(allUsers.find(u => u.ID === selectedUser));
                        })()}
                        inlinePlaceholder="Select an user"
                    />
                </div>
            </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}

            {selectedUser && <>
                <div className="genericTabs">
                    <div className={`genericTabs__tab ${selectedSpentType === "offer" ? "genericTabs__tab--active" : ""}`} onClick={e => selectedSpentType !== "offer" && setSelectedSpentType("offer")}>Offer</div>
                    <div className={`genericTabs__tab ${selectedSpentType === "campaign" ? "genericTabs__tab--active" : ""}`} onClick={e => selectedSpentType !== "campaign" && setSelectedSpentType("campaign")}>Campaign</div>
                </div>

                {selectedSpentType === "offer" && (allOffers ? <div className="genericModal__wrap__input genericModal__wrap__input--dropdown">
                    <p>Offer (optional)</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={[
                            {name: "No offer", value: null},
                            ...allOffers.map(c => {
                                return {name: `${c.OfferName} (${c.Country ?? "-"}, ${c.OfferType ?? "-"})`, value: c.ID};
                            })
                        ]}
                        onChange={e => selectedOffer !== e?.value && setSelectedOffer(e?.value)}
                        selected={(()=>{
                            if (!selectedOffer) return 0;
                            if (!allOffers) return 0;

                            return allOffers.indexOf(allOffers.find(o => o.ID === selectedOffer)) + 1;
                        })()}
                    />
                </div> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />)}

                {selectedSpentType === "campaign" && (allCampaigns ? <div className="genericModal__wrap__input genericModal__wrap__input--dropdown">
                    <p>Campaign (optional)</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        data={[
                            {name: "No campaign", value: null},
                            ...allCampaigns.map(c => {
                                return {name: c.CampaignName, value: c.ID};
                            })
                        ]}
                        onChange={e => selectedCampaign !== e?.value && setSelectedCampaign(e?.value)}
                        selected={(()=>{
                            if (!selectedCampaign) return 0;
                            if (!allCampaigns) return 0;

                            return allCampaigns.indexOf(allCampaigns.find(c => c.ID === selectedCampaign)) + 1;
                        })()}
                    />
                </div> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />)}

                <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Spent</p>
                    <input ref={spentRef} type="text" placeholder="Spent" defaultValue={props.edit ? props.edit.Spent : undefined} />
                </div>

                <div className="genericModal__wrap__input genericModal__wrap__input--text">
                    <p>Description (optional)</p>
                    <textarea ref={descriptionRef} placeholder="Description" defaultValue={props.edit ? props.edit.Description : undefined} />
                </div>
            </>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                {(selectedUser) && <div className="genericModal__wrap__buttons__btn" onClick={e => spinner ? e?.stopPropagation?.() : addSpent()}>
                    {spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Add"}
                </div>}
            </div>

            {infoP.text && <p style={{opacity: infoP.hadError ? 1 : 0}} className="genericModal__wrap__infoP">{infoP.text}</p>}
        </div>
    </div>
};

export default UserAdditionalSpents;