import React from "react";

import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDefer from "../../../../modules/hooks/useDefer";
import useOnScreen from "../../../../modules/hooks/useOnScreen";
import * as backendModule from "../../../../modules/backendModule";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import * as trackEventsModule from "../../../../modules/trackEventsModule";
import * as contextMenuModule from "../../../../modules/contextMenuModule";
import animateModule from "../../../../modules/animateModule";
import { animateBox } from "../../../../modules/componentAnimation";
import { siteRiskColors } from "../../../../modules/miscModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import FilterColumns from "../../../../components/filters/FilterColumns";
import Dropdown from "../../../../components/customComponents/Dropdown";
import RadioButton from "../../../../components/customComponents/RadioButton";
import Spinner from "../../../../components/customComponents/Spinner";
import LazyImage from "../../../../components/LazyImage";

import PreviewImageModal from "../../../../components/modals/PreviewImageModal";
import YesNoModal from "../../../../components/modals/YesNoModal";
import CampaignShareModal from "../../../../components/modals/CampaignShareModal";

import CampaignDashboard from "../../../../components/modals/CampaignDashboard";
import GlobalChanges from "../../../../components/modals/GlobalChangesModal";
import Checkbox from "../../../../components/customComponents/Checkbox";

const availableCampaignColumns = [
    "Visits", "Engagement",
    "Unique visits", "Conversions", "Abandoned", "Spent", "Revenue", "CR", "AR", "Approved",
    "DR", "CUR", "CURC", "Realtime", "Visits PL", "Visits LP", "Profit", "EPV", "CPA", "CPAO",
    "CRR", "CRS", "ROI", "ROAS", "Unique conversions", "PLC", "ADP", "Processed", "BACR", "BACC", "Score", "CPC", "LTV", "CTAClicked", "NC",
    "NCO", "Abandoned", "AbandonedR", "AddToCartR",
];

const OrganicCampaigns = (props) => {
    const [trackEvents, setTrackEvents] = React.useState();
    const [canPaginate, setCanpaginate] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [resetSpinner, setResetSpinner] = React.useState(false);
    const [data, setData] = React.useState();
    const [oldData, setOldData] = React.useState([]);
    const [footerData, setFooterData] = React.useState();
    const [order, setOrder] = React.useState();
    const [visibleColumns, setVisibleColumns] = React.useState();
    const [selectedData, setSelectedData] = React.useState([]);
    const [searchFilter, setSearchFilter] = React.useState();
    const [dateFilter, setDateFilter] = React.useState(props.dateFilter);
    const [campaignDateFilter, setCampaignDateFilter] = React.useState(props.campaignDateFilter);
    const [showAllCampaigns, setShowAllCampaigns] = React.useState(false);
    const [contextMenuTimestmap, setContextMenuTimestamp] = React.useState();
    const [clonedCampaigns, setClonedCampaigns] = React.useState([]);
    let dataTimeout = null;


    const checkboxFunctionsRef = React.useRef();
    const timestampRef = React.useRef();
    const footerTimestampRef = React.useRef();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const selectedUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
    const selectedTeamSelector = useSelector(state => state?.trackingProfiles?.selectedTeam ?? "-1");
    const allTeamsSelector = useSelector(state => state?.trackingProfiles?.teams ?? []);
    const campaignColumnExplanationsSelector = useSelector(state => state?.types?.campaignColumnExplanations ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const curDefer = useDefer();
    const visibleColumnsDefer = useDefer();
    const orderDefer = useDefer();
    const orderInitDefer = useDefer();
    const curScreen = useOnScreen();
    const curDispatch = useDispatch();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const isTeamLead = (teamID) => {
        let curTeam = allTeamsSelector.find(t => t.ID === teamID);
        if (!curTeam) return false;

        if (curTeam.CreatedBy === selectedUserSelector?.ID) return true;
        return false;
    };

    const prepareSearch = (e) => {
        clearTimeout(dataTimeout);

        dataTimeout = setTimeout(() => {
            setSearchFilter(e);
        }, 500);
    }


    React.useEffect(() => {
        prepareSearch(props.searchFilter)
    }, [props.searchFilter]);
    React.useEffect(() => {
        setSearchFilter(props.searchFilter)
    }, [props.searchFilter]);
    React.useEffect(() => {
        setDateFilter(props.dateFilter)
    }, [props.dateFilter]);
    React.useEffect(() => {
        setCampaignDateFilter(props.campaignDateFilter)
    }, [props.campaignDateFilter]);

    const parseTableOrders = () => {
        if (!order) return [];

        let tmp = { ...order };
        
        if (tmp?.name?.["$$typeof"]){
            // handle header child html
            tmp.name = tmp.name.props.children[tmp.name.props.children.length - 1];
        };

        switch (tmp?.name) {
            case "On / Off":
                tmp.name = "CampaignActive";
                break;
            case "Campaign":
                tmp.name = "CampaignName";
                break;
            default: break;
        };
        if (!tmp?.name) {
            tmp.name = order?.name?.replace?.(" ", "_");
        };
        if (tmp?.name) tmp.name = tmp.name.replace(" ", "_");

        return [{name: "CampaignActive", order: "asc"}, tmp];
    };

    const getFooterData = async (ts) => {
        if (!visibleColumns) return;
        let filters = [];
        let trackFilters = [];
        let campaignActiveFilters = [];

        if (footerTimestampRef.current !== ts) return;
        try {
            checkboxFunctionsRef.current?.reset();
        } catch {};

        if (searchFilter) {
            let searchSplit = searchFilter.split(" ").map(s => String(s)).filter(s => s);
            let searchFilterArr = [];

            for (let s of searchSplit) searchFilterArr.push({or: [
                {name: "CampaignName", op: "like", value: s},
                {name: "ID", op: "eq", value: s}
            ]});
            filters.push({and: searchFilterArr});
        };
        if (campaignDateFilter) {
            if (campaignDateFilter?.start && campaignDateFilter?.end) {
                filters.push({ name: "createdAt", op: "pdgeq", value: campaignDateFilter.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "pdleq", value: campaignDateFilter.end.toDate().getTime() });
            };
        };
        if (dateFilter) {
            if (dateFilter?.start && dateFilter?.end) {
                campaignActiveFilters.push({ name: "lastTrackedAt", op: "pdgeq", value: dateFilter.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime() });
            };
        };
        if (props?.campaignKeywordFilters?.length > 0) {
            filters.push({and: props.campaignKeywordFilters.map(f => {
                return {name: "CampaignName", op: "like", value: f}
            })});
        };
        
        const failover = () => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                data: {
                    IntegrationID: "-1",
                    TableHeaders: visibleColumns.map(vc => vc.replace(" ", "_")),
                    limit: null,
                    offset: 0,
                    filters,
                    trackFilters,
                    showAllCampaigns: showAllCampaigns,
                    orders: parseTableOrders()
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (footerTimestampRef.current !== ts) return;
                if (res.data.status === "ok") {
                    let totalADP = 0;
                    for (let item of res.data.data) {
                        if (item.TableData["ADP"]) totalADP += 1;
                    }
                    if (totalADP > 0) {
                        for (let item of res.data.data) {
                            if (item.TableData["ADP"]) {
                                item.TableData["ADP"] = item.TableData["ADP"] /= totalADP;
                            };
                        };
                    };
                };
                setFooterData(res.data);
            }).catch(() => {
                if (footerTimestampRef.current !== ts) return;
                setFooterData(backendModule.axiosConfig);
            });
        };

        let allCampaigns = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                filters: [
                    {name: "IntegrationID", op: "eq", value: selectedTrackingProfileSelector},
                    ...filters,
                    ...campaignActiveFilters
                ],
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (allCampaigns.status === "error") return failover();
        if (allCampaigns.data.length === 0) return failover();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                TableHeaders: visibleColumns.map(vc => vc.replace(/ /g, "_")),
                CampaignIDs: allCampaigns.data.map(c => c.ID),
                IntegrationType: allCampaigns.data[0].IntegrationType,
                filters: trackFilters,

                skipIntegrationDataPull: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (footerTimestampRef.current !== ts) return;
            if (res.data.status === "error") return failover();

            if (res.data.data.Integrations) {
                for (let key of Object.keys(res.data.data.Integrations)) {
                    let curIntegration = res.data.data.Integrations[key];
                    if (curIntegration) {
                        return setFooterData({status: "ok", data: [curIntegration]});
                    };
                };
            };
            return failover();
        }).catch(() => {
            if (footerTimestampRef.current !== ts) return;
            failover();
        });
    };

    const getData = (ts) => {
        if (order === undefined) return;
        if (!visibleColumns) return;
        let filters = [];
        let trackFilters = [];

        try {
            checkboxFunctionsRef.current?.reset();
        } catch {};

        if (searchFilter) {
            let searchSplit = searchFilter.split(" ").map(s => String(s)).filter(s => s);
            let searchFilterArr = [];

            for (let s of searchSplit) searchFilterArr.push({or: [
                {name: "CampaignName", op: "like", value: s},
                {name: "ID", op: "eq", value: s}
            ]});
            filters.push({and: searchFilterArr});
        };
        if (campaignDateFilter) {
            if (campaignDateFilter?.start && campaignDateFilter?.end) {
                filters.push({ name: "createdAt", op: "pdgeq", value: campaignDateFilter.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "pdleq", value: campaignDateFilter.end.toDate().getTime() });
            };
        };
        if (dateFilter) {
            if (dateFilter?.start && dateFilter?.end) {
                trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime() });
            };
        };
        if (props?.campaignKeywordFilters?.length > 0) {
            filters.push({and: props.campaignKeywordFilters.map(f => {
                return {name: "CampaignName", op: "like", value: f}
            })});
        };

        setOldData([]);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: "-1",
                TableHeaders: [...new Set([...visibleColumns.map(vc => vc.replace(" ", "_")), "Score"])],
                limit: 20,
                offset: 0,
                filters,
                trackFilters,
                showAllCampaigns: showAllCampaigns,
                orders: parseTableOrders()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setTimeout(() => setCanpaginate(true), 500);
                } else {
                    setCanpaginate(false);
                };
            };

            setData(res.data);
        }).catch(() => {
            if (ts !== timestampRef.current) return;
            setData(backendModule.axiosConfig);
        }).finally(() => {
            setResetSpinner(false);
        });
    };

    const continueData = (ts) => {
        if (!visibleColumns) return;
        if (!data) return;
        if (data.status !== "ok") return;
        setCanpaginate(false);

        let filters = [];
        let trackFilters = [];

        if (searchFilter) {
            let searchSplit = searchFilter.split(" ").map(s => String(s)).filter(s => s);
            let searchFilterArr = [];

            for (let s of searchSplit) searchFilterArr.push({or: [
                {name: "CampaignName", op: "like", value: s},
                {name: "ID", op: "eq", value: s}
            ]});
            filters.push({and: searchFilterArr});
        };
        if (campaignDateFilter) {
            if (campaignDateFilter?.start && campaignDateFilter?.end) {
                filters.push({ name: "createdAt", op: "pdgeq", value: campaignDateFilter.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "pdleq", value: campaignDateFilter.end.toDate().getTime() });
            };
        };
        if (dateFilter) {
            if (dateFilter?.start && dateFilter?.end) {
                trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime() });
            };
        };
        if (props?.campaignKeywordFilters?.length > 0) {
            filters.push({and: props.campaignKeywordFilters.map(f => {
                return {name: "CampaignName", op: "like", value: f}
            })});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: "-1",
                TableHeaders: [...new Set([...visibleColumns.map(vc => vc.replace(" ", "_")), "Score"])],
                limit: 20,
                offset: data.data.length,
                filters,
                trackFilters,
                showAllCampaigns: showAllCampaigns,
                orders: parseTableOrders()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== timestampRef.current) return;
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanpaginate(true);
                } else {
                    setCanpaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const getTotalData = (dataSource = data) => {
        if (!dataSource) return {};
        if (dataSource.status !== "ok") return null;
        let tmpTotal = {};
        for (let item of dataSource.data) {
            if (item?.TableData) {
                for (let info of Object.keys(item?.TableData)) {

                    if (!tmpTotal[info]) tmpTotal[info] = 0;
                    tmpTotal[info] += isNaN(Number(item.TableData[info])) ? 0 : Number(item.TableData[info])
                }
            }
        }
        return tmpTotal;
    };

    const toggleCampaign = (ID, newState) => {
        if (!data) return;
        if (data.status !== "ok") return;

        setData(d => {
            return {
                ...d,
                data: d.data.map(dt => {
                    if (dt.ID !== ID) return dt;
                    dt.CampaignActive = null;
                    return dt;
                })
            };
        });

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/toggleCampaign`,
            data: {
                ID,
                newState: !!newState
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setData(d => {
                return {
                    ...d,
                    data: d.data.map(dt => {
                        if (dt.ID !== ID) return dt;
                        dt.CampaignActive = !!newState;
                        return dt;
                    })
                };
            });
        });
    };

    const editCampaign = e => {
        if (selectedData.length !== 1) return;
        if (!data) return;
        if (data.status !== "ok") return;

        let foundCampaign = data.data.find(dt => dt.ID === selectedData[0]);
        if (!foundCampaign) return;

        animateBox(e, <AddCampaign edit={foundCampaign} onChange={(eID) => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);

            animateBox({currentTarget: document.querySelector(".component__filters__filterColumn")}, <CampaignURLs ids={[eID]} />);
        }} />);
    };

    const deleteCampaigns = e => {
        animateBox(e, <YesNoModal
            heading="Are You sure?"
            text={[
                "You are about to remove ",
                <span style={{ color: "rgb(108, 93, 211)" }}>{selectedData.length} campaigns</span>,
                ". Removal of the campaigns will also remove any tracking data associated with the selected campaign. ",
                "This action is irreversible, are You sure?"
            ]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);

                for (let item of selectedData) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/removeCampaign`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                if (data?.status === "ok") {
                    setData(d => {
                        return {
                            ...d,
                            data: d.data.filter(dt => !selectedData.includes(dt.ID))
                        };
                    });
                };
                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();

                try {
                    checkboxFunctionsRef.current?.reset();
                } catch {};
            }}
        />);
    };

    const cloneCampaigns = (e) => {
        if (selectedData.length === 0) return;
        if (!data) return;
        if (data.status !== "ok") return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["This will clone ", <span style={{color: "rgb(108, 93, 211)"}}>{selectedData.length} campaign{selectedData.length > 1 ? "s" : ""}</span>, ".\nAre you sure?"]}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage();

                for (let item of selectedData) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/cloneCampaign`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status === "ok") setClonedCampaigns(cc => [...cc, res.data.data]);
                    }).catch(() => null);
                };
                args.disabledAll(false);
                args.spinner(false);
                args.close();

                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
            }}
        />);
    };

    const prepareTableData = (data, column) => {
        if (column?.endsWith?.(".lastDate") && column?.startsWith?.("@")) {
            if (data === 0) return "Never";
            if (moment(data).isValid()) return moment(data).toDate().toLocaleString();
        };
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPC":
            case "CPA":
            case "CPAO":
            case "ADP":
            case "LTV":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(3)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "DR":
            case "BACR":
            case "CTR":
            case "AddToCartR":
            case "AbandonedR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(3)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(3)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const openItemMoreInfo = (selectedItem, e) => {
        if (!data) return;
        if (data.status !== "ok") return;

        let foundItem = data.data.find(d => d.ID === selectedItem);
        if (!foundItem) return;

        animateBox(e, <CampaignDashboard datePreset={props.dateFilter} integration={null} item={foundItem} />);
    };

    const openItemHistory = (selectedItem, e) => {
        if (!data) return;
        if (data.status !== "ok") return;

        let foundItem = data.data.find(d => d.ID === selectedItem);
        if (!foundItem) return;

        animateBox(e, <GlobalChanges ID={foundItem.ID} Table={"Campaigns"} />);
    };

    const checkCPAOColumnStyle = (campaign = null) => {
        const redColor = themeSelector === "dark" ? "#483234" : "#ffd0d0"; // "#483234";
        const selectedColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";

        if (!campaign) {
            if (!data) return [];
            if (data.status !== "ok") return [];
    
            let out = [];
            for (let campaign of data.data) {
                out.push(null);
                continue;
                let totalSum = campaign?.TableData?.Score || 0;
                if (!totalSum) {
                    out.push(redColor);
                } else {
                    out.push(null);
                };
            };
    
            return out.map((elem, elemIndex) => {
                if (selectedData.includes(data.data[elemIndex].ID)) return selectedColor;
                return elem;
            });
        } else {
            return null;
            if (!data) return null;
            if (data.status !== "ok") return null;
            if (typeof(campaign) === "string") {
                campaign = data.data.find(d => d.ID === campaign);
            };
            if (!campaign) return null;
            let totalSum = campaign.TableData?.Score || 0;
            if (!totalSum) {
                return redColor;
            } else {
                return null;
            };
        };
    };

    const calculateFooterData = col => {
        if (!data) return null;
        if (data.status !== "ok") return null;

        if (col === "CR") {
            if (!visibleColumns.includes("Visits") || !visibleColumns.includes("Conversions")) return null;

            let visits = 0;
            let conversions = 0;

            for (let item of data.data) {
                if (item.TableData["Visits"]) {
                    visits += item.TableData["Visits"];
                };
                if (item.TableData["Conversions"]) {
                    conversions += item.TableData["Conversions"];
                };
            };
            return 100 / visits * conversions;
        };

        return null;
    };

    const prepareOldData = () => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!Array.isArray(oldData)) return;
        if (!dateFilter || !props.compareEnabled) {
            setOldData(null);
            return;
        };

        let newEnd = moment(dateFilter?.start.toDate().getTime()).add(-1, "days");
        let diff = dateFilter.end.diff(dateFilter.start, "day");
        let newStart = moment(newEnd.toDate().getTime()).add(diff * -1, "days");
        newEnd = newEnd.endOf("day");

        let tmpNewData = [];
        for (let item of data.data) {
            let curOld = oldData.find(o => o.ID === item.ID);
            if (curOld) continue;

            tmpNewData.push({ID: item.ID});
        };
        if (tmpNewData.length === 0) return;

        setOldData(o => [...o, ...tmpNewData]);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: "-1",
                TableHeaders: visibleColumns.map(vc => vc.replace(/ /g, "_")),
                limit: null,
                offset: 0,
                filters: [
                    {name: "ID", op: "in", value: tmpNewData.map(t => t.ID)}
                ],
                trackFilters: [
                    {name: "createdAt", op: "pdgeq", value: newStart.toDate().getTime()},
                    {name: "createdAt", op: "pdleq", value: newEnd.toDate().getTime()}
                ],
                showAllCampaigns: showAllCampaigns,
                orders: parseTableOrders()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let out = [];
                for (let item of tmpNewData.map(t => t.ID)) {
                    let found = res.data.data.find(d => d.ID === item);
                    if (found) {
                        out.push(found);
                    } else {
                        out.push({ID: item, TableData: {}});
                    };
                };
                setOldData(o => {
                    let final = o.filter(od => od?.TableData);
                    return [...final, ...out];
                });
            } else {
                let out = [];
                for (let item of tmpNewData) {
                    out.push({...item, TableData: {}});
                };
                setOldData(o => {
                    let final = o.filter(od => od?.TableData);
                    return [...final, ...out];
                });
            };
        }).catch(() => {
            let out = [];
            for (let item of tmpNewData) {
                out.push({...item, TableData: {}});
            };
            setOldData(o => {
                let final = o.filter(od => !od?.TableData);
                return [...final, ...out];
            });
        });
    };
    const performDataCompare = (column, newData, oldData) => {
        if (column.endsWith("IN")) {
            column = column.substring(0, column.length-2) + "FB";
        };
        if (
            newData === null ||
            newData === undefined ||
            oldData === null ||
            oldData === undefined
        ) return null;

        let a = String(newData).split(" ");
        if (a.length > 1) a.pop();
        a = a.join(" ");

        let b = String(oldData).split(" ");
        if (b.length > 1) b.pop();
        b = b.join(" ");

        a = Number(a);
        b = Number(b);
        if (isNaN(a) || isNaN(b)) return null;

        const percentDiff = Math.abs((a - b) / b) * 100;
        const amountDiff = Number(a - b).toFixed(4);
        const dayDiff = dateFilter?.end?.diff(dateFilter?.start, "days");

        let colorA = null;
        let colorB = null;
        let colorPercent = null;

        if (a > b) {
            colorA = basicStylesModule.successColor;
            colorB = basicStylesModule.errorColor;
            colorPercent = basicStylesModule.successColor;
        } else if (b > a) {
            colorA = basicStylesModule.errorColor;
            colorB = basicStylesModule.successColor;
            colorPercent = basicStylesModule.errorColor;
        };

        const handleClick = e => {
            animateBox(e, <YesNoModal
                buttonLeftHidden={true}
                buttonRightText={"Ok"}
                isRightButtonNormal={true}
                heading={`${column} compare`}
                text={[
                    <p>Current period ({dayDiff} days): <span style={{color: colorA}}>{newData}</span></p>,
                    <p>Previous period ({dayDiff} days): <span style={{color: colorB}}>{oldData}</span></p>,
                    <p>Amount Difference: <span style={{color: colorPercent}}>{amountDiff}</span></p>,
                    <p>% Difference: <span style={{color: colorPercent}}>{percentDiff}</span></p>,
                ]}
            />);
        };

        if (a > b) {
            return <span className="route__user__campaigns__tableWrap__column__arrow" onClick={handleClick}>
                <img style={{width: "auto", height: "12px"}} src="/images/icon_upArrowGreen.svg" />
            </span>
        } else if (a < b) {
            return <span className="route__user__campaigns__tableWrap__column__arrow" onClick={handleClick}>
                <img style={{width: "auto", height: "12px"}} src="/images/icon_downArrowRed.svg" />
            </span>
        };
    };

    const getTrackEvents = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getAllForUser`,
            ...backendModule.axiosConfig
        }).then(res => {
            setTrackEvents(res.data);
        }).catch(() => {
            setTrackEvents(backendModule.genericError);
        });
    };

    React.useLayoutEffect(() => {
        if (data?.status !== "ok" || spinner || resetSpinner) return;
        if (selectedData.length === 0) return;
        if (!contextMenuTimestmap) return;

        let ctxData = [
            (selectedData.length === 1 ? { name: "Campagin performance", onClick: e => openItemMoreInfo(selectedData[0], e) } : null),
            (selectedData.length === 1 ? { name: "Performance tracking", onClick: () => animateNavigate(`/campaign-performance-tracking?search=${selectedData[0]}`) } : null),
            (selectedData.length === 1 ? { name: "Edit", onClick: e => editCampaign(e) } : null),
            { name: `Remove ${selectedData.length} campaigns`, onClick: e => deleteCampaigns(e) },
            { name: "Get tracking URL", onClick: e => animateBox(e, <CampaignURLs ids={selectedData} />) },
            {
                name: `Enable ${selectedData.length} campaigns`, onClick: () => {
                    for (let item of selectedData) toggleCampaign(item, true);
                }
            },
            {
                name: `Disable ${selectedData.length} campaigns`, onClick: () => {
                    for (let item of selectedData) toggleCampaign(item, false);
                }
            },
            { name: `Clone ${selectedData.length} campaigns`, onClick: e => cloneCampaigns(e) },
            (
                selectedTeamSelector !== '-1' ? {
                    name: `Transfer ${selectedData.length} campaigns`, onClick: e => animateBox(e, <TransferCampaigns ids={selectedData} />)
                } : null),
            (selectedData.length === 1 ? { name: "View changes history", onClick: e => openItemHistory(selectedData[0], e) } : null),
            (selectedData.length === 1 && (selectedUserSelector?.Flags?.isAdmin || selectedUserSelector?.Flags?.canShareCampaign) ? { name: "Share...", onClick: e => animateBox(e, <CampaignShareModal ID={selectedData[0]} integrationID={selectedTrackingProfileSelector} columns={trackEventsModule.init(availableCampaignColumns, trackEvents)} />) } : null)
        ].filter(t => t);

        let allItems = document.querySelectorAll(".route__user__campaigns__content .customComponents__table > .customComponents__table__data");

        let ctxItems = [];
        for (let item of allItems) {
            let ctxFinal = [];
            for (let ctxDataItem of ctxData) ctxFinal.push(new contextMenuModule.ContextMenuItem({
                name: ctxDataItem.name,
                onClick: () => {
                    let tmp = document.querySelector(".component__filters__filterByDate__right");
                    if (!tmp) return;
                    ctxDataItem.onClick({currentTarget: tmp});
                }
            }));
            ctxItems.push(contextMenuModule.registerContextMenu(item, ctxFinal));
        };

        return () => {
            for (let item of ctxItems) try { item.removeContextMenu(); } catch {};
        };
    }, [selectedData, contextMenuTimestmap]);

    React.useEffect(() => {
        if (!visibleColumns) return;
        if (data && !resetSpinner) setResetSpinner(true);
        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts);
        }, data ? 500 : 0);
        const handler = () => {
            if (data) setResetSpinner(true);
            curDefer(() => {
                let ts = Date.now();
                timestampRef.current = ts;
                footerTimestampRef.current = ts;
                getData(ts);
                getFooterData(ts);
            }, 0);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch { };
        };

    }, [visibleColumns, searchFilter, dateFilter, campaignDateFilter, order, showAllCampaigns, props.compareEnabled, props.campaignKeywordFilters]);

    React.useEffect(() => {
        let ts = Date.now();
        footerTimestampRef.current = ts;
        getFooterData(ts);
    }, [visibleColumns, searchFilter, dateFilter, campaignDateFilter, showAllCampaigns, props.campaignKeywordFilters]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curScreen.isIntersecting) return;

        let timeout = setTimeout(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            try {
                curScreen.observer.unobserve(curScreen.measureRef);
            } catch { };
            continueData(ts);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [curScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        if (!visibleColumns) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
                data: {
                    Integration: selectedTrackingProfileSelector
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setVisibleColumns(res.data.data);
                } else {
                    setVisibleColumns([]);
                };
            }).catch(() => {
                setVisibleColumns([]);
            });
        } else {
            visibleColumnsDefer(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
                    data: {
                        Integration: selectedTrackingProfileSelector,
                        Columns: visibleColumns
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            }, 500);
        };
    }, [visibleColumns]);

    React.useEffect(() => {
        if (order === undefined) {
            orderInitDefer(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
                    data: {
                        Integration: "organic-campaign-orders"
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        if (res.data.data.length !== 0) {
                            return setOrder({name: res.data.data[0], order: res.data.data[1]});
                        };
                    };
                    return setOrder(null);
                }).catch(() => {
                    setOrder(null);
                });
            }, 0);
        } else {
            orderInitDefer(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
                    data: {
                        Integration: "organic-campaign-orders",
                        Columns: order ? [order.name, order.order] : []
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            }, 1000);
        };
    }, [order]);

    React.useEffect(() => {
        if (props.functionsCB) props.functionsCB({
            openItemMoreInfo,
            editCampaign,
            selectedData,
            addCampaign: e => animateBox(e, <AddCampaign onChange={(eID) => {
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);

                animateBox({currentTarget: document.querySelector(".route__user__campaigns__content")}, <CampaignURLs ids={[eID]} />);
            }} />),
            editColumns: e => {
                document.querySelector(".component__filters__filterColumn").click();
            },
            openDropdown: e => {
                return [
                    (selectedData.length === 1 ? { name: "Campagin performance", onClick: e => openItemMoreInfo(selectedData[0], e) } : null),
                    (selectedData.length === 1 ? { name: "Edit", onClick: e => editCampaign(e) } : null),
                    { name: `Remove ${selectedData.length} campaigns`, onClick: e => deleteCampaigns(e) },
                    { name: "Get tracking URL", onClick: e => animateBox(e, <CampaignURLs ids={selectedData} />) },
                    {
                        name: `Enable ${selectedData.length} campaigns`, onClick: () => {
                            for (let item of selectedData) toggleCampaign(item, true);
                        }
                    },
                    {
                        name: `Disable ${selectedData.length} campaigns`, onClick: () => {
                            for (let item of selectedData) toggleCampaign(item, false);
                        }
                    },
                    { name: `Clone ${selectedData.length} campaigns`, onClick: e => cloneCampaigns(e) },
                    (
                        selectedTeamSelector !== '-1' ? {
                            name: `Transfer ${selectedData.length} campaigns`, onClick: e => animateBox(e, <TransferCampaigns ids={selectedData} />)
                        } : null),
                    (selectedData.length === 1 ? { name: "View changes history", onClick: e => openItemHistory(selectedData[0], e) } : null),
                    (selectedData.length === 1 && (selectedUserSelector?.Flags?.isAdmin || selectedUserSelector?.Flags?.canShareCampaign) ? { name: "Share...", onClick: e => animateBox(e, <CampaignShareModal ID={selectedData[0]} integrationID={selectedTrackingProfileSelector} columns={trackEventsModule.init(availableCampaignColumns, trackEvents)} />) } : null)
                ].filter(t => t);
            }
        });

        return () =>  props.functionsCB && props.functionsCB();
    }, [selectedData, order, campaignDateFilter, visibleColumns, props.campaignKeywordFilters]);

    React.useEffect(() => {
        prepareOldData();
    }, [data]);

    React.useEffect(() => {
        curDispatch(siteFunctionsActions.updateStickyHeader(false));

        getTrackEvents();

        return () => curDispatch(siteFunctionsActions.updateStickyHeader(true));
    }, []);

    return <>
        {(visibleColumns && trackEvents) && <FilterColumns integrationID={selectedTrackingProfileSelector} columns={trackEventsModule.init(availableCampaignColumns, trackEvents)} onChange={vc => {
            try {
                if (vc.join(",") === visibleColumns.join(",")) return;
            } catch {};
            setVisibleColumns(vc);
        }} defaultValue={visibleColumns} />}

        <div className="route__user__campaigns__tableWrap route__user__campaigns__tableWrap--sticky">
            <FilteredCustomTable
                onRender={() => setContextMenuTimestamp(Date.now())}
                colors={checkCPAOColumnStyle()}
                columnBorders={data?.status === "ok"}
                key="user-campaigns-table"
                customColumns={(data?.status === "ok" && data?.data?.length > 0) ? [
                    "80px",
                    "300px",
                    ...(new Array((visibleColumns ?? []).length)).fill("auto")
                ] : null}
                checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedData : false}
                checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
                checkboxContainerCB={(item, elem) => {
                    let curID = null;
                    if (item) {
                        if (Array.isArray(item?.columns)) {
                            if (item?.columns?.[0]?.keyID) {
                                curID = item.columns[0].keyID;
                            };
                        };
                    };
                    if (item?.isHeading) return <div className="route__user__campaigns__tableWrap__campaignWithImg__headingPadded"  style={{backgroundColor: item?.isFooter ? (themeSelector === "dark" ? "#4B4282" : "#b9aef0") : (selectedData.includes(curID) ? (themeSelector === "dark" ? "#373351" : "rgb(196, 191, 227)") : ""), transition: "background-color 0.3s ease"}}>
                        <div className="route__user__campaigns__tableWrap__campaignWithImg__padded__fill">
                            {elem}
                        </div>
                    </div>
                    return <p className="route__user__campaigns__tableWrap__campaignWithImg" style={{paddingLeft: "20px"}}>
                        <div className="route__user__campaigns__tableWrap__campaignWithImg__padded" style={{backgroundColor: item?.isFooter ? (themeSelector === "dark" ? "#4B4282" : "#b9aef0") : (selectedData.includes(curID) ? (themeSelector === "dark" ? "#373351" : "rgb(196, 191, 227)") : checkCPAOColumnStyle(curID)), transition: "background-color 0.3s ease"}}></div>
                        <p>
                            {elem}
                        </p>
                    </p>
                }}
                accent="#6C5DD3"
                theme={themeSelector}
                headers={["On / Off", "Campaign", ...(visibleColumns ?? [])]}
                customHeaders={{
                    "On / Off": <div>
                    <p className="route__user__campaigns__tableWrap__campaignWithImg__headingPadded"></p>
                        On / Off
                    </div>,
                    "Campaign": <div>
                        <p className="route__user__campaigns__tableWrap__campaignWithImg__headingPadded"></p>
                        Campaign
                    </div>,
                    ...((visibleColumns ?? []).reduce((acc, val) => {
                        let vc = val.replace(" ", "_");
                        if (campaignColumnExplanationsSelector[vc]) acc[val] = <span className="route__user__campaigns__tableWrap__campaignWithImg__headingPadded__withInfo" title={campaignColumnExplanationsSelector[vc]}>
                            {val}
                            <img src="/images/integrations/integration_info.svg" onClick={e => {
                                e.stopPropagation();
                                animateBox({currentTarget: e.target.parentNode}, <YesNoModal
                                    isLeftButtonNormal={true}
                                    buttonLeftText="Ok"
                                    buttonRightHidden={true}
                                    heading={val}
                                    text={campaignColumnExplanationsSelector[vc]}
                                />);
                            }} />
                        </span>
                        return acc;
                    }, {}))
                }}
                style={{
                    columnGap: "35px",
                    marginTop: "20px"
                }}
                stickyHeader={158}
                stickyHeaderClass="component__contentWrapper"
                stickyFooter={-3}
                stickyFooterClass="component__contentWrapper"
                orderCB={o => {
                    if (o === undefined && order === undefined) return;
                    orderDefer(() => setOrder(o), 500);
                }}
                data={(() => {
                    let out = [];

                    if (data) {
                        if (data.status === "ok") {
                            for (let item of data.data) out.push({
                                events: {
                                    onDoubleClick: e => {
                                        if (!data) return;
                                        if (data.status !== "ok") return;
                                        let ci = e.currentTarget.getAttribute("data-index");
                                        if (!ci) return;
                                        let foundData = data.data.find((_, d) => d === +ci);
                                        if (!foundData) return;

                                        if (checkboxFunctionsRef.current?.select) {
                                            if (selectedData.includes(foundData.ID)) {
                                                checkboxFunctionsRef.current.unselect([foundData.ID]);
                                            } else {
                                                checkboxFunctionsRef.current.select([foundData.ID]);
                                            };
                                        };
                                    }
                                },
                                style: {backgroundColor: selectedData.includes(item.ID) ? (themeSelector === "dark" ? "#373351" : "rgb(196, 191, 227)") : checkCPAOColumnStyle(item.ID), transition: "background-color 0.3s ease"},
                                columns: [
                                    { keyID: item.ID, type: "custom", data: <div className="route__user__campaigns__tableWrap__campaignWithImg__padded"  style={{backgroundColor: selectedData.includes(item.ID) ? (themeSelector === "dark" ? "#373351" : "rgb(196, 191, 227)") : checkCPAOColumnStyle(item.ID), transition: "background-color 0.3s ease"}}>
                                        <div className="route__user__campaigns__tableWrap__campaignWithImg__padded__fill">
                                            {item.CampaignActive === null ? <Spinner style={{ width: "20px", height: "20px" }} color="white" /> : <RadioButton onChange={e => toggleCampaign(item.ID, e)} checked={item.CampaignActive} />}
                                        </div>
                                    </div> },
                                    {
                                        keyID: item.ID, type: "custom", data: <p className="route__user__campaigns__tableWrap__campaignWithImg">
                                            <div className="route__user__campaigns__tableWrap__campaignWithImg__padded" style={{backgroundColor: selectedData.includes(item.ID) ? (themeSelector === "dark" ? "#373351" : "rgb(196, 191, 227)") : checkCPAOColumnStyle(item.ID), transition: "background-color 0.3s ease"}}></div>
                                            <p>
                                                <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: clonedCampaigns.includes(item.ID) ? "yellow" : null}} title={item.CampaignName} onClick={() => navigator.clipboard.writeText(item.CampaignName)}>{item.CampaignName}</span>
                                                <span></span>
                                            </p>
                                        </p>
                                    },
                                    ...visibleColumns.map(vc => {
                                        let finalData = prepareTableData(item?.TableData?.[vc.replace(" ", "_")] ?? "-", vc);
                                        let compareData = undefined;
                                        if (props.compareEnabled) {
                                            if (finalData !== "-") {
                                                if (Array.isArray(oldData)) {
                                                    compareData = oldData.find(o => o.ID === item.ID);
                                                    if (!compareData) {
                                                        compareData = null;
                                                    } else {
                                                        if (!compareData.TableData) {
                                                            compareData = null;
                                                        } else {
                                                            compareData = prepareTableData(compareData?.TableData?.[vc.replace(/ /g, "_")] ?? "-", vc);
                                                        };
                                                    };
                                                } else {
                                                    compareData = undefined;
                                                };
                                            };
                                        };
                                        if (compareData === "-") compareData = undefined;

                                        return { keyID: item.ID, type: "text", text: <span className="route__user__campaigns__tableWrap__column">
                                            {finalData}
                                            {compareData === null && <Spinner style={{width: "16px", height: "16px", marginLeft: "5px"}} color="rgba(255,255,255,1)" />}
                                            {compareData && performDataCompare(vc, finalData, compareData)}
                                        </span>, style: {
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            letterSpacing: "1px",
                                            textAlign: "left"
                                        } }
                                    })
                                ]
                            });
                        } else {
                            out.push({columns: [{ keyID: "noData-error", type: "text", text: "Error while getting campaigns" }]});
                        };
                    } else {
                        out.push({columns: [{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]});
                    };

                    if (spinner) {
                        out.push({columns: [{ keyID: "pagination-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]});
                    };
                    if (out.length === 0) {
                        out.push({columns: [{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]});
                    };
                    (data?.data?.length > 0) && out.push({
                        disableCheckbox: true,
                        isFooter: footerData?.status === "ok" && !spinner,
                        columns: [
                            { keyID: 'total', type: "custom", data: <p className="route__user__campaigns__tableWrap__campaignWithImg"><div className="route__user__campaigns__tableWrap__campaignWithImg__padded"></div></p> },
                            {
                                keyID: 'total2', type: "custom", data: <p className="route__user__campaigns__tableWrap__campaignWithImg"><div className="route__user__campaigns__tableWrap__campaignWithImg__padded"></div>Results from all campaigns</p>
                            },
                            ...visibleColumns?.map(vc => {
                                if (!footerData) return {keyID: `noDatavc-${vc}-spinner`, type: "spinner", color: "white", size: "16px"};

                                let totalData = getTotalData(footerData);
                                let finalData = null;

                                finalData = prepareTableData(totalData?.[vc.replace(" ", "_")] ?? "-", vc);
                                if (String(finalData)?.endsWith("%")) {
                                    finalData = Number(finalData.split("%")[0].trim()) / footerData.data.length;
                                    finalData = prepareTableData(finalData, vc);
                                } else if (String(finalData)?.endsWith("x")) {
                                    finalData = Number(finalData.split("x")[0].trim()) / footerData.data.length;
                                    finalData = prepareTableData(finalData, vc);
                                } else if (["EPV", "CPA", "CPAO"].includes(vc)) {
                                    finalData = Number(finalData.split(currencySignSelector)[0].trim()) / footerData.data.length;
                                    finalData = prepareTableData(finalData, vc);
                                };

                                return { keyID: 'total3', type: "text", text: finalData, style: {
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    letterSpacing: "1px",
                                    textAlign: "left"
                                } }
                            })
                        ]
                    })
                    return out;
                })()}
                spinnerColor={themeSelector === "dark" ? "white" : "black"}
                showSpinner={resetSpinner}
            />
            {canPaginate && <div className="route__user__campaigns__tableWrap__paginate" ref={curScreen.measureRef}></div>}
        </div>
    </>
};

const CampaignURLs = props => {
    const [urls, setUrls] = React.useState();

    const openTestURL = url => {
        try {
            let tmp = new URL(url);
            tmp.searchParams.set("sttest", "1");
            window.open(tmp.toString(), "_blank");
        } catch {};
    };

    React.useEffect(() => {
        if (!props.ids) return props.onClose();
        if (!Array.isArray(props.ids)) return props.onClose();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignURLs`,
            data: {
                IntegrationID: "-1",
                IDs: props.ids
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUrls(res.data);
        }).catch(() => {
            setUrls(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__campaigns__urls">
        <div className="route__user__campaigns__urls__wrap">
            <div className="route__user__campaigns__urls__wrap__head">
                <div className="route__user__campaigns__urls__wrap__head__left">Campaign URLs</div>
                <div className="route__user__campaigns__urls__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            {urls ? <>
                {urls.status === "ok" ? <>
                    <div className="route__user__campaigns__urls__wrap__urls">
                        {Object.keys(urls.data).map(key => {
                            return <div className="route__user__campaigns__urls__wrap__urls__url">
                                <p className="route__user__campaigns__urls__wrap__urls__url__name">
                                    <span>{urls.data[key].name}</span>
                                    <span className="route__user__campaigns__urls__wrap__urls__url__tag" style={{
                                        color: urls.data[key].tagged ? "#93fb84" : "gray"
                                    }}>{urls.data[key].tagged ? "URL Tagged" : "URL Untagged"}</span>
                                    <span className="route__user__campaigns__urls__wrap__urls__url__tag" style={{
                                        color: urls.data[key].active ? "#93fb84" : "#fb8484"
                                    }}>{urls.data[key].active ? "Active" : "Inactive"}</span>
                                </p>

                                {urls.data[key]["pre-landing"] && <div className="route__user__campaigns__urls__wrap__urls__url__info">
                                    <div className="route__user__campaigns__urls__wrap__urls__url__textWrap">
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__name">
                                            <span>Pre-landing URL</span>
                                            <div className="route__user__campaigns__urls__wrap__urls__url__textWrap__name__button" onClick={() => openTestURL(urls.data[key]["pre-landing"])}>Test</div>
                                            {urls.data[key].angle && <div className="route__user__campaigns__urls__wrap__urls__url__textWrap__name__button" onClick={() => window.open(`#/creatives?angle=${urls.data[key].angle}`, "_blank")}>View creatives</div>}
                                        </p>
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__link">{urls.data[key]["pre-landing"]}</p>
                                    </div>
                                </div>}

                                {urls.data[key]["landing"] && <div className="route__user__campaigns__urls__wrap__urls__url__info">
                                    <div className="route__user__campaigns__urls__wrap__urls__url__textWrap">
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__name">
                                            <spann>Landing URL</spann>
                                            <div className="route__user__campaigns__urls__wrap__urls__url__textWrap__name__button" onClick={() => openTestURL(urls.data[key]["landing"])}>Test</div>
                                            {urls.data[key].angle && <div className="route__user__campaigns__urls__wrap__urls__url__textWrap__name__button" onClick={() => window.open(`#/creatives?angle=${urls.data[key].angle}`, "_blank")}>View creatives</div>}
                                        </p>
                                        <p className="route__user__campaigns__urls__wrap__urls__url__textWrap__link">{urls.data[key]["landing"]}</p>
                                    </div>
                                </div>}
                            </div>
                        })}
                    </div>
                </> : <p className="route__user__campaigns__urls__wrap__error">There was an error while generating URLs!</p>}
            </> : <Spinner color="white" align="center" />}
        </div>
    </div>
};

const AddCampaign = props => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadError: false,
        inputs: []
    });
    const [spinner, setSpinner] = React.useState(false);
    const [websites, setWebsites] = React.useState();
    const [taggedTraffic, setTaggedTraffic] = React.useState();
    const [preLandingSite, setPreLandingSite] = React.useState();
    const [landingSite, setLandingSite] = React.useState();
    const [isWebsiteRotation, setIsWebsiteRotation] = React.useState();
    const [active, setActive] = React.useState(true);
    const [angle, setAngle] = React.useState();
    const [descriptors, setDescriptors] = React.useState({});

    const [stopRotationOnCondition, setStopRotationOnCondition] = React.useState();
    const [rotationColumnCompareType, setRotationColumnCompareType] = React.useState("bigger");
    const [rotationColumnCompare, setRotationColumnCompare] = React.useState("CR");
    const [rotationOnEqual, setRotationOnEqual] = React.useState("wait");
    const [rotationVisitsType, setRotationVisitsType] = React.useState("website");
    const [trackUserBehaviour, setTrackUserBehaviour] = React.useState(true);

    const siteRisksSelector = useSelector(state => state?.types?.siteRisks ?? {});
    const currencySelector = useSelector(state => state?.types?.currencySign ?? "?");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const mainRef = React.useRef();
    const campaignNameRef = React.useRef();
    const costPerClickRef = React.useRef();
    const rotationVisitsRef = React.useRef();

    const onClose = (force = false) => {
        if (spinner && !force) return;

        return props.onClose();
    };

    const createCampaignName = () => {
        if (!websites) return null;
        if (websites.status !== "ok") return null;
        if (!landingSite) return null;
        
        let curSite = websites.data.find(w => w.ID === landingSite);
        if (!curSite) return;

        return `- ${curSite._Offer?.OfferName} - ${curSite._Offer?.Country} - ${curSite._Offer?.OfferType} - ${campaignNameRef.current.value}`;
    };

    const setDataFromSite = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                extended: true,
                filters: [
                    {name: "ID", op: "eq", value: ID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status !== "ok") return;
            if (res.data.data.length !== 1) return;

            let curSite = res.data.data[0];
            if (!curSite?.IntegrationData?.ScaleCMS?.Info) return;
            if (typeof(curSite?.IntegrationData?.ScaleCMS?.Info) !== "object" || Array.isArray(curSite?.IntegrationData?.ScaleCMS?.Info)) return;

            if (props.edit) {
                if (curSite.IntegrationData.ScaleCMS.Info.Angle && !angle) setAngle(curSite.IntegrationData.ScaleCMS.Info.Angle);
                setDescriptors(d => {
                    let tmp = {...d};

                    if (curSite.IntegrationData.ScaleCMS.Info.LanderComments?.length && !descriptors?.["Comments"]?.filter?.(f => f)?.length) {
                        tmp["Comments"] = curSite.IntegrationData.ScaleCMS.Info.LanderComments;
                    };
                    if (curSite.IntegrationData.ScaleCMS.Info.KeyInformation && !descriptors?.["KeyInformation"]?.filter?.(f => f)?.length) {
                        tmp["KeyInformation"] = curSite.IntegrationData.ScaleCMS.Info.KeyInformation;
                    };
                    if (curSite.IntegrationData.ScaleCMS.Info.ClientPromises?.length && !descriptors?.["ClientPromises"]?.filter?.(f => f)?.length) {
                        tmp["ClientPromises"] = curSite.IntegrationData.ScaleCMS.Info.ClientPromises;
                    };

                    return tmp;
                });
            } else {
                setAngle(curSite.IntegrationData.ScaleCMS.Info.Angle ?? "");
                setDescriptors(d => {
                    let tmp = {...d};

                    if (curSite.IntegrationData.ScaleCMS.Info.LanderComments?.length) {
                        tmp["Comments"] = curSite.IntegrationData.ScaleCMS.Info.LanderComments;
                    } else {
                        tmp["Comments"] = []
                    };
                    if (curSite.IntegrationData.ScaleCMS.Info.KeyInformation) {
                        tmp["KeyInformation"] = curSite.IntegrationData.ScaleCMS.Info.KeyInformation;
                    } else {
                        tmp["KeyInformation"] = "";
                    };
                    if (curSite.IntegrationData.ScaleCMS.Info.ClientPromises?.length) {
                        tmp["ClientPromises"] = curSite.IntegrationData.ScaleCMS.Info.ClientPromises;
                    } else {
                        tmp["ClientPromises"] = [];
                    };

                    return tmp;
                });
            };
        }).catch(() => null);
    };

    const getSites = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                offset: 0,
                limit: null,
                extended: false,
                getOfferInfo: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setWebsites(res.data);
        }).catch(() => {
            setWebsites(backendModule.genericError);
        });
    };

    const showCountryIssueModal = (type = "", c1, c2) => new Promise((resolve) => {
        animateBox(<YesNoModal
            heading={"Site country missmatch"}
            text={[
                "This ",
                <span style={{color: "rgb(108, 93, 211)"}}>{type}</span>,
                " site has incorrect Country / Offer placed!",
                <span style={{color: basicStylesModule.errorColor}}>{`Site:${c1} != Offer:${c2}`}</span>,
                <br />,
                "The conuntry provided by the site is not the same as the offer country.",
                <br />,
                `You should consider going to `,
                <span style={{color: "rgb(108, 93, 211)"}}>Sites</span>,
                ` and fix this issue before you create the campaign!`,
                <br />,
                <br />,

                `If you proceed you might miss on conversion and tracking data provided by the integration.`,
                <br />,
                `ARE YOU REALLY SURE YOU WANT TO PROCEED?`
            ]}
            isRightButtonNormal={true}
            buttonLeftCallback={args => {
                args.close();
                resolve(false);
            }}
            buttonRightCallback={args => {
                args.close();
                resolve(true);
            }}
        />)
    });

    const addSite = async () => {
        if (spinner) return;
        setInfoP(i => { return { ...i, hadError: false, inputs: [] } });

        let ad = {};

        if (stopRotationOnCondition) {
            ad["scalecms_rotation"] = {
                Visits: null,
                VisitsType: null,
                Column: null,
                ColumnType: null,
                OnEqual: null
            };

            ad["scalecms_rotation"].Visits = Number(rotationVisitsRef.current.value);
            ad["scalecms_rotation"].VisitsType = rotationVisitsType;
            ad["scalecms_rotation"].Column = rotationColumnCompare;
            ad["scalecms_rotation"].ColumnType = rotationColumnCompareType;
            ad["scalecms_rotation"].OnEqual = rotationOnEqual;
        };

        let data = {
            IntegrationID: "-1",
            Name: campaignNameRef.current.value,
            Active: active,
            TaggedTraffic: taggedTraffic,
            LandingSiteID: landingSite,
            PreLandingSiteID: preLandingSite,
            CostPerClick: costPerClickRef.current.value,
            trackUserBehaviour: !!trackUserBehaviour,
            Angle: angle,

            AdditionalData: {
                ...ad
            },
            CampaignDescriptors: { ...descriptors }
        };
        data.CostPerClick = Number(data.CostPerClick);
        if (!props.edit) {
            let tmpName = campaignNameRef.current.value;
            tmpName = tmpName.replace(" ", "-").split("-").map(t => String(t).trim()).filter(t => t).join("-");
            if (tmpName.split("-") > 3) return setInfoP(i => { return { ...i, hadError: true, inputs: ["name"], error: "[Campaign] Name can't have more than 3 slugs." } });
            campaignNameRef.current.value = tmpName;

            data.Name = createCampaignName();
        };
        if (!data.Name) return setInfoP(i => { return { ...i, hadError: true, inputs: ["name"], error: "[Campaign] Name can't be empty." } });

        if (isNaN(data.CostPerClick)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["cpc"], error: "[Campaign] Cost per click must be a number." } });
        if (data.CostPerClick < 0) return setInfoP(i => { return { ...i, hadError: true, inputs: ["cpc"], error: "[Campaign] Cost per click must be greater or equals to 0." } });
        if (data.Active === null || data.Active === undefined) return setInfoP(i => { return { ...i, hadError: true, inputs: ["active"], error: "[Campaign] Campaign must be either set to active or inactive." } });
        if (data.TaggedTraffic === null || data.TaggedTraffic === undefined) return setInfoP(i => { return { ...i, hadError: true, inputs: ["taggedtraffic"], error: "[Campaign] Tagged traffic can't be empty." } });
        if (data.LandingSiteID === null || data.LandingSiteID === undefined) return setInfoP(i => { return { ...i, hadError: true, inputs: ["landingsite"], error: "[Campaign] Landing site can't be empty." } });
        if (!data.Angle) return setInfoP(i => { return { ...i, hadError: true, inputs: ["angle"], error: "[Campaign] Angle can't be empty." } });

        if (data.AdditionalData?.scalecms_rotation) {
            // handle cms rotation input
            if (isNaN(data.AdditionalData.scalecms_rotation.Visits)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["r_visits"], error: "[Rotation] Vists are invalid" } });
            if (data.AdditionalData.scalecms_rotation.Visits <= 0) return setInfoP(i => { return { ...i, hadError: true, inputs: ["r_visits"], error: "[Rotation] Vists must be greater than 0" } });
            if (!data.AdditionalData.scalecms_rotation.VisitsType) return setInfoP(i => { return { ...i, hadError: true, inputs: ["r_visits"], error: "[Rotation] Visits type must be selected" } });
            if (!data.AdditionalData.scalecms_rotation.Column) return setInfoP(i => { return { ...i, hadError: true, inputs: ["r_column"], error: "[Rotation] Column to comapre is missing" } });
            if (!data.AdditionalData.scalecms_rotation.ColumnType) return setInfoP(i => { return { ...i, hadError: true, inputs: ["r_column"], error: "[Rotation] Column compare type is missing" } });
            if (!data.AdditionalData.scalecms_rotation.OnEqual) return setInfoP(i => { return { ...i, hadError: true, inputs: ["r_onEqual"], error: "[Rotation] On equal data action is missing" } });
        };

        if (data.CampaignDescriptors?.["ClientPromises"]) data.CampaignDescriptors["ClientPromises"] = data.CampaignDescriptors["ClientPromises"].filter(f => f);
        if (data.CampaignDescriptors?.["Comments"]) data.CampaignDescriptors["Comments"] = data.CampaignDescriptors["Comments"].filter(f => f);
        if (!data.CampaignDescriptors["CGC"]) return setInfoP(i => { return { ...i, hadError: true, inputs: ["d_cgc"], error: "[Campaign] Creative group category can't be empty" } });
        if (!data.CampaignDescriptors["CGCDescription"]) return setInfoP(i => { return { ...i, hadError: true, inputs: ["d_cgc"], error: "[Campaign] Creative group category must have a description" } });
        if (!data.CampaignDescriptors["ClientPromises"]?.length) return setInfoP(i => { return { ...i, hadError: true, inputs: ["d_clientpromises"], error: "[Campaign] Client promises can't be empty" } });
        if (!data.CampaignDescriptors["KeyInformation"]) return setInfoP(i => { return { ...i, hadError: true, inputs: ["d_keyinformation"], error: "[Campaign] Key information can't be empty" } });
        if (!data.CampaignDescriptors["Comments"]?.length) return setInfoP(i => { return { ...i, hadError: true, inputs: ["d_comments"], error: "[Campaign] Comments can't be empty" } });

        if (props.edit) data["ID"] = props.edit["ID"];

        if (landingSite) {
            let curSite = websites.data.find(w => w.ID === landingSite);
            if (!curSite) return;
            if (curSite.Country !== curSite._Offer.Country) {
                let cim = await showCountryIssueModal("landing", curSite.Country, curSite._Offer.Country);
                if (!cim) return;
            };
        };

        if (preLandingSite) {
            let curSite = websites.data.find(w => w.ID === preLandingSite);
            if (!curSite) return;
            if (curSite.Country !== curSite._Offer.Country) {
                let cim = await showCountryIssueModal("pre-landing", curSite.Country, curSite._Offer.Country);
                if (!cim) return;
            };
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/${props.edit ? "editCampaign" : "addCampaign"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange(res.data.data);
                onClose(true);
            } else {
                if (!data.TaggedTraffic) {
                    setInfoP(i => { return { ...i, hadError: true, inputs: ["landingpage"], error: "Only one untagged campaign is allowed per website." } });
                } else {
                    setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Error while creating a campaign." } });
                };
            };
        }).catch(() => {
            setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out." } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getOfferAngles = () => {
        if (!landingSite) return [];
        if (websites?.status !== "ok") return [];

        let curSite = websites.data.find(w => w.ID === landingSite);
        if (!curSite) return [];
        if (!Array.isArray(curSite?._Offer?.OfferAngles)) return [];
        return curSite._Offer.OfferAngles;
    };

    React.useEffect(() => {
        if (!props.edit) return;
        if (!websites) return;
        if (websites.status !== "ok") return;

        setTaggedTraffic(props.edit.TaggedTraffic);
        if (props.edit.LandingSiteID) setLandingSite(props.edit.LandingSiteID);
        if (props.edit.PreLandingSiteID) setPreLandingSite(props.edit.PreLandingSiteID);
        if (props.edit.CampaignAngle) setAngle(props.edit.CampaignAngle);
        if (props.edit.CampaignDescriptors) {
            let d = props.edit.CampaignDescriptors;
            if (!d) d = {};
            if (typeof(d) !== "object" || Array.isArray(d)) d = {};
            setDescriptors(d);
        };
        if (props.edit.AdditionalData?.scalecms_rotation) setStopRotationOnCondition(true);
        if (props.edit.AdditionalData?.scalecms_rotation?.VisitsType) setRotationVisitsType(props.edit.AdditionalData?.scalecms_rotation?.VisitsType);
        if (props.edit.AdditionalData?.scalecms_rotation?.Column) setRotationColumnCompare(props.edit.AdditionalData?.scalecms_rotation?.Column);
        if (props.edit.AdditionalData?.scalecms_rotation?.ColumnType) setRotationColumnCompareType(props.edit.AdditionalData?.scalecms_rotation?.ColumnType);
        if (props.edit.AdditionalData?.scalecms_rotation?.OnEqual) setRotationOnEqual(props.edit.AdditionalData?.scalecms_rotation?.OnEqual);
        setTrackUserBehaviour(!!props.edit.trackUserBehaviour);
        setActive(!!props.edit.CampaignActive);
    }, [props.edit, websites]);

    React.useEffect(() => {
        if (isWebsiteRotation) setIsWebsiteRotation(false);
        if (!landingSite) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                filters: [
                    {name: "ID", op: "eq", value: landingSite}
                ],
                extended: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length !== 1) return;
                if (res.data.data[0]?.IntegrationData?.ScaleCMS?.FlowType === "rotation") {
                    if (res.data.data[0]?.IntegrationData.ScaleCMS?.BoundCampaign) {
                        if (props.edit) {
                            if (props.edit.ID !== res.data.data[0]?.IntegrationData.ScaleCMS?.BoundCampaign) return;
                            if (![null, undefined].includes(props.edit.AdditionalData?.scalecms_rotation?.wonSite)) return;
                        } else {
                            return;
                        };
                    };
                    setIsWebsiteRotation(true);
                };
            };
        }).catch(() => null);
    }, [landingSite]);

    React.useEffect(() => {
        if (props.edit) return;
        if (!landingSite) return;
        if (websites?.status !== "ok") return;

        let curSite = websites.data.find(w => w.ID === landingSite);
        if (curSite) setDataFromSite(curSite.ID);
    }, [websites, landingSite])

    React.useEffect(() => {
        getSites();
    }, []);

    return <div className="route__user__campaigns__add">
        <div className="route__user__campaigns__add__wrap" ref={mainRef}>
            <div className={`route__user__campaigns__add__wrap__spinner ${websites ? "" : "route__user__campaigns__add__wrap__spinner--active"}`}>
                <Spinner color="#fff" />
            </div>

            <div className="route__user__campaigns__add__wrap__head">
                <div className="route__user__campaigns__add__wrap__head__left">{props.edit ? "Edit" : "Add"} campaign</div>
                <div className="route__user__campaigns__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="genericTabs">
                <div className={`genericTabs__tab ${activeTab === 0 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(0)}>Campaign</div>
                {isWebsiteRotation && <div className={`genericTabs__tab ${activeTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(1)}>Landing rotation</div>}
            </div>

            {websites && (websites?.status === "ok" ? <>
                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("name") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>
                        <span>{props.edit ? "Campaign name" : "Campaign name suffix"}</span>
                        <img src="/images/question_mark.svg" title={`When the campaign is created for the first time, Campaign name will consist of {Landing page} {Offer} {Suffix}\nIf you decide that the name is not sufficient, you can always edit the campaign and change the name.`} />
                    </p>
                    <input defaultValue={props.edit?.CampaignName} ref={campaignNameRef} type="text" placeholder={props.edit ? "Campaign name" : "Campaign name suffix"} />
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("cpc") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Cost per click (CPC)</p>
                    <input defaultValue={props.edit?.CostPerClick} ref={costPerClickRef} type="number" placeholder="Cost per click" />
                </div>

                <div className={`route__user__campaigns__add__wrap__input genericModal__wrap__input--split ${infoP.inputs.includes("d_cgc") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null, gridTemplateColumns: "150px 1fr"}}>
                    <p>Creative group category</p>
                    <Dropdown
                        style={{gridColumn: descriptors["CGC"] ? null : "1 / span all"}}
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Creative group strategy"
                        data={[
                            {name: "Default", value: "Default"},
                            {name: "Plants", value: "Plants"},
                            {name: "Doctors", value: "Doctors"},
                            {name: "Lifestyle", value: "Lifestyle"},
                            {name: "Product-image", value: "Product-image"}
                        ]}
                        selected={(()=>{
                            if (!descriptors["CGC"]) return null;
                            switch (descriptors["CGC"]) {
                                case "Default": return 0;
                                case "Plants": return 1;
                                case "Doctors": return 2;
                                case "Lifestyle": return 3;
                                case "Product-image": return 4;
                                default: return 0;
                            };
                        })()}
                        onChange={e => setDescriptors(d => {
                            let tmp = {...d};
                            if (tmp["CGC"] !== e?.value) tmp["CGC"] = e?.value;
                            return tmp;
                        })}
                    />
                    {descriptors["CGC"] && <input type="text" value={descriptors["CGCDescription"] ?? ""} placeholder="Description" onChange={e => {
                        let txt = e?.target?.value;
                        setDescriptors(d => {
                            let tmp = {...d};
                            if (!tmp["CGC"]) return d;
                            tmp["CGCDescription"] = txt;
                            return tmp;
                        });
                    }} />}
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("d_clientpromises") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Client promises (max 3)</p>
                    {(()=>{
                        let vals = descriptors?.["ClientPromises"];
                        if (!Array.isArray(vals)) vals = [];
                        vals = vals.filter(f => f);
                        if (vals.length === 0) vals.push("");
                        if (vals[vals.length-1]) vals.push("");
                        while (vals.length > 3) vals.pop();

                        return vals.map((v, vIdx) => {
                            return <input type="text" placeholder="Client promise" value={v} onChange={e => {
                                let txt = e?.target?.value;
                                vals[vIdx] = txt;
                                setDescriptors(d => {
                                    let tmp = {...d};
                                    tmp["ClientPromises"] = vals;
                                    return tmp;
                                });
                            }} />
                        });
                    })()}
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("d_keyinformation") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Key information</p>
                    <input type="text" placeholder="Key information" value={descriptors["KeyInformation"]} onChange={e => {
                        let txt = e?.target?.value;
                        setDescriptors(d => {
                            let tmp = {...d};
                            tmp["KeyInformation"] = txt;
                            return tmp;
                        });
                    }} />
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("d_comments") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>First 3 lander comments (max 3)</p>
                    {(()=>{
                        let vals = descriptors?.["Comments"];
                        if (!Array.isArray(vals)) vals = [];
                        vals = vals.filter(f => f);
                        if (vals.length === 0) vals.push("");
                        if (vals[vals.length-1]) vals.push("");
                        while (vals.length > 3) vals.pop();

                        return vals.map((v, vIdx) => {
                            return <input type="text" placeholder="Comment" value={v} onChange={e => {
                                let txt = e?.target?.value;
                                vals[vIdx] = txt;
                                setDescriptors(d => {
                                    let tmp = {...d};
                                    tmp["Comments"] = vals;
                                    return tmp;
                                });
                            }} />
                        });
                    })()}
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("taggedtraffic") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>
                        <span>Only tagged traffic</span>
                        <img src="/images/question_mark.svg" title={`With tagged traffic enabled, a new URL will have to be generated to be able to track this campaign.\nAdditional parameters will automatically be added to the end of the URL.\nThis is very useful if you want to run the same page on multiple different sources (different campaigns), but for instance, still track your organic growth on the untagged campaign.\nIf the tagged traffic is disabled, URL is the same as the one in sites, with no modifications.\nOnly one untagged campaign can exist per landing website!\n\nTo get the tagged traffic url, go to "More actions", then click on "Get tracking URL".\n\nExamples:\nUntagged: https://yoursite.com\nTagged: https://yoursite.com?stcid=some_parameter_inserted_here_automatically`} />
                    </p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Tagged traffic"
                        data={[
                            { name: "Yes (only traffic with tag in the URL)", value: true },
                            { name: "No (any traffic counts)", value: false }
                        ]}
                        onChange={e => setTaggedTraffic(e?.value)}
                        selected={(() => {
                            if (taggedTraffic === null || taggedTraffic === undefined) return;
                            return taggedTraffic ? 0 : 1
                        })()}
                    />
                </div>

                <div className={`route__user__campaigns__add__wrap__input`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Pre-landing site (optional)</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Pre-landing site (optional)"
                        data={websites?.status === "ok" ? [
                            { name: "None", value: null },
                            ...websites.data.filter(website => {
                                return website.SiteType === 0 || website.SiteType === 2;
                            }).map(website => {
                                return {
                                    name: <div className="route__user__campaigns__add__wrap__input__dropItem">
                                        <LazyImage
                                            src={website.SiteImage ?? "/images/image-missing.png"}
                                            onError={e => e.target.src = "/images/image-missing.png"}
                                            onClick={e => {
                                                e.stopPropagation();
                                                animateBox({ currentTarget: mainRef.current }, <PreviewImageModal image={website.SiteImage} />);
                                            }}
                                        />
                                        <p style={{color: website.isTested ? null : "#FF450D"}}>{website.isTested ? "" : "[Untested] "}{website.SiteName}</p>
                                        <div className="route__user__campaigns__add__wrap__input__dropItem__risks">
                                            {Object.keys(siteRisksSelector).map(risk => {
                                                return <div
                                                    className="route__user__campaigns__add__wrap__input__dropItem__risks__risk"
                                                    style={{backgroundColor: risk <= website.SiteRisk ? siteRiskColors(website.SiteRisk) : siteRiskColors(-1)}}
                                                ></div>
                                            })}
                                        </div>
                                    </div>,
                                    search: `${website.SiteName} ${website.SiteURL}`,
                                    value: website.ID
                                };
                            })
                        ] : []}
                        onChange={e => setPreLandingSite(e?.value)}
                        selected={(() => {
                            if (!websites) return;
                            if (websites.status !== "ok") return;
                            if (!preLandingSite) return;
                            let wd = websites.data.filter(w => w.SiteType === 0 || w.SiteType === 2);

                            let final = wd.indexOf(wd.find(w => w.ID === preLandingSite));

                            if (final >= 0) final++;
                            return final;
                        })()}
                    />
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("landingsite") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Landing site</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Landing site"
                        data={websites?.status === "ok" ? [
                            { name: "None", value: null },
                            ...websites.data.filter(website => {
                                return website.SiteType === 1 || website.SiteType === 2;
                            }).map(website => {
                                return {
                                    name: <div className="route__user__campaigns__add__wrap__input__dropItem">
                                        <LazyImage
                                            src={website.SiteImage ?? "/images/image-missing.png"}
                                            onError={e => e.target.src = "/images/image-missing.png"}
                                            onClick={e => {
                                                e.stopPropagation();
                                                animateBox({ currentTarget: mainRef.current }, <PreviewImageModal image={website.SiteImage} />);
                                            }}
                                        />
                                        <p style={{color: website.isTested ? null : "#FF450D"}}>{website.isTested ? "" : "[Untested] "}{website.SiteName}</p>
                                        <div className="route__user__campaigns__add__wrap__input__dropItem__risks">
                                            {Object.keys(siteRisksSelector).map(risk => {
                                                return <div
                                                    className="route__user__campaigns__add__wrap__input__dropItem__risks__risk"
                                                    style={{backgroundColor: risk <= website.SiteRisk ? siteRiskColors(website.SiteRisk) : siteRiskColors(-1)}}
                                                ></div>
                                            })}
                                        </div>
                                        {website?._Offer && <span>({website._Offer.OfferPrice} {currencySelector})</span>}
                                    </div>,
                                    search: `${website.SiteName} ${website.SiteURL}`,
                                    value: website.ID
                                };
                            })
                        ] : []}
                        onChange={e => e?.value && setLandingSite(e?.value)}
                        selected={(() => {
                            if (!websites) return;
                            if (websites.status !== "ok") return;
                            if (!landingSite) return;
                            let wd = websites.data.filter(w => w.SiteType === 1 || w.SiteType === 2);

                            let final = wd.indexOf(wd.find(w => w.ID === landingSite));

                            if (final >= 0) final++;
                            return final;
                        })()}
                    />
                </div>

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("angle") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Angle</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Angle"
                        data={[
                            {name: "None", value: null},
                            ...getOfferAngles().map(a => {
                                return {name: a, value: a};
                            })
                        ]}
                        onChange={e => setAngle(e?.value)}
                        selected={(() => {
                            if (!angle) return 0;
                            let curAngles = getOfferAngles();
                            let curIdx = curAngles.indexOf(angle);
                            if (curIdx < 0) return null;

                            return curIdx + 1;
                        })()}
                    />
                </div>

                {/* <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("trackUserBehaviour") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Track user behaviour</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Track user behaviour"
                        data={[
                            { name: "Yes", value: true },
                            { name: "No", value: false }
                        ]}
                        onChange={e => setTrackUserBehaviour(e?.value)}
                        selected={(() => {
                            if (trackUserBehaviour === null || trackUserBehaviour === undefined) return;
                            return trackUserBehaviour ? 0 : 1
                        })()}
                    />
                </div> */}

                <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("active") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 0 ? "none" : null}}>
                    <p>Active</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Active"
                        data={[
                            { name: "Yes", value: true },
                            { name: "No", value: false }
                        ]}
                        onChange={e => setActive(e?.value)}
                        selected={(() => {
                            if (active === null || active === undefined) return;
                            return active ? 0 : 1
                        })()}
                    />
                </div>

                <div className="route__user__campaigns__add__wrap__input" style={{display: activeTab !== 1 ? "none" : "flex", alignItems: "center", flexDirection: "row"}}>
                    <Checkbox checked={stopRotationOnCondition} onChange={e => setStopRotationOnCondition(!!e)} />
                    <p>Stop rotation on condition</p>
                </div>
                {stopRotationOnCondition && <>
                    <div className={`route__user__campaigns__add__wrap__input ${infoP.inputs.includes("r_visits") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{
                        display: activeTab !== 1 ? "none" : "grid",
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "auto 1fr",
                        columnGap: "10px"
                    }}>
                        <p style={{gridColumn: "1 / span all"}}>Stop after visits are greater than</p>
                        <Dropdown
                            accent="#6C5DD3"
                            theme={themeSelector}
                            data={[
                                {name: "Combined", value: "combined"},
                                {name: "Per-website", value: "website"}
                            ]}
                            onChange={e => setRotationVisitsType(e?.value)}
                            selected={(()=>{
                                if (!rotationVisitsType) return null;
                                if (rotationVisitsType === "combined") return 0;
                                if (rotationVisitsType === "website") return 1;
                            })()}
                        />
                        <input ref={rotationVisitsRef} type="text" placeholder="Visits" defaultValue={props?.edit?.AdditionalData?.scalecms_rotation?.Visits ?? null} />
                    </div>

                    <div className={`route__user__campaigns__add__wrap__input route__user__campaigns__add__wrap__input--dropdown ${infoP.inputs.includes("r_column") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{
                        display: activeTab !== 1 ? "none" : "grid",
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "auto 1fr",
                        columnGap: "10px"
                    }}>
                        <p style={{gridColumn: "1 / span all"}}>Select the site with the column condition</p>
                        <Dropdown
                            accent="#6C5DD3"
                            theme={themeSelector}
                            data={[
                                {name: "Bigger", value: "bigger"},
                                {name: "Smaller", value: "smaller"},
                            ]}
                            onChange={e => setRotationColumnCompareType(e?.value)}
                            selected={(()=>{
                                if (!rotationColumnCompareType) return null;
                                if (rotationColumnCompareType === "bigger") return 0;
                                if (rotationColumnCompareType === "smaller") return 1;
                            })()}
                        />
                        <Dropdown
                            accent="#6C5DD3"
                            theme={themeSelector}
                            data={availableCampaignColumns.map(c => {
                                let name = c;
                                if (name.endsWith(" IN")) {
                                    name = name.substring(0, name.length - 2) + "FB";
                                };
                                return {name: name, value: c}
                            })}
                            onChange={e => setRotationColumnCompare(e?.value)}
                            selected={(()=>{
                                return availableCampaignColumns.indexOf(rotationColumnCompare);
                            })()}
                        />
                    </div>

                    <div className={`route__user__campaigns__add__wrap__input route__user__campaigns__add__wrap__input--dropdown ${infoP.inputs.includes("r_onEqual") ? "route__user__campaigns__add__wrap__input--error" : ""}`} style={{display: activeTab !== 1 ? "none" : null}}>
                        <p>On equal data</p>
                        <Dropdown
                            accent="#6C5DD3"
                            theme={themeSelector}
                            data={[
                                {name: "Select one at random", value: "random"},
                                {name: "Wait for more visits", value: "wait"}
                            ]}
                            onChange={e => setRotationOnEqual(e?.value)}
                            selected={(()=>{
                                if (!rotationOnEqual) return null;
                                if (rotationOnEqual === "random") return 0;
                                if (rotationOnEqual === "wait") return 1;
                            })()}
                        />
                    </div>
                </>}

                <div className="route__user__campaigns__add__wrap__buttons">
                    <div className="route__user__campaigns__add__wrap__buttons__btn route__user__campaigns__add__wrap__buttons__btn--secondary" onClick={onClose}>Close</div>
                    <div className="route__user__campaigns__add__wrap__buttons__btn" onClick={addSite}>
                        {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="#fff" /> : "Save"}
                    </div>
                </div>

                <p className="route__user__campaigns__add__wrap__infoP" style={{
                    opacity: infoP.hadError ? 1 : 0
                }}>{infoP.error}</p>
            </> : <>
                <p>There was an error while fetching sites!</p>
            </>)}
        </div>
    </div>
};


const TransferCampaigns = (props) => {
    const currentTrackingProfiles = useSelector(state => state?.trackingProfiles ?? {});

    const [users, setUsers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [error, setError] = React.useState();


    const getTeamData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/userGroups/getGroupByID`,
            data: {
                ID: currentTrackingProfiles.selectedTeam
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data)
        }).catch(() => {
            setError("Can't get team information!");
        })
    }

    const transfer = (e) => {
        if (!selectedUser) return setError("Please select user");
        animateBox(e, <YesNoModal
            heading="Are You sure?"
            text={[
                "You are about to transfer ",
                <span style={{ color: "rgb(108, 93, 211)" }}>{props.ids.length} campaign{props.ids.length > 1 && "s"}</span>,
                "This action is irreversible, are You sure?"
            ]}
            isRightButtonNormal={true}
            buttonRightCallback={async arg => {
                arg.disabledAll(true);
                arg.spinner(true);

                for (let item of props.ids) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/transferCampaign`,
                        data: {
                            ID: item,
                            newUser: selectedUser
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (item === props.ids[props.ids.length - 1]) props.onClose()
                    }).catch(() => {
                    })
                };
                arg.disabledAll(false);
                arg.spinner(false);
                arg.close();


            }}
        />);

    }

    React.useEffect(() => {
        if (!currentTrackingProfiles?.selectedTeam) return;
        getTeamData();
    }, [])
    return <div className="modals__transfer">
        <div className="modals__transfer__container">
            <p>Select an user to transfer {props.ids.length} campaign{props.ids.length > 1 && "s"}</p>
            {users?.status === 'ok' && <Dropdown
                style={{width: "100%"}}
                accent="#6C5DD3"
                theme="dark"
                inlinePlaceholder="Select user"
                data={users?.data.map(item => {
                    return { name: item.Username, value: item.ID }
                })}
                onChange={e => setSelectedUser(e?.value)}
            />}
            {error && <p className="modals__transfer__container__infoP">{error}</p>}
            <div className="modals__transfer__container__buttons">
                {!error && <div onClick={e => transfer(e)}>Confirm</div>}
                <div onClick={() => { props.onClose() }}>Cancel</div>
            </div>
        </div>

    </div>
}



export default OrganicCampaigns;
export {availableCampaignColumns};