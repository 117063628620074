import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../customComponents/Table";
import StyledInput from "../../../components/styledComponents/Input";

const CampaignCTAStatsModal = (props) => {
    const [trackedButtons, setTrackedButtons] = React.useState("1,2,3");
    const [data, setData] = React.useState();
    const curDefer = useDefer();

    const timestampRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const getData = async ts => {
        if (timestampRef.current !== ts) return;

        let btns = trackedButtons.split(",").map(b => {
            b = Number(b.trim());
            if (isNaN(b)) return null;
            return b;
        }).filter(f => f !== null && f !== undefined);
        btns = [...new Set(btns)];
        if (btns.length === 0) return setData({status: "ok", data: {}});

        let trackFilters = [];
        if (Array.isArray(props.filters)) trackFilters.push(...props.filters);

        let out = {};

        for (let item of btns) {
            let tmpData = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                data: {
                    allUsers: true,
                    TableHeaders: [
                        "Visits",
                        "Conversions",
                        "AbandonedR",
                        "Approved",
                        "AR",
                        "ADP"
                    ],
                    filters: [{name: "ID", op: "eq", value: props.ID}],
                    trackFilters: [
                        ...trackFilters,
                        {name: "IntegrationParams.CTAButtonID", op: "eq", value: item}
                    ],
                    IntegrationID: props.integration ?? -1,
                    limit: 1
                },
                ...backendModule.axiosConfig
            }).then(res => res.data).catch(() => backendModule.genericError);
            if (tmpData?.status !== "ok" || tmpData.data?.length !== 1) {
                out[item] = {ID: item, data: {}};
                continue;
            };
            out[item] = {ID: item, data: tmpData.data[0].TableData};
        };

        if (timestampRef.current !== ts) return;
        setData({status: "ok", data: out});
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
            case "CostPerAnyLead":
            case "CostPerLead":
            case "ADP":
            case "LTV":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${Number(tmpRevenue.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CARC":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
            case "AbandonedR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${Number(tmpCR.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${Number(tmpROAS.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    const getColumnData = (item, column) => {
        let tmp = item[column];
        if (tmp === null || tmp === undefined) return "-";

        return prepareTableData(tmp, column);
    };

    const calculateATC = (item) => {
        let totalVisits = 0;
        for (let key of Object.keys(data.data)) {
            if (data.data[key]?.data?.["Visits"]) {
                totalVisits += data.data[key].data.Visits;
            };
        };
        let curVisits = item?.Visits ?? 0;
        curVisits = Number(curVisits ?? 0);

        if (!curVisits) return prepareTableData(0, "CR");
        return prepareTableData(curVisits / totalVisits * 100, "CR");
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => getData(ts), 1000);
    }, [props.filters, trackedButtons]);

    return <div className="modals__campaignCTAStatsModal__wrap__content__left">
        <div className="modals__campaignCTAStatsModal__wrap__content__left__input">
            <span>Button IDs</span>
            <StyledInput value={trackedButtons} onChange={e => setTrackedButtons(e.target.value)} />
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            headers={["Button", "Visits", "Add to cart %", "Abandoned %", "Conversions", "Approved", "AR", "ADP"]}
            customColumns={(new Array(8)).fill("max-content")}
            style={{columnGap: "40px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                for (let key of Object.keys(data.data)) {
                    out.push([
                        {keyID: String(key), type: "text", text: key},
                        {keyID: String(key), type: "text", text: getColumnData(data.data[key].data, "Visits")},
                        {keyID: String(key), type: "text", text: calculateATC(data.data[key].data)},
                        {keyID: String(key), type: "text", text: getColumnData(data.data[key].data, "AbandonedR")},
                        {keyID: String(key), type: "text", text: getColumnData(data.data[key].data, "Conversions")},
                        {keyID: String(key), type: "text", text: getColumnData(data.data[key].data, "Approved")},
                        {keyID: String(key), type: "text", text: getColumnData(data.data[key].data, "AR")},
                        {keyID: String(key), type: "text", text: getColumnData(data.data[key].data, "ADP")},
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
    </div>
};

export default CampaignCTAStatsModal;