import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import exceljs from "exceljs";
import { useSelector, useDispatch } from "react-redux";

import useDefer from "../../../../modules/hooks/useDefer";
import { chartColorSets, chartBackgroundColorSets } from "../../../../modules/miscModule";
import { animateBox } from "../../../../modules/componentAnimation";
import * as backendModule from "../../../../modules/backendModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import * as miscModule from "../../../../modules/miscModule";
import { countries } from "../../../../modules/countryModule";

import AdvancedDropdown from "../../../../components/customComponents/AdvancedDropdown";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import RadioButton from "../../../../components/customComponents/RadioButton";
import StyledButton from "../../../../components/styledComponents/Button";
import StyledInput from "../../../../components/styledComponents/Input";


import FilterByDate from "../../../../components/filters/FilterByDate";
import FilterColumns from "../../../../components/filters/FilterColumns";
import FilterByUserAccount from "../../../../components/filters/FilterByUserAccount";
import FilterBySearch from "../../../../components/filters/FilterBySearch";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";

import OfferSelectModal from "../../../../components/modals/OfferSelectModal";
import YesNoModal from "../../../../components/modals/YesNoModal";
import CampaignAdsModal from "../../../../components/modals/CampaignAdsModal/modalinline";
import CampaignDashboard from "../../../../components/modals/CampaignDashboard";

import { availableCampaignColumns as FacebookColumns } from "../../Campaigns/Facebook";
import { availableCampaignColumns as OrganicColumns } from "../../Campaigns/Organic";
import { availableCampaignColumns as SMSColumns } from "../../Campaigns/SMSMarketing";
import { availableCampaignColumns as GenericColumns, mgidCampaignColumns, midasCampaignColumns, adnowCampaignColumns } from "../../Campaigns/GenericController";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import chartJszoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    chartJszoomPlugin
);

const genericAllColumns = [
    "Conversions", "Approved", "Profit", "Spent IN"
];

let _cache_campaigns = [];
let _autoRefreshTimer = null;
const MarketingOffersReport = () => {
    // totalLeads, goodLeads, profit, spent

    const [chartColumns, setChartColumns] = React.useState();
    const [chartColumnsTimestamp, setChartColumnsTimestamp] = React.useState(Date.now());

    const [allUsers, setAllUsers] = React.useState();
    const [allUsersWithMe, setAllUsersWithMe] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [allSites, setAllSites] = React.useState();
    const [allAdditionalSpents, setAllAdditionalSpents] = React.useState([]);
    const [compareAllAdditionalSpents, setCompareAllAdditionalSpents] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [data, setData] = React.useState();
    const [compareData, setCompareData] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState([]);
    const [groupBy, setGroupBy] = React.useState("offer");
    const [localOrder, setLocalOrder] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [excludedAccounts, setExcludedAccounts] = React.useState([]);
    const [userTrafficSources, setUserTrafficSources] = React.useState("selected");
    const [fetchedSites, setFetchedSites] = React.useState(true);
    const [progress, setProgress] = React.useState(); // {current: 0, maximum: 0}
    const [isCompare, setIsCompare] = React.useState(false);
    const [whatif_edit, setWhatif_edit] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [useCache, setUseCache] = React.useState(true);
    const [isSplitSource, setIsSplitSource] = React.useState(false);
    const [autoRefresh, setAutoRefresh] = React.useState({active: false, cur: 0, max: 0});
    const [reportSettings, setReportSettings] = React.useState({
        includeAdditionalSpent: true,
        includeCRMLeadSpent: true,
        includeCRMTail: true
    });

    const timestampRef = React.useRef();

    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
    const allTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const socialCostSelector = useSelector(state => state?.types?.socialCost ?? 0);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const handleOrderCallback = React.useCallback(o => setLocalOrder(o), []);

    const getCampaignSiteIDs = () => {
        if (allSites?.status !== "ok") return [];
        let tmpData = filterCampaigns();
        if (!tmpData) return [];
        if (tmpData.status !== "ok") return [];

        let out = [];
        for (let item of tmpData.data) {
            if (item.TableData["--SiteID"]) {
                let iv = getTableIntegrationValues(item.TableData["--SiteID"], "ST");
                for (let item of iv) {
                    if (item?.Value) out.push(item.Value);
                };
            };
        };

        return [...new Set(out.flat(3))];
    };

    const returnPossibleChartColumns = () => {
        let out = [];

        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID;
            };
        };

        if (!curSelector) return out;
        if (curSelector === "-1") out = [...OrganicColumns];
        if (curSelector === "-2") out = [...SMSColumns];
        if (curSelector === "md" || curSelector.startsWith("md-")) out = [...midasCampaignColumns];
        if (curSelector === "an" || curSelector.startsWith("an-")) out = [...adnowCampaignColumns];
        if (["-3"].includes(curSelector)) out = [...GenericColumns];
        if (curSelector.startsWith("fb-")) out = [...FacebookColumns];
        if (curSelector.startsWith("mg-")) out = [...mgidCampaignColumns];
        if (curSelector === "all-all") out = [...genericAllColumns]

        if (out.length === 0) out = [...genericAllColumns];
        return out;
    };

    const filterCampaigns = (additionalFilters = {}, dataSource = data) => {
        let af_json = JSON.stringify(additionalFilters);
        let isSecondary = dataSource === data;
        let curCache = _cache_campaigns.find(c => {
            if (c.af !== af_json) return false;
            if (c.isSecondary !== isSecondary) return false;
            return true;
        });
        if (curCache) return curCache.cache;

        if (!additionalFilters) additionalFilters = {};
        if (typeof(additionalFilters) !== "object" || Array.isArray(additionalFilters)) additionalFilters = {};

        let isOr = false;
        if (additionalFilters["_or"]) {
            isOr = !!additionalFilters["_or"];
            delete additionalFilters["_or"];
        };

        let socialFound = false;
        for (let item of dataSource.data) {
            if (item.IntegrationType === -3) {
                socialFound = item;
                break;
            };
        };

        let final = {
            ...data,
            data: [...dataSource.data.map(dd => {
                return { ...dd };
            }), ...(socialFound ? [(() => {
                return null;
                let tmp = {
                    CampaignActive: true,
                    CampaignName: "Social spent flow",
                    CreatedBy: -1,
                    ID: -1,
                    IntegrationID: "social-spent-flow",
                    TableData: []
                };
                let socialSpent = socialFound.TableData["Visits"];
                if (!socialSpent || socialSpent.length === 0) {
                    let curDate = moment();
                    tmp.TableData["Spent"] = [{
                        Date_day: curDate.get("day"),
                        Date_month: curDate.get("month"),
                        Date_year: curDate.get("year"),
                        Value: socialCostSelector
                    }];
                    return tmp;
                } else {
                    let lastDate = { ...socialSpent[socialSpent.length - 1] };
                    lastDate.Value = socialCostSelector;
                    tmp.TableData["Spent"] = [lastDate];
                    return tmp;
                };
            })()].filter(t => t) : [])]
        };
        if (final.status === "ok") {
            if (excludedAccounts) {
                final.data = final.data.filter((d) => {
                    let excluded = false;
                    for (let exclusion of excludedAccounts) {
                        if (exclusion?.UserID && exclusion?.IntegrationID) {
                            if (d.CreatedBy === exclusion.UserID && d.IntegrationID === exclusion.IntegrationID) {
                                excluded = true;
                                break;
                            };
                        } else if (exclusion?.UserID) {
                            if (d.CreatedBy === exclusion.UserID) {
                                excluded = true;
                                break;
                            };
                        } else if (exclusion.IntegrationID) {
                            if (d.IntegrationID === exclusion.IntegrationID) {
                                excluded = true;
                                break;
                            };
                        };
                    };
                    if (excluded) return false;
                    return true;
                });
            };
        };


        if (Object.keys(additionalFilters).length !== 0) {
            if (final.status === "ok") {
                final.data = final.data.filter(f => {
                    let hits = 0;
                    let keys = 0;
                    for (let key of Object.keys(additionalFilters)) {
                        keys += 1;

                        if (Array.isArray(additionalFilters[key])) {
                            if (!additionalFilters[key].includes(f[key])) {
                                if (isOr) {
                                    continue;
                                } else {
                                    return false;
                                };
                            }
                        } else {
                            if (f[key] !== additionalFilters[key]) if (isOr) {
                                continue;
                            } else {
                                return false;
                            };
                        };
                        hits += 1;
                    };
                    if (isOr) {
                        if (hits > 0) return true;
                        if (keys > 0) return false;
                    };
                    return true;
                });
            };
        };

        _cache_campaigns.push({
            af: af_json,
            isSecondary,
            cache: final
        });
        return final;
    };

    const checkIfExternalIntegration = (campaign, column) => {
        if (campaign.IntegrationType === 4) return "ST";
        if (["Clicks", "Impressions", "Reach"].includes(column)) return "INT";
        if (column !== "Spent") return "ST";

        let int = String(campaign.IntegrationID);
        if (int.startsWith("fb-") || int.startsWith("mg-")) return "INT";
        return "ST";
    };

    const getTableIntegrationValues = (value, key, integration) => {
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID;
            };
        };

        if (String(curSelector).startsWith("-")) {
            let tmp = value[key];
            if (!tmp) return [];
            return Array.isArray(tmp) ? tmp : [];
        } else {
            if (integration === "ST") {
                if (key === "Clicks") key = "Visits";

                let tmp = value[key];
                if (!tmp) tmp = [];
                if (!Array.isArray(tmp)) tmp = [];
                return tmp;
            } else {
                let tmp = value[`${key}_IN`];

                if (!tmp) tmp = [];
                if (!Array.isArray(tmp)) tmp = [];
                return Array.isArray(tmp) ? tmp : [];
            };
        };
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "index",
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'bottom'
            },
            tooltips: {
                mode: "index",
                intersect: false,
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            title: {
                display: false
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    wheel: {
                        enabled: true
                    },
                    mode: 'x'
                }
            }
        },
        scales: {
            yAxes: {
                grid: {
                    color: themeSelector === "dark" ? "#373A43" : "#E2E2E2"
                }
            },
            xAxes: {
                grid: {
                    color: themeSelector === "dark" ? "#373A43" : "#E2E2E2"
                }
            }
        }
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
            case "CostPerAnyLead":
            case "CostPerLead":
            case "ADP":
            case "LTV":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${Number(tmpRevenue.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CARC":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${Number(tmpCR.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${Number(tmpROAS.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    const getAdditionalSpents = (ts, fetchCompare) => {
        if (!reportSettings.includeAdditionalSpent) return setAllAdditionalSpents([]);
        if (timestampRef.current !== ts) return;
        setAllAdditionalSpents([]);

        if (isCompare && !fetchCompare) getAdditionalSpents(ts, true);

        let filters = [];
        if (dateFilters?.start && dateFilters?.end) {
            if (fetchCompare) {
                let ds = moment(dateFilters?.start);
                let de = moment(dateFilters?.end);
                let dateDiff = ds.diff(de, "days") - 1;

                filters.push({ name: "Date", op: "pdgeq", value: moment(dateFilters.start).add(dateDiff, "days").toDate().getTime() });
                filters.push({ name: "Date", op: "pdleq", value: moment(dateFilters.end).add(dateDiff, "days").toDate().getTime() });
            } else {
                filters.push({ name: "Date", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                filters.push({ name: "Date", op: "pdleq", value: dateFilters.end.toDate().getTime() });
            };
        };
        if (Array.isArray(selectedOffer)) {
            if (selectedOffer.length > 0) {
                filters.push({name: "OfferID", op: "in", value: selectedOffer.map(o => o.ID)});
            };
        };
        if (selectedUser?.UserID) {
            if (!selectedUser.UserID.startsWith("all-")) {
                filters.push({name: "UserID", op: "eq", value: selectedUser.UserID});
            };
            if (selectedUser.UserID.startsWith("all-team--")) {
                let teamName = selectedUser.UserID.replace("all-team--", "");
                if (allUsers?.status === "ok") {
                    let finalList = [];
                    const finalUsers = [
                        {
                            ID: userInfoSelector.ID,
                            Team: userInfoSelector?.Team ?? null,
                            _profiles: allTrackingProfilesSelector
                        },
                        ...(allUsers?.data ? allUsers.data : [])
                    ];
                    for (let usr of finalUsers) {
                        if (usr.Team === teamName) finalList.push(usr.ID);
                    };
                    filters.push({name: "UserID", op: "in", value: finalList});
                };
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/additionalSpents/getAll`,
            data: {
                limit: null,
                allUsers: true,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") (fetchCompare ? setCompareAllAdditionalSpents : setAllAdditionalSpents)(res.data.data.map(as => {
                let finalDate = moment(as.Date).format("DD-MM-YYYY");
                as._filterDate = finalDate;
                as._filterDateTime = `${finalDate} 12`;
                return as;
            }));
        }).catch(() => null);
    };
    const calculateAdditionalSpents = (dataSource = allAdditionalSpents, UserID = undefined, OfferID = undefined, CampaignID = undefined, Date = undefined) => {
        let total = 0;

        for (let item of dataSource) {
            if (Date) {
                if (Date.includes(" ")) {
                    if (Date !== item._filterDateTime) continue;
                } else {
                    if (Date !== item._filterDate) continue;
                };
            };
            if (UserID !== undefined) {
                if (Array.isArray(UserID)) {
                    if (!UserID.includes(item.UserID)) continue;
                } else {
                    if (UserID === null) {
                        if (!item.UserID) continue;
                    } else {
                        if (item.UserID !== UserID) continue;
                    };
                };
            };
            if (OfferID !== undefined) {
                if (Array.isArray(OfferID)) {
                    if (!OfferID.includes(item.OfferID)) continue;
                } else {
                    if (OfferID === null) {
                        if (!item.OfferID) continue;
                    } else {
                        if (item.OfferID !== OfferID) continue;
                    };
                };
            };
            if (CampaignID !== undefined) {
                if (Array.isArray(CampaignID)) {
                    if (!CampaignID.includes(item.CampaignID)) continue;
                } else {
                    if (CampaignID === null) {
                        if (!item.CampaignID) continue;
                    } else {
                        if (item.CampaignID !== CampaignID) continue;
                    };
                };
            };

            let finalValue = Number(item.Spent);
            if (!isNaN(finalValue)) total += finalValue;
        };
        
        return total;
    };

    const getData = (ts, fetchSites = false, stateFn, fetchCompare = false) => {
        setWhatif_edit(false);
        setSelectedRows([]);

        // if (fetchedSites) fetchSites = true;
        fetchSites = true;
        if (timestampRef.current !== ts) return;
        if (stateFn[0]) stateFn[1]();
        if (!selectedTrackingProfileSelector) return;
        if (dateFilters === undefined || dateFilters === null) return; // prevent "all" filter

        let trackFilters = [];
        let filters = [];
        if (dateFilters) {
            if (dateFilters?.start && dateFilters?.end) {
                if (!fetchCompare) {
                    filters.push({ name: "lastTrackedAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                    trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                    trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
                } else {
                    let ds = moment(dateFilters?.start);
                    let de = moment(dateFilters?.end);
                    let dateDiff = ds.diff(de, "days") - 1;

                    filters.push({ name: "lastTrackedAt", op: "pdgeq", value: moment(dateFilters.start).add(dateDiff, "days").toDate().getTime() });
                    trackFilters.push({ name: "createdAt", op: "pdgeq", value: moment(dateFilters.start).add(dateDiff, "days").toDate().getTime() });
                    trackFilters.push({ name: "createdAt", op: "pdleq", value: moment(dateFilters.end).add(dateDiff, "days").toDate().getTime() });
                };
            } else {
                _cache_campaigns = [];
                stateFn[1]({status: "ok", data: []});
                if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
                return;
            };
        };
        let smsFilters = [...trackFilters];

        let uInfo = {
            UserID: null,
            IntegrationID: selectedTrackingProfileSelector,
            ...(String(selectedTrackingProfileSelector).startsWith("-") ? {} : {
                IntegrationType: "campaigns"
            }),

            utcOffset: (new Date()).getTimezoneOffset()
        };

        if (selectedUser) {
            for (let key of Object.keys(selectedUser)) {
                uInfo[key] = selectedUser[key];
            };
            if (uInfo?.IntegrationID) {
                if (!uInfo.IntegrationID?.startsWith?.("-")) {
                    uInfo.IntegrationType = "campaigns";
                };
            };
        };

        if (selectedOffer.length > 0) {
            trackFilters.push({ name: "SiteID", op: "in", value: selectedOffer.map(s => s?.Sites ?? []).flat(3) ?? [] });
            filters.push({
                or: [
                    { name: "PreLandingSiteID", op: "in", value: selectedOffer.map(s => s?.Sites ?? []).flat(3) ?? [] },
                    { name: "LandingSiteID", op: "in", value: selectedOffer.map(s => s?.Sites ?? []).flat(3) ?? [] }
                ]
            });
        };

        if (selectedCountry && allOffers.status === "ok") {
            let found = false;
            for (let offer of allOffers.data) {
                if (offer.Country !== selectedCountry) continue;
                found = true;

                filters.push({
                    or: [
                        { name: "PreLandingSiteID", op: "in", value: offer?.Sites ?? [] },
                        { name: "LandingSiteID", op: "in", value: offer?.Sites ?? [] }
                    ]
                });
            };

            if (!found) {
                _cache_campaigns = [];
                stateFn[1]({ status: "ok", data: [] });
                if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
                return;
            };
        };

        let th = [
            "Conversions",
            "Approved",
            "AR",
            "ARC",
            "Cost",
            "Revenue",
            "Spent",
            "Profit",
            "CPA",
            "Visits",
            "CR",
            "CPC",
            "CARC",
            "ARC",
            "DRC",
            "LTV",
            "NC",
            "NCO",

            "Clicks_IN",
            "Impressions_IN",
            "Reach_IN",

            "Date_day", "Date_month", "Date_year", "Date_TrafficSource",
            "ROI", "ROAS", "ADP",
            "--ADP",
            ...(fetchSites ? [
                "Date_SiteID",
                "--SiteID"
            ] : [])
        ];
        if (reportSettings.includeCRMLeadSpent) th.push("SpentCRM");
        if (fetchSites && !fetchedSites) setFetchedSites(true);

        if (Array.isArray(chartColumns)) {
            let tcc = [...chartColumns];
            th.push(...tcc.map(c => {
                if (c.startsWith("Site_")) return null;
                c = c.replace(/ /g, "_");
                return c;
            }).filter(t => t));
        };
        if (chartColumns.includes("Site_Visits")) th.push("Visits", "Date_SiteID");
        if (chartColumns.includes("Site_Conversions")) th.push("Conversions", "Date_SiteID");
        if (chartColumns.includes("Site_CR")) th.push("Visits", "Conversions", "Date_SiteID");

        let curDates = getDates();
        if (curDates.length === 1) {
            th.push("Date_hour");
        };

        th = [...new Set(th)];

        if (selectedUser || userTrafficSources === "all") {
            if (selectedUser?.UserID?.startsWith("all-team-") || selectedUser?.IntegrationID?.endsWith("-all") || userTrafficSources === "all") {
                return getDataTeam(ts, {
                    ...uInfo,

                    trackFilters: [
                        ...trackFilters
                    ],
                    filters,
                    smsFilters,
                    trackGroupByDate: true,
                    showDeleted: true,
                    offset: 0,
                    limit: null,
                    TableHeaders: th,
                    fetchNextNode: true,
                    useCache
                }, selectedUser?.IntegrationID !== "usr-all", stateFn).then(() => {
                    if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
                });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                ...uInfo,

                trackFilters: [
                    ...trackFilters
                ],
                filters,
                smsFilters: trackFilters,
                trackGroupByDate: true,
                showDeleted: true,
                offset: 0,
                limit: null,
                TableHeaders: th,
                fetchNextNode: true,
                useCache
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            _cache_campaigns = [];
            stateFn[1](res.data);
            if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
        }).catch(() => {
            if (timestampRef.current !== ts) return;

            _cache_campaigns = [];
            stateFn[1](backendModule.axiosConfig);
            if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
        });
    };

    const getDataTeam = async (ts, info, includeAllUsers = true, stateFn) => {
        let finalUsers = [];
        let batches = [];

        if (includeAllUsers && selectedUser?.UserID) {
            if (!allUsers) return stateFn[1](backendModule.genericError);
            if (allUsers.status === "error") return stateFn[1](backendModule.genericError);

            finalUsers = [
                {
                    ID: userInfoSelector.ID,
                    Team: userInfoSelector?.Team ?? null,
                    _profiles: allTrackingProfilesSelector
                },
                ...(allUsers?.data ? allUsers.data : [])
            ];
        } else {
            if (selectedUser?.UserID) {
                let fUsr = allUsersWithMe.data.find(d => d.ID === selectedUser?.UserID);
                if (fUsr) {
                    finalUsers.push({
                        ID: fUsr.ID,
                        Team: fUsr?.Team ?? null,
                        _profiles: Array.isArray(fUsr._profiles) ? fUsr._profiles : []
                    });
                };
            };
        };
        if (userTrafficSources === "all" && (!selectedUser || !selectedUser?.UserID)) {
            finalUsers = [
                {
                    ID: userInfoSelector.ID,
                    Team: userInfoSelector?.Team ?? null,
                    _profiles: allTrackingProfilesSelector
                }
            ];
            info.IntegrationID = "all-all";
        };

        if (selectedUser?.UserID?.startsWith("all-team--")) {
            //? filter by team name...
            let teamName = selectedUser.UserID.substring(10, selectedUser.UserID.length);
            finalUsers = finalUsers.filter(f => f?.Team === teamName);
        };

        if (ts !== timestampRef.current) return;
        let out = [];
        if (!["fb-all", "tt-all", "mg-all", "md-all", "an-all", "all-all", "usr-all"].includes(info?.IntegrationID)) {
            let completedIntegrations = [];
            await Promise.allSettled(finalUsers.map(usr => new Promise(async r => {
                let tmpReq = { ...info, UserID: usr.ID };

                
                if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                    batches.push({...tmpReq});
                    setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                    r();
                } else {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                        data: tmpReq,
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.data.status === "ok") out.push(final.data.data);
                        setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                    }).finally(r);
                };
            })));

        } else {
            if (info.IntegrationID === "fb-all") {
                let completedIntegrations = [];
                let isFixedUser = false;
                if (selectedUser?.UserID) {
                    if (selectedUser?.UserID?.split?.("-").length === 5) isFixedUser = true;
                };
                await Promise.allSettled(finalUsers.map(usr => {
                    if (isFixedUser) {
                        if (selectedUser?.UserID !== usr?.ID) return null;
                    };
                    return usr._profiles.filter(p => p.ID.startsWith("fb-")).map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "tt-all") {
                let completedIntegrations = [];
                let isFixedUser = false;
                if (selectedUser?.UserID) {
                    if (selectedUser?.UserID?.split?.("-").length === 5) isFixedUser = true;
                };
                await Promise.allSettled(finalUsers.map(usr => {
                    if (isFixedUser) {
                        if (selectedUser?.UserID !== usr?.ID) return null;
                    };
                    return usr._profiles.filter(p => p.ID.startsWith("tt-")).map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "mg-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID.startsWith("mg-")).map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "md-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID === "md").map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "an-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID === "an").map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "all-all" || info.IntegrationID === "usr-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        let tmp_str = tmpReq?.IntegrationID;
                        tmp_str = `${usr.ID}-${tmp_str}`;
                        if (completedIntegrations.includes(tmp_str)) return r();
                        completedIntegrations.push(tmp_str);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        tmpReq.TableHeaders = [...tmpReq.TableHeaders].map(th => {
                            if (profile.ID.startsWith("mg-") || profile.ID.startsWith("fb-") || profile.ID.startsWith("tt-")) return th;

                            if (th.includes("_IN")) return th.substring(0, th.length - 3);
                            return th;
                        });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).catch(() => null).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            };
        };

        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
            for (let b of batches) b["BatchMethod"] = "/campaigns/getAllCampaigns";

            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/requestBatching/create`,
                data: {
                    Batches: batches
                },
                ...backendModule.axiosConfig
            }).then(final => {
                if (final.data.status === "ok") {
                    for (let d of final.data.data) {
                        if (d?.status === "ok") out.push(d.data);
                    };
                };
            });
        };

        if (ts !== timestampRef.current) return;
        _cache_campaigns = [];
        stateFn[1]({ status: "ok", data: out.flat(5) });
        setProgress();
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersTeamLead`,
            data: {
                excludeSelf: true,
                includeProfiles: true,
                includeDeleted: true,
                allUsers: true,
                checkActive: false,
                filters: [
                    { name: "Flags:userVisibleInReports", op: "eq", value: true }
                ],
                useCache: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
            if (res.data.status === "ok") {
                return setAllUsersWithMe({
                    status: "ok", data: [{
                        ...userInfoSelector,
                        _profiles: allTrackingProfilesSelector
                    }, ...res.data.data]
                });
            };
            setAllUsersWithMe(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
            setAllUsersWithMe(backendModule.genericError);
        });
    };

    const getKPIData = React.useCallback((column, dataSource) => {
        if (!dataSource) return "-";
        if (dataSource.status !== "ok") return "-";
        let finalData = filterCampaigns(null, dataSource);

        let total = 0;

        if (column === "BounceRate") {
            return "-";
        };
        if (column === "CTR") {
            let totalImpressions = 0;
            let totalClicks = 0;

            for (let item of finalData.data) {
                totalImpressions += getTableIntegrationValues(item.TableData, "Impressions", "INT").reduce((acc, val) => acc + +val.Value, 0);
                totalClicks += getTableIntegrationValues(item.TableData, "Clicks", "INT").reduce((acc, val) => acc + +val.Value, 0);
            };
            if (totalImpressions === 0 || totalClicks === 0) return prepareTableData(0, "CR");
            return prepareTableData(Number(totalClicks / totalImpressions * 100).toFixed(2), "CR");
        };
        if (column === "CPC") {
            let totalSpent = 0;
            let totalClicks = 0;

            for (let item of finalData.data) {
                totalSpent += getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent")).reduce((acc, val) => acc + +val.Value, 0);
                totalClicks += getTableIntegrationValues(item.TableData, "Clicks", checkIfExternalIntegration(item, "Clicks")).reduce((acc, val) => acc + +val.Value, 0);
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents);
            if (totalSpent === 0 || totalClicks === 0) return prepareTableData(0, "Spent");
            return prepareTableData(Number(totalSpent / totalClicks).toFixed(2), "Spent");
        };
        if (column === "CPM") {
            let totalImpressions = 0;
            let totalSpent = 0;

            for (let item of finalData.data) {
                totalSpent += getTableIntegrationValues(item.TableData, "Spent", "INT").reduce((acc, val) => acc + +val.Value, 0);
                totalImpressions += getTableIntegrationValues(item.TableData, "Impressions", "INT").reduce((acc, val) => acc + +val.Value, 0);
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents);
            if (totalSpent === 0 || totalImpressions === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalImpressions * 1000, "Spent");
        };
        if (column === "ROI") {
            let totalProfit = Number(getAggregateDataByDate(null, "Profit", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (column === "ROAS") {
            let totalProfit = Number(getAggregateDataByDate(null, "Revenue", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROAS");
        };
        if (column === "Cost") {
            let totalSpent = 0;
            let totalConversions = 0;

            for (let item of finalData.data) {
                totalSpent += getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent")).reduce((acc, val) => acc + +val.Value, 0);
                totalConversions += getTableIntegrationValues(item.TableData, "Conversions", "ST").reduce((acc, val) => acc + +val.Value, 0);
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents);
            if (totalConversions === 0) totalConversions = 1;
            return prepareTableData(Number(totalSpent / totalConversions).toFixed(2), "Spent");
        };
        if (column === "Profit") {
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalRevenue = Number(getAggregateDataByDate(null, "Revenue", {}, dataSource).split(" ")[0].replace(",", ""));

            return prepareTableData(Number(totalRevenue - totalSpent).toFixed(2), "Spent");
        };
        if (column === "_RevenueCRM") {
            let totalApproved = Number(getAggregateDataByDate(null, "Approved", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalADP = Number(getAggregateDataByDate(null, "ADP", {}, dataSource).split(" ")[0].replace(",", ""));

            if (totalApproved > 0 && totalADP > 0) {
                return prepareTableData(totalADP * totalApproved, "Spent");
            } else {
                return prepareTableData(0, "Spent");  
            };
        };
        if (column === "Frequency") {
            let totalImpressions = Number(getAggregateDataByDate(null, "Impressions", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalReach = Number(getAggregateDataByDate(null, "Reach", {}, dataSource).split(" ")[0].replace(",", ""));

            if (!totalImpressions || !totalReach) return prepareTableData(0, "Visits")
            return prepareTableData(Number(totalImpressions / totalReach).toFixed(2), "Visits");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    totalARC += +final.Value;
                };
            };
            totalSpent = getKPIData("CostPerAnyLead", dataSource);
            totalSpent = Number(totalSpent.split(" ")[0]);
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    totalLeads += final.Value ? +final.Value : 0;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    totalSpent += final.Value ? +final.Value : 0;
                };
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents);
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount === 0 && totalADP === 0) return "-";
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            return prepareTableData(final, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        for (let item of finalData.data) {
            // dont forget to handle ST and INT
            // handle AR -_-
            if (["ADP", "AR"].includes(column)) {
                let tmp = getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column)).reduce((acc, val) => acc + +val.Value, 0);
                let tc = getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column)).length;
                if (tc === 0) return "-";
                tmp /= tc;
                total += tmp;
            } else {
                total += getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column)).reduce((acc, val) => acc + +val.Value, 0);
            };
            if (isNaN(total)) total = 0;
        };

        if (column === "AR") total /= finalData.data.length;
        if (column === "Spent") {
            let finalSpent = calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents);
            if (reportSettings.includeCRMLeadSpent) finalSpent += Number(getAggregateDataByDate(null, "SpentCRM", {}, dataSource).split(" ")[0].replace(",", ""));
            total += finalSpent;
        };

        return prepareTableData(total, column);
    }, [data, compareData, allAdditionalSpents, compareAllAdditionalSpents]);

    const getAggregateDataByDate = React.useCallback((date, column, additionalFilters = {}, dataSource = data) => {
        let finalData = filterCampaigns(additionalFilters, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        let [dd, h] = date ? date.split(" ") : [null, null];
        if (h) h = Number(h);
        let [d, m, y] = dd ? dd.split("-") : [null, null, null];
        if (date) [d, m, y] = [+d, +m, +y];

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "SpentCRM", "Profit",
            "Visits", "CARC", "ARC", "DRC", "Clicks", "Impressions", "Reach", "NC"
        ].includes(column)) {
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        total += final.Value ? +final.Value : 0;
                        totalCount += 1;
                    }
                };
            };
            if (["CR", "CTR", "CPC", "CARC"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            if (column === "Spent") {
                total += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, undefined, undefined, date);
                if (reportSettings.includeCRMLeadSpent) total += Number(getAggregateDataByDate(date, "SpentCRM", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            };
            if (column === "Profit") {
                total -= calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, undefined, undefined, date);
                if (reportSettings.includeCRMLeadSpent) total -= Number(getAggregateDataByDate(date, "SpentCRM", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            };
            return prepareTableData(total, column);
        };

        if (["DR"].includes(column)) {
            let totalApproved = Number(getAggregateDataByDate(date, "Approved", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalDRC = Number(getAggregateDataByDate(date, "DRC", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalApproved === 0 || totalDRC === 0) return prepareTableData(0, "CR");
            return prepareTableData((100 / totalApproved * totalDRC), "CR");
        };
        if (["CTR"].includes(column)) {
            let totalProfit = Number(getAggregateDataByDate(date, "Clicks", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(date, "Impressions", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "CR");
            return prepareTableData((totalProfit / totalSpent) * 100, "CR");
        };
        if (["CPC"].includes(column)) {
            let totalProfit = Number(getAggregateDataByDate(date, "Clicks", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(date, "Spent", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData((totalSpent / totalProfit), "Spent");
        };
        if (["ROI"].includes(column)) {
            let totalProfit = Number(getAggregateDataByDate(date, "Profit", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(date, "Spent", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["ROAS"].includes(column)) {
            let totalProfit = Number(getAggregateDataByDate(date, "Revenue", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(date, "Spent", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += +final.Value;
                    };
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["PaymentPerLead"].includes(column)) {
            let finalOut = 0;
            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        if (!final.Date_SiteID) continue;
                        if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                    };
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalARC += +final.Value;
                    };
                };
            };
            totalSpent = getAggregateDataByDate(date, "CostPerAnyLead", additionalFilters, dataSource);
            totalSpent = Number(totalSpent.split(" ")[0].replace(",", ""));
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += final.Value ? +final.Value : 0;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += final.Value ? +final.Value : 0;
                    };
                };
            };

            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, undefined, undefined, date);
            if (reportSettings.includeCRMLeadSpent) totalSpent += Number(getAggregateDataByDate(date, "SpentCRM", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += +final.Value;
                    };
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        if (Array.isArray(final.Value)) {
                            for (let final2 of final.Value) {
                                totalCount += 1;
                                totalADP += final2 ? +final2 : 0;
                            };
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount === 0 && totalADP === 0) return "-";
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            return prepareTableData(final, "Spent");
        };

        if (["_RevenueCRM"].includes(column)) {
            let totalADP = Number(getAggregateDataByDate(date, "ADP", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalApproved = Number(getAggregateDataByDate(date, "Approved", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalADP > 0 && totalApproved > 0) {
                return prepareTableData(totalADP * totalApproved, "Spent");
            } else {
                return prepareTableData(0, "Spent");
            };
        };
        if (column === "Frequency") {
            let totalImpressions = Number(getAggregateDataByDate(date, "Impressions", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalReach = Number(getAggregateDataByDate(date, "Reach", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (!totalImpressions || !totalReach) return prepareTableData(0, "Visits")
            return prepareTableData(Number(totalImpressions / totalReach).toFixed(2), "Visits");
        };
    }, [data, compareData, allAdditionalSpents, compareAllAdditionalSpents]);

    const getAggregateDataByOffer = React.useCallback((offer, column, additionalFilters = {}, dataSource = data, premadeData = null) => {
        if (Object.keys(additionalFilters).length === 0 && offer) {
            additionalFilters = {
                _or: true,
                LandingSiteID: offer.Sites,
                PreLandingSiteID: offer.Sites
            };
        };
        let finalData = premadeData ? premadeData : filterCampaigns(additionalFilters, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "SpentCRM", "Profit",
            "Visits", "ARC", "DRC", "Clicks", "Impressions", "Reach", "NC", "NCO"
        ].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let total = 0;
            let totalCount = 0;
            
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    total += final.Value ? +final.Value : 0;
                    totalCount += 1;
                };
            };
            if (["CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            if (column === "Spent") {
                total += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, offer ? offer.ID : null);
                if (reportSettings.includeCRMLeadSpent) total += Number(getAggregateDataByOffer(offer, "SpentCRM", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            };
            if (column === "Profit") {
                total -= calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, offer ? offer.ID : null);
                if (reportSettings.includeCRMLeadSpent) total -= Number(getAggregateDataByOffer(offer, "SpentCRM", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            };
            return prepareTableData(total, column);
        };

        if (["DR"].includes(column)) {
            let totalApproved = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalDRC = Number(getAggregateDataByOffer(offer, "DRC", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalApproved === 0 || totalDRC === 0) return prepareTableData(0, "CR");
            return prepareTableData((100 / totalApproved * totalDRC), "CR");
        };
        if (["CTR"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalProfit = Number(getAggregateDataByOffer(offer, "Clicks", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Impressions", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "CR");
            return prepareTableData((totalProfit / totalSpent) * 100, "CR");
        };
        if (["CPC"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalProfit = Number(getAggregateDataByOffer(offer, "Clicks", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Spent", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData((totalSpent / totalProfit), "Spent");
        };
        if (["ROI"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalProfit = Number(getAggregateDataByOffer(offer, "Profit", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Spent", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["ROAS"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalProfit = Number(getAggregateDataByOffer(offer, "Revenue", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Spent", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["AR"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = Number(getAggregateDataByOffer(offer, "ARC", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["PaymentPerLead"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let finalOut = 0;
            if (offer !== null) return prepareTableData(Number(offer.Price), "Spent");

            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, finalData).replace(",", ""));
            let totalSpent = 0;
            let totalARC = Number(getAggregateDataByOffer(offer, "ARC", additionalFilters, dataSource, finalData).replace(",", ""));

            totalSpent = getAggregateDataByOffer(offer, "CostPerAnyLead", additionalFilters, dataSource, finalData);
            totalSpent = Number(totalSpent.split(" ")[0].replace(",", "")) ?? 0;

            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = Number(getAggregateDataByOffer(offer, "Conversions", additionalFilters, dataSource, finalData).replace(",", ""));
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    totalSpent += +final.Value;
                };
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, offer ? offer.ID : null);
            if (reportSettings.includeCRMLeadSpent) totalSpent += Number(getAggregateDataByOffer(offer, "SpentCRM", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["ADP"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount === 0 && totalADP === 0) return "-";
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            return prepareTableData(final, "Spent");
        };

        if (["_RevenueCRM"].includes(column)) {
            let totalADP = Number(getAggregateDataByOffer(offer, "ADP", additionalFilters, dataSource, premadeData).split(" ")[0].replace(",", ""));
            let totalApproved = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, premadeData).split(" ")[0].replace(",", ""));

            if (totalADP > 0 && totalApproved > 0) {
                return prepareTableData(totalADP * totalApproved, "Spent");
            } else {
                return prepareTableData(0, "Spent");
            };
        };
        if (column === "Frequency") {
            let totalImpressions = Number(getAggregateDataByOffer(offer, "Impressions", additionalFilters, dataSource, premadeData).split(" ")[0].replace(",", ""));
            let totalReach = Number(getAggregateDataByOffer(offer, "Reach", additionalFilters, dataSource, premadeData).split(" ")[0].replace(",", ""));

            if (!totalImpressions || !totalReach) return prepareTableData(0, "Visits")
            return prepareTableData(Number(totalImpressions / totalReach).toFixed(2), "Visits");
        };
    }, [data, compareData, whatif_edit, allAdditionalSpents, compareAllAdditionalSpents]);

    const getAggregateDataByUser = React.useCallback((userID, column, dataSource = data) => {
        let finalData = filterCampaigns({}, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "SpentCRM", "Profit",
            "Visits", "ARC", "DRC", "Clicks", "Conversions", "Clicks", "Impressions", "Reach", "NC"
        ].includes(column)) {
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    total += final.Value ? +final.Value : 0;
                    totalCount += 1;
                };
            };
            if (["CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            if (column === "Spent") {
                total += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, userID ? userID : null);
                if (reportSettings.includeCRMLeadSpent) total += Number(getAggregateDataByUser(userID, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            };
            if (column === "Profit") {
                total -= calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, userID ? userID : null);
                if (reportSettings.includeCRMLeadSpent) total -= Number(getAggregateDataByUser(userID, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            };
            return prepareTableData(total, column);
        };

        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["DR"].includes(column)) {
            let totalApproved = Number(getAggregateDataByUser(userID, "Approved", dataSource).split(" ")[0].replace(",", ""));
            let totalDRC = Number(getAggregateDataByUser(userID, "ARC", dataSource).split(" ")[0].replace(",", ""));

            if (totalApproved === 0 || totalDRC === 0) return prepareTableData(0, "CR");
            return prepareTableData((100 / totalApproved * totalDRC), "CR");
        };
        if (["ROI"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Profit", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["CTR"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Clicks", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Impressions", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "CR");
            return prepareTableData((totalProfit / totalSpent) * 100, "CR");
        };
        if (["CPC"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Clicks", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData((totalSpent / totalProfit), "Spent");
        };
        if (["ROAS"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Revenue", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["PaymentPerLead"].includes(column)) {
            let finalOut = 0;

            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalARC += +final.Value;
                };
            };
            totalSpent = getAggregateDataByUser(userID, "CostPerAnyLead", dataSource);
            totalSpent = Number(totalSpent.split(" ")[0]) ?? 0;
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalSpent += +final.Value;
                };
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, userID ? userID : null);
            if (reportSettings.includeCRMLeadSpent) totalSpent += Number(getAggregateDataByUser(userID, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            if (totalCount === 0) return "-";
            return prepareTableData(final, "Spent");
        };

        if (["_RevenueCRM"].includes(column)) {
            let totalADP = Number(getAggregateDataByUser(userID, "ADP", dataSource).split(" ")[0].replace(",", ""));
            let totalApproved = Number(getAggregateDataByUser(userID, "Approved", dataSource).split(" ")[0].replace(",", ""));

            if (totalADP > 0 && totalApproved > 0) {
                return prepareTableData(totalADP * totalApproved, "Spent");
            } else {
                return prepareTableData(0, "Spent");
            };
        };
        if (column === "Frequency") {
            let totalImpressions = Number(getAggregateDataByUser(userID, "Impressions", dataSource).split(" ")[0].replace(",", ""));
            let totalReach = Number(getAggregateDataByUser(userID, "Reach", dataSource).split(" ")[0].replace(",", ""));

            if (!totalImpressions || !totalReach) return prepareTableData(0, "Visits")
            return prepareTableData(Number(totalImpressions / totalReach).toFixed(2), "Visits");
        };
    }, [data, compareData, allAdditionalSpents, compareAllAdditionalSpents]);

    const getAggregateDataByPortal = React.useCallback((portal, column, dataSource = data) => {
        let finalData = filterCampaigns({}, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "SpentCRM", "Profit",
            "Visits", "ARC", "DRC", "Clicks", "Conversions", "Clicks", "Impressions", "Reach", "NC"
        ].includes(column)) {
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    total += final.Value ? +final.Value : 0;
                    totalCount += 1;
                };
            };
            if (["CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            if (column === "Spent") {
                if (reportSettings.includeCRMLeadSpent) total += Number(getAggregateDataByPortal(portal, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            };
            if (column === "Profit") {
                if (reportSettings.includeCRMLeadSpent) total -= Number(getAggregateDataByPortal(portal, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            };
            return prepareTableData(total, column);
        };

        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["DR"].includes(column)) {
            let totalApproved = Number(getAggregateDataByPortal(portal, "Approved", dataSource).split(" ")[0].replace(",", ""));
            let totalDRC = Number(getAggregateDataByPortal(portal, "ARC", dataSource).split(" ")[0].replace(",", ""));

            if (totalApproved === 0 || totalDRC === 0) return prepareTableData(0, "CR");
            return prepareTableData((100 / totalApproved * totalDRC), "CR");
        };
        if (["ROI"].includes(column)) {
            let totalProfit = Number(getAggregateDataByPortal(portal, "Profit", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByPortal(portal, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["CTR"].includes(column)) {
            let totalProfit = Number(getAggregateDataByPortal(portal, "Clicks", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByPortal(portal, "Impressions", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "CR");
            return prepareTableData((totalProfit / totalSpent) * 100, "CR");
        };
        if (["CPC"].includes(column)) {
            let totalProfit = Number(getAggregateDataByPortal(portal, "Clicks", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByPortal(portal, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData((totalSpent / totalProfit), "Spent");
        };
        if (["ROAS"].includes(column)) {
            let totalProfit = Number(getAggregateDataByPortal(portal, "Revenue", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByPortal(portal, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["PaymentPerLead"].includes(column)) {
            let finalOut = 0;

            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalARC += +final.Value;
                };
            };
            totalSpent = getAggregateDataByPortal(portal, "CostPerAnyLead", dataSource);
            totalSpent = Number(totalSpent.split(" ")[0]) ?? 0;
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalSpent += +final.Value;
                };
            };

            if (reportSettings.includeCRMLeadSpent) totalSpent += Number(getAggregateDataByPortal(portal, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (!final.Date_TrafficSource) continue;
                    if (portal !== null && final.Date_TrafficSource !== portal) continue;
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            if (totalCount === 0) return "-";
            return prepareTableData(final, "Spent");
        };

        if (["_RevenueCRM"].includes(column)) {
            let totalADP = Number(getAggregateDataByPortal(portal, "ADP", dataSource).split(" ")[0].replace(",", ""));
            let totalApproved = Number(getAggregateDataByPortal(portal, "Approved", dataSource).split(" ")[0].replace(",", ""));

            if (totalADP > 0 && totalApproved > 0) {
                return prepareTableData(totalADP * totalApproved, "Spent");
            } else {
                return prepareTableData(0, "Spent");
            };
        };
        if (column === "Frequency") {
            let totalImpressions = Number(getAggregateDataByPortal(portal, "Impressions", dataSource).split(" ")[0].replace(",", ""));
            let totalReach = Number(getAggregateDataByPortal(portal, "Reach", dataSource).split(" ")[0].replace(",", ""));

            if (!totalImpressions || !totalReach) return prepareTableData(0, "Visits")
            return prepareTableData(Number(totalImpressions / totalReach).toFixed(2), "Visits");
        };
    }, [data, compareData, allAdditionalSpents, compareAllAdditionalSpents]);

    const getAggregateDataByCampaign = React.useCallback((campaign, column, dataSource = data) => {
        let finalData = filterCampaigns({}, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "SpentCRM", "Profit",
            "Visits", "ARC", "DRC", "Clicks", "Conversions", "Clicks", "Impressions", "Reach", "LTV", "NC", "NCO"
        ].includes(column)) {
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    total += final.Value ? +final.Value : 0;
                    totalCount += 1;
                };
            };
            if (["CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            if (column === "Spent") {
                total += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, undefined, campaign ? campaign : null);
                if (reportSettings.includeCRMLeadSpent) total += Number(getAggregateDataByCampaign(campaign, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            };
            if (column === "Profit") {
                total -= calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, undefined, campaign ? campaign : null);
                if (reportSettings.includeCRMLeadSpent) total -= Number(getAggregateDataByCampaign(campaign, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            };
            return prepareTableData(total, column);
        };

        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["DR"].includes(column)) {
            let totalProfit = Number(getAggregateDataByCampaign(campaign, "Profit", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByCampaign(campaign, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent), "ROI");
        };
        if (["ROI"].includes(column)) {
            let totalProfit = Number(getAggregateDataByCampaign(campaign, "Profit", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByCampaign(campaign, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["CTR"].includes(column)) {
            let totalProfit = Number(getAggregateDataByCampaign(campaign, "Clicks", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByCampaign(campaign, "Impressions", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "CR");
            return prepareTableData((totalProfit / totalSpent) * 100, "CR");
        };
        if (["CPC"].includes(column)) {
            let totalProfit = Number(getAggregateDataByCampaign(campaign, "Clicks", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByCampaign(campaign, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData((totalSpent / totalProfit), "Spent");
        };
        if (["ROAS"].includes(column)) {
            let totalProfit = Number(getAggregateDataByCampaign(campaign, "Revenue", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByCampaign(campaign, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["PaymentPerLead"].includes(column)) {
            let finalOut = 0;

            let finalSites = [];

            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    totalARC += +final.Value;
                };
            };
            totalSpent = getAggregateDataByCampaign(campaign, "CostPerAnyLead", dataSource);
            totalSpent = Number(totalSpent.split(" ")[0]) ?? 0;
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    totalSpent += +final.Value;
                };
            };
            totalSpent += calculateAdditionalSpents(dataSource === data ? allAdditionalSpents : compareAllAdditionalSpents, undefined, undefined, campaign ? campaign : null);
            if (reportSettings.includeCRMLeadSpent) totalSpent += Number(getAggregateDataByCampaign(campaign, "SpentCRM", dataSource).split(" ")[0].replace(",", ""));
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                if (campaign && item.ID !== campaign) continue;
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            if (totalCount === 0) return "-";
            return prepareTableData(final, "Spent");
        };

        if (["_RevenueCRM"].includes(column)) {
            let totalADP = Number(getAggregateDataByCampaign(campaign, "ADP", dataSource).split(" ")[0].replace(",", ""));
            let totalApproved = Number(getAggregateDataByCampaign(campaign, "Approved", dataSource).split(" ")[0].replace(",", ""));

            if (totalADP > 0 && totalApproved > 0) {
                return prepareTableData(totalADP * totalApproved, "Spent");
            } else {
                return prepareTableData(0, "Spent");
            };
        };
        if (column === "Frequency") {
            let totalImpressions = Number(getAggregateDataByCampaign(campaign, "Impressions", dataSource).split(" ")[0].replace(",", ""));
            let totalReach = Number(getAggregateDataByCampaign(campaign, "Reach", dataSource).split(" ")[0].replace(",", ""));

            if (!totalImpressions || !totalReach) return prepareTableData(0, "Visits")
            return prepareTableData(Number(totalImpressions / totalReach).toFixed(2), "Visits");
        };
    }, [data, compareData, allAdditionalSpents, compareAllAdditionalSpents]);

    const getDates = (includeHours = false, usePreviousTime = false) => {
        if (!data) return [];
        if (!dateFilters) return;
        if (data.status === "error") return [];

        let tmp = [];

        let ds = moment(dateFilters.start);
        let de = moment(dateFilters.end);
        if (usePreviousTime) {
            let diff = ds.diff(de, "days") - 1;
            ds.add(diff, "days");
            de.add(diff, "days");
        };

        tmp = [];
        while (ds.isBefore(de)) {
            tmp.push(ds.format("DD-MM-YYYY"));
            ds = ds.add(1, "days");
        };

        if (tmp.length === 1 && includeHours) {
            tmp = [];

            ds = moment(dateFilters.start);
            de = moment(dateFilters.end);
            if (usePreviousTime) {
                let diff = ds.diff(de, "days") - 1;
                ds.add(diff, "days");
                de.add(diff, "days");
            };

            while (ds.isBefore(de)) {
                tmp.push(ds.format("DD-MM-YYYY HH"));
                ds = ds.add(1, "hours");
            };
        };

        return tmp;
    };

    const getPortals = () => {
        if (!data) return [];
        if (data.status !== "ok") return [];

        let portals = [];
        for (let item of data.data) {
            if (item.TableData?.["Date_TrafficSource"]) {
                for (let p of item.TableData["Date_TrafficSource"]) {
                    if (!p.Value) continue;
                    if (!portals.includes(p.Value)) {
                        portals.push(p.Value);
                    };
                };
            };
        };

        return portals;
    };

    const getChartDataDates = () => {
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        // totalLeads, goodLeads, profit, spent
        let labels = getDates(true);
        if (!Array.isArray(labels)) return { datasets: [], labels: [] };

        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;
        let sitesExist = false;

        for (let item of tcc) {
            if (item.startsWith("Site_")) {
                sitesExist = true;
                continue;
            };
            datasets.push({
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        if (sitesExist) {
            let cmpSites = getCampaignSiteIDs();

            for (let site of cmpSites) {
                let curSites = allSites.data.find(s => s.ID === site);
                if (!curSites) continue;

                if (tcc.includes("Site_Visits")) datasets.push({
                    fill: false,
                    label: `Site_Visits ${curSites.SiteName}`,
                    _key: curSites.ID,
                    data: [],
                    borderColor: chartColorSets[count] ?? "gray",
                    backgroundColor: chartBackgroundColorSets[count] ?? "gray"
                });
                if (tcc.includes("Site_Conversions")) datasets.push({
                    fill: false,
                    label: `Site_Conversions ${curSites.SiteName}`,
                    _key: curSites.ID,
                    data: [],
                    borderColor: chartColorSets[count] ?? "gray",
                    backgroundColor: chartBackgroundColorSets[count] ?? "gray"
                });
                if (tcc.includes("Site_CR")) datasets.push({
                    fill: false,
                    label: `Site_CR ${curSites.SiteName}`,
                    _key: curSites.ID,
                    data: [],
                    borderColor: chartColorSets[count] ?? "gray",
                    backgroundColor: chartBackgroundColorSets[count] ?? "gray"
                });
                count += 1;
            };
        };

        let hourFound = false;
        for (let dates of labels) {
            let [dd, h] = dates.split(" ");
            if (h) {
                h = Number(h);
                hourFound = true;
            };
            let [d, m, y] = dd.split("-");
            [d, m, y] = [+d, +m, +y];


            let totals = {};
            let siteData = {};

            if (sitesExist) {
                let cmpSites = getCampaignSiteIDs();

                for (let site of cmpSites) {
                    let curSites = allSites.data.find(s => s.ID === site);
                    if (!curSites) continue;

                    siteData[curSites.ID] = {
                        Name: curSites.SiteName,
                        Visits: 0,
                        Conversions: 0,
                        CR: 0
                    };
                };
            };

            for (let item of datasets) totals[item.label] = 0;

            for (let item of finalData.data) {
                for (let ds of datasets) {
                    if (!totals[ds.label]) totals[ds.label] = 0;

                    let finalColumn = ds.label.replace(/ /g, "_");
                    let int = "ST";
                    if (finalColumn.endsWith("_IN")) int = "INT";
                    if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);
                    for (let obj of getTableIntegrationValues(item.TableData, finalColumn, int)) {
                        if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                            if (h !== null && (obj.Date_hour !== h)) continue;
                            totals[ds.label] += +obj.Value;
                        };
                    };
                };

                if (sitesExist && item.TableData["--SiteID"]) {
                    //? process site data
                    if (tcc.includes("Site_Visits") || tcc.includes("Site_CR")) {
                        for (let obj of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                            if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                                if (h !== null && (obj.Date_hour !== h)) continue;
                                if (!obj.Date_SiteID) continue;
                                if (!siteData[obj.Date_SiteID]) continue;
                                siteData[obj.Date_SiteID].Visits += +obj.Value;
                            };
                        };
                    };
                    if (tcc.includes("Site_Conversions") || tcc.includes("Site_CR")) {
                        for (let obj of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                            if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                                if (h !== null && (obj.Date_hour !== h)) continue;
                                if (!obj.Date_SiteID) continue;
                                if (!siteData[obj.Date_SiteID]) continue;
                                siteData[obj.Date_SiteID].Conversions += +obj.Value;
                            };
                        };
                    };
                };
            };
            for (let ds of datasets) {
                if (ds.label.startsWith("Site_")) continue;
                ds.data.push(totals[ds.label]);
            };
            if (tcc.includes("Site_CR")) {
                for (let key of Object.keys(siteData)) {
                    let cr = 100 / siteData[key].Visits * siteData[key].Conversions;
                    cr = Number(cr).toFixed(0);
                    if (isNaN(cr)) cr = 0;

                    siteData[key]["CR"] = cr;
                };
            };
            if (sitesExist) {
                for (let key of Object.keys(siteData)) {
                    for (let ds of datasets) {
                        if (ds._key !== key) continue;
                        if (ds.label.startsWith("Site_Visits")) ds.data.push(siteData[key].Visits);
                        if (ds.label.startsWith("Site_Conversions")) ds.data.push(siteData[key].Conversions);
                        if (ds.label.startsWith("Site_CR")) ds.data.push(siteData[key].CR);
                    };
                };
            };
        };


        if (!hourFound) {
            labels = labels.map(l => moment(l, "DD-MM-YYYY").toDate().toLocaleDateString());
        } else {
            labels = labels.map(l => moment(l, "DD-MM-YYYY HH").format("HH:00 A"));
        };

        return { datasets, labels };
    };

    const getChartDataOffers = () => {
        if (!allOffers) return { datasets: [], labels: [] };
        if (allOffers.status !== "ok") return { datasets: [], labels: [] };
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        let labels = [];
        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;

        for (let item of tcc) {
            datasets.push({
                barThickness: 9,
                borderWidth: 1,
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        for (let offer of allOffers.data) {
            labels.push(offer.Name);

            for (let ds of datasets) {
                let finalColumn = ds.label.replace(/ /g, "_");
                let int = "ST";
                if (finalColumn.endsWith("_IN")) int = "INT";
                if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);

                let count = 0;
                let total = 0;
                for (let cmp of finalData.data) {
                    if (!cmp.TableData[ds.label.replace(/ /g, "_")]) continue;

                    count += 1;
                    for (let item of getTableIntegrationValues(cmp.TableData, finalColumn, int)) {
                        if (!offer.Sites.includes(item.Date_SiteID)) continue;
                        if (item?.Value) total += +item.Value;
                    };
                };

                if (["CR", "CPA", "CPAO"].includes(finalColumn)) total /= count;
                ds.data.push(total);
            };
        };

        //? remove unneeded labels and datasets
        let removedLabels = [];
        labels = labels.filter((l, lID) => {
            if (datasets.reduce((acc, val) => acc + val.data[lID], 0) === 0) {
                removedLabels.push(lID);
                return false;
            };
            return true;
        });
        if (removedLabels.length !== 0) datasets = datasets.map(ds => {
            ds.data = ds.data.filter((_, dID) => !removedLabels.includes(dID));
            return ds;
        });

        while (labels.length < 20) labels.push("");
        return { datasets, labels };
    };

    const getChartDataPortals = () => {
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        let labels = [];
        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;

        for (let item of tcc) {
            datasets.push({
                barThickness: 9,
                borderWidth: 1,
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        let trafficSources = [];
        for (let item of data.data) {
            if (item.TableData?.["Date_TrafficSource"]) {
                for (let ts of item.TableData["Date_TrafficSource"]) {
                    if (!trafficSources.includes(ts.Value)) trafficSources.push(ts.Value);
                };
            };
        };

        for (let trafficSource of trafficSources) {
            labels.push(trafficSource);

            for (let ds of datasets) {
                let finalColumn = ds.label.replace(/ /g, "_");
                let int = "ST";
                if (finalColumn.endsWith("_IN")) int = "INT";
                if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);

                let count = 0;
                let total = 0;
                for (let cmp of finalData.data) {
                    if (!cmp.TableData[ds.label.replace(/ /g, "_")]) continue;

                    count += 1;
                    for (let item of getTableIntegrationValues(cmp.TableData, finalColumn, int)) {
                        if (item.Date_TrafficSource !== trafficSource) continue;
                        if (item?.Value) total += +item.Value;
                    };
                };

                if (["CR", "CPA", "CPAO"].includes(finalColumn)) total /= count;
                ds.data.push(total);
            };
        };

        //? remove unneeded labels and datasets
        let removedLabels = [];
        labels = labels.filter((l, lID) => {
            if (datasets.reduce((acc, val) => acc + val.data[lID], 0) === 0) {
                removedLabels.push(lID);
                return false;
            };
            return true;
        });
        if (removedLabels.length !== 0) datasets = datasets.map(ds => {
            ds.data = ds.data.filter((_, dID) => !removedLabels.includes(dID));
            return ds;
        });

        while (labels.length < 20) labels.push("");
        return { datasets, labels };
    };

    const getChartDataCampaigns = () => {
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        let labels = [];
        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;

        for (let item of tcc) {
            datasets.push({
                barThickness: 9,
                borderWidth: 1,
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        let campaigns = [];
        for (let item of data.data) {
            if (!item.CampaignActive) continue;
            campaigns.push({ID: item.ID, Name: item.CampaignName});
        };

        for (let campaign of campaigns) {
            labels.push(campaign.Name);

            for (let ds of datasets) {
                let finalColumn = ds.label.replace(/ /g, "_");
                let int = "ST";
                if (finalColumn.endsWith("_IN")) int = "INT";
                if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);

                let count = 0;
                let total = 0;
                for (let cmp of finalData.data) {
                    if (cmp.ID !== campaign.ID) continue;
                    if (!cmp.TableData[ds.label.replace(/ /g, "_")]) continue;

                    count += 1;
                    for (let item of getTableIntegrationValues(cmp.TableData, finalColumn, int)) {
                        if (item?.Value) total += +item.Value;
                    };
                };

                if (["CR", "CPA", "CPAO"].includes(finalColumn)) total /= count;
                ds.data.push(total);
            };
        };

        //? remove unneeded labels and datasets
        let removedLabels = [];
        labels = labels.filter((l, lID) => {
            if (datasets.reduce((acc, val) => acc + val.data[lID], 0) === 0) {
                removedLabels.push(lID);
                return false;
            };
            return true;
        });
        if (removedLabels.length !== 0) datasets = datasets.map(ds => {
            ds.data = ds.data.filter((_, dID) => !removedLabels.includes(dID));
            return ds;
        });

        while (labels.length < 20) labels.push("");
        return { datasets, labels };
    };

    const getChartDataTeam = () => {
        if (!allUsersWithMe) return { datasets: [], labels: [] };
        if (allUsersWithMe.status !== "ok") return { datasets: [], labels: [] };
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        let labels = [];
        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;

        for (let item of tcc) {
            datasets.push({
                barThickness: 9,
                borderWidth: 1,
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        for (let usr of allUsersWithMe.data) {
            labels.push(usr.Username);

            for (let ds of datasets) {
                let finalColumn = ds.label.replace(/ /g, "_");
                let int = "ST";
                if (finalColumn.endsWith("_IN")) int = "INT";
                if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);

                let count = 0;
                let total = 0;
                for (let cmp of finalData.data) {
                    if (cmp.CreatedBy !== usr.ID) continue;
                    if (!cmp.TableData[ds.label.replace(/ /g, "_")]) continue;

                    count += 1;
                    for (let item of getTableIntegrationValues(cmp.TableData, finalColumn, int)) {
                        if (item?.Value) total += +item.Value;
                    };
                };

                if (["CR", "CPA", "CPAO"].includes(finalColumn)) total /= count;
                ds.data.push(total);
            };
        };

        //? remove unneeded labels and datasets
        let removedLabels = [];
        labels = labels.filter((l, lID) => {
            if (datasets.reduce((acc, val) => acc + val.data[lID], 0) === 0) {
                removedLabels.push(lID);
                return false;
            };
            return true;
        });
        if (removedLabels.length !== 0) datasets = datasets.map(ds => {
            ds.data = ds.data.filter((_, dID) => !removedLabels.includes(dID));
            return ds;
        });

        while (labels.length < 20) labels.push("");
        return { datasets, labels };
    };

    const performSort = (data, headers) => {
        let finalOrd = localOrder;

        if (!finalOrd) finalOrd = {name: "Leads", order: "desc"};
        if (!finalOrd?.name || !finalOrd?.order) finalOrd = {name: "Leads", order: "desc"};
        if (finalOrd?.name === "Date" || finalOrd?.name === "Offer") return data;

        
        headers = headers ? headers : ["-", "Visits", "Leads", "CLR", "CLs", "CARC", "PPL", "CPL", "CPAL", "CR", "CTR", "CPC", "ROI", "ROAS", "Revenue", "Spent", "ADP", "Profit"];
        let curHeaderIndex = ["Date", "Offer", "User"].includes(finalOrd.name) ? 0 : headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            switch (headers[curHeaderIndex]) {
                case "CLR":
                case "PPL":
                case "CPAL":
                case "Revenue":
                case "Spent":
                case "Profit":
                case "CR":
                case "CTR":
                case "CPC":
                case "ROI":
                    return Number(String(item).split(" ")[0].replace(",", "")) ?? 0;
                case "ROAS":
                    return Number(String(item).replace("x", "")) ?? 0;
                default: return Number(String(item).split(" ")[0].replace(",", ""));
            };
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a.columns[curHeaderIndex]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a.columns[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a.columns[curHeaderIndex]?.group)) {
                a1 = removeExtra(a.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a.columns[curHeaderIndex].text);
            };
            if (Array.isArray(b.columns[curHeaderIndex]?.group)) {
                b1 = removeExtra(b.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b.columns[curHeaderIndex].text);
            };

            if (finalOrd.order === "asc") {
                if (+a1 > +b1) return 1; else return -1;
            } else {
                if (+b1 > +a1) return 1; else return -1;
            };
        });
    };

    const getAllIntegrationIDs = () => {
        if (!data) return [];
        let finalData = data;
        if (!finalData) return [];
        if (finalData.status === "error") return [];

        let finalUsers = [
            {
                ID: userInfoSelector.ID,
                Username: userInfoSelector?.Username,
                Team: userInfoSelector?.Team ?? null,
                _profiles: allTrackingProfilesSelector
            },
            ...(allUsers?.data ? allUsers.data : [])
        ];

        let allIntegrations = [];
        for (let item of finalData.data) {
            let foundProfile = false;
            for (let usr of finalUsers) {
                for (let profile of (usr._profiles ?? [])) {
                    if (profile.ID === item.IntegrationID && usr.ID === item.CreatedBy) {

                        for (let p of allIntegrations) {
                            if (p.UserID === usr.ID && p.IntegrationID === item.IntegrationID) {
                                foundProfile = true;
                                break;
                            };
                        };
                        if (!foundProfile) allIntegrations.push({
                            Username: usr.Username,
                            UserID: usr.ID,
                            IntegrationID: item.IntegrationID,
                            IntegrationName: profile.name
                        });
                        foundProfile = true;
                        break;
                    };
                };
                if (foundProfile) break;
            };
        };

        allIntegrations.sort((a, b) => {
            return a?.Username > b?.Username ? 1 : -1;
        });
        allIntegrations.push({
            Username: "System user",
            UserID: -1,
            IntegrationID: "social-spent-flow",
            IntegrationName: `Social spent flow (50 ${currencySignSelector})`
        });
        return allIntegrations;
    };

    const getCampaignData_offers = (offer) => {
        if (!offer) return null;
        if (!data) return null;

        let final = {};
        let campaignTypes = [];
        for (let item of (filterCampaigns()?.data ?? [])) {
            if (!campaignTypes.includes(item.CampaignType)) campaignTypes.push(item.CampaignType);
        };

        if (campaignTypes.length === 0) return null;
        for (let cType of campaignTypes) {
            final[cType ?? "Other"] = {
                Visits: getAggregateDataByOffer(offer, "Visits", {CampaignType: cType}),
                Conversions: getAggregateDataByOffer(offer, "Conversions", {CampaignType: cType}),
                CR: getAggregateDataByOffer(offer, "CR", {CampaignType: cType}),
                Approved: getAggregateDataByOffer(offer, "Approved", {CampaignType: cType}),
                AR: getAggregateDataByOffer(offer, "AR", {CampaignType: cType}),
                Spent: getAggregateDataByOffer(offer, "Spent", {CampaignType: cType}),
                CPA: getAggregateDataByOffer(offer, "CostPerAnyLead", {CampaignType: cType}),
                CPAO: getAggregateDataByOffer(offer, "CostPerLead", {CampaignType: cType}),
                ROI: getAggregateDataByOffer(offer, "ROI", {CampaignType: cType}),
                ROAS: getAggregateDataByOffer(offer, "ROAS", {CampaignType: cType}),
                DR: getAggregateDataByOffer(offer, "DR", {CampaignType: cType})
            };
        };

        return final;
    };

    const getCampaignData_offers_dates = offer => {
        if (!offer) return null;
        if (!data) return null;
        if (!Array.isArray(offer?.Sites)) return null;
        if (offer.Sites.length === 0) return null;

        let final = {};
        let dates = getDates(false);

        if (dates.length === 0) return null;
        for (let cType of dates) {
            final[cType ?? "Other"] = {
                Visits: getAggregateDataByDate(dates.length === 1 ? null : cType, "Visits", {LandingSiteID: offer.Sites}),
                ARC: getAggregateDataByDate(dates.length === 1 ? null : cType, "ARC", {LandingSiteID: offer.Sites}),
                Conversions: getAggregateDataByDate(dates.length === 1 ? null : cType, "Conversions", {LandingSiteID: offer.Sites}),
                CR: getAggregateDataByDate(dates.length === 1 ? null : cType, "CR", {LandingSiteID: offer.Sites}),
                Approved: getAggregateDataByDate(dates.length === 1 ? null : cType, "Approved", {LandingSiteID: offer.Sites}),
                AR: getAggregateDataByDate(dates.length === 1 ? null : cType, "AR", {LandingSiteID: offer.Sites}),
                Spent: getAggregateDataByDate(dates.length === 1 ? null : cType, "Spent", {LandingSiteID: offer.Sites}),
                CPA: getAggregateDataByDate(dates.length === 1 ? null : cType, "CostPerAnyLead", {LandingSiteID: offer.Sites}),
                CPAO: getAggregateDataByDate(dates.length === 1 ? null : cType, "CostPerLead", {LandingSiteID: offer.Sites}),
                ROI: getAggregateDataByDate(dates.length === 1 ? null : cType, "ROI", {LandingSiteID: offer.Sites}),
                ROAS: getAggregateDataByDate(dates.length === 1 ? null : cType, "ROAS", {LandingSiteID: offer.Sites}),
                DR: getAggregateDataByDate(dates.length === 1 ? null : cType, "DR", {LandingSiteID: offer.Sites})
            };
        };

        return final;
    };

    const getCampaignData_offers_campaigns = offer => {
        if (!data) return null;
        if (data.status !== "ok") return null;
        if (!offer) return null;
        if (!data) return null;

        let final = {};
        for (let item of filterCampaigns({LandingSiteID: offer.Sites}).data) {
            final[item.CampaignName] = {
                Visits: getAggregateDataByOffer(offer, "Visits", {ID: item.ID}),
                ARC: getAggregateDataByOffer(offer, "ARC", {ID: item.ID}),
                Conversions: getAggregateDataByOffer(offer, "Conversions", {ID: item.ID}),
                CR: getAggregateDataByOffer(offer, "CR", {ID: item.ID}),
                Approved: getAggregateDataByOffer(offer, "Approved", {ID: item.ID}),
                AR: getAggregateDataByOffer(offer, "AR", {ID: item.ID}),
                Spent: getAggregateDataByOffer(offer, "Spent", {ID: item.ID}),
                CPA: getAggregateDataByOffer(offer, "CostPerAnyLead", {ID: item.ID}),
                CPAO: getAggregateDataByOffer(offer, "CostPerLead", {ID: item.ID}),
                ROI: getAggregateDataByOffer(offer, "ROI", {ID: item.ID}),
                ROAS: getAggregateDataByOffer(offer, "ROAS", {ID: item.ID}),
                DR: getAggregateDataByOffer(offer, "DR", {ID: item.ID}),

                ID: item.ID,
                createdAt: item.createdAt,
                IntegrationType: item.IntegrationType,
                IntegrationID: item.IntegrationID
            };
        };

        return final;
    };

    const getCamapinData_IDs = (offer) => {
        if (!offer) return [];
        if (!data) return [];

        let out = [];
        for (let item of (filterCampaigns()?.data ?? [])) {
            if (!offer.Sites.includes(item.LandingSiteID) && !offer.Sites.includes(item.PreLandingSiteID)) continue;
            if (!out.includes(item.ID)) out.push(item.ID);
        };

        return out;
    };

    const getTeams = () => {
        if (!allUsers) return [];
        if (allUsers?.status !== "ok") return [];

        let finalUsers = [
            {
                ID: userInfoSelector.ID,
                Username: userInfoSelector?.Username,
                Team: userInfoSelector?.Team ?? null,
                _profiles: allTrackingProfilesSelector
            },
            ...(allUsers?.data ? allUsers.data : [])
        ];

        let out = [];
        for (let item of finalUsers) {
            if (!item.Team) continue;
            if (!out.includes(item.Team)) out.push(item.Team);
        };
        return out;
    };

    const getKPIDifference = (column) => {
        let oldKPIData = getKPIData(column, compareData);
        let newKPIData = getKPIData(column, data);

        if (column === "ROAS") {
            oldKPIData = String(oldKPIData);
            oldKPIData = oldKPIData.substring(0, oldKPIData.length - 1);

            newKPIData = String(newKPIData);
            newKPIData = newKPIData.substring(0, newKPIData.length - 1);
        };

        oldKPIData = Number(oldKPIData.split(" ")[0].replace(",", ""));
        newKPIData = Number(newKPIData.split(" ")[0].replace(",", ""));

        let biggerColor = themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
        let smallerColor = themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
        let biggerSign = "+";
        let smallerSign = "";

        if ([
            "CostPerAnyLead",
            "CostPerLead",
            "Spent",
            "CPM"
        ].includes(column)) {
            [biggerColor, smallerColor] = [smallerColor, biggerColor];
        };

        if (isNaN(oldKPIData)) oldKPIData = 0;
        if (isNaN(newKPIData)) newKPIData = 0;

        if (oldKPIData === newKPIData) return <span style={{fontWeight: "normal", fontSize: "13px"}}>-</span>;
        if (oldKPIData > newKPIData) return <span style={{color: smallerColor, fontWeight: "normal", fontSize: "13px"}}>{smallerSign}{prepareTableData(newKPIData - oldKPIData, column)}</span>
        if (oldKPIData < newKPIData) return <span style={{color: biggerColor, fontWeight: "normal", fontSize: "13px"}}>{biggerSign}{prepareTableData(newKPIData - oldKPIData, column)}</span>
    };

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts, true, [data, setData]);
            getAdditionalSpents(ts);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        curDefer(handler, 500);

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [selectedTrackingProfileSelector, dateFilters, selectedUser, selectedOffer, selectedCountry, chartColumns, userTrafficSources, isCompare, useCache, reportSettings]);

    React.useEffect(() => {
        clearTimeout(_autoRefreshTimer);
        if (autoRefresh.active) {
            _autoRefreshTimer = setTimeout(() => {
                if (autoRefresh.cur >= autoRefresh.max) {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts, true, [data, setData]);
                    getAdditionalSpents(ts);
                    return setAutoRefresh(ar => {
                        return {...ar, cur: 0};
                    });
                };

                setAutoRefresh(ar => {
                    return {...ar, cur: ar.cur + 1};
                });
            }, 1000);
        };
    }, [autoRefresh, selectedTrackingProfileSelector, dateFilters, selectedUser, selectedOffer, selectedCountry, chartColumns, userTrafficSources, isCompare, useCache, reportSettings]);

    React.useEffect(() => {
        if (selectedOffer);
        if (groupBy !== "offer") setGroupBy("offer");
        if (userTrafficSources !== "selected") setUserTrafficSources("selected");
    }, [selectedUser]);

    React.useEffect(() => {
        if (!userInfoSelector?.Flags?.isAdmin && !userInfoSelector?.Flags?.isTeamLeader && !userInfoSelector?.Flags?.isManager) return setAllUsers({ status: "ok", data: [] });
        if (allUsers) return;

        getAllUsers();
    }, [userInfoSelector]);

    React.useEffect(() => {
        setSelectedOffer([]);
    }, [selectedTrackingProfileSelector]);

    React.useEffect(() => {
        setChartColumns();
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID
            };
        };

        if (!curSelector) return setChartColumns();
        setChartColumns([
            "Conversions", "Approved", "Profit",
            (curSelector?.startsWith?.("fb-") || curSelector?.startsWith?.("mg-") || curSelector === "all-all") ? "Spent IN" : "Spent"
        ]);
        setChartColumnsTimestamp(Date.now());
    }, [selectedTrackingProfileSelector, selectedUser]);

    React.useEffect(() => {
        setLocalOrder(null);
        setSelectedRows([]);
    }, [groupBy]);

    React.useEffect(() => {
        if (groupBy === "additional-spents") setGroupBy("offer");
    }, [reportSettings]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null,
                offset: 0,
                getOfferInfo: true,
                extended: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllSites(res.data);

            if (res.data.status === "ok") {
                let out = {};

                for (let item of res.data.data) {
                    if (!item?._Offer) continue;
                    if (!item._Offer?.ID) continue;

                    if (!out[item._Offer.ID]) {
                        out[item._Offer.ID] = {
                            Name: item._Offer.OfferName,
                            Price: item._Offer.OfferPrice,
                            OfferBrand: item._Offer.OfferBrand,
                            OfferType: item._Offer.OfferType,
                            OfferNiche: item._Offer.OfferNiche,
                            Country: item._Offer.Country,
                            ResponsiblePerson: item._Offer.ResponsiblePerson,
                            Sites: [item.ID]
                        };
                    } else {
                        out[item._Offer.ID].Sites.push(item.ID);
                    };
                };

                setAllOffers({
                    status: "ok", data: Object.keys(out).map(id => {
                        return {
                            ID: id,
                            ...out[id]
                        };
                    })
                });
            } else {
                setAllOffers(backendModule.genericError);
            };
        }).catch(() => {
            setAllOffers(backendModule.genericError);
        });
    }, []);

    return <div className="route__reports__marketingOffers">
        {autoRefresh?.active && <div className="route__reports__marketingOffers__progress">
            <div className="route__reports__marketingOffers__progress__item" style={{
                width: `${100 / autoRefresh.max * autoRefresh.cur}%`,
                maxWidth: "100%"
            }}></div>
        </div>}

        <div className={`route__reports__marketingOffers__filters ${(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.isManager) ? " route__reports__marketingOffers__filters--additional" : ""}`}>
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.isManager) && <div className="route__reports__marketingOffers__filters__userFilter">
                <FilterByUserAccount
                    headline="Select user / account"
                    data={(() => {
                        if (!allUsers) return null;
                        if (allUsers.status === "error") return [];
                        return [
                            {
                                ID: "all-team-users",
                                Username: "All teams",
                                _profiles: [
                                    { ID: "-1", type: "scale-track", name: "Generic tracking" },
                                    { ID: "-2", type: "scale-track-sms", name: "SMS marketing" },
                                    { ID: "-3", type: "scale-track-social", name: "Social campaigns" },
                                    { ID: "fb-all", type: "facebook", name: "Facebook", generic: true },
                                    { ID: "tt-all", type: "tiktok", name: "Tiktok", generic: true },
                                    { ID: "mg-all", type: "mgid", name: "Mgid", generic: true },
                                    { ID: "md-all", type: "midas", name: "Midas Network", generic: true },
                                    { ID: "an-all", type: "adnow", name: "AdNow One", generic: true },
                                    { ID: "all-all", type: "all", name: "All traffic sources", generic: true }
                                ]
                            },
                            ...(getTeams().map(t => {
                                return {
                                    ID: `all-team--${t}`,
                                    style: {color: "orange"},
                                    Username: `[Team] ${t}`,
                                    _profiles: [
                                        { ID: "-1", type: "scale-track", name: "Generic tracking" },
                                        { ID: "-2", type: "scale-track-sms", name: "SMS marketing" },
                                        { ID: "-3", type: "scale-track-social", name: "Social campaigns" },
                                        { ID: "fb-all", type: "facebook", name: "Facebook", generic: true },
                                        { ID: "tt-all", type: "tiktok", name: "Tiktok", generic: true },
                                        { ID: "mg-all", type: "mgid", name: "Mgid", generic: true },
                                        { ID: "md-all", type: "midas", name: "Midas Network", generic: true },
                                        { ID: "an-all", type: "adnow", name: "AdNow One", generic: true },
                                        { ID: "all-all", type: "all", name: "All traffic sources", generic: true }
                                    ]
                                }
                            })),
                            ...(allUsers.data.map(ud => {
                                return {
                                    ...ud,
                                    _profiles: [
                                        ...ud._profiles,
                                        { ID: "usr-all", type: "all", name: "All traffic sources", generic: true },
                                        ...(ud._profiles.sort((a, b) => {
                                            return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                        }).find(p => p?.ID?.startsWith?.("fb-")) ? [
                                            { ID: "fb-all", type: "facebook", name: "All accounts" },
                                        ] : []),
                                        ...(ud._profiles.sort((a, b) => {
                                            return (a.active !== b.active && a.active) ? (a.active ? -1 : 1) : 0
                                        }).find(p => p?.ID?.startsWith?.("tt-")) ? [
                                            { ID: "tt-all", type: "tiktok", name: "All accounts" }
                                        ] : []),
                                    ]
                                };
                            }))
                        ];
                    })()}
                    onChange={e => setSelectedUser(e)}
                    defaultUser={null}
                />
                {selectedUser?.IntegrationID === "all-all" && <div className="route__reports__marketingOffers__filters__userFilter__image" onClick={e => {
                    animateBox(<FilterByIntegration integrations={getAllIntegrationIDs()} excluded={excludedAccounts} onChange={e => setExcludedAccounts([...e])} />)
                }}>
                    <img src="/images/filterlight.svg" />
                </div>}
                {progress && <div className="route__reports__marketingOffers__filters__userFilter__progress">
                    <div className="route__reports__marketingOffers__filters__userFilter__progress__item" style={{
                        width: `${100 / progress.maximum * progress.current}%`,
                        maxWidth: "100%"
                    }}></div>
                </div>}
            </div>}

            <AdvancedDropdown
                headline="Country"
                showSearch={true}
                data={[{ key: "all-countries", name: "All countries", value: null }, ...countries.map(c => {
                    return { key: c.code, name: c.name, value: c.code, image: `/images/countryFlags/${c.code.toLowerCase()}.png` }
                })]}
                onChange={e => {
                    if (selectedCountry !== e?.value) setSelectedCountry(e?.value);
                }}
                selected={(() => {
                    if (!selectedCountry) return 0
                    return countries.indexOf(countries.find(c => c.code === selectedCountry)) + 1;
                })()}
            />

            <FilterByDate
                defaultValue="yesterday"
                onChange={e => setDateFilters(e)}
                disableAll={true}
                disable24h={true}
                {...(isCompare ? {
                    textToday: <><span style={{color: "rgb(122, 108, 219)"}}>Today</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Yesterday</span></>,
                    textYesterday: <><span style={{color: "rgb(122, 108, 219)"}}>Yesterday</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previous day</span></>,
                    text7Days: <><span style={{color: "rgb(122, 108, 219)"}}>7 Days</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previos 7 days</span></>,
                    text30Days: <><span style={{color: "rgb(122, 108, 219)"}}>30 days</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previos 30 days</span></>,
                    textThisMonth: <><span style={{color: "rgb(122, 108, 219)"}}>This month</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Last month</span></>,
                    textLastMonth: <><span style={{color: "rgb(122, 108, 219)"}}>Last month</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previous mont</span>h</>
                } : {})}
            />

            {allOffers?.status === "ok" && <div className="route__reports__marketingOffers__filters__btn" onClick={e => animateBox(e, <OfferSelectModal
                onChange={e => setSelectedOffer(e)}
                defaultValue={selectedOffer}
                data={allOffers?.data ?? []}
            />)}>Select offers ({selectedOffer?.length ?? 0} / {allOffers?.data?.length ?? 0})</div>}
        </div>

        <div className="route__reports__marketingOffers__kpi">

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>Visits</span>
                    <span>Conversions</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Visits", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Conversions", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Visits", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Conversions", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Visits")}
                                {getKPIDifference("Conversions")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>Processed</span>
                    <span>Approved</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {data ? <>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("ARC", data)}</span>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("Approved", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("ARC", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Approved", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("ARC")}
                                {getKPIDifference("Approved")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CR %</span>
                    <span>AR %</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CR", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("AR", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CR", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("AR", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CR")}
                                {getKPIDifference("AR")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CTR</span>
                    <span>CPC</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CTR", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CPC", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CTR", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CPC", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CTR")}
                                {getKPIDifference("CPC")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
            <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CPM</span>
                    <span>LTV</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CPM", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("LTV", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CPM", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("LTV", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CPM")}
                                {getKPIDifference("LTV")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CPA</span>
                    <span>CPAO</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("CostPerAnyLead", data)}</span>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("CostPerLead", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CostPerAnyLead", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CostPerLead", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CostPerAnyLead")}
                                {getKPIDifference("CostPerLead")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>Revenue</span>
                    <span>CRM Revenue</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Revenue", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("_RevenueCRM", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Revenue", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("_RevenueCRM", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Revenue")}
                                {getKPIDifference("_RevenueCRM")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>NC</span>
                    <span>Frequency</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("NC", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Frequency", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("NC", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Frequency", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("NC")}
                                {getKPIDifference("Frequency")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>Profit</span>
                    <span>Spent</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Profit", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Spent", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Profit", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Spent", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Profit")}
                                {getKPIDifference("Spent")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>ROI</span>
                    <span>ROAS</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("ROI", data)}</span>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("ROAS", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("ROI", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("ROAS", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("ROI")}
                                {getKPIDifference("ROAS")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

        </div>

        {chartColumns && <div className="route__reports__marketingOffers__columns">
            {(data?.status === "ok") && <div className="route__reports__marketingOffers__columns__export" style={{ backgroundImage: `url("/images/icon_botDetect.svg")` }} title="Bot Detection AI" onClick={e => animateBox(e, <MarketingOffersReport_botDetectionAI data={filterCampaigns()} dateFilters={dateFilters} />)}></div>}
            {(allUsers && data?.status === "ok") && <div className="route__reports__marketingOffers__columns__export" style={{ backgroundImage: `url("/images/icon_export.svg")` }} title="Export data" onClick={e => animateBox(e, <ExportSessions allUsers={allUsers?.status === "ok" ? allUsers.data : []} selectedUser={selectedUser} data={data?.data} dateFilters={dateFilters} />)}></div>}
            <div className="route__reports__marketingOffers__columns__export" style={{ backgroundImage: `url("/images/settings_light.svg")` }} onClick={() => animateBox(<ReportSettings settings={reportSettings} setSettings={setReportSettings} />)}></div>

            <div className="route__reports__marketingOffers__columns__groupBy route__reports__marketingOffers__columns__groupBy--red">
                <p>Cache:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${useCache ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (!useCache) setUseCache(true);
                }}>Yes</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${!useCache ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => useCache && animateBox(<YesNoModal
                    heading="Are you sure?"
                    text="This will disable the caching for any further requests. Unless You have a pretty good reason to turn this off, DO NOT TURN THIS OFF! This will increase the time needed to show the reprt, and further ones wont be cached for further use later on!"
                    isLeftButtonNormal={true}
                    buttonRightCallback={args => {
                        setUseCache(false);
                        args.close();
                    }}
                />)}>No</div>
            </div>

            <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Auto-refresh</p>
                <Dropdown
                    theme={themeSelector}
                    accent="transparent"
                    style={{
                        backgroundColor: "transparent",
                        height: "100%",
                        width: "max-content",
                        padding: 0,
                        minWidth: 0
                    }}
                    data={[
                        {name: <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>No&nbsp;</span>, value: false, search: "No"},
                        {name: <span>5m&nbsp;</span>, value: 5, search: "5m"},
                        {name: <span>10m&nbsp;</span>, value: 10, search: "10m"},
                        {name: <span>15m&nbsp;</span>, value: 15, search: "15m"},
                        {name: <span>20m&nbsp;</span>, value: 20, search: "20m"},
                        {name: <span>25m&nbsp;</span>, value: 25, search: "25m"},
                        {name: <span>30m&nbsp;</span>, value: 30, search: "30m"},
                    ]}
                    selected={(()=>{
                        if (!autoRefresh.active) return 0;
                        switch (autoRefresh.max) {
                            case 5*60: return 1;
                            case 10*60: return 2;
                            case 15*60: return 3;
                            case 20*60: return 4;
                            case 25*60: return 5;
                            case 30*60: return 6;
                            default: return null;
                        };
                    })()}
                    onChange={e => {
                        if (!e?.value && !autoRefresh.active) return;
                        if (e?.value * 60 === autoRefresh.max) return;

                        if (!e?.value) return setAutoRefresh({active: false, cur: 0, max: 0});
                        return setAutoRefresh({active: true, cur: 0, max: e?.value * 60});
                    }}
                />
            </div>

            <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Split source:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${isSplitSource ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (!isSplitSource) setIsSplitSource(true);
                }}>Yes</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${!isSplitSource ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => isSplitSource && setIsSplitSource(false)}>No</div>
            </div>

            <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Compare:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${isCompare ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (!isCompare) setIsCompare(true);
                    setTimeout(() => document.querySelector(".route__reports__marketingOffers__filters .component__filters__filterByDate").click(), 100);
                }}>Yes</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${!isCompare ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => isCompare && setIsCompare(false)}>No</div>
            </div>

            {(!selectedUser || selectedUser?.UserID === null) && <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Traffic source:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${userTrafficSources === "selected" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (userTrafficSources !== "selected") && setUserTrafficSources("selected")}>Selected</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${userTrafficSources === "all" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (userTrafficSources !== "all") setUserTrafficSources("all");
                }}>All</div>
            </div>}

            <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Group by:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "date" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "date") && setGroupBy("date")}>Date</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "offer" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (groupBy !== "offer") setGroupBy("offer");
                    if (!fetchedSites) {
                        getData(timestampRef.current, true, [data, setData]);
                    };
                }}>Offers</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "portal" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "portal") && setGroupBy("portal")}>Portal</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "campaigns" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "campaigns") && setGroupBy("campaigns")}>Campaigns</div>
                {(((userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.isManager)) && selectedUser?.UserID?.startsWith("all-team-")) && <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "team" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "team") && setGroupBy("team")}>Team</div>}
                {reportSettings?.includeAdditionalSpent && <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "additional-spents" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "additional-spents") && setGroupBy("additional-spents")}>Additional spents</div>}
            </div>

            {/* <FilterColumns
                key={`filter-columns-${selectedTrackingProfileSelector}-${chartColumnsTimestamp}`}
                defaultValue={chartColumns}
                columns={returnPossibleChartColumns()}
                onChange={e => setChartColumns(e)}
                integrationID={`user-marketing-report-offers`}
                style={{
                    width: "200px",
                }}
            /> */}
        </div>}

        {/* <div className="route__dashboard__chart">
            <div className={`route__reports__marketingOffers__chart__spinner ${!data ? "route__reports__marketingOffers__chart__spinner--active" : ""}`}>
                <Spinner color={themeSelector === "dark" ? "white" : "black"} />
            </div>

            {(chartColumns && groupBy === "date") && <Line
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataDates()}
                key="report-chart-perDate"
            />}
            {(chartColumns && groupBy === "offer") && <Bar
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataOffers()}
                key="report-chart-perOffer"
            />}
            {(chartColumns && groupBy === "portal") && <Bar
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataPortals()}
                key="report-chart-perPortal"
            />}
            {(chartColumns && groupBy === "team") && <Bar
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataTeam()}
                key="report-chart-perTeam"
            />}
        </div> */}

        <div className="route__reports__marketingOffers__table genericTableWrap genericTableWrap--sticky">
            {groupBy === "date" && <MarketingOffersReport_tableWrap_date
                data={data}
                compareData={compareData}
                getAggregateDataByDate={getAggregateDataByDate}
                isCompare={isCompare}
                performSort={performSort}
                themeSelector={themeSelector}
                getDates={getDates}
                handleOrderCallback={handleOrderCallback}
                localOrder={localOrder}

                prepareTableData={prepareTableData}
            />}

            {groupBy === "offer" && <MarketingOffersReport_tableWrap_offer
                isSplitSource={isSplitSource}
                data={data}
                compareData={compareData}
                getAggregateDataByOffer={getAggregateDataByOffer}
                isCompare={isCompare}
                performSort={performSort}
                themeSelector={themeSelector}
                getDates={getDates}
                handleOrderCallback={handleOrderCallback}
                localOrder={localOrder}

                whatif_edit={whatif_edit}
                setWhatif_edit={setWhatif_edit}
                allOffers={allOffers}
                selectedUser={selectedUser}
                userInfoSelector={userInfoSelector}
                dateFilters={dateFilters}
                allUsers={allUsers}
                allTrackingProfilesSelector={allTrackingProfilesSelector}
                prepareTableData={prepareTableData}

                getCampaignData_offers={getCampaignData_offers}
                getCampaignData_offers_dates={getCampaignData_offers_dates}
                getCampaignData_offers_campaigns={getCampaignData_offers_campaigns}
                getCamapinData_IDs={getCamapinData_IDs}
            />}

            {groupBy === "team" && <MarketingOffersReport_tableWrap_team
                data={data}
                compareData={compareData}
                themeSelector={themeSelector}
                allUsersWithMe={allUsersWithMe}
                isCompare={isCompare}
                getAggregateDataByUser={getAggregateDataByUser}
                allUsers={allUsers}
                performSort={performSort}
                handleOrderCallback={handleOrderCallback}
                dateFilters={dateFilters}
                localOrder={localOrder}
            />}

            {groupBy === "portal" && <MarketingOffersReport_tableWrap_portal
                data={data}
                compareData={compareData}
                getAggregateDataByPortal={getAggregateDataByPortal}
                isCompare={isCompare}
                performSort={performSort}
                themeSelector={themeSelector}
                getDates={getDates}
                getPortals={getPortals}
                handleOrderCallback={handleOrderCallback}
                localOrder={localOrder}

                prepareTableData={prepareTableData}
            />}
            {groupBy === "campaigns" && <MarketingOffersReport_tableWrap_campaigns
                data={data}
                compareData={compareData}
                getAggregateDataByCampaign={getAggregateDataByCampaign}
                isCompare={isCompare}
                performSort={performSort}
                themeSelector={themeSelector}
                dateFilters={dateFilters}
                getCampaigns={() => {
                    let cData = filterCampaigns();
                    if (cData?.status !== "ok") return [];
                    return cData.data.filter(c => c.TableData?.["Visits"]?.length > 0).map(c => {
                        return {ID: c.ID, Name: c.CampaignName, createdAt: c.createdAt, Active: c.CampaignActive, item: c};
                    });
                }}
                handleOrderCallback={handleOrderCallback}
                localOrder={localOrder}

                prepareTableData={prepareTableData}
            />}
            {groupBy === "additional-spents" && <MarketingOffersReport_tableWrap_additionalSpents
                allAdditionalSpents={allAdditionalSpents}
                themeSelector={themeSelector}
                performSort={performSort}
                localOrder={localOrder}
                handleOrderCallback={handleOrderCallback}
                prepareTableData={prepareTableData}
            />}
        </div>
    </div>
};

const FilterByIntegration = props => {
    const [excluded, setExcluded] = React.useState(props.excluded ?? []);
    const [search, setSearch] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const checkIsExcluded = (UserID, IntegrationID) => {
        for (let item of excluded) {
            if (item?.UserID === UserID && item?.IntegrationID === IntegrationID) return true;
        };
        return false;
    };

    const performSearch = item => {
        if (!search) return true;

        let searchStrings = search.trim().split(" ").map(s => s.trim().toLowerCase());
        if (searchStrings.length === 0) return true;

        for (let s of searchStrings) {
            if (
                !String(item.IntegrationID).toLowerCase().includes(s) &&
                !String(item.IntegrationName).toLowerCase().includes(s) &&
                !String(item.UserID).toLowerCase().includes(s) &&
                !String(item.Username).toLowerCase().includes(s)
            ) return false;
        };

        return true;
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__reports__marketingOffers__filterByIntegration" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Filter integrations</div>

                <StyledButton style={{ marginLeft: "auto", marginRight: "20px", height: "30px" }} onClick={() => {
                    if (typeof (props.onChange) === "function") props.onChange(excluded);
                    props.onClose();
                }}>Save</StyledButton>
                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                <FilterBySearch onChange={e => search !== e && setSearch(e)} />

                <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__buttons">
                    <StyledButton onClick={() => setExcluded([])}>Select all</StyledButton>
                    <StyledButton onClick={() => {
                        setExcluded(props.integrations.filter(performSearch).map(i => {
                            return { UserID: i.UserID, IntegrationID: i.IntegrationID };
                        }));
                    }}>Deselect all{search && ` filtered`}</StyledButton>
                </div>

                <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations">
                    {props.integrations.filter(performSearch).map(i => {
                        return <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations__integration">
                            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations__integration__left">
                                <RadioButton checked={!checkIsExcluded(i.UserID, i.IntegrationID)} onChange={e => {
                                    if (e && checkIsExcluded(i.UserID, i.IntegrationID)) {
                                        setExcluded(e => e.filter(ef => ef.UserID !== i.UserID && ef.IntegrationID !== i.IntegrationID));
                                    } else if (!e && !checkIsExcluded(i.UserID, i.IntegrationID)) {
                                        setExcluded(e => {
                                            return [
                                                ...e,
                                                { UserID: i.UserID, IntegrationID: i.IntegrationID }
                                            ];
                                        });
                                    };
                                }} />
                            </div>
                            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations__integration__right">
                                <p style={{ color: i.UserID === -1 ? (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight) : null }}>{i.Username}</p>
                                <p>{i.IntegrationName}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
    </div>
};

const ReportSettings = props => {
    const [settings, setSettings] = React.useState(props.settings ?? {});

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Report settings</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "5px", flexDirection: "row", cursor: "pointer"}} onClick={() => setSettings(s => {return {...s, includeAdditionalSpent: !s.includeAdditionalSpent}})}>
                <RadioButton style={{pointerEvents: "none"}} checked={settings?.includeAdditionalSpent ?? false} />
                <span style={{userSelect: "none"}}>Include additional spent</span>
            </div>
            <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "5px", flexDirection: "row", cursor: "pointer"}} onClick={() => setSettings(s => {return {...s, includeCRMLeadSpent: !s.includeCRMLeadSpent}})}>
                <RadioButton style={{pointerEvents: "none"}} checked={settings?.includeCRMLeadSpent ?? false} />
                <span style={{userSelect: "none"}}>Include CRM Lead Spent</span>
            </div>
            <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "5px", flexDirection: "row", cursor: "pointer"}} onClick={() => setSettings(s => {return {...s, includeCRMTail: !s.includeCRMTail}})}>
                <RadioButton style={{pointerEvents: "none"}} checked={settings?.includeCRMTail ?? false} />
                <span style={{userSelect: "none"}}>[TODO] Include CRM tail leads</span>
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => {
                    props.setSettings(settings);
                    props.onClose();
                }}>Save</div>
            </div>
        </div>
    </div>
};

const ExportSessions = props => {
    const [progress, setProgress] = React.useState();
    const [infoP, setInfoP] = React.useState("");
    const [name, setName] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");
    const userInfoSelector = useSelector(state => state?.usetData?.userData?.UserInfo ?? {});

    const exportNameRef = React.useRef();

    const getData = async () => {
        setInfoP("");

        let filters = [];
        if (props?.dateFilters) {
            if (props?.dateFilters?.start && props?.dateFilters?.end) {
                filters.push({ name: "createdAt", op: "pdgeq", value: props.dateFilters.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "pdleq", value: props.dateFilters.end.toDate().getTime() });
            };
        };

        let fetched = [];
        let success = 0;
        let failed = 0;

        if (props.selectedUser) {
            // handle selection (probably team or specific team user)
            let allCampaigns = {};
            for (let c of props.data) {
                if (!allCampaigns[c.CreatedBy]) allCampaigns[c.CreatedBy] = {};
                if (!allCampaigns[c.CreatedBy][c.IntegrationType]) allCampaigns[c.CreatedBy][c.IntegrationType] = [];

                allCampaigns[c.CreatedBy][c.IntegrationType].push(c.ID);
            };
            let totalCampaigns = 0;
            for (let key of Object.keys(allCampaigns)) {
                totalCampaigns += Object.keys(allCampaigns[key]).length;
            };
            setProgress({ current: 0, maximum: totalCampaigns ?? 0 });

            for (let usrKey of Object.keys(allCampaigns)) {
                for (let typeKey of Object.keys(allCampaigns[usrKey])) {
                    let final = await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/getAllOrders`,
                        data: {
                            CampaignIDs: allCampaigns[usrKey][typeKey],
                            IntegrationType: typeKey === "null" ? null : typeKey,
                            UserID: usrKey,
                            limit: null,
                            offset: 0,
                            filters
                        },
                        ...backendModule.axiosConfig
                    }).then(res => res.data).catch(() => backendModule.genericError);

                    if (final.status === "ok") {
                        success += 1;
                        fetched.push(...final.data);
                    } else {
                        failed += 1;
                    };
                    setProgress(p => { return { ...p, current: p.current + 1 } });
                };
            };
        } else {
            // handle this user and its integration
            let allCampaigns = {};
            for (let c of props.data) {
                if (!allCampaigns[c.IntegrationType]) allCampaigns[c.IntegrationType] = [];
                allCampaigns[c.IntegrationType].push(c.ID);
            };
            setProgress({ current: 0, maximum: Object.keys(allCampaigns).length });

            for (let key of Object.keys(allCampaigns)) {
                let final = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getAllOrders`,
                    data: {
                        CampaignIDs: allCampaigns[key],
                        IntegrationType: key,
                        UserID: userInfoSelector?.ID,
                        limit: null,
                        offset: 0,
                        filters
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);

                if (final.status === "ok") {
                    success += 1;
                    fetched.push(...final.data);
                } else {
                    failed += 1;
                };
                setProgress(p => { return { ...p, current: p.current + 1 } });
            };
        };

        fetched = fetched.sort((a, b) => {
            return moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1;
        });

        if (failed > 0) {
            let resp = await new Promise(r => {
                animateBox(<YesNoModal
                    heading="Fetch errors"
                    text={`There were ${failed} errors while fetching the session/conversion data. Do You want to continue with the export?`}
                    isRightButtonNormal={true}
                    buttonLeftCallback={args => {
                        args.close();
                        r(false)
                    }}
                    buttonRightCallback={args => {
                        args.close();
                        r(true);
                    }}
                />);
            });
            if (!resp) return props.onClose();
        };

        const ip_local_replace = ip => {
            if (!ip) return ip;
            ip = String(ip);
            if (ip.startsWith("::ffff:")) {
                ip = ip.substring(7, ip.length);
            };
            return ip;
        };

        let csvOut = [];
        // Click ID, Date, Ip Address, Scale CRM ID
        for (let item of fetched) {
            let curOrderID = undefined;
            if (item?.ClientInfo?.ID) {
                curOrderID = item.ClientInfo.ID;
            } else if (item?.ClientInfo?.["integration_scalecrm"]) {
                curOrderID = item.ClientInfo["integration_scalecrm"];
            };

            csvOut.push({
                "Click ID": item.ID,
                Date: moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss"),
                "IP Address": ip_local_replace(item?.DeviceInfo?.IP),
                "Scale-CRM ID": curOrderID ?? "",
                "Scale-CRM Status": `${item.ClientInfo?.["ScalecrmLeadStatus"]} | ${item.ClientInfo?.["ScalecrmLeadStatusText"]}`
            });
        };

        if (csvOut.length === 0) return setInfoP("Nothing to export");

        let keysSplit = [...Object.keys(csvOut[0])];

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet("Offer report");
        let headersInserted = [];
        for (let elem of csvOut) {
            if (headersInserted.length === 0) {
                headersInserted = Object.keys(elem).map(key => {
                    return { header: key, key: key };
                });
                break;
            };
        };
        sheet.addTable({
            name: "Conversions",
            "ref": "A1",
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: headersInserted.map(hi => { return { name: hi.header } }),
            rows: csvOut.map(c => {
                let out = [];
                for (let item of keysSplit) {
                    out.push(c[item]);
                };
                return out;
            })
        });
        sheet.views = [
            { state: "frozen", ySplit: 1, xSplit: 1 }
        ];
        sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        workbook.xlsx.writeBuffer().then(data => {
            let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = `${name}.xlsx`;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                props.onClose();
            }, 0);
            animateBox(<YesNoModal
                heading="Export successful"
                text={`${success} entries parsed successfully, and encountered ${failed} errors!`}
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).catch(() => {
            setInfoP("A generic error occured while saving the file, please try again later...");
        });
    };

    React.useEffect(() => {
        if (!name) return;
        getData();
    }, [name]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Export</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            {!name ? <>
                <div className="genericModal__wrap__input">
                    <p>Export name</p>
                    <input ref={exportNameRef} type="text" defaultValue="ScaleTrack-Offer-Export" />
                </div>
                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={e => {
                        if (exportNameRef.current.value) setName(exportNameRef.current.value);
                    }}>Export</div>
                </div>
            </> : <>
                {infoP ? <>
                    <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>{infoP}</p>
                </> : <>
                    <p>Preparing export...{progress ? ` (${progress.current} / ${progress.maximum})` : ""}</p>
                    {progress ? <>
                        <div className="genericModal__wrap__progress">
                            <div className="genericModal__wrap__progress__item" style={{
                                width: `${100 / progress.maximum * progress.current}%`
                            }}></div>
                        </div>
                    </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} />}
                </>}
            </>}
        </div>
    </div>
};

const MarketingOffersReport_showCampaignAds = props => {
    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);


    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Campaign Ads for {props.headline}</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <CampaignAdsModal
                ID={props.ID}
                filters={props.filters}
                allUsers={true}
            />
        </div>
    </div>
};

const MarketingOffersReport_trafficSourceOnly = props => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();

    const wrapRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteType?.theme ?? "dark");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    const getData = () => {
        if (!Array.isArray(props.campaignIDs)) return setData(backendModule.genericError);
        if (props.campaignIDs.length === 0) return setData(backendModule.genericError);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingTrafficSources`,
            data: {
                filters: [
                    {name: "CampaignID", op: "in", value: props.campaignIDs},
                    ...props.filters   
                ],
                orders: orders ? [orders] : []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getData();
    }, [orders]);

    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Report for {props.headline ?? "?"}</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                <FilteredCustomTable
                    orderCB={o => setOrders(o)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Campaign type", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO"]}

                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];
                        let out = [];

                        for (let item of data.data) {
                            out.push([
                                {keyID: item.SourceName, type: "text", text: item.SourceName},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Visits, "Visits")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Conversions, "Conversions")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CR, "CR")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Approved, "Approved")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.AR, "AR")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Spent, "Spent")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CPA, "Spent")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CPAO, "Spent")}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

const MarketingOffersReport_perCampaignType = (props) => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();
    const [newClients, setNewClients] = React.useState();
    const [reportType, setReportType] = React.useState("campaignType");
    const [onlyNewClients, setOnlyNewClients] = React.useState(false);
    const [onlyNewClientsForOffer, setOnlyNewClientsForOffer] = React.useState(true);

    const wrapRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteType?.theme ?? "dark");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    const convertTimeToString = time => {
        time = Number(time);
        if (isNaN(time)) return "-";
        time = Number(time.toFixed(0));

        let seconds = +time;
        let minutes = 0;
        let hours = 0;

        while (seconds >= 60) {
            seconds -= 60;
            minutes++;
        };
        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        };

        if (hours) return `${hours}h : ${minutes}m : ${seconds}s`;
        if (minutes) return `${minutes}m : ${seconds}s`;
        return `${seconds}s`;
    };

    const getData = () => {
        if (!Array.isArray(props.campaignIDs)) return setData(backendModule.genericError);
        if (props.campaignIDs.length === 0) return setData(backendModule.genericError);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingTrafficSources`,
            data: {
                filters: [
                    {name: "CampaignID", op: "in", value: props.campaignIDs},
                    ...props.filters   
                ],
                orders: orders ? [orders] : []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            a1 = a1?.text ?? "";
            b1 = b1?.text ?? "";

            if (headerIndex > 0 && !["Created At"].includes(headers[headerIndex])) {
                a1 = String(a1).split(" ")[0].replace(",", "");
                b1 = String(b1).split(" ")[0].replace(",", "");
            };

            if (Number(a1) && Number(b1)) {
                return Number(a1) > Number(b1) ? asc : desc;
            } else if (headers[headerIndex] === "Created at") {
                return moment(a1).isAfter(moment(b1)) ? asc : desc;
            } else {
                return a1 > b1 ? asc : desc;
            };
        });
    };

    const checkHasAds = integrationType => {
        switch (String(integrationType)) {
            case "0":
            case "1":
                return true;
            default: return false;
        };
    };

    const getIntegrationInfo = IntegrationID => {
        if (!props.allUsers) return null;

        let iID = String(IntegrationID);
        for (let usr of props.allUsers) {
            for (let profile of (Array.isArray(usr?._profiles) ? usr._profiles : [])) {
                if (profile?.ID === iID) {
                    return <p className="route__reports__marketingOffers__filterByIntegration__wrap__content__tableProfileItem">
                        <img src={miscModule.getTrackingProfileImage(profile?.type)} />
                        <p>{profile?.name ?? "-"}</p>
                        <p>{iID}</p>
                    </p>;
                };
            };
        };

        return null;
    };

    const getNewClients = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllOrders`,
            data: {
                CampaignIDs: props.campaignIDs,
                noIntegrationType: true,
                limit: null,
                orders: [["createdAt", "desc"]],
                filters: [
                    ...(props.filters ?? []),
                    (onlyNewClients ? {name: "ClientData.ScalecrmNewClient", op: "eq", value: true} : null),
                    (onlyNewClientsForOffer ? {name: "ClientData.ScalecrmNewClientOffer", op: "eq", value: true} : null),
                    {name: "OfferID", op: "eq", value: props.offerID}
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => setNewClients(res.data)).catch(() => setNewClients(backendModule.genericError));
    };

    const CTAStats = () => {
        const [cta_buttons, setCta_buttons] = React.useState("1,2,3");
        const [cta_data, setCta_data] = React.useState();

        const curDefer = useDefer();
        const timestampRef = React.useRef();

        const getData = async (ts) => {
            if (timestampRef.current !== ts) return;
            let btns = cta_buttons.split(",").map(b => {
                b = Number(b.trim());
                if (isNaN(b)) return null;
                return b;
            }).filter(f => f !== null && f !== undefined);
            if (btns.length === 0) return setCta_data({status: "ok", data: []});

            let tmp = {};
            for (let item of btns) {
                if (timestampRef.current !== ts) return;
                let tmpData = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                    data: {
                        allUsers: true,
                        TableHeaders: [
                            "Visits",
                            "Conversions",
                            "AbandonedR",
                            "Approved",
                            "AR",
                            "ADP"
                        ],
                        CampaignIDs: props.campaignIDs,
                        filters: [
                            ...(Array.isArray(props.filters) ? props.filters : []),
                            {name: "IntegrationParams.CTAButtonID", op: "eq", value: item}
                        ],
                        _o_useDateHints: true
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);
                tmp[item] = tmpData?.status === "ok" ? tmpData.data?.TableData ?? {} : {};
            };
            if (timestampRef.current !== ts) return;
            if (Object.keys(tmp).length === 0) return setCta_data({status: "ok", data: []});

            let totalVisits = 0;
            for (let key of Object.keys(tmp)) {
                totalVisits += +tmp[key]?.Visits ?? 0;
            };

            let out = [];
            for (let key of Object.keys(tmp)) {
                out.push([
                    key,
                    props.prepareTableData(tmp[key].Visits ?? "-", "Visits"),
                    props.prepareTableData(((tmp[key]?.Visits ?? 0) / totalVisits) * 100, "CR"),
                    props.prepareTableData(tmp[key].CR, "CR"),
                    props.prepareTableData(tmp[key].Conversions, "Conversions"),
                    props.prepareTableData(tmp[key].Approved, "Approved"),
                    props.prepareTableData(tmp[key].AR, "AR"),
                    props.prepareTableData(tmp[key].ADP, "ADP")
                ]);
            };
            setCta_data({status: "ok", data: out});
        };

        React.useEffect(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            setCta_data();
            curDefer(() => getData(ts), 1000);
        }, [cta_buttons]);

        return <>
            <div style={{display: "flex", alignItems: "center", gap: "10px", marginBottom: "30px"}}>
                <p>Button IDs</p>
                <StyledInput value={cta_buttons} onChange={e => setCta_buttons(e.target.value)} style={{width: "300px"}}></StyledInput>
            </div>

            <FilteredCustomTable
                accent="#6C5DD3"
                theme={themeSelector}
                headers={["Button", "Visits", "Add to cart %", "Abandoned %", "Conversions", "Approved", "AR", "ADP"]}
                customColumns={(new Array(8)).fill("max-content")}
                style={{columnGap: "40px"}}
                data={(()=>{
                    if (!cta_data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                    if (cta_data?.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "Error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                    let out = [];
                    for (let item of cta_data.data) {
                        out.push(item.map(i => {
                            return {keyID: String(item[0]), type: "text", text: i};
                        }))
                    };

                    if (out.length === 0) out.push([{keyID: "noData-noDat", type: "custom", data: "Nothing to show..."}]);
                    return out;
                })()}
            />
        </>
    };
    
    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getData();
    }, [orders]);

    React.useEffect(() => {
        setOrders();

        if (reportType === "newclients") getNewClients();
    }, [reportType, onlyNewClients, onlyNewClientsForOffer]);

    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Report for {props.headline ?? "?"}</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                <div className="genericTabs" style={{position: "sticky", left: 0}}>
                    <div className={`genericTabs__tab ${reportType === "campaignType" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "campaignType" && setReportType("campaignType")}>Report by campaign type</div>
                    <div className={`genericTabs__tab ${reportType === "trafficSource" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "trafficSource" && setReportType("trafficSource")}>Report by traffic source</div>
                    {props.dateData && <div className={`genericTabs__tab ${reportType === "date" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "date" && setReportType("date")}>Report by date</div>}
                    {props.campaignData && <div className={`genericTabs__tab ${reportType === "campaign" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "campaign" && setReportType("campaign")}>Report by campaign</div>}
                    {props.offerID && <div className={`genericTabs__tab ${reportType === "newclients" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "newclients" && setReportType("newclients")}>Conversions</div>}
                    {props.offerID && <div className={`genericTabs__tab ${reportType === "cta-stats" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "cta-stats" && setReportType("cta-stats")}>CTA stats</div>}
                </div>

                {reportType === "campaignType" && <FilteredCustomTable
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px", overflow: "auto"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Campaign type", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]}

                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(props.data)) {
                            out.push([
                                {keyID: key, type: "text", text: key},
                                {keyID: key, type: "text", text: props.data[key]["Visits"]},
                                {keyID: key, type: "text", text: props.data[key]["Conversions"]},
                                {keyID: key, type: "text", text: props.data[key]["CR"]},
                                {keyID: key, type: "text", text: props.data[key]["Approved"]},
                                {keyID: key, type: "text", text: props.data[key]["AR"]},
                                {keyID: key, type: "text", text: props.data[key]["Spent"]},
                                {keyID: key, type: "text", text: props.data[key]["CPA"]},
                                {keyID: key, type: "text", text: props.data[key]["CPAO"]},
                                {keyID: key, type: "text", text: props.data[key]["ROI"]},
                                {keyID: key, type: "text", text: props.data[key]["ROAS"]},
                                {keyID: key, type: "text", text: props.data[key]["DR"]}
                            ]);
                        };

                        if (orders) out = orderData(out, ["Campaign type", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]);
                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "date" && <FilteredCustomTable
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px", overflow: "auto"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Date", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]}

                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(props.dateData)) {
                            if (props.dateData[key]?.["Visits"] === "0") continue;
                            out.push([
                                {keyID: key, type: "text", text: key},
                                {keyID: key, type: "text", text: props.dateData[key]["Visits"]},
                                {keyID: key, type: "text", text: props.dateData[key]["ARC"]},
                                {keyID: key, type: "text", text: props.dateData[key]["Conversions"]},
                                {keyID: key, type: "text", text: props.dateData[key]["CR"]},
                                {keyID: key, type: "text", text: props.dateData[key]["Approved"]},
                                {keyID: key, type: "text", text: props.dateData[key]["AR"]},
                                {keyID: key, type: "text", text: props.dateData[key]["Spent"]},
                                {keyID: key, type: "text", text: props.dateData[key]["CPA"]},
                                {keyID: key, type: "text", text: props.dateData[key]["CPAO"]},
                                {keyID: key, type: "text", text: props.dateData[key]["ROI"]},
                                {keyID: key, type: "text", text: props.dateData[key]["ROAS"]},
                                {keyID: key, type: "text", text: props.dateData[key]["DR"]}
                            ]);
                        };

                        if (orders) out = orderData(out, ["Date", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]);
                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "campaign" && <FilteredCustomTable
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px", overflow: "auto"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Campaign", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR", "Created at", "Campaign ID", "Account Name"]}

                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(props.campaignData)) {
                            if (props.campaignData[key]?.["Visits"] === "0") continue;
                            out.push([
                                {keyID: key, type: "text", text: !checkHasAds(props.campaignData[key]["IntegrationType"]) ? key : <span className="route__reports__marketingOffers__filterByIntegration__wrap__content__clickable" onClick={() => {
                                    animateBox(<MarketingOffersReport_showCampaignAds
                                        ID={props.campaignData[key]["ID"]}
                                        filters={props.filters}
                                        headline={key}
                                    />);
                                }}><span style={{color: "#9390FF"}}>[Has Ads]</span> {key}</span>},
                                {keyID: key, type: "text", text: props.campaignData[key]["Visits"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["ARC"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["Conversions"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["CR"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["Approved"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["AR"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["Spent"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["CPA"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["CPAO"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["ROI"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["ROAS"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["DR"]},
                                {keyID: key, type: "text", text: moment(props.campaignData[key]["createdAt"]).toDate().toLocaleString()},
                                {keyID: key, type: "text", text: props.campaignData[key]["ID"]},
                                {keyID: key, type: "text", text: getIntegrationInfo(props.campaignData[key]["IntegrationID"])},
                            ]);
                        };

                        if (orders) out = orderData(out, ["Campaign", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR", "Created at", "Campaign ID", "Account Name"]);
                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "trafficSource" && <FilteredCustomTable
                    orderCB={o => setOrders(o)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px", overflow: "auto"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Traffic source", "Average session duration", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO"]}

                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];
                        let out = [];

                        for (let item of data.data) {
                            out.push([
                                {keyID: item.SourceName, type: "text", text: item.SourceName},
                                {keyID: item.SourceName, type: "text", text: convertTimeToString(item.sessionDuration_avg)},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Visits, "Visits")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Conversions, "Conversions")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CR, "CR")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Approved, "Approved")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.AR, "AR")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Spent, "Spent")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CPA, "Spent")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CPAO, "Spent")}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "newclients" && <>
                    <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                            <RadioButton defaultValue={onlyNewClients} onChange={e => e !== onlyNewClients && setOnlyNewClients(e)} />
                            <span>Only new clients</span>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                            <RadioButton defaultValue={onlyNewClientsForOffer} onChange={e => e !== onlyNewClientsForOffer && setOnlyNewClientsForOffer(e)} />
                            <span>Only new clients for offer</span>
                        </div>
                    </div>
                    <FilteredCustomTable
                        orderCB={o => setOrders(o)}
                        accent="#6C5DD3"
                        theme={themeSelector}
                        style={{columnGap: "40px", overflow: "auto"}}
                        customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                        headers={["No.", "Date", "CustomerID", "Lead ID", "New Client", "New Client Offer", "Name"]}

                        data={(()=>{
                            if (!newClients) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                            if (newClients.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];
                            let out = [];

                            let count = 0;
                            for (let item of newClients.data) {
                                count += 1;
                                out.push([
                                    {keyID: item.ID, type: "text", text: `${count}.`},
                                    {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                    {keyID: item.ID, type: "text", text: item?.ClientInfo?.ScalecrmClientID ? <span
                                        className="route__reports__marketingOffers__filterByIntegration__wrap__content__hoverItem"
                                        onClick={() => {
                                            window.open(`https://scale-crm.com/#/modals/viewLeadsByClientID?clientID=${item?.ClientInfo?.ScalecrmClientID}`, "_blank");
                                        }}
                                    >{item?.ClientInfo?.ScalecrmClientID}</span> : <span style={{color: "gray"}}>(none)</span>},
                                    {keyID: item.ID, type: "text", text: item?.ClientInfo?.integration_scalecrm ?? item?.ClientInfo?.integration_scalelead ?? <span style={{color: "gray"}}>(none)</span>},
                                    {keyID: item.ID, type: "text", text: item.ClientInfo?.ScalecrmNewClient ? <span style={{color: themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight}}>Yes</span> : <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>No</span>},
                                    {keyID: item.ID, type: "text", text: item.ClientInfo?.ScalecrmNewClientOffer ? <span style={{color: themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight}}>Yes</span> : <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>No</span>},
                                    {keyID: item.ID, type: "text", text: item.ClientInfo?.FirstLastName ? item.ClientInfo.FirstLastName : `${item.ClientInfo?.FirstName ?? "-"} ${item.ClientInfo?.LastName ?? ""}`},
                                ]);
                            };

                            if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                            return out;
                        })()}
                    />
                </>}

                {reportType === "cta-stats" && <CTAStats />}
            </div>
        </div>
    </div>
};

const MarketingOffersReport_tableWrap_date = React.memo(function MarketingOffersReport_tableWrap_date({
    data,
    compareData,
    getAggregateDataByDate,
    isCompare,
    performSort,
    themeSelector,
    getDates,
    handleOrderCallback,
    localOrder,
    prepareTableData
}) {
    const [selected, setSelected] = React.useState([]);

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    let finalData = React.useMemo(() => {
        setSelected([]);
        if (isCompare) {
            if (!data || !compareData) return [];
            if (data.status === "error" || compareData.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data) return [];
            if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };

        let cDates = getDates(true);
        let cmpCDates = isCompare ? getDates(true, true) : [];
        if (!cDates) return [];

        let out = [];
        for (let date of cDates) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: date, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(date)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== date);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, date];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{date.includes(" ") ? moment(date, "DD-MM-YYYY HH").format("HH:00 A") : moment(date, "DD-MM-YYYY").toDate().toLocaleDateString()}</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Visits", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Visits", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Conversions", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Conversions", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "NC", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "NC", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ARC", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ARC", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Approved", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Approved", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "AR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "AR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "PaymentPerLead", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "PaymentPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerAnyLead", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CostPerAnyLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerLead", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CostPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ADP", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ADP", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROI", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ROI", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROAS", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ROAS", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "DR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "DR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Revenue", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Revenue", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "_RevenueCRM", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "_RevenueCRM", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Spent", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Spent", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Profit", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Profit", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CTR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CTR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CPC", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CPC", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Frequency", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Frequency", {}, compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                });
                continue;
            };
            out.push({
                columns: [
                    {
                        keyID: date, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(date)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== date);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, date];
                                };
                            });
                        }} onDoubleClick={() => {
                            let curDate = null;
                            let startDate = null;
                            let endDate = null;
                            let filters = [];
                            let campaignIDs = [];

                            if (date.includes(" ")) {
                                curDate = moment(date, "DD-MM-YYYY HH");
                                startDate = moment(curDate).startOf("hour");
                                endDate = moment(curDate).endOf("hour");

                                filters.push(...[
                                    {name: "createdAt", op: "pdgeq", value: startDate.toDate().getTime()},
                                    {name: "createdAt", op: "pdleq", value: endDate.toDate().getTime()}
                                ]);
                            } else {
                                curDate = moment(date, "DD-MM-YYYY");
                                startDate = moment(curDate).startOf("day");
                                endDate = moment(curDate).endOf("day");

                                filters.push(...[
                                    {name: "createdAt", op: "dgeq", value: startDate.toDate().getTime()},
                                    {name: "createdAt", op: "dleq", value: endDate.toDate().getTime()}
                                ]);
                            };

                            for (let item of data.data) {
                                if (!campaignIDs.includes(item.ID)) campaignIDs.push(item.ID);
                            };

                            animateBox(<MarketingOffersReport_trafficSourceOnly
                                campaignIDs={campaignIDs}
                                filters={filters}
                                headline={date.includes(" ") ? `${startDate.toDate().toLocaleString()} - ${endDate.toDate().toLocaleString()}` : startDate.toDate().toLocaleDateString()}
                                prepareTableData={(data, column) => prepareTableData(data, column)}
                            />)
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{date.includes(" ") ? moment(date, "DD-MM-YYYY HH").format("HH:00 A") : moment(date, "DD-MM-YYYY").toDate().toLocaleDateString()}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Visits") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Conversions") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "NC") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ARC") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Approved") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "AR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "PaymentPerLead") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerAnyLead") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerLead") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ADP") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROI") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROAS") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "DR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Revenue") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "_RevenueCRM") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Spent") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Profit") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CTR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CPC") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Frequency") }
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["Date", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "ADP", "Profit", "CTR", "CPC", "Frequency"]);
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "NC", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "NC", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ARC", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ARC", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "AR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "AR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ADP", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ADP", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "DR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "DR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "_RevenueCRM", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "_RevenueCRM", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Frequency", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Frequency", {}, compareData), style: {color: "#f4cb7e"} }
                        ]}
                    ]
                });
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "NC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ARC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "AR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ADP") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "DR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "_RevenueCRM") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Frequency") }
                    ]
                });
            };
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData, isCompare, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["Date", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "Profit", "CTR", "CPC", "Frequency"]}
        customHeaders={{
            "Date": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                Date
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate">CR %</span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={494}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "200px",
            ...(new Array(21).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_offer = React.memo(function MarketingOffersReport_tableWrap_offer({
    isSplitSource,
    data,
    compareData,
    getAggregateDataByOffer,
    isCompare,
    performSort,
    themeSelector,
    handleOrderCallback,
    localOrder,

    whatif_edit,
    setWhatif_edit,
    allOffers,
    selectedUser,
    userInfoSelector,
    dateFilters,
    allUsers,
    allTrackingProfilesSelector,
    prepareTableData,

    getCampaignData_offers,
    getCampaignData_offers_dates,
    getCampaignData_offers_campaigns,
    getCamapinData_IDs
}) {
    const [selected, setSelected] = React.useState([]);
    const [offerSearch, setOfferSearch] = React.useState("");

    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    const finalData = React.useMemo(() => {
        setSelected([]);
        if (isCompare) {
            if (!data || !compareData || !allOffers) return [];
            if (data.status === "error" || compareData.status === "error" || allOffers.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data || !allOffers) return [];
            if (data.status === "error" || allOffers.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };

        let flt = {};
        let offerSearchArr = [];
        if (offerSearch) {
            let searchSplit = offerSearch.split(" ").map(s => s.trim().toLowerCase()).filter(s => s);

            if (searchSplit.length > 0) {
                let sites = [];

                for (let offer of (allOffers?.status === "ok" ? allOffers.data : [])) {
                    let found = true;
                    for (let item of searchSplit) {
                        if (
                            !String(offer.ID).toLowerCase().includes(item) &&
                            !String(offer.Name).toLowerCase().includes(item) &&
                            !String(offer.OfferNiche).toLowerCase().includes(item) &&
                            !String(offer.OfferType).toLowerCase().includes(item) &&
                            !String(offer.Country).toLowerCase().includes(item)
                        ) {
                            found = false;
                            break;
                        };
                    };

                    if (found) {
                        sites.push(...(offer?.Sites ?? []));
                        offerSearchArr.push(offer?.ID);
                    };
                };

                sites = [...new Set(sites)];
                if (sites.length > 0) {
                    flt["LandingSiteID"] = sites;
                };
            };
        };

        let tmpOffers = allOffers.data.filter(d => {
            if (offerSearch.length > 0 && !offerSearchArr.includes(d?.ID)) return false;

            if (selectedUser?.UserID?.startsWith("all-team-")) return true;

            if (!selectedUser || !selectedUser?.UserID) {
                let userID = userInfoSelector?.ID;
                if (userID) {
                    if (!d.ResponsiblePerson?.find?.(rp => rp?.ID === userID)) return false;
                };
                return true;
            };
            return !!d.ResponsiblePerson?.find?.(rp => rp?.ID === selectedUser.UserID);
        });
        if (isSplitSource) {
            let tmpOffers2 = [];

            for (let offer of tmpOffers) {
                let integrations = [];
                if (!Array.isArray(offer.ResponsiblePerson)) offer.ResponsiblePerson = [];
                for (let rp of offer.ResponsiblePerson) {
                    if (!integrations.includes(rp.IntegrationID)) integrations.push(rp.IntegrationID);
                };

                for (let int of integrations) {
                    let curIntegration = null;
                    for (let item of supportedIntegrationsSelector) {
                        if (item.Type == int) {
                            curIntegration = item.Name;
                            break;
                        };
                    };
                    if (!curIntegration) continue;
                    let final = {...offer};
                    final._filters = {IntegrationType: int};
                    final.ID = `${final.ID} - ${int}`;
                    final.Name = `${final.Name} - ${curIntegration}`;

                    tmpOffers2.push(final);
                };
            };

            tmpOffers = tmpOffers2;
        };

        let out = [];
        for (let offer of tmpOffers) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: offer.ID, type: "custom", data: curID => <p className="genericTableWrap__campaignWithImg" onDoubleClick={() => {
                                let outData = getCampaignData_offers(offer);
                                if (!outData) return;

                                let dateData = getCampaignData_offers_dates(offer);
                                let campaignData = getCampaignData_offers_campaigns(offer);

                                let campaignIDs = getCamapinData_IDs(offer);
                                animateBox(<MarketingOffersReport_perCampaignType
                                    data={outData}
                                    dateData={dateData}
                                    campaignData={campaignData}
                                    headline={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}
                                    campaignIDs={campaignIDs}
                                    allUsers={allUsers?.status === "ok" ? [
                                        {
                                            ID: userInfoSelector.ID,
                                            Team: userInfoSelector?.Team ?? null,
                                            _profiles: allTrackingProfilesSelector
                                        },
                                        ...(allUsers?.data ? allUsers.data : [])
                                    ] : []}
                                    filters={(()=>{
                                        let trackFilters = [];
                                        if (dateFilters) {
                                            if (dateFilters?.start && dateFilters?.end) {
                                                trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                                                trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
                                            };
                                        };
                                        return trackFilters;
                                    })()}
                                    prepareTableData={(data, column) => prepareTableData(data, column)}
                                />)
                            }} onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(offer?.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== offer?.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, offer?.ID];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}>{curID}. {offer.Name} ({offer.OfferType ?? "-"}, {offer.Country ?? "?"})</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Visits", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Visits", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Conversions", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Conversions", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "NCO", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "NCO", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CR", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CR", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ARC", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ARC", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Approved", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Approved", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "AR", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "AR", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "PaymentPerLead", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "PaymentPerLead", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerAnyLead", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerAnyLead", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerLead", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerLead", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ADP", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ADP", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROI", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROI", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROAS", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROAS", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "DR", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "DR", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Revenue", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Revenue", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "_RevenueCRM", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "_RevenueCRM", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Spent", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Spent", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Profit", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Profit", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CTR", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CTR", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CPC", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CPC", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Frequency", offer._filters ?? {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Frequency", offer._filters ?? {}, compareData), style: {color: "#f4cb7e"}},
                        ] }
                    ]
                })
                continue;
            };
            out.push({
                columns: [
                    {
                        keyID: offer.ID, type: "custom", data: curID => <p className="genericTableWrap__campaignWithImg" onDoubleClick={() => {
                            let outData = getCampaignData_offers(offer);
                            if (!outData) return;
                            
                            let dateData = getCampaignData_offers_dates(offer);
                            let campaignData = getCampaignData_offers_campaigns(offer);
                            
                            let campaignIDs = getCamapinData_IDs(offer);
                            animateBox(<MarketingOffersReport_perCampaignType
                                offerID={offer.ID}
                                data={outData}
                                dateData={dateData}
                                campaignData={campaignData}
                                headline={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}
                                campaignIDs={campaignIDs}
                                allUsers={allUsers?.status === "ok" ? [
                                    {
                                        ID: userInfoSelector.ID,
                                        Team: userInfoSelector?.Team ?? null,
                                        _profiles: allTrackingProfilesSelector
                                    },
                                    ...(allUsers?.data ? allUsers.data : [])
                                ] : []}
                                filters={(()=>{
                                    let trackFilters = [];
                                    if (dateFilters) {
                                        if (dateFilters?.start && dateFilters?.end) {
                                            trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                                            trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
                                        };
                                    };
                                    return trackFilters;
                                })()}
                                prepareTableData={(data, column) => prepareTableData(data, column)}
                            />)
                        }} onClick={(e) => {
                            let t = e.target;
                                setSelected(s => {
                                    if (s.includes(offer?.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== offer?.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, offer?.ID];
                                    };
                                });
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}>{curID}. {offer.Name} ({offer.OfferType ?? "-"}, {offer.Country ?? "?"})</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Visits", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Conversions", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "NCO", offer._filters ?? {}) },
                    (whatif_edit ? {
                        keyID: offer.ID,
                        type: "custom",
                        data: <StyledInput
                            type="number"
                            step=".01"
                            style={{height: "17px", width: "120px"}}
                            value={whatif_edit?.[offer?.ID]?.["CR"] ?? getAggregateDataByOffer(offer, "CR", offer._filters ?? {}).split(" ")[0]}
                            onChange={e => {
                                let val = e?.target?.value;
                                if (val === null || val === undefined) return;
                                val = Number(val);
                                if (isNaN(val)) return;
                                if (val < 0 || val > 100) return;

                                let wf = {...whatif_edit};
                                if (!wf[offer?.ID]) wf[offer?.ID] = {};
                                wf[offer?.ID]["CR"] = val;

                                let totalVisits = getAggregateDataByOffer(offer, "Visits", offer._filters ?? {});
                                totalVisits = Number(totalVisits.replace(",", ""));
                                wf[offer?.ID]["Conversions"] = (val === 0) ? 0 : Number(totalVisits / 100 * val).toFixed(0);
                                wf[offer?.ID]["ARC"] = wf[offer?.ID]["Conversions"];

                                // also include Approved/AR
                                let totalConversions = wf[offer?.ID]["Conversions"];
                                let totalAR = getAggregateDataByOffer(offer, "AR", offer._filters ?? {});
                                totalAR = Number(totalAR.split(" ")[0].replace(",", ""));
                                wf[offer?.ID]["Approved"] = (totalAR === 0) ? 0 : Number(totalConversions / 100 * totalAR).toFixed(0);

                                setWhatif_edit(wf);
                            }}
                        />
                    } : { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CR", offer._filters ?? {}) }),
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ARC", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Approved", offer._filters ?? {}) },
                    (whatif_edit ? {
                        keyID: offer.ID,
                        type: "custom",
                        data: <StyledInput
                            type="number"
                            step=".01"
                            style={{height: "17px", width: "120px"}}
                            value={whatif_edit?.[offer?.ID]?.["AR"] ?? getAggregateDataByOffer(offer, "AR", offer._filters ?? {}).split(" ")[0]}
                            onChange={e => {
                                let val = e?.target?.value;
                                if (val === null || val === undefined) return;
                                val = Number(val);
                                if (isNaN(val)) return;
                                if (val < 0 || val > 100) return;

                                let wf = {...whatif_edit};
                                if (!wf[offer?.ID]) wf[offer?.ID] = {};
                                wf[offer?.ID]["AR"] = val;

                                let totalVisits = getAggregateDataByOffer(offer, "Conversions", offer._filters ?? {});
                                totalVisits = Number(totalVisits.replace(",", ""));
                                wf[offer?.ID]["Approved"] = (val === 0) ? 0 : Number(totalVisits / 100 * val).toFixed(0);

                                setWhatif_edit(wf);
                            }}
                        />
                    } : { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "AR", offer._filters ?? {}) }),
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "PaymentPerLead", offer._filters ?? {}) },
                    (whatif_edit ? {
                        keyID: offer.ID,
                        type: "custom",
                        data: <StyledInput
                            type="number"
                            step=".01"
                            style={{height: "17px", width: "120px"}}
                            value={whatif_edit?.[offer?.ID]?.["CostPerAnyLead"] ?? getAggregateDataByOffer(offer, "CostPerAnyLead", offer._filters ?? {}).split(" ")[0].replace(",", "")}
                            onChange={e => {
                                let val = e?.target?.value;
                                if (val === null || val === undefined) return;
                                val = Number(val);
                                if (isNaN(val)) return;
                                if (val < 0) return;

                                let wf = {...whatif_edit};
                                if (!wf[offer?.ID]) wf[offer?.ID] = {};
                                wf[offer?.ID]["CostPerAnyLead"] = val;

                                setWhatif_edit(wf);
                            }}
                        />
                    } : { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerAnyLead", offer._filters ?? {}) }),
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerLead", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ADP", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROI", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROAS", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "DR", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Revenue", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "_RevenueCRM", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Spent", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Profit", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CTR", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CPC", offer._filters ?? {}) },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Frequency", offer._filters ?? {}) }
                ]
            });
        };

        if (out.length > 0 && !whatif_edit) {
            out = performSort(out, ["Offer", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "ADP", "Profit", "CTR", "CPC", "Frequency"]);
            let index = 1;
            for (let offer of out) {
                try {
                    offer.columns[0].data = offer.columns[0].data(index);
                } catch (e) {};

                index += 1;
            };
            out = out.filter(t => {
                if (String(t.columns[1].text) === "0") return false;
                return true;
            });
            
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Visits", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Visits", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Conversions", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Conversions", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "NCO", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "NCO", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ARC", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ARC", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Approved", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Approved", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "AR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "AR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "PaymentPerLead", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "PaymentPerLead", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerAnyLead", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerAnyLead", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerLead", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerLead", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ADP", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ADP", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROI", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROI", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROAS", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROAS", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "DR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "DR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Revenue", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Revenue", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "_RevenueCRM", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "_RevenueCRM", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Spent", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Spent", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Profit", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Profit", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CTR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CTR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CPC", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CPC", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Frequency", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Frequency", flt, compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                })
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Visits", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Conversions", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "NCO", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ARC", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Approved", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "AR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "PaymentPerLead", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerAnyLead", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerLead", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ADP", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROI", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROAS", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "DR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Revenue", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "_RevenueCRM", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Spent", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Profit", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CTR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CPC", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Frequency", flt) }
                    ]
                });
            };
        } else if (out.length === 0) {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };
        return out;
    }, [data, compareData, isCompare, whatif_edit, offerSearch, localOrder, isSplitSource]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["Offer", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "Profit", "CTR", "CPC", "Frequency"]}
        customHeaders={{
            "Offer": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                <FilterBySearch placeholder="Search..." style={{width: "calc(100% - 60px)", height: "40px"}} onChange={e => setOfferSearch(e)} onClick={e => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                }} />
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <span>CR %</span>
                {(!whatif_edit && !isCompare) && <img src="/images/icon_edit.svg" style={{width: "17px", height: "17px"}} onClick={e => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setWhatif_edit({});
                }} />}
            </span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={494}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "300px",
            ...(new Array(21).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_team = React.memo(function MarketingOffersReport_tableWrap_team({
    data,
    compareData,
    themeSelector,
    allUsersWithMe,
    isCompare,
    getAggregateDataByUser,
    allUsers,
    performSort,
    handleOrderCallback,
    dateFilters,
    localOrder
}) {
    const [selected, setSelected] = React.useState([]);

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    const VerifySpent = (props2) => {
        const [data, setData] = React.useState();

        const getApprovedSpentData = async (userID) => {
            if (props2.dateFilters === undefined || props2.dateFilters === null) return null;
    
            let tmp = [userID];
            if (tmp.length === 0) return null;
    
            let filters = [];
            if (props2.dateFilters) {
                if (props2.dateFilters?.start && props2.dateFilters?.end) {
                    filters.push({ name: "Date", op: "dgeq", value: moment(props2.dateFilters.start).add(12, "hours").toDate().getTime() });
                    filters.push({ name: "Date", op: "dleq", value: moment(props2.dateFilters.end).toDate().getTime() });
                };
            };
    
            return await axios({
                method: "POST",
                url: `${backendModule.backendURL}/approvedSpendsPerAccount/getApprovedSpends`,
                data: {
                    allUsers: true,
                    filters: [
                        ...filters,
                        {name: "CreatedBy", op: "in", value: tmp}
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    let totalSpent = 0;
                    for (let s of res.data.data) {
                        totalSpent += +s.Amount;
                    };
    
                    let dataSpent = getAggregateDataByUser(userID, "Spent", props2.data);
                    dataSpent = Number(String(dataSpent).split(" ")[0].replace(",", ""));
    
                    if (dataSpent === 0 && totalSpent === 0) return null;
                    
                    if (Number(totalSpent).toFixed(0) >= Number(dataSpent).toFixed(0)) {
                        return true;
                    } else {
                        return false;
                    };
                } else {
                    return false;
                };
            }).catch(() => {
                return null;
            });
        };

        React.useEffect(() => {
            getApprovedSpentData(props2.ID).then(setData);
        }, []);

        if (data === undefined) return <Spinner style={{width: "18px", height: "18px"}} color={props2.themeSelector === "dark" ? "white" : "black"} />
        return <span style={{
            color: data === null ? null : (data ? (props2.themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (props2.themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight))
        }}>{props2.Spent}</span>
    };

    const finalData = React.useMemo(() => {
        if (isCompare) {
            if (!data || !compareData || !allUsersWithMe) return [];
            if (data.status === "error" || compareData.status === "error" || allUsersWithMe.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data || !allUsersWithMe) return [];
            if (data.status === "error" || allUsersWithMe.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };
        let out = [];
        for (let usr of allUsersWithMe.data) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: usr.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(usr.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== usr.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, usr.ID];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={usr.Username}>{allUsers.data.indexOf(usr) + 1}. {usr.Username}</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Visits", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Conversions", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "NCO", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "NCO", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ARC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Approved", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "AR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "AR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "PaymentPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerAnyLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ADP", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROI", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROAS", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "DR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "DR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Revenue", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "_RevenueCRM", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "_RevenueCRM", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Spent", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Profit", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CTR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CPC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Frequency", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Frequency", compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                })
                continue;
            };

            out.push({
                columns: [
                    {
                        keyID: usr.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(usr.ID)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== usr.ID);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, usr.ID];
                                };
                            });
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={usr.Username}>{allUsers.data.indexOf(usr) + 1}. {usr.Username}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Visits") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Conversions") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "NCO") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ARC") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Approved") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "AR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "PaymentPerLead") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerAnyLead") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerLead") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ADP") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROI") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROAS") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "DR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Revenue") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "_RevenueCRM") },
                    { keyID: usr.ID, type: "text", text: <VerifySpent Spent={getAggregateDataByUser(usr.ID, "Spent")} ID={usr.ID} themeSelector={themeSelector} dateFilters={dateFilters} data={data} /> },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Profit") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CTR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CPC") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Frequency") }
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["User", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Spent", "ADP", "Profit", "CTR", "CPC", "Frequency"]);
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Visits", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Conversions", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "NCO", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "NCO", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ARC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Approved", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "AR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "AR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "PaymentPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerAnyLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ADP", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROI", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROAS", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "DR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "DR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Revenue", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "_RevenueCRM", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "_RevenueCRM", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Spent", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Profit", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CTR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CPC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Frequency", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Frequency", compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                })
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Visits") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Conversions") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "NCO") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ARC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Approved") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "AR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "PaymentPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerAnyLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ADP") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROI") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROAS") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "DR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Revenue") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "_RevenueCRM") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Spent") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Profit") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CTR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CPC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Frequency") }
                    ]
                })
            };
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["User", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "Profit", "CTR", "CPC", "Frequency"]}
        customHeaders={{
            "User": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                User
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate">CR %</span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={494}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "200px",
            ...(new Array(21).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_portal = React.memo(function MarketingOffersReport_tableWrap_portal({
    data,
    compareData,
    getAggregateDataByPortal,
    isCompare,
    performSort,
    themeSelector,
    getDates,
    getPortals,
    handleOrderCallback,
    localOrder,
    prepareTableData
}) {
    const [selected, setSelected] = React.useState([]);

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    let finalData = React.useMemo(() => {
        setSelected([]);
        if (isCompare) {
            if (!data || !compareData) return [];
            if (data.status === "error" || compareData.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data) return [];
            if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };

        let cPortals = getPortals();

        let out = [];
        for (let portal of cPortals) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: portal, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(portal)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== portal);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, portal];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{portal}</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Visits", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Conversions", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "NCO", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "NCO", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ARC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Approved", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "AR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "AR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "PaymentPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CostPerAnyLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CostPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ADP", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ROI", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ROAS", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "DR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "DR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Revenue", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "_RevenueCRM", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "_RevenueCRM", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Spent", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Profit", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CTR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CPC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: portal, type: "group", group: [
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Frequency", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Frequency", compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                });
                continue;
            };
            out.push({
                columns: [
                    {
                        keyID: portal, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(portal)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== portal);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, portal];
                                };
                            });
                        }} >
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{portal}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Visits") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Conversions") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "NCO") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CR") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ARC") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Approved") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "AR") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "PaymentPerLead") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CostPerAnyLead") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CostPerLead") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ADP") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ROI") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "ROAS") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "DR") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Revenue") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "_RevenueCRM") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Spent") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Profit") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CTR") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "CPC") },
                    { keyID: portal, type: "text", text: getAggregateDataByPortal(portal, "Frequency") }
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["Portal", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "ADP", "Profit", "CTR", "CPC", "Frequency"]);
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Visits", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Conversions", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "NCO", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "NCO", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ARC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Approved", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "AR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "AR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "PaymentPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CostPerAnyLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CostPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ADP", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ROI", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ROAS", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "DR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "DR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Revenue", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "_RevenueCRM", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "_RevenueCRM", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Spent", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Profit", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CTR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CPC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Frequency", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Frequency", {}, compareData), style: {color: "#f4cb7e"} }
                        ]}
                    ]
                });
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Visits") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Conversions") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "NCO") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ARC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Approved") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "AR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "PaymentPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CostPerAnyLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CostPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ADP") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ROI") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "ROAS") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "DR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Revenue") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "_RevenueCRM") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Spent") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Profit") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CTR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "CPC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByPortal(null, "Frequency") }
                    ]
                });
            };
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData, isCompare, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["Portal", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Revenue CRM", "Spent", "Profit", "CTR", "CPC", "Frequency"]}
        customHeaders={{
            "Portal": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                Portal
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate">CR %</span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={494}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "200px",
            ...(new Array(21).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_campaigns = React.memo(function MarketingOffersReport_tableWrap_campaigns({
    data,
    compareData,
    getAggregateDataByCampaign,
    isCompare,
    performSort,
    themeSelector,
    getCampaigns,
    handleOrderCallback,
    localOrder,
    prepareTableData,
    dateFilters
}) {
    const [selected, setSelected] = React.useState([]);
    const [campaignSearch, setCampaignSearch] = React.useState("");

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    const DailyBudget = (props2) => {
        const [bData, setBData] = React.useState();
        const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

        React.useState(() => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getDailyBudgetForCampaign`,
                data: {
                    ID: props2.ID
                },
                ...backendModule.axiosConfig
            }).then(res => setBData(res.data)).catch(() => setBData(backendModule.genericError));
        }, []);

        return bData ? <>
            {prepareTableData(bData.status === "ok" ? (bData.data ?? 0) : 0, "Spent")}
        </> : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />
    };

    const TotalDailyBudget = props2 => {
        const [total, setTotal] = React.useState();

        const getData = async () => {
            let t = 0;
            for (let campaign of props2.data) {
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getDailyBudgetForCampaign`,
                    data: {
                        ID: campaign.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        t += Number(res.data.data);
                    };
                }).catch(() => null);
            };

            setTotal(t);
        };

        React.useEffect(() => {
            getData();
        }, []);

        if (total === undefined) return <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />;
        return prepareTableData(total, "Spent");
    };

    let finalData = React.useMemo(() => {
        setSelected([]);
        if (isCompare) {
            if (!data || !compareData) return [];
            if (data.status === "error" || compareData.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data) return [];
            if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };

        let cCampaigns = getCampaigns();

        let out = [];
        let s = campaignSearch.toLowerCase();
        for (let campaign of cCampaigns) {
            if (s) {
                if (!campaign.Name.toLowerCase().includes(s)) continue;
            };
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: campaign.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(campaign.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== campaign.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, campaign.ID];
                                    };
                                });
                            }} onDoubleClick={() => {
                                animateBox(<CampaignDashboard
                                    integration={campaign.item.IntegrationType}
                                    integrationID={campaign.item.IntegrationID}
                                    item={campaign.item}
                                    datePreset={dateFilters}
                                />);
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{campaign.Name}</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: campaign.ID, type: "text", text: campaign.Active ? "Active" : "Inactive", style: {color: campaign.Active ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)} },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Visits", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Conversions", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "NCO", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "NCO", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ARC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Approved", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "AR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "AR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "PaymentPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CostPerAnyLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CostPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ADP", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ROI", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ROAS", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "DR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "DR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "text", text: <DailyBudget key={`marketing-report-dailybudget-${campaign.ID}`} ID={campaign.ID} /> },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Revenue", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "_RevenueCRM", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "_RevenueCRM", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Spent", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Profit", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CTR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CPC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "LTV", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "LTV", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "group", group: [
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Frequency", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Frequency", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: campaign.ID, type: "text", text: moment(campaign.createdAt).toDate().toLocaleString() }
                    ]
                });
                continue;
            };
            out.push({
                columns: [
                    {
                        keyID: campaign.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(campaign.ID)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== campaign.ID);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, campaign.ID];
                                };
                            });
                        }} onDoubleClick={() => {
                            animateBox(<CampaignDashboard
                                integration={campaign.item.IntegrationType}
                                integrationID={campaign.item.IntegrationID}
                                item={campaign.item}
                                datePreset={dateFilters}
                            />);
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{campaign.Name}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: campaign.ID, type: "text", text: campaign.Active ? "Active" : "Inactive", style: {color: campaign.Active ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)} },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Visits") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Conversions") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "NCO") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CR") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ARC") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Approved") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "AR") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "PaymentPerLead") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CostPerAnyLead") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CostPerLead") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ADP") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ROI") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "ROAS") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "DR") },
                    { keyID: campaign.ID, type: "text", text: <DailyBudget key={`marketing-report-dailybudget-${campaign.ID}`} ID={campaign.ID} /> },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Revenue") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "_RevenueCRM") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Spent") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Profit") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CTR") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "CPC") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "LTV") },
                    { keyID: campaign.ID, type: "text", text: getAggregateDataByCampaign(campaign.ID, "Frequency") },
                    { keyID: campaign.ID, type: "text", text: moment(campaign.createdAt).toDate().toLocaleString() }
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["Campaign", "Status", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Daily budget", "Revenue", "Revenue CRM", "Spent", "ADP", "Profit", "CTR", "CPC", "LTV", "Frequency", "Created at"]);
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Visits", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Conversions", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "NCO", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "NCO", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ARC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Approved", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "AR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "AR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "PaymentPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CostPerAnyLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CostPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ADP", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ROI", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ROAS", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "DR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "DR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "text", text: <TotalDailyBudget data={cCampaigns} /> },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Revenue", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "_RevenueCRM", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "_RevenueCRM", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Spent", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Profit", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CTR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CPC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "LTV", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "LTV", compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Frequency", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Frequency", compareData), style: {color: "#f4cb7e"} }
                        ]}
                    ]
                });
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: "" },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Visits") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Conversions") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "NCO") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ARC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Approved") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "AR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "PaymentPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CostPerAnyLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CostPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ADP") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ROI") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "ROAS") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "DR") },
                        { keyID: "footer-1", type: "text", text: <TotalDailyBudget data={cCampaigns} /> },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Revenue") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "_RevenueCRM") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Spent") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Profit") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CTR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "CPC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "LTV") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByCampaign(null, "Frequency") }
                    ]
                });
            };
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData, isCompare, campaignSearch, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["Campaign", "Status", "Visits", "Leads", "NC", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Daily budget", "Revenue", "Revenue CRM", "Spent", "Profit", "CTR", "CPC", "LTV", "Frequency", "Created at"]}
        customHeaders={{
            "Campaign": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                <FilterBySearch placeholder="Search..." style={{width: "calc(100% - 60px)", height: "40px"}} onChange={e => setCampaignSearch(e)} onClick={e => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                }} />
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate">CR %</span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={494}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "400px",
            ...(new Array(24).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_additionalSpents = React.memo(function MarketingOffersReport_tableWrap_additionalSpents({
    allAdditionalSpents,
    themeSelector,
    performSort,
    localOrder,
    handleOrderCallback,
    prepareTableData
}) {
    const [selected, setSelected] = React.useState([]);
    const [spentSearch, setSpentSearch] = React.useState("");

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    let finalData = React.useMemo(() => {
        setSelected([]);
        let out = [];
        let s = spentSearch.toLowerCase();
        
        for (let item of allAdditionalSpents) {
            let searchStrings = [];
            if (s) {
                searchStrings.push(moment(item.Date).toDate().toLocaleDateString());
                if (item.UserID) searchStrings.push(item._User?.Username);
                if (item.OfferID) searchStrings.push(`${item._Offer?.OfferName} (${item._Offer?.OfferType}, ${item._Offer?.Country ?? "-"})`);
                if (item.CampaignID) searchStrings.push(item._Campaign?.CampaignName);

                let found = false;
                for (let ss of searchStrings) {
                    if (ss.toLowerCase().includes(s)) {
                        found = true;
                        break;
                    };
                };
                if (!found) continue;
            };

            let boundTo = <span style={{color: "rgb(108, 93, 211)"}}>User</span>;
            if (item.CampaignID) {
                boundTo = <><span style={{color: "rgb(108, 93, 211)"}}>User, Campaign</span> {`-> ${item._Campaign?.CampaignName ?? "-"}`}</>
            } else if (item.OfferID) {
                boundTo = <><span style={{color: "rgb(108, 93, 211)"}}>User, Offer</span> {`-> ${item._Offer?.OfferName} (${item._Offer?.Country ?? "-"}, ${item._Offer?.OfferType ?? "-"})`}</>
            };

            out.push({
                columns: [
                    {
                        keyID: item.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(item.ID)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== item.ID);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, item.ID];
                                };
                            });
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{moment(item.Date).toDate().toLocaleDateString()}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    {keyID: item.ID, type: "text", text: item._User?.Username ?? "-"},
                    {keyID: item.ID, type: "text", text: prepareTableData(item.Spent, "Spent")},
                    {keyID: item.ID, type: "text", text: boundTo}
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["Date", "User", "Spent", "Bound to"]);
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [allAdditionalSpents, spentSearch, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={true}
        headers={["Date", "User", "Spent", "Bound to"]}
        customHeaders={{
            "Date": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                <FilterBySearch placeholder="Search..." style={{width: "calc(100% - 60px)", height: "40px"}} onChange={e => setSpentSearch(e)} onClick={e => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                }} />
            </div>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={494}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={false}
        customColumns={[
            "200px",
            ...(new Array(3).fill("max-content"))
        ]}
        data={finalData}
    />
});



const MarketingOffersReport_botDetectionAI = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(true);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const getBotData = () => {
        let filters = [];
        if (props.dateFilters) {
            filters.push(...[
                {name: "createdAt", op: "pdgeq", value: props.dateFilters.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: props.dateFilters.end.toDate().getTime()}
            ]);
        };

        let allIDs = [];
        for (let item of props.data.data) {
            if (!allIDs.includes(item.ID)) allIDs.push(item.ID);
        };
        if (allIDs.length === 0) return setData(backendModule.genericError);
        filters.push({name: "CampaignID", op: "in", value: allIDs});


        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/botDetection/botCheck`,
            data: {
                filters,
                returnIDs: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            return setData(res.data);
        }).catch(() => {
            return setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const calculatePercent = (current, total) => {
        let tmp = `(0.00 %)`;
        current = Number(current);
        total = Number(total);

        if (isNaN(current) || isNaN(total)) return tmp;
        if (!current || !total) return tmp;

        return `(${Number((current / total) * 100).toFixed(2)} %)`;
    };

    const sortObjectTop5 = obj => {
        let keys = Object.keys(obj);
        keys = keys.sort((a, b) => {
            if (obj[a] > obj[b]) return -1;
            return 1;
        });

        let out = [];
        for (let key of keys.splice(0, 5)) {
            out.push(<p>{key} <span>(seen {obj[key]} times)</span></p>)
        };

        return out;
    };

    const exportData = (data, name) => {
        if (data.length === 0) return;

        let headers = Object.keys(data[0]);

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet(name);

        const convertTime = (time) => {
            let totalSeconds = Math.floor(time);
            let s = totalSeconds % 60;
            let m = Math.floor(totalSeconds / 60);

            let out = [];
            if (m) {
                out.push(`${m}m`);
                out.push(`${s}s`);
            } else if (s) {
                out.push(`${s}s`);
            };

            return out.join(" ");
        };

        sheet.addTable({
            name: name,
            "ref": "A1",
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: headers.map(hi => { return { name: hi } }),
            rows: data.map(c => {
                let out = [];
                for (let item of headers) {
                    if (item === "firstInteraction" || item === "sessionDuration") {
                        if (item === "firstInteraction") c[item] = c[item] ? c[item] / 1000 : null
                        out.push(convertTime(c[item]));
                    } else {
                        out.push(c[item]);
                    };
                };
                return out;
            })
        });
        sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        let fileName = `Export-bot-detection-ai-${name}`;
        fileName += ".xlsx";

        workbook.xlsx.writeBuffer().then(data => {
            let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);

        }).catch(() => null);
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getBotData();
    }, []);

    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Bot detection AI</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                {spinner ? <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} /> : <>
                    {data ? data.status === "ok" ? <div className="route__admin__botDetection__data">
                        <p>Total sessions: <span>{data.data.stats.totalRequests.length}</span></p>
                        <br />

                        {/* <p style={{display: "flex", alignItems: "center"}}>Sessions without interaction: <span>{data.data.stats.hasNoInteraction.length} {calculatePercent(data.data.stats.hasNoInteraction.length, data.data.stats.totalRequests.length)}</span> <img title="How many sessions haven't had any interactions. For an interaction to count, user must click on the page, scroll or hover over an element of the page." src="/images/question_mark.svg" /></p> */}
                        <p>Zero-duration sessions: <span>{data.data.stats.sessionDurationLessThan_0s.length} {calculatePercent(data.data.stats.sessionDurationLessThan_0s.length, data.data.stats.totalRequests.length)}</span></p>
                        <p>Sessions with duration &lt; 2s: <span>{data.data.stats.sessionDurationLessThan_2s.length} {calculatePercent(data.data.stats.sessionDurationLessThan_2s.length, data.data.stats.totalRequests.length)}</span></p>
                        <p>Total suspicious sessions: <span>{data.data.suspicious.length} {calculatePercent(data.data.suspicious.length, data.data.stats.totalRequests.length)}</span>  {data.data.suspicious.length > 0 && <StyledButton style={{height: "100%"}} onClick={() => exportData(data.data.suspicious, "Bot-suspicious")}>Export</StyledButton>}</p>

                        {Object.keys(data.data.repeatings.repeatingIPSuspicious).length > 0 && <>
                            <br />
                            <h4 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Top 5 suspicious IPs</h4>
                            {sortObjectTop5(data.data.repeatings.repeatingIPSuspicious)}
                        </>}

                        {Object.keys(data.data.repeatings.repeatingUserAgentSuspicious).length > 0 && <>
                            <br />
                            <h4 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Top 5 suspicious User Agents</h4>
                            {sortObjectTop5(data.data.repeatings.repeatingUserAgentSuspicious)}
                        </>}
                    </div> : <p>There was an error while fetching data</p> : null}
                </>}
            </div>
        </div>
    </div>
};

export default MarketingOffersReport;