import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import { getParamsFromURLObject } from "../../../modules/urlModule";

import OrganicCampaigns from "./Organic";
import FacebookCampaigns from "./Facebook";
import SMSMarketingCampaigns from "./SMSMarketing";
import GenericControllerCampaigns from "./GenericController";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";

const UserCampaigns = () => {
    const profilesSelector = useSelector(state => state?.trackingProfiles ?? {});
    const curProfile = profilesSelector?.profiles?.find(p => p.ID === profilesSelector?.selectedProfile);
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const [searchFilter, setSearchFilter] = React.useState("");
    const [campaignKeywordFilters, setCampaignKeywordFilters] = React.useState([]);
    const [dateFilter, setDateFilter] = React.useState();
    const [campaignDateFilter, setCampaignDateFilter] = React.useState();
    const [campaignFunctions, setCampaignFunctions] = React.useState();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [compareEnabled, setCompareEnabled] = React.useState(false);
    const [urlParams, setURLParams] = React.useState({});

    const getCampaignType = () => {
        if (!curProfile) return;

        let props = {
            key: `campaigns-list-${curProfile?.ID}`,
            functionsCB: e => setCampaignFunctions(e),

            campaignKeywordFilters,
            searchFilter,
            dateFilter,
            campaignDateFilter,
            compareEnabled
        };

        switch (curProfile?.type) {
            case "scale-track": return <OrganicCampaigns {...props} />
            case "scale-track-sms": return <SMSMarketingCampaigns {...props} />
            case "scale-track-social": return <GenericControllerCampaigns options={{
                CPC: false,
                UntaggedTraffic: false,
                PreLandingPage: false,
                SetActive: false,

                canRemove: false,
                canClone: false,
                canTransfer: false,
                canToggle: false,
                canEdit: false
            }} integrationID="-3" {...props} />
            case "midas": return <GenericControllerCampaigns options={{
                CPC: true,
                UntaggedTraffic: false,
                PreLandingPage: true,
                SetActive: true,

                canRemove: true,
                canClone: true,
                canTransfer: true,
                canToggle: true,

                hasUTM: false,
                utmString: "midas",
                columnString: "midas",
                columnShorthand: "MD",
                canEdit: (userInfoSelector?.Flags?.isAdmin || urlParams?.["_admin-viewbyid"]) ? true : false,
                bulkEditColumnsAllowed: [
                    {name: "Spent", value: "Spent"}
                ]
            }} integrationID={curProfile?.ID} {...props} integrationType={4} />
            case "adnow": return <GenericControllerCampaigns options={{
                CPC: true,
                UntaggedTraffic: false,
                PreLandingPage: true,
                SetActive: true,

                canRemove: true,
                canClone: true,
                canTransfer: true,
                canToggle: true,

                hasUTM: false,
                utmString: "adnow",
                columnString: "adnow",
                columnShorthand: "AN",
                canEdit: true,
                bulkEditColumnsAllowed: [
                    {name: "Spent", value: "Spent"}
                ]
            }} integrationID={curProfile?.ID} {...props} integrationType={5} />
            case "mgid": return <GenericControllerCampaigns options={{
                CPC: false,
                UntaggedTraffic: false,
                PreLandingPage: true,
                SetActive: true,

                canRemove: true,
                canClone: true,
                canTransfer: true,
                canToggle: true,

                hasUTM: true,
                utmString: "mgid",
                columnString: "mgid",
                columnShorthand: "MG",
                canEdit: true
            }} integrationID={curProfile?.ID} {...props} integrationType={1} />
            case "tiktok": return <GenericControllerCampaigns options={{
                CPC: false,
                UntaggedTraffic: false,
                PreLandingPage: true,
                SetActive: true,

                canRemove: true,
                canClone: true,
                canTransfer: true,
                canToggle: true,

                hasUTM: true,
                utmString: "tiktok",
                columnString: "tiktok",
                columnShorthand: "TT",
                canEdit: true
            }} integrationID={curProfile?.ID} {...props} integrationType={6} />
            case "facebook": return <FacebookCampaigns {...props} />
            default: return null;
        };
    };

    const checkDeps = (name) => {
        if (name === "canAdd") {
            if (curProfile?.type === "scale-track-social") return false;
        } else if (name === "canBulkDataChange") {
            if (curProfile?.type === "facebook" && urlParams?.["_admin-viewbyid"]) return true;
            
            if (
                curProfile?.type !== "midas" &&
                curProfile?.type !== "adnow"
            ) return false;
        } else if (name === "canImportFromProfile") {
            if (curProfile?.type !== "facebook" && curProfile?.type !== "mgid") return false;
        } else if (name === "canCheckAds") {
            if (curProfile?.type !== "facebook") return true;
        } else if (name === "canEditColumns") {
            if (userInfoSelector?.Flags?.isAdmin) return true;
            if (curProfile?.type === "facebook") return false;
            if (curProfile?.type === "tiktok") return false;
            return true;
        };
        return true;
    };

    const checkSidebarActive = (mustBeSelected = true, mustBeOnlyOne = true) => {
        if (!campaignFunctions) return false;
        if (!mustBeSelected) return true;

        if (!campaignFunctions.selectedData) return false;
        if (!Array.isArray(campaignFunctions.selectedData)) return false;
        if (mustBeOnlyOne) {
            if (campaignFunctions.selectedData.length === 1) return true;
        } else {
            if (campaignFunctions.selectedData.length > 0) return true;
        };

        return false;
    };

    const openMoreInfo = (e) => {
        if (!checkSidebarActive()) return;
        if (campaignFunctions.selectedData.length === 0) return;

        let tmp = campaignFunctions.selectedData[0];
        if (!tmp) return;

        if (!campaignFunctions.openItemMoreInfo) return;
        campaignFunctions.openItemMoreInfo(tmp, {currentTarget: e?.currentTarget?.parentNode});
    };

    const addCampaign = (e) => {
        if (!checkSidebarActive(false)) return;

        if (!campaignFunctions.addCampaign) return;
        campaignFunctions.addCampaign({currentTarget: e?.currentTarget?.parentNode});
    };

    const checkDuplicateAds = (e) => {
        if (!checkSidebarActive(false)) return;

        if (!campaignFunctions.checkDuplicateAds) return;
        campaignFunctions.checkDuplicateAds();
    };

    const getDropdownItems = (e) => {
        if (!checkSidebarActive(true, false)) return [];
        if (campaignFunctions.selectedData.length === 0) return [];

        if (!campaignFunctions.openDropdown) return [];
        return campaignFunctions.openDropdown();
    };

    const openDropdown = (e) => {
        if (!checkSidebarActive(true, false)) return [];
        if (campaignFunctions.selectedData.length === 0) return [];

        e.stopPropagation();
        e.preventDefault();
        e.target.parentNode.querySelector(".customComponents__buttonWithDropdown").click();
    };

    const editColumns = (e) => {
        if (!checkSidebarActive(false)) return;

        if (!campaignFunctions.editColumns) return;
        campaignFunctions.editColumns({currentTarget: e?.currentTarget?.parentNode});
    };

    const bulkDataChange = e => {
        if (!campaignFunctions.bulkDataChange) return;
        let tmp = campaignFunctions.selectedData;
        if (!tmp) return;

        campaignFunctions.bulkDataChange({currentTarget: e?.currentTarget?.parentNode}, tmp);
    };

    const importCampaignsFromAccount = e => {
        if (!campaignFunctions.importCampaignsFromAccount) return;

        campaignFunctions.importCampaignsFromAccount({currentTarget: e?.currentTarget?.parentNode});
    };

    React.useEffect(() => {
        setIsDropdownOpen(false);
    }, [curProfile]);

    React.useEffect(() => {
        setURLParams(getParamsFromURLObject(String(window.location)));
    }, []);

    return <div className="route__user__campaigns">
        <div className="route__user__campaigns__content">
            <div className="route__user__campaigns__content__filters">
                <FilterBySearch onChange={e => {
                    setSearchFilter(e);
                }} onKeyDown={e => {
                    // if (e.keyCode === 13) {
                    //     if (e?.target?.value) {
                    //         setCampaignKeywordFilters(ckf => [...ckf, e?.target?.value ?? ""]);
                    //         setSearchFilter();
                    //         e.target.value = "";
                    //     };
                    // };
                }} />
                <FilterByDate text="Campaign created at" defaultValue="all" onChange={setCampaignDateFilter} enable1h={true} enable4h={true} />
                <FilterByDate onChange={setDateFilter} defaultValue="today" disable24h={true} />

                {campaignKeywordFilters.length > 0 && <div className="route__user__campaigns__content__filters__pills">
                    {campaignKeywordFilters.map(ckf => {
                        return <p onClick={() => setCampaignKeywordFilters(f => f.filter(ckff => ckff !== ckf))}>
                            <span>{ckf}</span>
                            <img src="/images/icon_close.svg" />
                        </p>
                    })}
                </div>}
            </div>
            {getCampaignType()}
        </div>

        <div className={`route__user__campaigns__sidebar ${checkSidebarActive() ? "route__user__campaigns__sidebar--active" : ""}`}>
            {checkDeps("canAdd") && <div className="route__user__campaigns__sidebar__item route__user__campaigns__sidebar__item--active" onClick={addCampaign} title="Add campaign">
                <img src="/images/icon_add.svg" />
            </div>}
            {checkDeps("canCOmpare") && <div className={`route__user__campaigns__sidebar__item route__user__campaigns__sidebar__item--active route__user__campaigns__sidebar__item--toggle ${compareEnabled ? "route__user__campaigns__sidebar__item--toggleActive" : ""}`} onClick={() => setCompareEnabled(ce => !ce)} title="Compare to previous dates">
                <img src="/images/icon_toggleCompare.svg" />
            </div>}
            {checkDeps("canEditColumns") && <div className="route__user__campaigns__sidebar__item route__user__campaigns__sidebar__item--active" onClick={editColumns} title="Edit columns">
                <img src="/images/icon_editColumn.svg" />
            </div>}
            {checkDeps("canBulkDataChange") && <div className="route__user__campaigns__sidebar__item route__user__campaigns__sidebar__item--active" onClick={bulkDataChange} title="Bulk data change request">
                <img src="/images/icon_tableEdit.svg" />
            </div>}
            {checkDeps("canImportFromProfile") && <div className="route__user__campaigns__sidebar__item route__user__campaigns__sidebar__item--active" onClick={importCampaignsFromAccount} title="Clone campaigns from another profile / integration">
                <img src="/images/icon_importCamapigns.svg" />
            </div>}
            {checkDeps("canCheckAds") && <div className="route__user__campaigns__sidebar__item route__user__campaigns__sidebar__item--active" onClick={checkDuplicateAds} title="Check for duplicate ads">
                <img src="/images/icon_checkAds.svg" />
            </div>}
            <div className="route__user__campaigns__sidebar__item" onClick={openMoreInfo} title="View more info">
                <img src="/images/campaigns/campaigns_chart.svg" />
            </div>
            <div className={`route__user__campaigns__sidebar__item ${checkSidebarActive(true, false) ? "route__user__campaigns__sidebar__item--active" : ""} ${isDropdownOpen ? "route__user__campaigns__sidebar__item--enabled" : ""}`} title="More actions" onClick={openDropdown}>
                <img src="/images/icon_edit.svg" />
                <ButtonWithDropdown onUpdate={e => setIsDropdownOpen(!!e)} data={getDropdownItems()} maxHeight={600} />
            </div>
        </div>
    </div>
};

export default UserCampaigns;